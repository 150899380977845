import { FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Col, DatePicker } from 'antd';
import moment from 'moment';

import { TasksActivityPageHeaderProps } from './TasksActivityPageHeader.props';
import SubjectSelect from '../../SubjectSelect';

const TasksActivityPageHeader: FC<TasksActivityPageHeaderProps> = ({ router }) => {
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const partnerId = router.query.partner ? +router.query.partner : undefined;
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  return (
    <PageHeader
      title="Активность менеджеров"
      ghost={false}
      style={{ marginBottom: 16 }}
    >
      <Row gutter={12}>
        <Col>
          <SubjectSelect
            typeBrief={['EMPLOYEE']}
            placeholder="Выберите менеджера"
            style={{ width: 200 }}
            onChange={(manager) => onFilter('manager', manager)}
            value={managerId}
          />
        </Col>
        <Col>
          <SubjectSelect
            typeBrief={['ORGANIZATION', 'PARTNER']}
            placeholder="Партнер"
            style={{ width: 200 }}
            onChange={(partner) => onFilter('partner', partner)}
            value={partnerId}
          />
        </Col>
        <Col>
          <DatePicker
            value={(dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : undefined )}
            format="DD.MM.YYYY"
            onChange={(dateFrom) => onFilter('dateFrom', (dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined))}
          />
        </Col>
        <Col>
          <DatePicker
            value={(dateTo ? moment(dateTo, 'YYYY-MM-DD') : undefined )}
            format="DD.MM.YYYY"
            onChange={(dateTo) => onFilter('dateTo', (dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined))}
          />
        </Col>
      </Row>
    </PageHeader>
  );
};

export default withRouter(TasksActivityPageHeader);

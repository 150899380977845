import { FC } from 'react';
import { Affix } from 'antd';

import { CommentProps } from './Comment.props';
import CommentList from './CommentList';
import CommentTabs from './CommentTabs';

const Comment: FC<CommentProps> = ({
  objectId,
  objectTypeId,
  showList = true,
  showForm = true,
  entityType = null,
  swap = false,
  style = {},
  onAddTask = () => ({}),
  disabled = false,
}) => (
  <div style={style}>
    {swap ? (
      <>
        {showForm ? (
          <Affix offsetBottom={0}>
            <CommentTabs
              objectId={objectId}
              objectTypeId={objectTypeId}
              entityType={entityType}
              onAddTask={onAddTask}
            />
          </Affix>
        ) : null}
        {showList ? (
          <CommentList
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
          />
        ) : null}
      </>
    ) : (
      <>
        {showList ? (
          <CommentList
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            disabled={disabled}
          />
        ) : null}
        {showForm ? (
          <Affix offsetBottom={0}>
            <CommentTabs
              objectId={objectId}
              objectTypeId={objectTypeId}
              entityType={entityType}
              onAddTask={onAddTask}
            />
          </Affix>
        ) : null}
      </>
    )}
  </div>
);

export default Comment;

import { useState, useEffect, useRef, ChangeEvent, KeyboardEvent, FocusEvent, FC } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import { CalendarOutlined, CloseCircleFilled } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import moment, { Moment } from 'moment';
import cn from 'classnames';

import { limitNumber } from '~/utils';

const dateFormat = (val: string) => {
  const day = limitNumber(val.substring(0, 2), '31');
	const month = limitNumber(val.substring(2, 4), '12');
  const year = val.substring(4, 8);

  return day + (month.length ? '.' + month : '') + (year.length ? '.' + year : '');
};

const CustomDatePicker: FC<DatePickerProps> = ({
  value,
  defaultValue,
  onChange = () => ({}),
  style = {},
  placeholder,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const [innerValue, setInnerValue] = useState<Moment | null | undefined>();
  const clearIcon = useRef<any>();
  const [firstRender, setFirstRender] = useState(true);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setInnerValue(null);

      return;
    }

    if (e.target.value.length < 10) return;

    if (!moment(e.target.value, 'DD.MM.YYYY').isValid()) return;

    if (e.target.value === moment(innerValue).format('DD.MM.YYYY')) return;

    setInnerValue(moment(e.target.value, 'DD.MM.YYYY'));
  };

  const onPanelChange = (v: Moment | null) => {
    setInnerValue(v);
    setOpen(false);

    setTimeout(() => {
      input.current?.setSelectionRange(10, 10);
    }, 100);
  };

  const onClear = () => {
    setInnerValue(null);
  };

  const onInputClick = () => {
    if (!open) setOpen(true);
  };

  const onInputFocus = () => {
    setFocused(true);
  };

  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    setOpen(false);
    setFocused(false);

    if (e.relatedTarget && e.relatedTarget === clearIcon.current) {
      input.current?.focus();
    }
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const keycode = e.keyCode || e.which;

		if (keycode !== 13) return;

		if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
	};

  useEffect(() => {
    if (innerValue === undefined) return;

    onChange(innerValue, moment(innerValue).format('DD.MM.YYYY'));
  }, [innerValue]);

  useEffect(() => {
    if (firstRender) {
      setInnerValue(value || defaultValue);
      setFirstRender(false);
    } else {
      setInnerValue(value);
    }
  }, [value, defaultValue]);

  return (
    <div
      className={cn('ant-picker', { 'ant-picker-focused': focused })}
      style={style}
    >
      <div className="ant-picker-input">
        <NumberFormat
          format={dateFormat}
          value={innerValue ? moment(innerValue).format('DD.MM.YYYY') : ''}
          getInputRef={input}
          onFocus={onInputFocus}
          onClick={onInputClick}
          onBlur={onInputBlur}
          onChange={onInputChange}
          placeholder={placeholder}
          onKeyDown={onInputKeyDown}
        />
        <span className="ant-picker-suffix">
          <CalendarOutlined
            style={{ color: 'rgba(0, 0, 0, 0.25)' }}
          />
        </span>
        {innerValue ? (
          <span className="ant-picker-clear">
            <CloseCircleFilled
              ref={clearIcon}
              style={{ color: 'rgba(0, 0, 0, 0.25)' }}
              onClick={onClear}
            />
          </span>
        ) : null}
      </div>
      <DatePicker
        value={innerValue}
        onChange={onPanelChange}
        open={open}
        style={{
          visibility: 'hidden',
          width: 0,
          padding: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
        {...props}
      />
    </div>
  );
};

export default CustomDatePicker;

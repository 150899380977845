import { FC } from 'react';
import { Button, Popconfirm, message, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { 
  useGetFtranListLazyQuery, 
  useUpdateOrderByPkMutation, 
  useGetDocumentListLazyQuery, 
} from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ order, router }) => {

  // запрос получает все документы с оплатой
  const [getDocsPayment] = useGetDocumentListLazyQuery({
    variables: {
      where: {
        doc_type_id: { _eq: 3 },
        deleted: { _neq: true },
        order_id: { _eq: order.order_id }

      },
    },
    fetchPolicy: 'network-only'
  });
  const fetchDocsPayment = async () => {
    return new Promise((resolve, reject) => {
      getDocsPayment({
        onCompleted: resolve,
        onError: reject,
      });
    });
  };

  // запрос получает все документы с реализацией
  const [getDocsSale] = useGetDocumentListLazyQuery({
    variables: {
      where: {
        doc_type_id: { _eq: 4 },
        deleted: { _neq: true },
        order_id: { _eq: order.order_id }

      },
    },
    fetchPolicy: 'network-only'
  });
  const fetchDocsSale = async () => {
    return new Promise((resolve, reject) => {
      getDocsSale({
        onCompleted: resolve,
        onError: reject,
      });
    });
  };

  const [updateOrder, { loading: deleting }] = useUpdateOrderByPkMutation({
    onCompleted: () => {
      router.replace('/orders');
    },
    refetchQueries: ['getCurrentOrder']
  });

  const [getOrderCosts, { loading: checking }] = useGetFtranListLazyQuery({
    onCompleted: async (data) => {
      
      // получим все документы оплаты
      const docsPaymnets: any = await fetchDocsPayment();
      // получим все документы реализации
      const docsSales: any = await fetchDocsSale();
      if (docsPaymnets?.t_doc?.length > 0 || docsSales?.t_doc?.length > 0) {
        return message.error('Нельзя удалить сделку, где есть Оплата или УПД!');
      }

      if (data.t_ftran.length > 0) {
        return message.error('Данную сделку нельзя удалить, тк к ней привязаны Расходы!');
      }

      updateOrder({
        variables: {
          orderId: order.id,
          set: {
            deleted: true
          }
        }
      });
    },
    fetchPolicy: 'network-only'
  });

  const onConfirm = () => {
    getOrderCosts({
      variables: {
        where: {
          order_id: {
            _eq: order.id
          }
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Удалить заказ?"
      okText="Удалить заказ"
      cancelText="Передумал"
      onConfirm={onConfirm}
      disabled={deleting || checking}
      placement="bottomRight"
    >
      <Tooltip placement="bottomRight" title="Удалить заказ">
        <Button type="primary" danger icon={<DeleteOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

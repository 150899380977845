import { useState, FC } from 'react';
import { List, message } from 'antd';
import { useSession } from 'next-auth/react';

import { useGetCommentSubscription, useUpdateCommentByPkMutation } from '~/apollo/generated/hooks';
import { CommentListProps, CommentListQueryProps } from './CommentList.props';
import { TCommentProps } from "~/interfaces";
import Item from './Item';

const CommentList: FC<CommentListProps> = ({
  data = [],
  objectId,
  entityType = null,
  disabled = false,
}) => {
  const { data: session } = useSession();
  const [activeItemId, setActiveItemId] = useState(null);

  const [deleteComment] = useUpdateCommentByPkMutation({
    onCompleted: () => {
      setActiveItemId(null);
    },
    onError: (err) => {
      message.error('При удалении комментария произошла ошибка. Сообщение ошибки: ' + err.message, 10);
    }
  });

  const [updateComment, { loading }] = useUpdateCommentByPkMutation({
    onCompleted: () => {
      setActiveItemId(null);
    },
    onError: () => {
      message.error('Произошла ошибка при обновлении комментария');
    }
  });

  const onDeleteComment = (id: string) => {
    deleteComment({
      variables: {
        commentId: id,
        set: { deleted: true }
      }
    });
  };

  const onSaveComment = (text: string, item: TCommentProps) => {
    if (text === item.text) {
      setActiveItemId(null);
      return;
    }

    updateComment({
      variables: {
        commentId: item.id,
        set: {
          text,
          upd_date: 'now()',
          upd_user: session?.user.subject_id
        }
      }
    });
  };

  return (
    <List
      header={`${data.length} комментария`}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <Item
          item={item}
          opened={activeItemId === item.id}
          onOpen={(id) => setActiveItemId(id)}
          onSave={(text) => onSaveComment(text, item)}
          onCancel={() => setActiveItemId(null)}
          onRemove={onDeleteComment}
          loading={loading}
          objectId={objectId}
          entityType={entityType}
          disabled={disabled}
        />
      )}
    />
  );
};

const CommentListQuery: FC<CommentListQueryProps> = ({
  objectId,
  objectTypeId,
  entityType = null,
  disabled = false,
}) => {
  const { data } = useGetCommentSubscription({
    variables: {
      where: entityType === 'PARTNER'
        ? {
          comment_partner: {
            partner_id: {_eq: objectId}
          },
          deleted: { _neq: true }
        }
        : {
          object_id: { _eq: objectId },
          object_type_id: { _eq: objectTypeId },
          deleted: { _neq: true } // deleted: { _eq: false }
        }
    }
  });

  return (
    <CommentList
      data={data?.t_comment || []}
      objectId={objectId}
      entityType={entityType}
      disabled={disabled}
    />
  );
};

export default CommentListQuery;

import { FC } from 'react';
import { withRouter } from 'next/router';

import { OrganizationPageOrdersTableProps } from './OrganizationPageOrdersTable.props';
import { useGetOrderListQuery } from '~/apollo/generated/hooks';
import OrdersTable from '../../orders/OrdersTable';

const OrganizationPageOrdersTable: FC<OrganizationPageOrdersTableProps> = ({
  router,
}) => {
  const organizationId = router.query.id ? +router.query.id : undefined;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'customer') {
        return { customer: { name: value } };
      }

      if (key === 'order_sum') {
        return { orderline_actual_aggregate: { sum: { amount: value } } };
      }

      if (key === 'status') {
        return { status: { name: value } };
      }

      if (key === 'deal_owner') {
        return { deal_owner: { lastname: value } };
      }

      return { [key]: value };
    });
  }

  const { data } = useGetOrderListQuery({
    variables: {
      offset: (page - 1) * limit,
      limit,
      where: {
        deleted: { _eq: false },
        customer_id: {
          _eq: organizationId
        }
      },
      orderBy,
    }
  });

  const onPaginationChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <OrdersTable
      id="ID_ORGANIZATION_PAGE_ORDERS_TABLE"
      data={data?.order_list || []}
      pagination={{
        current: page,
        total: (data && data.order_list_count.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onPaginationChange}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
    />
  );
};

export default withRouter(OrganizationPageOrdersTable);

import { FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Calendar, Badge } from 'antd';
import moment, { Moment } from 'moment';

import {
  TasksCalendarPageCalendarProps,
  TasksCalendarPageCalendarQueryProps,
} from './TasksCalendarPageCalendar.props';
import { useGetTaskListCalendarQuery } from '~/apollo/generated/hooks';

import styles from './TasksCalendarPageCalendar.module.css';
import { useSession } from 'next-auth/react';

const CalendarCell = ({ data }: any) => (
  <ul className={styles.events}>
    {data.map((item: any) => (
      <li key={item.content}>
        <Link href={`/tasks/${item.id}`}>
          <a>
            <Badge
              status={item.type}
              text={item.content}
              className={styles.badge}
            />
          </a>
        </Link>
      </li>
    ))}
  </ul>
);

const TasksCalendarPageCalendar: FC<TasksCalendarPageCalendarProps> = ({
  data
}) => {
  const getListData = (value: Moment) => {
    const filteredTasks = data.filter((task) => {
      return task.deadline && value.isSame(moment(task.deadline), 'days');
    });

    const listData = filteredTasks.map((task) => {
      return {
        id: task.id,
        type: task.status?.name === 'Новая' ? 'success' : 'error',
        content: task.object_subject && task.object_subject.name || task.description || '-'
      };
    });

    return listData;
  };

  const getMonthData = (value: Moment) => {
    const filteredTasks = data.filter((task) => {
      return task.deadline && value.isSame(moment(task.deadline), 'month');
    });

    const listData = filteredTasks.map((task) => {
      return {
        id: task.id,
        type: task.status?.name === 'Новая' ? 'success' : 'error',
        content: task.object_subject && task.object_subject.name || task.description || '-'
      };
    });

    return listData;
  };

  const dateCellRender = (value: Moment) => {
    const listData = getListData(value);

    return <CalendarCell data={listData} />;
  };

  const monthCellRender = (value: Moment) => {
    const listData = getMonthData(value);

    return <CalendarCell data={listData} />;
  };

  return (
    <Calendar
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
    />
  );
};

const TasksCalendarPageCalendarQuery: FC<TasksCalendarPageCalendarQueryProps> = ({
  router
}) => {
  const statusId = router.query.status ? +router.query.status : undefined;
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const { data: session } = useSession();

  const { data } = useGetTaskListCalendarQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        owner_id: { _eq: managerId || session?.user.subject_id },
        task_type: {
          brief: { _eq: 'TASK' }
        },
        status_id: statusId ? {
          _eq: statusId
        } : undefined
      }
    }
  });

  return <TasksCalendarPageCalendar data={data?.t_task || []} />;
};

export default withRouter(TasksCalendarPageCalendarQuery);

import { gql } from '@apollo/client';

export const ADD_ARRAY_TASK_LINK = gql`
  mutation addArrayTaskLink($links: [t_task_link_insert_input!]!) {
    task_link: insert_t_task_link(objects: $links) {
      affected_rows
      returning {
        link_type_id
      }
    }
  }
`;

export const DELETE_ARRAY_TASK_LINK = gql`
  mutation deleteArrayTaskLink($linksId: [bigint!]!) {
    delete_t_task_link(where: {task_link_id: {_in: $linksId}}) {
      affected_rows
    }
  }
`;

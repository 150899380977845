import { FC } from 'react';
import { Tooltip, Popconfirm, message } from 'antd';
import { withRouter } from 'next/router';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useUpdateTaskByPkMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({
  task,
  router,
}) => {
  const [updateTask, { loading }] = useUpdateTaskByPkMutation({
    onCompleted: async () => {
      router.replace('/tasks');
    },
    onError: () => {
      message.error('При удалении задачи произошла ошибка');
    },
  });

  const onConfirm = () => {
    updateTask({
      variables: {
        taskId: task.id,
        set: {
          deleted: true
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Уверены?"
      onConfirm={onConfirm}
      disabled={loading}
      >
      <Tooltip title="Удалить">
        <DeleteOutlined
          disabled={false}
          style={{ color: loading ? 'rgba(0, 0, 0, 0.25)' : '#ff4d4f' }}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default withRouter(DeleteButton);

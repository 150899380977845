import { useState, FC } from 'react';
import Link from 'next/link';
import { List, Switch, Tooltip, Space, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { truncate } from 'lodash';

import { ContactListProps } from './ContactList.props';
import {
  useGetContactListQuery,
  useUpdateSubjectByPkMutation,
} from '~/apollo/generated/hooks';
import ClientsDrawer from '../../../clients/ClientsDrawer';
import PartnerEditableInput from '../../PartnerEditableInput';
import { Order_By } from '~/interfaces';

const ContactList: FC<ContactListProps> = ({
  partner
}) => {
  const [currentContactId, setCurrentContactId] = useState<number | undefined>();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const { data, loading, client } = useGetContactListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'CONTACT_PERSON' }
        },
        _or: [
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              subject_object: {
                subject_type: {
                  brief: { _eq: 'ORGANIZATION' }
                },
                object_subject_links: {
                  t_link_type: { brief: { _eq: 'PARTNER_ORGANIZATION' } },
                  t_object_type: { brief: { _eq: 'SUBJECT' } },
                  subject_id: { _eq: partner.id }
                }
              }
            }
          },
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              object_id: { _eq: partner.id }
            }
          }
        ]
      },
      orderBy: {
        is_active: Order_By.Desc
      }
    }
  });

  const [updateContact] = useUpdateSubjectByPkMutation({
    refetchQueries: ['getContactList']
  });

  const onAddNewContact = () => {
    client.refetchQueries({
      include: ['getContactList']
    });
    setVisibleDrawer(false);
  };

  const showContact = (id: number) => {
    setCurrentContactId(id);
    setVisibleDrawer(true);
  };

  const closeContact = () => {
    setCurrentContactId(undefined);
    setVisibleDrawer(false);
  };

  return (
    <div>
      {data?.contact_list.length ? (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data.contact_list}
          renderItem={(contact) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <>
                    <Tooltip title={contact.is_active ? 'Активный' : 'Не активный'} />
                    <Switch
                      checked={contact.is_active || false}
                      size="small"
                      onChange={(active) => {
                        updateContact({
                          variables: {
                            subjectId: contact.id,
                            subject: { is_active: !!active }
                          }
                        });
                      }}
                    />
                  </>
                }
                title={
                  <Space>
                    <Link href={`/contacts/${contact.id}`}>
                      <a style={{ color: '#0094FF' }}>
                        {contact.name} {contact.lastname || ''}
                      </a>
                    </Link>

                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => showContact(contact.id)}
                    />
                  </Space>
                }
                description={
                  <>
                    {contact.post ? (
                      <div>Должность: {contact.post}</div>
                    ) : null}
                    {contact.email ? (
                      <div>
                        <Space>
                          Email:
                          <PartnerEditableInput
                            defaultValue={contact.email || '-'}
                            column="email"
                            subjectId={contact.id}
                            onChange={() => {
                              client.refetchQueries({ include: ['getContactList'] });
                            }}
                          />
                        </Space>
                      </div>
                    ) : null}
                    {contact.phone ? (
                      <div>
                        <Space>
                          Телефон:
                          <PartnerEditableInput
                            defaultValue={contact.phone || '-'}
                            column="phone"
                            subjectId={contact.id}
                            onChange={() => {
                              client.refetchQueries({ include: ['getContactList'] });
                            }}
                          />
                        </Space>
                      </div>
                    ) : null}
                    {contact.address ? (
                      <div>{contact.address}</div>
                    ) : null}
                    {contact.info ? (
                      <div>{truncate(contact.info, { length: 50 })}</div>
                    ) : null}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : null}

      {partner.id ? (
        <ClientsDrawer
          organizationId={partner.id}
          contactId={currentContactId}
          visible={visibleDrawer}
          onClose={closeContact}
          onSave={onAddNewContact}
        />
      ) : null}
    </div>
  );
};

export default ContactList;

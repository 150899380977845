import { FC } from 'react';
import { Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useRemoveFTranMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ id }) => {
  if (!id) return null;

  const [removeFTran, { loading }] = useRemoveFTranMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getFtranList', 'getOrderDocTotals']
  });

  return (
    <Popconfirm
      title="Удалить списание по документу? (документ удален не будет!)"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => removeFTran({ variables: {
        ftranId: id
      } })}
      disabled={loading}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined style={{ color: '#EB2F96' }} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

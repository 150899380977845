import { FC } from 'react';
import { List, Space } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { TaskFileListProps } from './TaskFileList.props';

const TaskFileList: FC<TaskFileListProps> = ({
  task
}) => (
  <>
    <div>Файлы:</div>
    <List
      rowKey="id"
      size="small"
      dataSource={task.gdrive_file_list}
      renderItem={(item: any, i) => (
        <List.Item key={item.id || i}>
          <Space>
            <LinkOutlined />
            <a href={item.url}>{item.name}</a>
          </Space>
        </List.Item>
      )}
    />
  </>
);

export default TaskFileList;

import { useState, FC } from 'react';
import cn from 'classnames';

import styles from './Row.module.css';

import Cell from '../Cell';
import { CellProps, RowProps } from './Row.props';

const Row: FC<RowProps> = ({
  index,
  rows,
  row,
  activeRowId,
  activeCellId,
  onCellClickHandler,
  className,
  style,
  getRowProps,
  getColumnProps,
  getCellProps,
  ...props
}) => {
	const [hover, setHover] = useState(false);

	const onMouseEnterHandler = () => {
		setHover(true);
	};

	const onMouseLeaveHandler = () => {
		setHover(false);
	};

	return (
		<div
			onMouseEnter={onMouseEnterHandler}
			onMouseLeave={onMouseLeaveHandler}
      {...row.getRowProps([
        {
          ...props,
          className: cn(
            row.getRowProps().className,
            styles.row,
            className,
            {
              [styles.hover]: hover === true,
              // [styles.active]: row.id === activeRowId,
              [styles.last]: index === rows.length - 1,
            }
          ),
          style: {
            ...row.getRowProps().style,
            ...style,
          },
        },
        getRowProps(row)
      ])}
		>
			{row.cells.map((cell: CellProps, k: number) => (
        <Cell
          {...cell.getCellProps([
            {
              className: cell.column.className,
              style: cell.column.style,
            },
            getColumnProps(cell.column),
            getCellProps(cell)
          ])}
          isActive={row.id === activeRowId && cell.column.id === activeCellId}
          isLast={k === row.cells.length - 1}
          onClick={() => {
            if (typeof getCellProps(cell).onClick === 'function') {
              getCellProps(cell).onClick();
            }
            onCellClickHandler(cell);
          }}
          key={k}
        >
          {cell.render('Cell')}
        </Cell>
      ))}
		</div>
	);
};

export default Row;

import { FC } from 'react';
import Link from 'next/link';
import { Row, Avatar } from 'antd';
import { FireOutlined, UserOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { ListItemProps } from './ListItem.props';
import Form from '../Form';

import styles from './ListItem.module.css';

const ListItem: FC<ListItemProps> = ({
  data,
  opened = false,
  onCancel = () => ({}),
  onOpen = () => ({}),
  onSave = () => ({}),
  onRemove = () => ({}),
}) => (
  <div>
    {opened ? (
      <Form
        data={data}
        onCancel={onCancel}
        onSave={onSave}
        onRemove={onRemove}
      />
    ) : (
      <Row justify="space-between" align="middle">
        {data.important ? (
          <FireOutlined
            style={{ color: 'red', marginRight: 8 }}
          />
        ) : null}
        <div
          className={styles.wrap}
          onClick={() => onOpen(data.id)}
        >
          <div
            className={cn({ [styles.completed]: data.completed })}
          >
            {data.title}
          </div>
        </div>
        {data.owner?.id || data.date || data.time ? (
          <div style={{ marginLeft: 8 }}>
            {data.owner?.id ? (
              <Row justify="end">
                <Avatar size={24} icon={<UserOutlined />} style={{ marginRight: 8 }} />
                <Link href={`/employees/${data.owner.id}`}>
                  <a>{data.owner.name} {data.owner.lastname}</a>
                </Link>
              </Row>
            ) : null}
            {data.date ? (
              <div className={styles.date}>
                до {data.date} {data.time}
              </div>
            ) : null}
          </div>
        ) : null}
      </Row>
    )}
  </div>
);

export default ListItem;

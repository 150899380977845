import { FC } from 'react';
import { PageHeader } from 'antd';

import LogisticsTabs from './LogisticsTabs';

const LogisticsPageHeader: FC = () => (
  <PageHeader
    title="Логистика"
    ghost={false}
    style={{ marginBottom: 16 }}
    footer={
      <LogisticsTabs />
    }
  />
);

export default LogisticsPageHeader;

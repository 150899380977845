import { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddButton: FC<ButtonProps> = ({
  type = 'link',
  size = 'small',
  icon,
  ...props
}) => (
  <Button
    type={type}
    size={size}
    icon={icon ? icon :(
      <PlusOutlined
        style={{
          fontSize: 11,
          verticalAlign: 0
        }}
      />
    )}
    {...props}
  >
    Привязать расходный документ
  </Button>
);

export default AddButton;

import { FC } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { NewVersionButtonProps } from './NewVersionButton.props';
import { useNewOrderVersionLazyQuery } from '~/apollo/generated/hooks';

const NewVersionButton: FC<NewVersionButtonProps> = ({ order }) => {
  const client = useApolloClient();

  const [newOrderVersion, { loading }] = useNewOrderVersionLazyQuery({
    onCompleted: () => {
      client.refetchQueries({
        include: ['getCurrentOrder', 'getOrderVersions']
      });
    },
    fetchPolicy: 'network-only',
  });

  const onConfirm = () => {
    newOrderVersion({
      variables: { orderId: order.id }
    });
  };

  return (
    <Popconfirm
      title="Cоздать новую версию?"
      okText="Создать"
      cancelText="Передумал"
      onConfirm={onConfirm}
      disabled={loading}
      placement="bottom"
    >
      <Tooltip placement="bottom" title="Создать версию">
        <Button icon={<FileAddOutlined />}>Создать версию</Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default NewVersionButton;

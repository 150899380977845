import { gql } from '@apollo/client';

export const ADD_SUBJECT_PROP_LIST = gql`
  mutation addSubjectPropList($objects: [t_subject_prop_insert_input!]!) {
    subject_prop_list: insert_t_subject_prop(objects: $objects) {
      returning {
        id: subject_prop_id
      }
    }
  }
`;

export const ADD_SUBJECT_PROP = gql`
  mutation addSubjectProp($prop: t_subject_prop_insert_input!) {
    insert_t_subject_prop_one(object: $prop) {
      subject_prop_id
    }
  }
`;

export const UPDATE_SUBJECT_PROP = gql`
  mutation updateSubjectProp($set: t_subject_prop_set_input, $where: t_subject_prop_bool_exp!) {
    update_t_subject_prop(where: $where, _set: $set) {
      returning {
        id: subject_prop_id
        subject_prop_id
      }
    }
  }
`;

export const DELETE_SUBJECT_PROP = gql`
  mutation deleteSubjectProp($where: t_subject_prop_bool_exp!) {
    delete_t_subject_prop(where: $where) {
      affected_rows
    }
  }
`;

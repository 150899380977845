import { FC } from 'react';
import { Radio, Tooltip } from 'antd';
import {
  PhoneOutlined,
  EnvironmentOutlined,
  BookOutlined,
  CalendarOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { TaskTypeRadioProps, TaskTypeRadioQueryProps } from './TaskTypeRadio.props';

const TaskTypeRadio: FC<TaskTypeRadioProps> = ({
  data,
  value,
  onChange = () => ({}),
}) => (
  <Radio.Group
    value={value}
    onChange={onChange}
    optionType="button"
    buttonStyle="solid"
  >
    {data.map((type: any) => (
      <Tooltip
        title={type.value}
        placement="bottom"
      >
        <Radio.Button value={type.id}>
          {type.brief === 'ZVONOK' ? <PhoneOutlined /> : null}
          {type.brief === 'VSTRECHA' ? <EnvironmentOutlined /> : null}
          {type.brief === 'NAPOMINANIE' ? <BookOutlined /> : null}
          {type.brief === 'SOBITIE' ? <CalendarOutlined /> : null}
          {type.brief === 'PISMO' ? <MailOutlined /> : null}
        </Radio.Button>
      </Tooltip>
    ))}
  </Radio.Group>
);

const TaskTypeRadioQuery: FC<TaskTypeRadioQueryProps> = ({
  value,
  onChange = () => ({})
}) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'TIP_ZADACHI' }
        }
      }
    }
  });

  return (
    <TaskTypeRadio
      data={data?.t_prop_value || []}
      value={value}
      onChange={onChange}
    />
  );
};

export default TaskTypeRadioQuery;

import { FC } from 'react';
import { Row } from 'antd';
import { CellProps } from 'react-table';
import cn from 'classnames';

import styles from './ExpanderCell.module.css';

const ExpanderCell: FC<CellProps<any>> = ({ row }) => (
  <button
    {...row.getToggleRowExpandedProps()}
    className={styles.button}
  >
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <span
        className={cn(styles.icon, {
          [styles.active]: row.isExpanded
        })}
      />
    </Row>
  </button>
);

export default ExpanderCell;

import { useMemo, FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { withRouter } from 'next/router';
import { Row, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';

import { useGetEmployeesCountQuery, useGetEmployeesListQuery } from '~/apollo/generated/hooks';
import { EmployeesPageTableProps, EmployeesPageTableQueryProps } from './EmployeesPageTable.props';
import { TEmployeeSubject } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { Table } from '~/components';

const EmployeesPageTable: FC<EmployeesPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'ФИО',
        accessor: 'name',
        width: 200,
        Cell: ({ row }: CellProps<TEmployeeSubject>) => (
          <span>
            <Link href={`/employees/${row.original.id}`}>
              <a>
                <Row align="middle">
                  {row.original.avatar ? (
                    <Avatar
                      size={24}
                      src={
                        <Image
                          src={`http://oasis.retailsuite.ru/${row.original.avatar}?width=50`}
                          width={24}
                          height={24}
                          alt=""
                        />
                      }
                    />
                  ) : (
                    <Avatar size={24} icon={<UserOutlined />} />
                  )}
                  <span style={{ marginLeft: 8 }}>
                    {row.original.name || ''} {row.original.lastname || ''}
                  </span>
                </Row>
              </a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Филиал',
        accessor: 'branch',
        sorter: true,
      },
      {
        Header: 'Должность',
        accessor: 'dolznost',
        Cell: ({ value }: CellProps<TEmployeeSubject, TEmployeeSubject['dolznost']>) => (
          <span>
            {value.map((d) => d.prop_value?.value)}
          </span>
        )
      },
      {
        Header: 'Контакты',
        accessor: 'contacts',
        Cell: ({ row }: CellProps<TEmployeeSubject>) => (
          <span style={{ lineHeight: '16px', display: 'flex', alignItems: 'center' }}>
            { row.original.phone }
            { row.original.phone && row.original.email ? <br /> : null}
            { row.original.email }
          </span>
        )
      },
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const EmployeesPageTableQuery: FC<EmployeesPageTableQueryProps> = ({ router, ...props }) => {
  const search = router.query.search ? router.query.search : undefined;
  const page = router.query.page ? +router.query.page : 1;
  const archive = router.query.archive ? router.query.archive : false;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      return { [key]: value };
    });
  }

  const { data } = useGetEmployeesListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        is_active: { _eq: !archive },
        subject_type: {
          brief: { _eq: 'EMPLOYEE' },
        },
        name: {
          _ilike:
            (search && `%${search.toString().replace(/(\s|'|")/g, "")}%`) ||
            null,
        },
      },
      limit,
      offset: (page - 1) * limit,
      orderBy,
    }
  });

  const { data: dataCount } = useGetEmployeesCountQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        is_active: { _eq: !archive },
        subject_type: {
          brief: { _eq: "EMPLOYEE" },
        },
        name: {
          _ilike:
            (search && `%${search?.toString().replace(/(\s|'|")/g, "")}%`) ||
            null,
        },
      },
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <EmployeesPageTable
      id="ID_EMPLOYEES_PAGE_TABLE"
      data={data?.employee_list || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.employee.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      {...props}
    />
  );
};

export default withRouter(EmployeesPageTableQuery);

import { FC } from 'react';
import { withRouter } from 'next/router';
import { Card, Space } from 'antd';
import { useApolloClient } from '@apollo/client';

import { PartnerPageBasicContentProps } from './PartnerPageBasicContent.props';
import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
import Comment from '../../Comment';
import TaskList from './TaskList';

const PartnerPageBasicContent: FC<PartnerPageBasicContentProps> = ({
  router
}) => {
  const { id } = router.query;
  const client = useApolloClient();

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = data.partner_list[0];

  if (!partner) return null;

  const onAddTask = () => {
    client.refetchQueries({
      include: ['getTaskListPartner']
    });
  };

  return (
    <div>
      <TaskList partnerId={+id} />
      <Card bodyStyle={{ padding: 5 }}>
        <Space size={5}>
        {partner.info}
          <Space size={5} v-if="partner.created_by_subject">
            Партнера добавил:
            <Space size={5}>
              { partner.created_by_subject?.name  }
              { partner.created_by_subject?.lastname  }
              { partner.ins_date_format }
            </Space>
          </Space>
        </Space>
      </Card>
      <Comment
        entityType="PARTNER"
        objectId={+id}
        objectTypeId={3}
        swap
        onAddTask={onAddTask}
      />
    </div>
  );
};

export default withRouter(PartnerPageBasicContent);

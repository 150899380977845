import { Fragment, useState, FC } from 'react';
import { Cell as CellProps } from 'react-table';

import { BodyProps, RowProps, ColumnProps } from './Body.props';
import Row from '../Row';

import styles from './Body.module.css';

const Body: FC<BodyProps> = ({
  rows,
  prepareRow,
  getBodyProps,
  getRowProps,
  getColumnProps,
  getCellProps,
  renderRowSubComponent,
}) => {
  const [activeRowId, setActiveRowId] = useState<RowProps['id']>();
  const [activeCellId, setActiveCellId] = useState<ColumnProps['id']>();

  const onCellClickHandler = ({ row, column }: CellProps<any>) => {
    setActiveRowId(row.id);
    setActiveCellId(column.id);
  };

	return (
    <div {...getBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <Fragment key={index}>
            <Row
              key={index}
              index={index}
              rows={rows}
              row={row}
              activeRowId={activeRowId}
              activeCellId={activeCellId}
              onCellClickHandler={onCellClickHandler}
              getRowProps={getRowProps}
              getColumnProps={getColumnProps}
              getCellProps={getCellProps}
            />

            {row.isExpanded ? (
              <div className={styles.expanded}>
                {renderRowSubComponent({ row })}
              </div>
            ) : null}
          </Fragment>
        );
      })}
    </div>
	);
};

export default Body;

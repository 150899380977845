import { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import styles from './Logo.module.css';

const Logo: FC = () => {
  return (
    <div className={styles.logo}>
      <Link href="/">
        <a className={styles.link}>
          <Image
            src="/images/grasias_logo.png"
            width={135}
            height={48}
            alt="Лого Grasias"
          />
        </a>
      </Link>
    </div>
  );
};

export default Logo;

import { useState, FC } from 'react';
import cn from 'classnames';

import styles from './Cell.module.css';

import { CellProps } from './Cell.props';

const Cell: FC<CellProps> = ({
  isActive = false,
  isLast = false,
  className,
  children,
  ...props
}) => {
	const [hover, setHover] = useState(false);

	const onMouseEnterHandler = () => {
		setHover(true);
	};

	const onMouseLeaveHandler = () => {
		setHover(false);
	};

	return (
		<div
			className={cn(styles.cell, className, {
				[styles.hover]: hover === true,
				[styles.active]: isActive === true,
				[styles.last]: isLast === true,
			})}
			onMouseEnter={onMouseEnterHandler}
			onMouseLeave={onMouseLeaveHandler}
			{...props}
		>
			{children}
		</div>
	);
};

export default Cell;

import { FC } from 'react';
import { Tabs } from 'antd';
import {
  BookOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import { CommentTabsProps } from './CommentTabs.props';

import styles from './CommentTabs.module.css';
import CommentForm from './CommentForm';
import TaskForm from './TaskForm';
import ReminderForm from './ReminderForm';
import CallForm from './CallForm';
import MeetForm from './MeetForm';
import EventForm from './EventForm';

const CommentTabs: FC<CommentTabsProps> = ({
  objectId,
  objectTypeId,
  entityType = null,
  onAddTask = () => ({}),
}) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Tabs type="card">
        <Tabs.TabPane key="1" tab="Комментарий">
          <CommentForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Задача">
          <TaskForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            onAddTask={onAddTask}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={<><BookOutlined />Напоминание</>}>
          <ReminderForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            onAddTask={onAddTask}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab={<><PhoneOutlined />Звонок</>}>
          <CallForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            onAddTask={onAddTask}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="5" tab={<><EnvironmentOutlined />Встреча</>}>
          <MeetForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            onAddTask={onAddTask}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="6" tab={<><CalendarOutlined />Событие</>}>
          <EventForm
            objectId={objectId}
            objectTypeId={objectTypeId}
            entityType={entityType}
            onAddTask={onAddTask}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  </div>
);

export default CommentTabs;

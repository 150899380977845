import { useMemo, FC, useEffect } from 'react';
import { Modal } from 'antd';
import { Row as RowProps, CellProps } from 'react-table';

import { OrdersTableProps, OrdersModalProps } from './OrdersModal.props';
import { Table } from '~/components';
import { TDocProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { roubleFormatter } from '~/utils';
import { useGetDocumentListLazyQuery } from '~/apollo/generated/hooks';

const OrdersTable: FC<OrdersTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Номер заказа',
        id: 'id',
        Cell: ({ row }: CellProps<TDocProps['ftrans'][0]>) => (
          <span>{row.original.order?.order_id}</span>
        )
      },
      {
        Header: 'Конрагент',
        id: 'customer',
        Cell: ({ row }: CellProps<TDocProps['ftrans'][0]>) => (
          <span>{row.original.order?.customer?.name}</span>
        )
      },
      {
        Header: 'Привязано к заказу, р',
        accessor: 'amount',
        Cell: ({ value }: CellProps<TDocProps['ftrans'][0], TDocProps['ftrans'][0]['amount']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: 'Комментарий',
        accessor: 'description',
      },
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      getRowProps={(row: RowProps<TDocProps['ftrans'][0]>) => ({
        onClick: () => window.open(`/orders/${row.original.order?.order_id}`)
      })}
      {...props}
    />
  );
};

const OrdersModal: FC<OrdersModalProps> = ({
  docId,
  visible = false,
  onClose = () => ({}),
  onOk = () => ({})
}) => {
  const [getOrders, { data }] = useGetDocumentListLazyQuery();

  useEffect(() => {
    if (!visible) return;

    getOrders({
      variables: {
        where: {
          doc_id: {
            _eq: docId
          }
        }
      }
    });
  }, [visible, docId]);

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      width={950}
    >
      <OrdersTable
        id="ID_VENDOR_DOCS_PAGE_TABLE_ORDERS_MODAL_TABLE"
        data={data?.t_doc[0].ftrans || []}
      />
    </Modal>
  );
};

export default OrdersModal;

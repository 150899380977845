import { useState, useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { OrderPageDeliveryTableProps, OrderPageDeliveryTableQueryProps } from './OrderPageDeliveryTable.props';
import Table from '../../Table';
import DeliveryRequestDrawer from '../../delivery/DeliveryRequestDrawer';
import { useGetCurrentOrderQuery, useGetTasksByOrderIdQuery, useGetTasksCountByOrderIdQuery } from '~/apollo/generated/hooks';
import { TTaskByOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const OrderPageDeliveryTable: FC<OrderPageDeliveryTableProps> = ({
  order,
  data,
  ...props
}) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
      },
      {
        Header: 'Текущий статус',
        accessor: 'status',
        Cell: ({ value }: CellProps<TTaskByOrderProps, TTaskByOrderProps['status']>) => (
          <span>{value?.name}</span>
        )
      },
      {
        Header: 'Дата начала',
        accessor: 'date_start_string',
      },
      {
        Header: 'Дедлайн',
        accessor: 'deadline_string',
      },
      {
        Header: 'Получатель',
        accessor: 'subject',
        Cell: ({ value }: CellProps<TTaskByOrderProps, TTaskByOrderProps['subject']>) => (
          <span>{value?.name}</span>
        )
      },
    ],
    []
  );

  return (
    <>
      {order?.archived ? null : (
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          style={{ marginBottom: 16 }}
          onClick={() => setVisibleDrawer(true)}
        >
          Запланировать доставку
        </Button>
      )}
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <DeliveryRequestDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getTasksByOrderId', 'getTasksCountByOrderId']
          });
          setVisibleDrawer(false);
        }}
        objectId={order?.id}
      />
    </>
  );
};

const OrderPageDeliveryTableQuery: FC<OrderPageDeliveryTableQueryProps> = ({
  router,
  ...props
}) => {
  const { id } = router.query;
  const page = router.query.page_shipment ? +router.query.page_shipment : 1;
  const limit = 10;

  if (!id) return null;

  const { data: order } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  const { data: dataDelivery } = useGetTasksByOrderIdQuery({
    variables: {
      orderId: id,
      limit,
      offset: (page - 1) * limit,
      taskTypeBrief: 'DELIVERY_REQUEST',
      objectTypeBrief: 'ORDER',
    }
  });

  const { data: dataCount } = useGetTasksCountByOrderIdQuery({
    variables: {
      taskTypeBrief: 'DELIVERY_REQUEST',
      objectTypeBrief: 'ORDER',
      orderId: id
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <OrderPageDeliveryTable
      id="ID_ORDER_PAGE_DELIVERY_TABLE"
      order={order?.t_order[0]}
      data={dataDelivery?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      {...props}
    />
  );
};

export default withRouter(OrderPageDeliveryTableQuery);

import { FC, useCallback } from 'react';
import { withRouter } from 'next/router';
import { message } from 'antd';
import { Row as RowProps, Cell } from 'react-table';

import { OrderPageProductsTableProps } from './OrderPageProductsTable.props';
import { useGetOrderlineListQuery, useUpdateOrderlineMutation } from '~/apollo/generated/hooks';
import { Order_By, TOrderlineProps } from '~/interfaces';
import OrderProductsTable from '../OrderProductsTable';
import ExpenseTable from '../OrderProductsTable/ExpenseTable/ExpenseTable';
import DocsTable from '../OrderProductsTable/DocsTable/DocsTable';

const OrderPageProductsTableQuery: FC<OrderPageProductsTableProps> = ({
  router,
  disabled = false
}) => {
  const { id } = router.query;
  const page = router.query.page ? +router.query.page : 1;

  const limit = 40;

  if (!id) return null;

  const getVariables = () => {
    return {
      where: {
        deleted: { _eq: false },
        order_id: { _eq: +id }
      },
      orderBy: {
        rownum: Order_By.Asc
      },
      limit,
      offset: (page - 1) * limit
    };
  };

  const { data } = useGetOrderlineListQuery({
    variables: getVariables()
  });

  const [updateOrderline] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onUpdateTableData = (cell: Cell<TOrderlineProps>, value: any) => {
    updateOrderline({
      variables: {
        orderlineId: cell.row.original.id,
        set: {
          [cell.column.id]: value,
        }
      }
    });
  };

  const renderRowSubComponent = useCallback(
    ({ row }: { row: RowProps<TOrderlineProps> }) => (
      <div style={{ paddingLeft: 91 }}>
        <ExpenseTable data={row.original} rowId={row.id} disabled={disabled} />
        <br />
        <br />
        <DocsTable orderId={+id} data={row.original} rowId={row.id} disabled={disabled} />
      </div>
    ),
    [id, disabled]
  );

  return (
    <OrderProductsTable
      id="ID_ORDER_PAGE_PRODUCTS_TABLE"
      data={data?.orderline_list || []}
      pagination={{
        current: page,
        total: (data && data.count.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      onUpdateTableData={onUpdateTableData}
      renderRowSubComponent={renderRowSubComponent}
      getRowId={(row: TOrderlineProps, relativeIndex, parent) =>
        parent ? [parent.id, relativeIndex].join('.') : row.id.toString()
      }
      disabled={disabled}
    />
  );
};

export default withRouter(OrderPageProductsTableQuery);



import { useState, useEffect, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Form, Input, Button, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { OrganizationsPageHeaderProps } from './OrganizationsPageHeader.props';
import OrganizationsAddModal from '../OrganizationsAddModal';

const { Search } = Input;

const OrganizationsPageHeader: FC<OrganizationsPageHeaderProps> = ({
  router
}) => {
  const client = useApolloClient();
  const [form] = Form.useForm();
  const [visibleModal, setVisibleModal] = useState(false);
  const search = router.query.search?.toString();
  const visible = router.query.visible?.toString() || 'my';

  const onSearch = (text: string) => {
    router.replace({
      query: {
        ...router.query,
        visible: text ? 'all': undefined,
        search: text || undefined,
        page: 1,
      }
    });
  };

  const onChangeVisible = (value: string) => {
    router.replace({
      query: {
        ...router.query,
        visible: value === 'my' ? undefined : value,
        search: undefined,
        page: 1,
      }
    });
  };

  const onAddNewOrganization = () => {
    setVisibleModal(false);
    client.refetchQueries({
      include: ['getCompanyList', 'getCountCompany']
    });
  };

  useEffect(() => {
    form.setFieldsValue({ search, visible });
  }, [router.query]);

  return (
    <>
      <PageHeader
        title="Организации"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Form
          form={form}
          layout="inline"
          initialValues={{ search, visible }}
        >
          <Form.Item>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setVisibleModal(true)}
            >
              Добавить
            </Button>
          </Form.Item>
          <Form.Item name="search">
            <Search
              style={{ width: 250 }}
              allowClear
              placeholder="Поиск"
              onSearch={onSearch}
            />
          </Form.Item>
          <Form.Item name="visible">
            <Select
              style={{ width: 80 }}
              onChange={onChangeVisible}
            >
              <Select.Option value="all">Все</Select.Option>
              <Select.Option value="my">Мои</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </PageHeader>

      <OrganizationsAddModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onSave={onAddNewOrganization}
      />
    </>
  );
};

export default withRouter(OrganizationsPageHeader);

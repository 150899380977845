import { useState, FC } from 'react';
import Link from 'next/link';
import { Card, List, Space, Button, Form, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { SubjectSelect } from '~/components';
import { CustomerProps } from './Customer.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';

const Customer: FC<CustomerProps> = ({
  order,
  bordered = false,
  bodyStyle = { padding: '6px 0' },
  ...props
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const [updateCustomer, { loading }] = useUpdateOrderByPkMutation({
    onCompleted: () => {
      setEdit(false);
    },
    onError: (error) => {
      message.error(`
        При обновлении заказа произошла ошибка. Сообщение ошибки: ${error.message}
      `);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onSave = (values: any) => {
    if (values.customer === order.customer_id) {
      setEdit(false);
      return;
    }

    updateCustomer({
      variables: {
        set: {
          customer_id: values.customer,
          upd_date: 'now()'
        },
        orderId: order.id
      }
    });
  };

  const onCancel = () => {
    form.resetFields();
    setEdit(false);
  };

  return (
    <Card bordered={bordered} bodyStyle={bodyStyle} {...props}>
      {(!edit && order.customer) ? (
        <List
          itemLayout="horizontal"
          dataSource={(order.customer && [order.customer]) || []}
          renderItem={(item) => (
            <List.Item
              style={{ padding: '6px 0' }}
              actions={[
                <Button
                  key="edit_customer_icon"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => setEdit(true)}
                  size="small"
                  disabled={!!order.archived}
                />
              ]}
            >
              <List.Item.Meta
                title={
                  <Link href={`/partners/${item.id}`}>
                    <a>
                      { (item.name || "") + " " }
                      { (item.lastname || "") + " " }
                    </a>
                  </Link>
                }
                description={
                  <>
                    {item.email ? (
                      <Link href={`mailto:${item.email}`}>
                        <a>{ item.email }</a>
                      </Link>
                    ) : null}
                    {(item.email && item.phone) ? <br /> : null}
                    {item.phone ? (
                      <Link href={`tel:${item.phone}`}>
                        <a>{ item.phone }</a>
                      </Link>
                    ) : null}
                  </>
                }
              />
            </List.Item>
          )}
          style={{ marginRight: -12 }}
        >
        </List>
      ) : (
        <Form
          form={form}
          autoComplete="off"
          onFinish={onSave}
          initialValues={{
            customer: order.customer?.id || undefined
          }}
        >
          <Form.Item name="customer" style={{ marginBottom: 8 }}>
            <SubjectSelect
              typeBrief={['PARTNER']}
              style={{ width: '100%' }}
              autoFocus
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Сохранить
              </Button>
              <Button
                type="primary"
                danger
                onClick={onCancel}
              >
                Отменить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

export default Customer;

import { useState, FC } from 'react';
import Link from 'next/link';
import { Card, List, Space, Button, Form, message } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { ContactsProps } from './Contacts.props';
import ContactSelect from './ContactSelect/ContactSelect';
import { useAddSubjectLinkListMutation, useDeleteSubjectLinkByPkMutation } from '~/apollo/generated/hooks';

const Customer: FC<ContactsProps> = ({
  order,
  title = 'Контакты',
  bordered = false,
  headStyle = { padding: 0, borderBottomWidth: 0 },
  bodyStyle = { padding: '6px 0' },
  extra,
  ...props
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const [addLinks, { loading }] = useAddSubjectLinkListMutation({
    onCompleted: () => {
      setEdit(false);
    },
    onError: (error) => {
      message.error(`
        При сохранении контактных лиц произошла ошибка. Сообщение ошибки: ${error.message}
      `, 10);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const [deleteLink] = useDeleteSubjectLinkByPkMutation({
    onError: (error) => {
      message.error(`
        При удалении контакта произошла ошибка. Сообщение ошибки: ${error.message}
      `, 10);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onSave = (values: any) => {
    if (!values.contacts || !values.contacts.length) {
      setEdit(false);
      return;
    }

    const linkList = [];

    for (const id of values.contacts) {
      const isContact = order.contact_list.find(
        contact => contact.subject_id === id
      );

      if (isContact) {
        continue;
      }

      const link = {
        subject_id: id,
        object_type_id: 2,
        object_id: order.id,
        link_type_id: 1
      };

      linkList.push(link);
    }

    if (linkList.length) {
      addLinks({
        variables: {
          linkList
        }
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    setEdit(false);
  };

  return (
    <Card
      title={title}
      bordered={bordered}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      extra={
        extra ? extra : (
          <>
            {!edit ? (
              <Button
                type="primary"
                size="small"
                icon={<PlusCircleOutlined style={{ fontSize: 12 }} />}
                disabled={!!order.archived}
                onClick={() => setEdit(true)}
              >
                Добавить контакт
              </Button>
            ): null}
          </>
        )
      }
      {...props}
    >
      {edit ? (
        <Form
          form={form}
          autoComplete="off"
          onFinish={onSave}
        >
          <Form.Item name="contacts" style={{ marginBottom: 8 }}>
            <ContactSelect order={order} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Сохранить
              </Button>
              <Button
                type="primary"
                danger
                onClick={onCancel}
              >
                Отменить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : null}
      {order.contact_list && order.contact_list.length ? (
        <List
          itemLayout="horizontal"
          dataSource={order.contact_list}
          renderItem={(item) => (
            <List.Item
              style={{ padding: '6px 0' }}
              actions={[
                <a
                  key="edit_contact_icon"
                  onClick={() => deleteLink({ variables: { linkId: item.id } })}
                >
                  <DeleteOutlined style={{ color: '#FF4D4F', marginLeft: '15px' }} />
                </a>
              ]}
            >
              <List.Item.Meta
                title={
                  <Link href={`/contacts/${item.subject_id}`}>
                    <a>
                      { item.subject?.name || "" }
                      { item.subject?.lastname || "" }
                      { (item.subject?.post && `(${item.subject.post})`) || "" }
                    </a>
                  </Link>
                }
                description={
                  <>
                    {item.subject?.email ? (
                      <Link href={`mailto:${item.subject.email}`}>
                        <a>{ item.subject.email }</a>
                      </Link>
                    ) : null}
                    {item.subject?.phone ? (
                      <>
                        <br />
                        <Link href={`tel:${item.subject.phone}`}>
                          <a>{ item.subject.phone }</a>
                        </Link>
                      </>
                    ) : null}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : null}
    </Card>
  );
};

export default Customer;

import React, { MouseEvent, useState, FC } from 'react';

import { SubjectSelectCellProps } from './SubjectSelectCell.props';
import SubjectSelect from '../../../SubjectSelect';

const SubjectSelectCell: FC<SubjectSelectCellProps<number | undefined>> = ({
  value,
  cell,
  onUpdateTableData = () => ({}),
  inputProps = {
    typeBrief: ['EMPLOYEE']
  },
  editable = true,
  children,
}) => {
  const [edit, setEdit] = useState(false);

  const onChange = (val?: any) => {
    onUpdateTableData(cell, val);
    setEdit(false);
  };

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <SubjectSelect
            defaultValue={value}
            onChange={onChange}
            onBlur={() => setEdit(false)}
            defaultOpen
            autoFocus
            style={{
              width: '100%',
              height: '100%',
              fontSize: 12,
              borderRadius: 0
            }}
            {...inputProps}
          />
        </div>
      ) : (
        <span
          style={children ? { textOverflow: 'unset' } : {}}
          {...(editable ? {
            className: 'editable-cell',
            onClick: onClick
          }: {})}
        >
          {children || value}
        </span>
      )}
    </>
  );
};

export default SubjectSelectCell;

import { FC, useMemo } from 'react';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Tag } from 'antd';

import {
  LogisticsPageShipmentRequestTableProps,
  LogisticsPageShipmentRequestTableQueryProps
} from './LogisticsPageShipmentRequestTable.props';
import { Table } from '~/components';
import { useGetTasksByOrderIdQuery, useGetTasksCountByOrderIdQuery } from '~/apollo/generated/hooks';
import { TTaskByOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const LogisticsPageShipmentRequestTable: FC<LogisticsPageShipmentRequestTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
      },
      {
        Header: 'Текущий статус',
        id: 'status',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original.selected_shipment_task
              && row.original.selected_shipment_task[0]
              && row.original.selected_shipment_task[0].status
              && row.original.selected_shipment_task[0].status.name
              ? (
                <Tag color="blue">{row.original.selected_shipment_task[0].status.name}</Tag>
              ) : '-'
            }
          </span>
        )
      },
      {
        Header: 'Дата начала',
        accessor: 'date_start_string'
      },
      {
        Header: 'Дедлайн',
        accessor: 'deadline_string'
      },
      {
        Header: 'Поставщик',
        accessor: 'subject',
        Cell: ({ value }: CellProps<TTaskByOrderProps, TTaskByOrderProps['subject']>) => (
          <span>{value?.name}</span>
        )
      },
      {
        Header: 'Номер документа поставщика',
        accessor: 'document',
        Cell: ({ value }: CellProps<TTaskByOrderProps, TTaskByOrderProps['document']>) => (
          <span>{(value && value.number) || '-'}</span>
        )
      },
      {
        Header: 'Кол-во кубов',
        id: 'qty_cubs',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_KUBOV')
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_KUBOV')?.int_val
            ) || '-'}
          </span>
        )
      },
      {
        Header: 'Кол-во мест',
        accessor: 'qty_places',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_MEST')
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_MEST')?.int_val
            ) || '-'}
          </span>
        )
      },
      {
        Header: 'Вариант отгрузки',
        accessor: 'variant',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')?.prop_value
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')?.prop_value?.value
            ) || '-' }
          </span>
        )
      },
      {
        Header: 'Комментарий',
        accessor: 'description'
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const LogisticsPageShipmentRequestTableQuery: FC<LogisticsPageShipmentRequestTableQueryProps> = ({
  router,
  ...props
}) => {
  const page = router.query.page_shipment ? +router.query.page_shipment : 1;
  const limit = 10;

  const { data } = useGetTasksByOrderIdQuery({
    variables: {
      taskTypeBrief: 'SHIPMENT_REQUEST',
      objectTypeBrief: 'ORDER',
      offset: (page && ((page - 1) * limit)) || 0,
      limit
    }
  });

  const { data: dataCount } = useGetTasksCountByOrderIdQuery({
    variables: {
      taskTypeBrief: 'SHIPMENT_REQUEST',
      objectTypeBrief: 'ORDER'
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <LogisticsPageShipmentRequestTable
      id="ID_LOGISTICS_PAGE_SHIPMENT_REQUEST_TABLE"
      data={data?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      {...props}
    />
  );
};

export default withRouter(LogisticsPageShipmentRequestTableQuery);

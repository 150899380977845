import { useCallback, FC } from 'react';
import { withRouter } from 'next/router';
import { Row as RowProps, Cell } from 'react-table';
import { Button, Descriptions, Popconfirm, Space, message } from 'antd';

import { VersionsTableProps } from './VersionsTable.props';
import { useGetOrderlineListQuery, useGetOrderVersionTotalsQuery, useUpdateOrderlineMutation } from '~/apollo/generated/hooks';
import ExpenseTable from '../../OrderProductsTable/ExpenseTable/ExpenseTable';
import DocsTable from '../../OrderProductsTable/DocsTable/DocsTable';
import { Order_By, TOrderlineProps } from '~/interfaces';
import OrderProductsTable from '../../OrderProductsTable';

const VersionsTable: FC<VersionsTableProps> = ({
  orderId,
  version,
  router,
  changeVersion = () => ({}),
  deleteVersion = () => ({}),
  disabled = false
}) => {
  const page = router.query.page ? +router.query.page : 1;
  const limit = 50;

  const getVariables = () => ({
    where: {
      ver: { _eq: version },
      order_id: { _eq: orderId }
    },
    orderBy: {
      rownum: Order_By.Asc
    },
  });

  const { data } = useGetOrderlineListQuery({
    variables: getVariables()
  });

  const { data: totals } = useGetOrderVersionTotalsQuery({
    variables: {
      orderId,
      version,
    }
  });

  const [updateOrderline] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onUpdateTableData = (cell: Cell<TOrderlineProps>, value: any) => {
    updateOrderline({
      variables: {
        orderlineId: cell.row.original.id,
        set: {
          [cell.column.id]: value,
        }
      }
    });
  };

  const renderRowSubComponent = useCallback(
    ({ row }: { row: RowProps<TOrderlineProps> }) => (
      <div style={{ paddingLeft: 91 }}>
        <ExpenseTable data={row.original} rowId={row.id} disabled={disabled} />
        <br />
        <br />
        <DocsTable orderId={orderId} data={row.original} rowId={row.id} disabled={disabled} />
      </div>
    ),
    [orderId, disabled]
  );

  return (
    <>
      <OrderProductsTable
        id="ID_ORDER_PAGE_PRODUCTS_TABLE"
        data={data?.orderline_list || []}
        pagination={{
          current: page,
          total: (data && data.count.aggregate?.count) || 0,
          pageSize: limit,
        }}
        onPaginationChange={onTableChange}
        onUpdateTableData={onUpdateTableData}
        renderRowSubComponent={renderRowSubComponent}
        disabled={disabled}
      />

      <Descriptions
        style={{ backgroundColor: '#ffffff' }}
        column={4}
        bordered
        size="small"
        layout="vertical"
      >
        <Descriptions.Item label="Сумма">
          { totals?.data[0]?.totals?.total_amount }
        </Descriptions.Item>
        <Descriptions.Item label="Расходы">
          { totals?.data[0]?.totals?.total_expenses }
        </Descriptions.Item>
        <Descriptions.Item label="Прибыль">
          { totals?.data[0]?.totals?.total_marzh }
        </Descriptions.Item>
        <Descriptions.Item label="Прибыль %">
          { totals?.data[0]?.totals?.marzh_perc }
        </Descriptions.Item>
      </Descriptions>

      <Space style={{ marginTop: 20 }}>
        <Popconfirm
          title="Переместить версию в актуальный заказ?"
          okText="Согласен"
          cancelText="Передумал"
          onConfirm={() => changeVersion(orderId, version)}
        >
          <Button>Сделать версию актуальной</Button>
        </Popconfirm>
        {disabled ? null : (
          <Popconfirm
            title="Удалить текущую версию?"
            okText="Удалить"
            cancelText="Передумал"
            onConfirm={() => deleteVersion(orderId, version)}
          >
            <Button>Удалить версию</Button>
          </Popconfirm>
        )}
      </Space>
    </>
  );
};

export default withRouter(VersionsTable);

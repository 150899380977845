import { useState, useEffect, FC } from 'react';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { PageHeader, Form, Button, Input, DatePicker, Select, Checkbox } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import CitySelect from './CitySelect/CitySelect';

import { SubjectSelect, OrdersAddDrawer } from '~/components';
import { OrdersPageHeaderProps } from './OrdersPageHeader.props';

const { Search } = Input;

const OrdersPageHeader: FC<OrdersPageHeaderProps> = ({ router }) => {  console.log('router.query = ', router.query);
  const [form] = Form.useForm();
  const { data: session } = useSession();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const contractor = router.query.contractor ? +router.query.contractor : undefined;
  const search = router.query.search?.toString();
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;
  const added = router.query.added ? +router.query.added : undefined;
  const statuses = router.query.statuses
    ? router.query.statuses.toString()?.split(',')
    : undefined;
  const owner = router.query.owner ? +router.query.owner : undefined;
  const archive = router.query.archive?.toString() || null;
  const archived = router.query.archived?.toString() || null;
  const quarters = router.query.quarters
    ? router.query.quarters.toString()?.split(',')
    : undefined;

  const managers = !router.query.managers
    ? [session?.user.subject_id]
    : router.query.managers && router.query.managers === 'all'
    ? undefined
    : router.query.managers.toString().split(',');
  const city = router.query.city ? +router.query.city : undefined;

  const onFilter = (queryName: string, value?: any) => {
    if (Array.isArray(value)) {
      return router.replace({
        query: {
          ...router.query,
          page: 1,
          ...{ [queryName]: value.join(',') },
        }
      });
    }

    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  const getIds = (nums: any[]) => {
    return nums.map((num: any) => {
      if (typeof parseFloat(num) === 'number') return +num;

      return num;
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      contractor,
      search,
      dateFrom: dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : undefined,
      dateTo: dateTo ? moment(dateTo, 'YYYY-MM-DD') : undefined,
      added,
      statuses: (statuses && statuses[0]) ? getIds(statuses) : [],
      managers: (managers && managers[0]) ? getIds(managers) : [],
      owner,
      quarters: (quarters && quarters[0]) ? quarters : [],
      archived: archived === 'yes',
      city,
    });
  }, [router.query, quarters, statuses, managers, dateFrom, dateTo, archived]);

  const quarterArray = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 2021; i--) {
    for (let j = 1; j <= 4; j++) {
      quarterArray.push({
        label: `${j}кв ${i}`,
        value: `Q${j}${String(i).slice(2)}`,
      });
    }
  }

  return (
    <>
      <PageHeader
        title={`Сделки ${archive === 'yes' ? '(архив)' : ''}`}
        ghost={false}
        style={{ marginBottom: 16 }}
        extra={
          <>
            {archive === 'yes' ? (
              <Button
                type="link"
                onClick={() => onFilter('archive', 'no')}
              >
                Актуально
              </Button>
            ) : null}
            {archive !== 'yes' ? (
              <Button
                type="link"
                onClick={() => onFilter('archive', 'yes')}
              >
                Архив
              </Button>
            ) : null}
          </>
        }
      >
        <Form
          form={form}
          layout="inline"
          style={{ marginBottom: -12 }}
        >
          <Form.Item style={{ marginBottom: 12 }}>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="contractor">
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['ORGANIZATION', 'PARTNER', 'PROVIDER']}
              placeholder="Партнер"
              onChange={(contractor) => onFilter('contractor', contractor)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="search">
            <Search
              style={{ width: 250 }}
              allowClear
              placeholder="Номер сделки/счета/УПД"
              onSearch={(search) => onFilter('search', search)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="dateFrom">
            <DatePicker
              placeholder="Дата УПД с .."
              format="DD.MM.YYYY"
              name="dateFrom"
              onChange={(dateFrom) => onFilter('dateFrom', (dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined))}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="dateTo">
            <DatePicker
              placeholder="Дата УПД по ..."
              format="DD.MM.YYYY"
              name="dateTo"
              onChange={(dateTo) => onFilter('dateTo', (dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined))}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="added">
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Добавил"
              onChange={(added) => onFilter('added', added)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="statuses">
            <Select
              style={{ width: 270 }}
              placeholder="Статус"
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              onChange={(statuses) => onFilter('statuses', statuses)}
            >
              <Select.Option value={1}>Запрос</Select.Option>
              <Select.Option value={2}>В работе</Select.Option>
              <Select.Option value={5}>Оплачен</Select.Option>
              <Select.Option value={3}>Отгружен</Select.Option>
              <Select.Option value={4}>Закрыт</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="managers">
            <SubjectSelect
              style={{ width: 400 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Менеджеры"
              mode="multiple"
              onChange={(managers) => {
                onFilter('managers', managers && managers.length > 0 ? managers : 'all');
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="owner">
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Ответственный"
              onChange={(owner) => onFilter('owner', owner)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="quarters">
            <Select
              placeholder="Квартал"
              defaultActiveFirstOption={false}
              style={{ width: 180 }}
              allowClear
              options={quarterArray}
              //   [
              //   // для следующего 2025 года раскомментировать этот блок, других изменений не треубется
              //   // { label: '1кв 2025', value: 'Q125' },
              //   // { label: '2кв 2025', value: 'Q225' },
              //   // { label: '3кв 2025', value: 'Q325' },
              //   // { label: '4кв 2025', value: 'Q425' },
              //   { label: '1кв 2024', value: 'Q124' },
              //   { label: '2кв 2024', value: 'Q224' },
              //   { label: '3кв 2024', value: 'Q324' },
              //   { label: '4кв 2024', value: 'Q424' },
              //   { label: '1кв 2023', value: 'Q123' },
              //   { label: '2кв 2023', value: 'Q223' },
              //   { label: '3кв 2023', value: 'Q323' },
              //   { label: '4кв 2023', value: 'Q423' },
              //   { label: '1кв 2022', value: 'Q122' },
              //   { label: '2кв 2022', value: 'Q222' },
              //   { label: '3кв 2022', value: 'Q322' },
              //   { label: '4кв 2022', value: 'Q422' },
              //   { label: '1кв 2021', value: 'Q121' },
              //   { label: '2кв 2021', value: 'Q221' },
              //   { label: '3кв 2021', value: 'Q321' },
              //   { label: '4кв 2021', value: 'Q421' },
              // ]}
              onChange={(quarters) => onFilter('quarters', quarters)}
              mode="multiple"
              // maxTagCount="responsive"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="archived" valuePropName="checked">
            <Checkbox
              onChange={(e) => onFilter('archived', e.target.checked ? 'yes' : 'no')}
            >
              Показать архивные
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="city">
            <CitySelect
              style={{ width: 200 }}
              onChange={(city) => onFilter('city', city)}
            />
          </Form.Item>
        </Form>
      </PageHeader>

      <OrdersAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => setVisibleDrawer(false)}
      />
    </>
  );
};

export default withRouter(OrdersPageHeader);

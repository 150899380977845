import {FC, useState} from 'react';
import {Card, Input, DatePicker, Form, message, Button} from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { AccountProps } from './Account.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';
import { CHECK_ORDER_BY_INVOICE_NUMBER_AND_YEAR } from '~/apollo/queries/orders';

const validateNumber = (value: any) => {
  if (value && !value.match(/^(ИТ|ТЕ|ЭС)\d{1,4}$/)) {
    return Promise.reject('Неверный формат');
  }

  return Promise.resolve();
};

const Account: FC<AccountProps> = ({
  order,
  title = 'Счет',
  headStyle = { padding: 0, borderBottomWidth: 0 },
  bordered = false,
  bodyStyle = { padding: '6px 0' },
  ...props
}) => {
  const client = useApolloClient();
  const [form] = Form.useForm();


  const [updateInvoice] = useUpdateOrderByPkMutation({
    onError: (error) => {
      message.error(`
        При обновлении заказа произошла ошибка. Сообщение ошибки: ${error.message}
      `);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onValuesChange = () => {
    form
      .validateFields()
      .then(async (values) => {

        const invoiceNumber = values.number;
        const year = values.date ? Number(values.date.format('YYYY')) : null;

        if (invoiceNumber && year) {
          const { data } = await client.query({
            query: CHECK_ORDER_BY_INVOICE_NUMBER_AND_YEAR,
            variables: {
              invoiceNumber,
              year,
            },
          });

          if (data.order[0]?.result?.if_exists === true) {
            message.error('Такой Счёт уже существует', 10);
            form.setFieldsValue({ number: '' });
            return;
          }
        }
        updateInvoice({
          variables: {
            set: {
              invoice_number: values.number,
              invoice_date: values.date ? values.date.format('YYYY-MM-DD') : null
            },
            orderId: order.id
          }
        });
      })
      .catch(() => ({}));
  };

  return (
    <>
    <Card
      title={title}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      bordered={bordered}
      {...props}
    >

      <Form
        form={form}
        autoComplete="off"
        onValuesChange={debounce(onValuesChange, 500)}
        initialValues={{
          number: order.invoice_number?.toString(),
          date: order.invoice_date ? moment(order.invoice_date, 'YYYY-MM-DD') : undefined
        }}
      >
        <Form.Item
          name="number"
          style={{ marginBottom: 8 }}
          rules={[{ validator: (_, value) => validateNumber(value) }]}
        >
          <Input placeholder="Номер в формате: 'ИТ25', 'ЭС456'" disabled={!!order.archived} />
        </Form.Item>
        <Form.Item name="date" noStyle={true}>
          <DatePicker format="DD.MM.YYYY" disabled={!!order.archived} />
        </Form.Item>
      </Form>
    </Card>

    </>
  );
};

export default Account;

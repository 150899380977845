import { useState, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { List, Button, Card, Avatar } from 'antd';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { PartnerPageContactsListProps, PartnerPageContactsListQueryProps } from './PartnerPageContactsList.props';
import { useGetContactListQuery } from '~/apollo/generated/hooks';
import ClientsDrawer from '../../clients/ClientsDrawer';

const PartnerPageContactsList: FC<PartnerPageContactsListProps> = ({
  data,
  partnerId,
}) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const client = useApolloClient();

  const onAddNewContact = () => {
    client.refetchQueries({
      include: ['getContactList']
    });
    setVisibleDrawer(false);
  };

  return (
    <Card bordered={false}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setVisibleDrawer(true)}
        style={{ marginBottom: 24 }}
      >
        Добавить
      </Button>
      {data.length ? (
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(contact) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar icon={<UserOutlined />} size="large" />
                }
                title={
                  <Link href={`/contacts/${contact.id}`}>
                    <a style={{ color: '#0094FF' }}>
                      {contact.name} {contact.lastname || ''}
                    </a>
                  </Link>
                }
                description={
                  <>
                    {contact.post ? (
                      <div>Должность: {contact.post}</div>
                    ) : null}
                    {contact.email ? (
                      <div>
                        Email: <Link href={`mailto:${contact.email}`}><a>{contact.email}</a></Link>
                      </div>
                    ) : null}
                    {contact.phone ? (
                      <div>
                        Телефон: <Link href={`tel:${contact.phone}`}><a>{contact.phone}</a></Link>
                      </div>
                    ) : null}
                    {contact.address ? (
                      <div>{contact.address}</div>
                    ) : null}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : null}

      <ClientsDrawer
        organizationId={partnerId}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onAddNewContact}
      />
    </Card>
  );
};

const PartnerPageContactsListQuery: FC<PartnerPageContactsListQueryProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  const { data } = useGetContactListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'CONTACT_PERSON' }
        },
        _or: [
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              subject_object: {
                subject_type: {
                  brief: { _eq: 'ORGANIZATION' }
                },
                object_subject_links: {
                  t_link_type: { brief: { _eq: 'PARTNER_ORGANIZATION' } },
                  t_object_type: { brief: { _eq: 'SUBJECT' } },
                  subject_id: { _eq: +id }
                }
              }
            }
          },
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              object_id: { _eq: +id }
            }
          }
        ]
      }
    }
  });

  return (
    <PartnerPageContactsList data={data?.contact_list || []} partnerId={+id} />
  );
};

export default withRouter(PartnerPageContactsListQuery);

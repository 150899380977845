import { useState, useEffect, FC } from 'react';
import { Drawer, Button, Form, Input, DatePicker, Radio, Row, Col, Checkbox, Space, InputNumber, message } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useAddTaskFuncLazyQuery } from '~/apollo/generated/hooks';
import { DeliveryRequestDrawerProps } from './DeliveryRequestDrawer.props';
import SubjectSelect from '../../SubjectSelect';

const DeliveryRequestDrawer: FC<DeliveryRequestDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({}),
  objectId = null,
}) => {
  const [form] = Form.useForm();
  const [showOrgField, setShowOrgField] = useState(false);

  const [addTask, { loading }] = useAddTaskFuncLazyQuery({
    onCompleted: () => {
      message.success('Доставка сохранена успешно');
      form.resetFields();
      onSave();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении доставки');
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const [dateStart, dateEnd] = values.date;

        addTask({
          variables: {
            params: {
              task: {
                typeId: 1,
                statusId: 4,
                orderId: +objectId,
                objectTypeId: 2,
                deadlineTime: values.time
                  ? values.time._d.toTimeString().split(' ')[0]
                  : null,
                dateStart: dateStart ? dateStart._d : undefined,
                deadline: dateEnd ? dateEnd._d : undefined,
                ownerId: null,
                priorityId: values.urgency ? 1 : null,
                subjectId: values.subjectId || null,
                description: values.description
              },
              props: [
                {
                  propId: 3,
                  textVal: values.fromAddress || null,
                },
                {
                  propId: 2,
                  textVal: values.address || null,
                }
              ]
            }
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onValuesChange = (changedValues: any) => {
    if ('recipient' in changedValues) {
      if (changedValues.recipient === 2) {
        setShowOrgField(true);
      } else {
        setShowOrgField(false);
      }
    }
  };

  useEffect(() => {
    if (!visible) return;

    form.setFieldsValue({
      time: moment(new Date()),
    });
  }, [visible]);

  return (
    <Drawer
      title="Планирование доставки"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <Row justify="end">
          <Space>
            <Button
              onClick={onCancel}
              disabled={loading}
            >
              Отмена
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              disabled={loading}
              loading={loading}
            >
              Сохранить
            </Button>
          </Space>
        </Row>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="delivery_request_form"
        autoComplete="off"
        onValuesChange={onValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="recipient"
              label="Получатель"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={1}>Клиент</Radio.Button>
                <Radio.Button value={2}>Подрядчик</Radio.Button>
                <Radio.Button value={3}>Другое</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          {showOrgField ? (
            <Col span={12}>
              <Form.Item name="subjectId" label="Подрядчик">
                <SubjectSelect
                  typeBrief={['ORGANIZATION']}
                  placeholder="Выберите организацию"
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Form.Item name="fromAddress" label="Адрес забора">
          <Input suffix={<EnvironmentOutlined />} />
        </Form.Item>
        <Form.Item
          name="address"
          label="Адрес доставки"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input suffix={<EnvironmentOutlined />} />
        </Form.Item>
        <Row gutter={24} align="bottom">
          <Col span={12}>
            <Form.Item
              name="date"
              label="Дата доставки"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <DatePicker.RangePicker format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="time" label="Точное время">
              <DatePicker.TimePicker format="HH:mm" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="urgency" valuePropName="checked">
              <Checkbox>Срочно</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Кол-во кубов" name="cubsQty">
          <InputNumber min={1} max={999} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Тип доставки"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={1}>Свой водитель</Radio.Button>
            <Radio.Button value={2}>Наемный водитель</Radio.Button>
            <Radio.Button value={3}>Служба доставки</Radio.Button>
            <Radio.Button value={4}>Другое</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Комментарий" name="description">
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default DeliveryRequestDrawer;

import { FC } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { PriceInputProps } from './PriceInput.props';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 20, // how many digits allowed after the decimal
  integerLimit: 30, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput: FC<PriceInputProps> = ({
  inputMode = 'numeric',
  maskOptions = {},
  className = 'ant-input',
  placeholder = '',
  ...props
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      mask={currencyMask}
      inputMode={inputMode}
      className={className}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default CurrencyInput;

import { useState, FC } from 'react';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { List, Comment, Avatar, Tooltip, Space, Input, Button, Popconfirm, Form } from 'antd';
import { EditOutlined, UserOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons';

import { ItemProps } from './Item.props';
import { useGetPartnerListQuery } from '~/apollo/generated/hooks';

import styles from './Item.module.css';

const Item: FC<ItemProps> = ({
  item,
  opened = false,
  onOpen = () => ({}),
  onSave = () => ({}),
  onCancel = () => ({}),
  onRemove = () => ({}),
  loading = false,
  objectId,
  entityType = null,
  disabled = false,
}) => {
  const { data: session } = useSession();
  const [showFiles, setShowFiles] = useState(false);
  const [form] = Form.useForm();

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: objectId }
      },
      limit: 1
    }
  });

  const partner = data?.partner_list[0];

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values.text);
      })
      .catch(() => ({}));
  };

  return (
    <List.Item
      actions={disabled ? [] : [
        <>
          {opened ? (
            <Space>
              <Button
                type="primary"
                onClick={onSubmit}
                loading={loading}
                disabled={loading}
              >
                Сохранить
              </Button>
              <Button
                danger
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
              >
                Отменить
              </Button>
            </Space>
          ) : (
            <Space>
              {item.subject && item.subject.id === session?.user.subject_id ? (
                <>
                  <Tooltip title="Редактировать">
                    <EditOutlined
                      style={{ color: '#0094FF' }}
                      onClick={() => onOpen(item.id)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Вы уверены?"
                    placement="left"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => onRemove(item.id)}
                  >
                    <Tooltip title="Удалить">
                      <DeleteOutlined style={{ color: '#ff4d4f' }} />
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : null}
            </Space>
          )}
        </>
      ]}
    >
      <Comment
        className={styles.comment}
        avatar={
          (item.subject && item.subject.avatar)
            ? `http://oasis.retailsuite.ru/${item.subject.avatar}?width=100`
            : <Avatar size={32} icon={<UserOutlined />} />
        }
        author={
          <>
            {item.subject ? (
              <Link href={`/employees/${item.subject.id}`}>
                <a>{(item.subject && item.subject.name && ((item.subject.lastname && `${item.subject.name} ${item.subject.lastname}`) || item.subject.name)) || 'Неизвестный пользователь' }</a>
              </Link>
            ) : 'Неизвестный пользователь'}
          </>
        }
        content={
          <>
            {opened ? (
              <Form form={form} initialValues={{ text: item.text }}>
                <Form.Item
                  name="text"
                  rules={[{
                    required: true,
                    validator: (_, value) => {
                      if (!value || value.toString().trim() === '') {
                        return Promise.reject('Обязательное поле!');
                      }

                      return Promise.resolve();
                    }
                  }]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Form>
            ) : (
              <>
                <span style={{ whiteSpace: 'pre-line' }}>{item.text}</span>

                {entityType === 'PARTNER' ? (
                  <>
                    <br/>
                    <br/>
                    {item.object_subject && item.object_id !== partner?.id ? (
                      <Space>
                        Контакт:
                        <Link href={`/contacts/${item.object_id}`}>
                          <a>{ item.object_subject.name || '' } { item.object_subject.lastname || '' }</a>
                        </Link>
                      </Space>
                    ) : null}
                    {item.object_task ? (
                      <>
                        {item.object_task.object_id === partner?.id ? (
                          <Space>
                            Задача:
                            {item.object_task.deleted ? (
                              <span style={{ fontWeight: 'bold' }}>
                                {item.object_task.name || item.object_task.description || `Задача №${item.object_id}`} (Удалена)
                              </span>
                            ) : (
                              <Link href={`/tasks/${item.object_id}`}>
                                <a>{item.object_task.name || item.object_task.description || `Задача №${item.object_id}`}</a>
                              </Link>
                            )}
                          </Space>
                        ) : (
                          <>
                            {item.object_task.object_subject ? (
                              <Space>
                                Контакт:
                                <Link href={`/contacts/${item.object_task.object_id}`}>
                                  <a>{item.object_task.object_subject.name || '' } { item.object_task.object_subject.lastname || ''}</a>
                                </Link>
                              </Space>
                            ) : null}
                            <br />
                            <Space>
                              Задача:
                              {item.object_task.deleted ? (
                                <span style={{ fontWeight: 'bold' }}>
                                  {item.object_task.name || item.object_task.description || `Задача №${item.object_id}`} (Удалена)
                                </span>
                              ) : (
                                <Link href={`/tasks/${item.object_id}`}>
                                  <a>{item.object_task.name || item.object_task.description || `Задача №${item.object_id}`}</a>
                                </Link>
                              )}
                            </Space>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                {item.files && item.files.length ? (
                  <>
                    <br />
                    <br />
                    <span onClick={() => setShowFiles((show) => !show)}>
                      {showFiles ? 'Скрыть' : 'Показать'} файлы ({ item.files.length })
                    </span>
                    {showFiles ? (
                      <List
                        rowKey="id"
                        size="small"
                        dataSource={item.files}
                        renderItem={(i) => (
                          <List.Item>
                            <div style={{ paddingLeft: 10 }}>
                              <LinkOutlined />
                              <a href={`/${i.file?.path}`} style={{ marginLeft: 5 }} download>
                                {i.file?.name}
                              </a>
                            </div>
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </>
        }
        datetime={
          <Space>
            <Tooltip title={`${item.ins_date_string} в ${item.ins_time_string}`}>
              {`${item.ins_date_string} в ${item.ins_time_string}`}
            </Tooltip>
            {item.upd_date_string ? (
              <Tooltip title={`${item.upd_date_string} в ${item.upd_time_string || '00:00'}`}>
                <EditOutlined />
                Изменено
              </Tooltip>
            ) : null}
          </Space>
        }
      />
    </List.Item>
  );
};

export default Item;

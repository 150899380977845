import { useState, useEffect, FC } from 'react';
import Link from 'next/link';
import { Card, Button, Space, Form, Avatar, message } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';

import { CrucialCardProps } from './CrucialCard.props';
import { useUpdateTaskByPkMutation } from '~/apollo/generated/hooks';
import SubjectSelect from '../../../../SubjectSelect';

const CrucialCard: FC<CrucialCardProps> = ({
  user,
  task,
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const onOk = () => {
    form.resetFields();
    setEdit(false);
  };

  const [updateTask, { loading }] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      onOk();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentTask']
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateTask({
          variables: {
            taskId: task.id,
            set: {
              owner_id: values.crucial
            }
          }
        });
      })
      .catch(() => ({}));
  };

  useEffect(() => {
    form.setFieldsValue({
      crucial: task.owner_id
    });
  }, [task, edit]);

  return (
    <Card
      bordered={false}
      title="Ответственный:"
      className="mini-card"
      extra={
        <>
          {user.subject_id === task.owner_id || user.id === task.ins_user ? (
            <EditOutlined onClick={() => setEdit(true)} />
          ) : null}
        </>
      }
    >
      {edit ? (
        <Form
          form={form}
          layout="horizontal"
          autoComplete="off"
        >
          <Form.Item name="crucial">
            <SubjectSelect
              typeBrief={['EMPLOYEE']}
              placeholder="Выберите сотрудника"
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            >
              Сохранить
            </Button>
            <Button
              disabled={loading}
              onClick={onOk}
            >
              Отменить
            </Button>
          </Space>
        </Form>
      ) : (
        <Space align="center">
          <Avatar size={24} icon={<UserOutlined />} />
          <Link href={`/employees/${task.owner_id}`}>
            <a>
              { task.subject_owner?.name || '' } { task.subject_owner?.lastname || '' }
            </a>
          </Link>
        </Space>
      )}
    </Card>
  );
};

export default CrucialCard;

import { useState, useMemo, FC } from 'react';
import Link from 'next/link';
import { Tag } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';
import moment from 'moment';

import { ContractorDocOrdersModalTableProps, OrdersTableProps } from './ContractorDocOrdersModalTable.props';
import { Order_By, TOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { useGetOrderListQuery } from '~/apollo/generated/hooks';
import { Table } from '~/components';
import { getStatusColor, roubleFormatter } from '~/utils';

const OrdersTable: FC<OrdersTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Сделка',
        accessor: 'id',
        width: 80,
        Cell: ({ value }: CellProps<TOrderProps, number>) => {
          if (!value) return <span></span>;

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Link href={`/orders/${value}`}>
                <a style={{ fontSize: 12 }} target="_blank">№{ value }</a>
              </Link>
            </span>
          );
        }
      },
      {
        Header: 'Статус',
        accessor: 'status',
        width: 95,
        Cell: ({ value, row }: CellProps<TOrderProps, TOrderProps['status']>) => {
          if (!value) return <span></span>;

          return (
            <span style={{ textOverflow: 'unset' }}>
              <Tag
                color={getStatusColor(row.original.status?.name)}
                style={{
                  borderWidth: 0
                }}
              >
                { value.name }
              </Tag>
            </span>
          );
        }
      },
      {
        Header: '',
        accessor: 'priority',
        Cell: (cell: CellProps<TOrderProps, TOrderProps['priority']>) => {
          const priorities = cell.value;

          if (!priorities || priorities.length === 0) return <span></span>;

          return (
            <span>
              {priorities.map((p) => (
                <>
                  {(p.prop_value && p.prop_value.value === 'Важный') ? (
                    <FireOutlined style={{ color: 'red' }} />
                  ) : null}
                </>
              ))}
            </span>
          );
        }
      },
      {
        Header: 'Номер счета',
        accessor: 'invoice_number',
      },
      {
        Header: 'Дата счета',
        accessor: 'invoice_date',
      },
      {
        Header: 'Клиент',
        accessor: 'customer',
        Cell: ({ value, row }: CellProps<TOrderProps, TOrderProps['customer']>) => {
          if (!value) return <span></span>;

          return (
            <span>
              <Link href={`/orders/${row.original.order_id}`}>
                <a>
                  <span>{(value && value.name) || ""}</span>
                  <span>{(value && value.lastname) || ""}</span>
                </a>
              </Link>
            </span>
          );
        },
        width: 300,
      },
      {
        Header: 'Cумма',
        accessor: 'order_sum',
        width: 80,
        Cell: (cell: CellProps<TOrderProps, TOrderProps['order_sum']>) => (
          <span>
            {roubleFormatter(cell.value)}
          </span>
        )
      },
      {
        Header: 'Расходы',
        accessor: 'order_expenses_fakt',
        width: 90,
        Cell: (cell: CellProps<TOrderProps, TOrderProps['order_expenses_fakt']>) => (
          <span>
            {roubleFormatter(cell.value)}
          </span>
        )
      },
      {
        Header: 'Приб.',
        accessor: 'marzh_order_fakt',
        width: 120,
        Cell: ({ value, row }: CellProps<TOrderProps, TOrderProps['marzh_order_fakt']>) => {
          if (!value) return <span></span>;

          return (
            <span>
              {value} ({row.original.marzh_order_fakt_perc || ''}%)
            </span>
          );
        }
      },
      {
        Header: 'Дата УПД',
        accessor: 'bill_date',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['bill_date']>) => {
          if (!value) return <span></span>;

          return (
            <span>
              {moment(value).format('DD.MM.YYYY')}
            </span>
          );
        }
      },
      {
        Header: 'Ответственный',
        accessor: 'deal_owner',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['deal_owner']>) => {
          if (!value) return <span></span>;

          return (
            <span>{ value.name } {value.lastname}</span>
          );
        }
      },
      {
        Header: 'Информация',
        accessor: 'description',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['description']>) => (
          <span>{value}</span>
        ),
        sorter: true,
      },
    ],
    []
  );

  return (
    <Table
      data={data || []}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const ContractorDocOrdersModalTable: FC<ContractorDocOrdersModalTableProps> = ({ filter = {}, ...props }) => {
  const limit = 20;
  const [page, setPage] = useState(1);
  const sorter = { order_id: Order_By.Desc };

  const getVariables = () => {
    let where: any = {};

    if (filter.partnerId) {
      where = {
        ...where,
        customer_id: { _eq: filter.partnerId },
      };
    }

    if (filter.search) {
      const search = filter.search.toUpperCase();

      const onlyNumber = search.replace(/\D/g, '');
      const _or: any = [
        { invoice_number: { _like: `%${search}%` } },
        { bill_number: { _like: `%${search}%` } }
      ];

      if (onlyNumber && onlyNumber !== '') {
        _or.push({
          order_id: { _eq: onlyNumber }
        });
      }

      where = {
        ...where,
        archived: { _in: [false, true] },
        _or
      };
    }

    if (filter.dateFrom) {
      where = {
        ...where,
        ins_date: {
          _gte: filter.dateFrom.format('YYYY.MM.DD 24:00')
        },
      };
    }

    if (filter.dateTo) {
      where = {
        ...where,
        ins_date: {
          ...where.ins_date,
          _lte: filter.dateTo.format('YYYY.MM.DD 24:00'),
        }
      };
    }

    if (filter.insSubjectId) {
      where = {
        ...where,
        user_created: { subject_id: { _eq: filter.insSubjectId } }
      };
    }

    if (filter.owner) {
      where = {
        ...where,
        deal_owner_id: { _eq: filter.owner },
      };
    }

    if (filter.managerIds && filter.managerIds.length > 0) {
      where = {
        ...where,
        customer: {
          subject_links: {
            object_id: { _in: filter.managerIds },
            link_type_id: { _eq: 11 }
          }
        },
      };
    }

    // if (filter.sorter && filter.sorter.sort) {
    //   sorter = { [filter.sorter.sort]: filter.sorter.order };
    // }

    if (filter.statusId && filter.statusId.length > 0) {
      where = { ...where, status_id: { _in: filter.statusId } };
    }

    if (filter.archived) {
      where = { ...where, archived: { _in: [true, false] } };
    }

    return {
      offset: (page - 1) * limit,
      limit,
      orderBy: [{ ...sorter }],
      where: {
        deleted: { _eq: false },
        archived: { _eq: false },
        "_not": {
          "_or": [
            { "status_id": { "_eq": 4 } },
            { "_and": {
              "archived": { "_eq": true },
              "status_id": { "_eq": 1 }
            } }
          ]
        },
        ...where
      }
    };
  };

  const onTableChange = (pagination: any) => {
    setPage((pagination && pagination.current) || 1);
  };

  const { data } = useGetOrderListQuery({
    variables: getVariables()
  });

  return (
    <OrdersTable
      id="ID_CONTRACTOR_DOC_ORDERS_MODAL_TABLE"
      data={data?.order_list || []}
      onPaginationChange={onTableChange}
      pagination={{
        current: +page,
        total: (data && data?.order_list_count?.aggregate?.count) || 0,
        pageSize: limit,
      }}
      {...props}
    />
  );
};

export default ContractorDocOrdersModalTable;

import { FC } from 'react';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';

import { useGetSubjectListQuery } from '~/apollo/generated/hooks';
import { SubjectSelectProps } from './SubjectSelect.props';
import { TSubjectProps } from '~/interfaces';

const SubjectSelect: FC<SubjectSelectProps> = ({
  typeBrief = [],
  typeOrg,
  nullOption,
  placeholder = 'Выберите клиента',
  value = undefined,
  defaultValue = undefined,
  allowClear = true,
  maxTagCount = 'responsive',
  defaultActiveFirstOption = false,
  showSearch = true,
  filterOption = false,
  onSearch,
  onClear,
  notFoundContent,
  loading,
  disabled = false,
  no1c = false,
  ...props
}) => {
  const getVariables = (selected?: TSubjectProps['id'] | TSubjectProps['id'][]) => {
    let where: any = {}, ids: number[] | undefined = undefined;

    if (selected) {
      if (Array.isArray(selected)) {
        ids = selected;
      } else {
        ids = [selected];
      }
    }

    if (typeOrg) {
      where = {
        props: {
          prop: { brief: { _eq: "TIP_ORGANIZACII" } },
          prop_value_id: { _in: [...typeOrg] },
        },
      };
    }

    if (no1c) {
     where = {
        ...where,
        guid: { _is_null: true },
     };
    }

    return {
      where: {
        ...where,
        // guid: { _eq: true },
        deleted: { _eq: false },
        subject_type: {
          brief: { _in: typeBrief },
        },
        subject_id: ids?.length
          ? { _in: ids }
          : undefined
      },
      limit: 50,
    };
  };

  const { data, refetch, loading: loadingData } = useGetSubjectListQuery({
    variables: getVariables(value || defaultValue),
    fetchPolicy: 'cache-and-network',
    skip: disabled,
  });

  const onSearchSubject = (value: string) => {
    let searchString = value && value.trim();

    searchString = `%${searchString}%`;

    refetch({
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _in: typeBrief },
        },
        _or: [
          { name: { _ilike: searchString } },
          { lastname: { _ilike: searchString } },
        ],
      },
      limit: 70,
    });
  };

  const onClearInput = () => {
    refetch(getVariables(undefined));
  };

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      filterOption={filterOption}
      notFoundContent={
        notFoundContent || <>{loadingData ? <Spin size="small" /> : null}</>
      }
      placeholder={placeholder}
      showSearch={showSearch}
      defaultActiveFirstOption={defaultActiveFirstOption}
      allowClear={allowClear}
      onSearch={onSearch || debounce(onSearchSubject, 800)}
      onClear={onClear || onClearInput}
      maxTagCount={maxTagCount}
      loading={loading || loadingData}
      {...props}
    >
      {nullOption ? (
        <Select.Option key={nullOption.value} value={nullOption.value}>
          {nullOption.label}
        </Select.Option>
      ) : null}
      {data?.subject_list.map((subject) => (
        <Select.Option key={subject.id} value={subject.id}>
          { subject.name } { subject.lastname || '' }
        </Select.Option>
      ))}
    </Select>
  );
};

export default SubjectSelect;

/**
 * TODO: в шапке страницы при выборе подрядчика выходит ошибка в консоли
 * * пример:/accounting/[id]
 */

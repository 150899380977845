import { gql } from '@apollo/client';

export const ADD_SUBJECT_LINK_ONE = gql`
  mutation addSubjectLinkOne($link: t_subject_link_insert_input!) {
    subject_link: insert_t_subject_link_one(object: $link) {
      id: subject_link_id
    }
  }
`;

export const ADD_SUBJECT_LINK_LIST = gql`
  mutation addSubjectLinkList($linkList: [t_subject_link_insert_input!]!) {
    link_list: insert_t_subject_link(objects: $linkList) {
      returning {
        link_type_id
        object_id
        object_type_id
        subject_id
        subject_link_id
      }
    }
  }
`;

export const DELETE_SUBJECT_LINK = gql`
  mutation deleteSubjectLink($where: t_subject_link_bool_exp!) {
    delete_t_subject_link(where: $where) {
      affected_rows
      returning {
        subject_link_id
      }
    }
  }
`;

export const DELETE_SUBJECT_LINK_BY_PK = gql`
  mutation deleteSubjectLinkByPk($linkId: bigint!) {
    delete_t_subject_link_by_pk(subject_link_id: $linkId) {
      id: subject_link_id
    }
  }
`;

export const UPDATE_SUBJECT_LINK_BY_PK = gql`
  mutation updateSubjectLinkByPk($set: t_subject_link_set_input, $subjectLinkId: bigint!) {
    subject_link: update_t_subject_link_by_pk(
      _set: $set
      pk_columns: { subject_link_id: $subjectLinkId }
    ) {
      id: subject_link_id
    }
  }
`;

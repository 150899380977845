import { useState, FC, useEffect } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { PageHeader, Descriptions, Space, Tag, Button } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
// import { PartnerPageHeaderProps } from './PartnerPageHeader.props';
import PartnerTabs from './PartnerTabs';
import DeleteButton from './DeleteButton';
import PartnerEditableInput from '../PartnerEditableInput';
import PartnersDrawer from '../../partners/PartnersDrawer';

const PartnerPageHeader = ({
  router
}: {router: any}) => {
  const { id, showVisibleDrawer } = router.query;
  const { data: session } = useSession();
  const client = useApolloClient();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  useEffect(() => {
    console.log('useEffect, showVisibleDrawer = ', showVisibleDrawer);
    if (showVisibleDrawer) setVisibleDrawer(true);
  }, [showVisibleDrawer]);

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = (data && data.partner_list && data.partner_list[0]) || null;

  if (!partner) return null;

  const isShowEditButton = () => {
    const owner = (partner.owner && partner.owner[0]) || null;
    const manager = (partner.manager && partner.manager[0]) || null;

    if (session?.user.is_admin) { return true; }

    if (owner && owner.subject_id === session?.user.subject_id) { return true; }

    if (manager && manager.subject_id === session?.user.subject_id) { return true; }

    return false;
  };

  const showEditButton = isShowEditButton();

  const checkProtocol = (url: string) => {
    const reg = /(http(s?)):\/\//i;

    if (reg.test(url)) {
      return true;
    }

    return false;
  };

  const onUpdateParter = () => {
    setVisibleDrawer(false);
    client.refetchQueries({ include: ['getPartnerList'] });
  };

  return (
    <>
      <PageHeader
        title={
          <Space>
            {partner.name}
            {partner.status ? (
              <>
                {partner.status.name === 'Потенциальный' ? (
                  <Tag color="blue">{partner.status.name}</Tag>
                ) : null}
                {partner.status.name === 'Активный' ? (
                  <Tag color="green">{partner.status.name}</Tag>
                ) : null}
                {partner.status.name === 'Отказ' ? (
                  <Tag color="red">{partner.status.name}</Tag>
                ) : null}
              </>
            ) : null}
            {partner.SFERA_DEYATELNOSTI.map((activity) => (
              <Tag key={activity.id} color="orange">
                {activity.prop_value?.value}
              </Tag>
            ))}
            {partner.guid && partner.guid.length === 36 ? (
              <CheckOutlined style={{ color: 'rgb(250 173 18)' }} />
            ) : null}
            {showEditButton ? (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => setVisibleDrawer(true)}
              />
            ) : null}
          </Space>
        }
        ghost={false}
        style={{ marginBottom: 16 }}
        extra={
          <>
            {session?.user.is_admin ? (
              <DeleteButton partner={partner} router={router} />
            ) : null}
          </>
        }
        footer={
          <PartnerTabs partner={partner} />
        }
        onBack={() => router.back()}
        className="partner-page-header"
      >
        <Descriptions size="small" column={4}>
          <Descriptions.Item label="Телефон">
            <PartnerEditableInput
              defaultValue={partner.phone || '-'}
              column='phone'
              subjectId={partner.id}
              onChange={onUpdateParter}
            />
          </Descriptions.Item>
          {partner.phone2 ? (
            <Descriptions.Item label="">
              <PartnerEditableInput
                defaultValue={partner.phone2 || '-'}
                column='phone2'
                subjectId={partner.id}
                onChange={onUpdateParter}
              />
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Email">
            <PartnerEditableInput
              defaultValue={partner.email || '-'}
              column='email'
              subjectId={partner.id}
              onChange={onUpdateParter}
            />
          </Descriptions.Item>
          {partner.email2 ? (
            <Descriptions.Item label="">
              <PartnerEditableInput
                defaultValue={partner.email2 || '-'}
                column='email2'
                subjectId={partner.id}
                onChange={onUpdateParter}
              />
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Адрес">
            <PartnerEditableInput
              defaultValue={partner.address || '-'}
              column='address'
              subjectId={partner.id}
              onChange={onUpdateParter}
            />
          </Descriptions.Item>
          {partner.city.length ? (
            <Descriptions.Item label="Город">
              {partner.city.map((c) => (
                <span key={c.id}>{c.prop_value?.value}</span>
              ))}
            </Descriptions.Item>
          ) : null}
          {partner.website ? (
            <Descriptions.Item label="Сайт">
              <a
                target="_blank"
                href={checkProtocol(partner.website) ? partner.website : '//' + partner.website}
              >
                {partner.website}
              </a>
            </Descriptions.Item>
          ) : null}
          {partner.manager.length ? (
            <Descriptions.Item label="Менеждер">
              {partner.manager.map((m) => (
                <Link key={m.id} href={`/employees/${m.subject?.id}`}>
                  <a>{(m.subject?.name) || ''} {(m.subject?.lastname) || ''}</a>
                </Link>
              ))}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Филиал">
            {partner.manager.map((m) => (
              <div key={m.subject?.id}>
                {m.subject?.branch ? (
                  <Link href={`/branches/${m.subject.branch.id}`}>
                    <a>{(m.subject.branch.sales_area && m.subject.branch.sales_area.name) || '' }</a>
                  </Link>
                ) : '-'}
              </div>
            ))}
          </Descriptions.Item>
          {partner.type_organization.length ? (
            <Descriptions.Item label="Тип партнера">
              {partner.type_organization.map((t) => (
                <Tag key={t.id} color="blue">
                  {t.prop_value?.value}
                </Tag>
              ))}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </PageHeader>

      <PartnersDrawer
        partnerId={+id}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onUpdateParter}
      />
    </>
  );
};

export default withRouter(PartnerPageHeader);

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { FeedsAddDrawer } from '~/components';

const FeedsPageHeader: FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  return (
    <>
      <PageHeader
        title="Публикации"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Button
          icon={<PlusCircleOutlined />}
          style={{ marginRight: 8 }}
          type="primary"
          onClick={() => setVisibleDrawer(true)}
        >
          Добавить
        </Button>
      </PageHeader>

      <FeedsAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => setVisibleDrawer(false)}
      />
    </>
  );
};

export default withRouter(FeedsPageHeader);

const getActiveKey: Props = (routes, pathname) => {
  const selected = Object.keys(routes).filter((key) => {
    return routes[key].includes(pathname);
  });

  if (selected && selected.length > 0) {
    return selected[0];
  }

  return undefined;
};

export default getActiveKey;

type Props = (routes: { [key: string]: string[] }, pathname: string) => string | undefined;

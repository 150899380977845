import { FC, useMemo } from 'react';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';

import {
  LogisticsPageDeliveryRequestTableProps,
  LogisticsPageDeliveryRequestTableQueryProps,
} from './LogisticsPageDeliveryRequestTable.props';
import { Table } from '~/components';
import { useGetTasksByOrderIdQuery, useGetTasksCountByOrderIdQuery } from '~/apollo/generated/hooks';
import { TTaskByOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const LogisticsPageDeliveryRequestTable: FC<LogisticsPageDeliveryRequestTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
      },
      {
        Header: 'Текущий статус',
        id: 'status',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original && row.original.status && row.original.status.name}
          </span>
        )
      },
      {
        Header: 'Дата начала',
        accessor: 'date_start_string'
      },
      {
        Header: 'Дедлайн',
        accessor: 'deadline_string'
      },
      {
        Header: 'Получатель',
        accessor: 'provider',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original && row.original.subject && row.original.subject.name}
          </span>
        )
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const LogisticsPageDeliveryRequestTableQuery: FC<LogisticsPageDeliveryRequestTableQueryProps> = ({
  router,
  ...props
}) => {
  const page = router.query.page_shipment ? +router.query.page_shipment : 1;
  const limit = 10;

  const { data } = useGetTasksByOrderIdQuery({
    variables: {
      taskTypeBrief: 'DELIVERY_REQUEST',
      objectTypeBrief: 'ORDER',
      offset: (page && ((page - 1) * limit)) || 0,
      limit
    }
  });

  const { data: dataCount } = useGetTasksCountByOrderIdQuery({
    variables: {
      taskTypeBrief: 'DELIVERY_REQUEST',
      objectTypeBrief: 'ORDER'
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <LogisticsPageDeliveryRequestTable
      id="ID_LOGISTICS_PAGE_DELIVERY_REQUEST_TABLE"
      data={data?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      {...props}
    />
  );
};

export default withRouter(LogisticsPageDeliveryRequestTableQuery);

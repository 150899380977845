import { FC } from 'react';
import { Radio } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { CityRadioGroupProps, CityRadioGroupQueryProps } from './CityRadioGroup.props';

const CityRadioGroup: FC<CityRadioGroupProps> = ({
  data,
  value,
  onChange = () => ({}),
}) => (
  <Radio.Group
    value={value}
    onChange={onChange}
    options={data.map((opt) => ({
      label: opt.value,
      value: opt.id
    }))}
    optionType="button"
    buttonStyle="solid"
  />
);

const CityRadioGroupQuery: FC<CityRadioGroupQueryProps> = ({ value, onChange = () => ({}) }) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'CITY' }
        }
      }
    }
  });

  return (
    <CityRadioGroup
      data={data?.t_prop_value || []}
      value={value}
      onChange={onChange}
    />
  );
};

export default CityRadioGroupQuery;

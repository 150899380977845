import { useState, FC } from 'react';
import { useSession } from 'next-auth/react';
import { ApolloClient } from '@apollo/client';
import { Form, Input, Row, Col, Space, Button, message } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import { CommentFormProps } from './CommentForm.props';
import FileUpload from '../../../FileUpload';
import ContactSelect from '../ContactSelect';
import { useAddCommentMutation } from '~/apollo/generated/hooks';
import { FILE_UPLOAD } from '~/apollo/mutations/files';
import { getFileAsBase64 } from '~/utils';

const CommentForm: FC<CommentFormProps> = ({
  objectId,
  objectTypeId,
  entityType = null,
  onAddTask = () => ({}),
}) => {
  const [loading, setLoading] = useState(false);
  const { data: session } = useSession();
  const [form] = Form.useForm();

  const saveFiles = async (client: ApolloClient<any>, objectId: number) => {
    const files = form.getFieldValue('files');

    try {
      await Promise.all(files.map(async (f: UploadFile) => {
        const base64str = await getFileAsBase64(f.originFileObj);

        await client.mutate({
          mutation: FILE_UPLOAD,
          variables: {
            objectId,
            objectTypeId: 5,
            linkTypeId: 5,
            fileType: 'File',
            mimeType: f.type,
            fileName: f.name,
            base64str,
          }
        });
      }));

      return {};
    } catch (error) {
      return { error };
    }
  };

  const [saveComment, { client }] = useAddCommentMutation({
    onCompleted: async (data) => {
      if (!data.insert_t_comment_one) {
        setLoading(false);
        return message.error('При сохранении комментария произошла ошибка');
      }

      if (form.getFieldValue('files') && form.getFieldValue('files').length > 0) {
        const res = await saveFiles(client, data.insert_t_comment_one.comment_id);

        if (res.error) {
          setLoading(false);
          return message.error('При сохранении комментария произошла ошибка');
        }
      }

      setLoading(false);
      message.success('Коментарий сохранен успешно');
      form.resetFields();
      onAddTask(data.insert_t_comment_one.comment_id);
    },
    onError: () => {
      setLoading(false);
      message.error('При сохранении комментария произошла ошибка');
    }
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;

    return e && e.fileList;
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const comment = {
          text: values.text,
          object_id: objectId,
          object_type_id: objectTypeId,
          ins_user: session?.user.subject_id
        };

        if (values.contactId && entityType === 'PARTNER') {
          comment.object_id = values.contactId;
        }

        setLoading(true);

        saveComment({
          variables: { comment }
        });
      })
      .catch(() => ({}));
  };

  return (
    <Form form={form}>
      <Form.Item
        name="text"
        rules={[{
          required: true,
          validator: (_, value) => {
            if (!value || value.toString().trim() === '') {
              return Promise.reject('Обязательное поле!');
            }

            return Promise.resolve();
          }
        }]}
      >
        <Input.TextArea
          placeholder="Введите комментарий"
          rows={2}
        />
      </Form.Item>
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Col>
          {entityType === 'PARTNER' ? (
            <Form.Item name="contactId">
              <ContactSelect objectId={objectId} />
            </Form.Item>
          ) : null}
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <FileUpload label={false} icon={<PaperClipOutlined />} />
          </Form.Item>
        </Col>
        <Col>
          <Space>
            <Button
              htmlType="reset"
              disabled={loading}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            >
              Создать
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default CommentForm;

import { useState, useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Button, Tag, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { CellProps } from 'react-table';

import { OrderPageTasksTableProps, OrderPageTasksTableQueryProps } from './OrderPageTasksTable.props';
import Table from '../../Table';
import TaskPriorityIcon from '../../TaskPriorityIcon';
import TasksAddDrawer from '../../tasks/TasksAddDrawer';
import { useGetCurrentOrderQuery, useGetTaskListQuery, useGetTasksCountByOrderIdQuery } from '~/apollo/generated/hooks';
import { Order_By, TTaskProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const OrderPageTasksTable: FC<OrderPageTasksTableProps> = ({
  taskId,
  data,
  order,
  ...props
}) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['id']>) => (
          <span>
            <Link href={`/tasks/${value}`}>
              <a>{value}</a>
            </Link>
          </span>
        )
      },
      {
        Header: 'Описание',
        accessor: 'description',
        Cell: ({ value, row }: CellProps<TTaskProps, TTaskProps['description']>) => (
          <span>
            <Link href={`/tasks/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        )
      },
      {
        Header: 'Дата создания',
        accessor: 'ins_date',
        Cell: ({ row }: CellProps<TTaskProps, TTaskProps['ins_date']>) => (
          <span>{row.original.ins_date_string || ''}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Срок выполнения',
        accessor: 'deadline',
        sorter: true,
      },
      {
        Header: 'Ответственный',
        accessor: 'subject_owner',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['subject_owner']>) => (
          <span>
            {value ? (
              <Link href={`/employees/${value.id}`}>
                <a>
                  {(value && value.name) || ''} {(value && value.lastname) || ''}
                </a>
              </Link>
            ) : null}
          </span>
        )
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['status']>) => (
          <span>
            <Tag color="blue">
              {(value && value.name) || ''}
            </Tag>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Тип задачи',
        accessor: 'tip_zadachi',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['tip_zadachi']>) => (
          <span>
            {value.map((type) => (
              <Tooltip
                key={type.id}
                title={type.prop_value?.value}
              >
                {type.prop_value ? (
                  <TaskPriorityIcon priority={type.prop_value.brief} />
                ) : null}
              </Tooltip>
            ))}
          </span>
        )
      },
      {
        Header: 'Приоритет',
        accessor: 'task_priority',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['task_priority']>) => (
          <span>
            {(value && value.name) || ''}
          </span>
        )
      }
    ],
    []
  );

  return (
    <div style={{ backgroundColor: '#FFF', padding: 16 }}>
      {order?.archived ? null : (
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          style={{ marginBottom: 16 }}
          onClick={() => setVisibleDrawer(true)}
        >
          Добавить
        </Button>
      )}
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <TasksAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getTaskList', 'getTasksCountByOrderId']
          });
          setVisibleDrawer(false);
        }}
        objectId={taskId ? +taskId : null}
        objectTypeId={2}
      />
    </div>
  );
};

const OrderPageTasksTableQuery: FC<OrderPageTasksTableQueryProps> = ({ router }) => {
  const { id } = router.query;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (!id) return null;

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'status') {
        return { status: { name: value } };
      }

      return { [key]: value };
    });
  } else {
    orderBy = [{ deadline: Order_By.Asc }];
  }

  const { data: order } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  const { data: dataTasks } = useGetTaskListQuery({
    variables: {
      offset: (page - 1) * limit,
      limit,
      type: 'TASK',
      objectId: id,
      objectTypeBrief: 'ORDER',
      orderBy
    }
  });

  const { data: dataCount } = useGetTasksCountByOrderIdQuery({
    variables: {
      taskTypeBrief: 'TASK',
      orderId: id,
      objectTypeBrief: 'ORDER'
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <OrderPageTasksTable
      id="ID_ORDER_PAGE_TASKS_TABLE"
      taskId={id}
      data={dataTasks?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      order={order?.t_order[0]}
    />
  );
};

export default withRouter(OrderPageTasksTableQuery);

import { FC } from 'react';
import { Cell } from 'react-table';

import styles from './ColumnFilter.module.css';

const ColumnFilter: FC<Cell> = ({
	column: { filterValue, setFilter },
}) => (
  <input
    value={filterValue || ''}
    onChange={e => {
      setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    }}
    className={styles.input}
  />
);

export default ColumnFilter;

import { useState, useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Row, Button, Tooltip } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Row as RowProps, CellProps } from 'react-table';
import { useApolloClient } from '@apollo/client';

import { TasksPageTableProps, TasksPageTableQueryProps } from './TasksPageTable.props';
import { useGetTaskCountQuery, useGetTaskForTaskListPageQuery } from '~/apollo/generated/hooks';
import Table from '../../Table';
import TaskPriorityIcon from '../../TaskPriorityIcon';
import TasksAddReportModal from '../../tasks/TasksAddReportModal';
import DeleteButton from './DeleteButton';
import { TTaskProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const TasksPageTable: FC<TasksPageTableProps> = ({ data, ...props }) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentTask, setCurrentTask] = useState<TTaskProps | null>(null);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'task_priority',
        width: 40,
        Cell: ({ value, row }: CellProps<TTaskProps, TTaskProps['task_priority']>) => (
          <span>
            <Row
              align="middle"
              style={{ height: '100%', flexWrap: 'nowrap' }}
            >
              {row.original.tip_zadachi
                .filter((type) => type ? true : false)
                .map((type) => (
                  <Tooltip
                    key={type.id}
                    style={{
                      marginRight: (value && value.name === 'Высокий') ? 16 : 0,
                    }}
                    title={type.prop_value?.value}
                  >
                    {type.prop_value ? (
                      <TaskPriorityIcon priority={type.prop_value.brief} />
                    ) : null}
                  </Tooltip>
              ))}
              {(value && value.name === 'Высокий') ? (
                <FireOutlined style={{ color: 'red' }} />
              ) : null}
            </Row>
          </span>
        )
      },
      {
        Header: '№',
        accessor: 'id',
        width: 70,
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['id']>) => (
          <span>
            <Link href={`/tasks/${value}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Объект',
        accessor: 'object',
        width: 200,
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {row.original.object_id ? (
              <>
                {(row.original.object_task && row.original.object_type?.brief === 'TASK') ? (
                  <Link href={`/tasks/${row.original.object_task.id}`}>
                    <a>Задача №{ row.original.object_task.id }</a>
                  </Link>
                ) : null}

                {(row.original.object_subject && row.original.object_type?.brief === 'SUBJECT') ? (
                  <>
                    {(row.original.object_subject?.subject_type?.brief === 'EMPLOYEE') ? (
                      <Link href={`/employees/${row.original.object_subject.id}`}>
                        <a>{ row.original.object_subject?.name || '' } { row.original.object_subject?.lastname || '' }</a>
                      </Link>
                    ) : null}
                    {(row.original.object_subject?.subject_type?.brief === 'ORGANIZATION') ? (
                      <Link href={`/organizations/${row.original.object_subject.id}`}>
                        <a>{ row.original.object_subject?.name || '' } { row.original.object_subject?.lastname || '' }</a>
                      </Link>
                    ) : null}
                    {(row.original.object_subject?.subject_type?.brief === 'CONTACT_PERSON') ? (
                      <Link href={`/contacts/${row.original.object_subject.id}`}>
                        <a>{ row.original.object_subject?.name || '' } { row.original.object_subject?.lastname || '' }</a>
                      </Link>
                    ) : null}
                    {(row.original.object_subject?.subject_type?.brief === 'PARTNER') ? (
                      <Link href={`/partners/${row.original.object_subject.id}`}>
                        <a>{ row.original.object_subject?.name || '' } { row.original.object_subject?.lastname || '' }</a>
                      </Link>
                    ) : null}
                  </>
                ) : null}

                {(row.original.object_order && row.original.object_type?.brief === 'ORDER') ? (
                  <Link href={`/orders/${row.original.object_order.id}`}>
                    <a>Заказ №{ row.original.object_order.id }</a>
                  </Link>
                ) : null}
              </>
            ) : null}
          </span>
        ),
      },
      {
        Header: 'Описание',
        accessor: 'description',
        Cell: ({ value, row }: CellProps<TTaskProps, TTaskProps['description']>) => (
          <span style={row.original.last_comment ? { lineHeight: '16px' } : {}}>
            <Link href={`/tasks/${row.original.id}`}>
              <a>{value}</a>
            </Link>
            {row.original.last_comment ? (
              <span>
                &nbsp;
                {row.original.last_comment.text}
                <br />
                <strong>
                  {row.original.last_comment.author}
                </strong>
                { row.original.last_comment.ins_date }
              </span>
            ) : null}
          </span>
        )
      },
      {
        Header: 'Срок выполнения',
        accessor: 'deadline',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['deadline']>) => (
          <span
            style={{ color: (moment(value, 'DD.MM.YYYY') < moment()) ? 'red':'' }}
          >
            {value}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Ответственный',
        accessor: 'subject_owner',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['subject_owner']>) => (
          <span>
            {value ? (
              <Link href={`/employees/${value.id}`}>
                <a>{ value.name || '' } {value.lastname || '' }</a>
              </Link>
            ) : null }
          </span>
        )
      },
      {
        Header: 'Действия',
        id: 'action',
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {row.original.status?.name !== 'Завершена' ? (
              <Row align="middle" justify="center" style={{ height: '100%' }}>
                <Button
                  size="small"
                  onClick={() => showModal(row.original)}
                >
                  Добавить отчет
                </Button>
              </Row>
            ) : null}
          </span>
        )
      },
      {
        Header: '',
        id: 'remove',
        width: 50,
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <Row align="middle" justify="center" style={{ height: '100%' }}>
            <DeleteButton id={row.original.id} />
          </Row>
        )
      }
    ],
    [data]
  );

  const showModal = (task?: TTaskProps) => {
    setCurrentTask(task || null);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setCurrentTask(null);
    setVisibleModal(false);
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <TasksAddReportModal
        visible={visibleModal}
        onOk={() => {
          client.refetchQueries({
            include: ['getTaskForTaskListPage', 'getCurrentTask']
          });
          closeModal();
        }}
        onCancel={() => closeModal()}
        task={currentTask}
      />
    </>
  );
};

const TasksPageTableQuery: FC<TasksPageTableQueryProps> = ({ router }) => {
  const { data: session } = useSession();
  const statusId = router.query.status ? +router.query.status : null;
  const userType = router.query.role ? router.query.role : null;
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 15;

  let orderBy: any[] = [];
  let _or: any[] | undefined = [];
  let _and: any = [];

  if (userType) {
    if (userType === 'owner') {
      _or.push({
        owner_id: { _eq: session?.user.subject_id }
      });
    }
    if (userType === 'creator') {
      _or.push({
        ins_user: { _eq: session?.user.id }
      });
    }
    if (userType === 'coexecutor') {
      _or.push({
        task_links: {
          object_id: { _eq: session?.user.subject_id },
          link_type: {
            brief: { _eq: 'SOISPOLNITEL' }
          },
          object_type: {
            brief: { _eq: 'SUBJECT' }
          }
        }
      });
    }
    if (userType === 'watcher') {
      _or.push({
        task_links: {
          object_id: { _eq: session?.user.subject_id },
          link_type: {
            brief: { _eq: 'WATCHER' }
          },
          object_type: {
            brief: { _eq: 'SUBJECT' }
          }
        }
      });
    }
  } else {
    _or.push({ manager_id: { _eq: session?.user.subject_id } },
      { subject_id: { _eq: session?.user.subject_id } },
      { owner_id: { _eq: session?.user.subject_id } },
      { ins_user: { _eq: session?.user.id } },
      {
        task_links: {
          object_id: { _eq: session?.user.subject_id },
          link_type: {
            brief: { _eq: 'WATCHER' }
          },
          object_type: {
            brief: { _eq: 'SUBJECT' }
          }
        }
      },
      {
        task_links: {
          object_id: { _eq: session?.user.subject_id },
          link_type: {
            brief: { _eq: 'SOISPOLNITEL' }
          },
          object_type: {
            brief: { _eq: 'SUBJECT' }
          }
        }
      },
      {
        parent_task: {
          _or: [
            { manager_id: { _eq: session?.user.subject_id } },
            { subject_id: { _eq: session?.user.subject_id } },
            { owner_id: { _eq: session?.user.subject_id } },
            { ins_user: { _eq: session?.user.id } },
            {
              task_links: {
                object_id: { _eq: session?.user.subject_id },
                link_type: {
                  brief: { _eq: 'WATCHER' }
                },
                object_type: {
                  brief: { _eq: 'SUBJECT' }
                }
              }
            },
            {
              task_links: {
                object_id: { _eq: session?.user.subject_id },
                link_type: {
                  brief: { _eq: 'SOISPOLNITEL' }
                },
                object_type: {
                  brief: { _eq: 'SUBJECT' }
                }
              }
            }
          ]
        }
      },
      {
        child_tasks: {
          owner_id: { _eq: session?.user.subject_id }
        }
      });
  }

  if (managerId) {
    _or = undefined;
    _and = [
      { owner_id: { _eq: managerId } }
    ];
  }

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'id') {
        return { task_id: value };
      }

      return { [key]: value };
    });
  }

  const where = {
    task_type: {
      brief: { _eq: 'TASK' }
    },
    deleted: { _eq: false },
    status_id: statusId ? {
      _eq: statusId
    } : undefined,
    _or,
    _and
  };

  const { data: dataTasks } = useGetTaskForTaskListPageQuery({
    variables: {
      where,
      offset: (page - 1) * limit,
      limit,
      // type: 'TASK',
      orderBy
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: dataCount } = useGetTaskCountQuery({
    variables: {
      where
    },
    fetchPolicy: 'cache-and-network'
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <TasksPageTable
      id="ID_TASKS_PAGE_TABLE"
      data={dataTasks?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      getRowProps={(row: RowProps<TTaskProps>) => ({
        style: {
          opacity: row.original.status?.name !== 'Завершена' ? 1 : 0.5
        }
      })}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
    />
  );
};

export default withRouter(TasksPageTableQuery);

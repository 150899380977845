import { FC } from 'react';

import { HorizontalLineProps } from "./HorizontalLine.props";

const HorizontalLine: FC<HorizontalLineProps> = ({
  width = '100%',
  color = '#EEEEEE',
  thickness = '1px',
  style = {},
}) => {
  return (
    <hr
      style={{
        width,
        backgroundColor: color,
        height: thickness,
        border: 'none',
        ...style,
      }}
    />
  );
};

export default HorizontalLine;

import { Fragment, useMemo, FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Space, Avatar, Tooltip, Badge, Tag, Button } from 'antd';
import {UserOutlined, InfoCircleOutlined, CheckOutlined} from '@ant-design/icons';
import { CellProps } from 'react-table';
import fetch from 'isomorphic-unfetch';

import { PartnersPageTableProps, PartnersPageTableQueryProps } from './PartnersPageTable.props';
import { Table } from '~/components';
import { useGetPartnerListForPartnersPageQuery } from '~/apollo/generated/hooks';
import { TPagePartnerProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { userHaveRights } from '~/utils';

const getStatus = (id: number) => {
  switch (id) {
    case 5:
      return 'default';
    case 6:
      return 'processing';
    case 2:
      return 'success';
    case 1:
      return 'error';
    default:
      break;
  }
};

const PartnersPageTable: FC<PartnersPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Наименование',
        accessor: 'name',
        width: 300,
        Cell: ({ value, row }: CellProps<TPagePartnerProps, TPagePartnerProps['name']>) => (
          <span>
            <Space>
              {row.original.status ? (
                <Tooltip title={row.original.status.name || ''}>
                  {row.original.status_id ? (
                    <Badge
                      status={getStatus(row.original.status_id)}
                    />
                  ) : null}
                  {row.original.guid ? (
                    // <Badge count={10} color='#faad14' />
                      <CheckOutlined style={{ color: 'rgb(250 173 18)' }} />
                  ) :
                    <Badge count={10} color='#fff' />
                  }
                </Tooltip>
              ) : null}
              <Link href={`/partners/${row.original.id}`}>
                <a target="_blank">{value}</a>
              </Link>
              &nbsp;
              {row.original.tag_list.map((tag) => (
                <Tag key={tag.id} color="orange">
                  {tag.prop_value?.value}
                </Tag>
              ))}
            </Space>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Email/Телефон',
        accessor: 'email',
        Cell: ({ value, row }: CellProps<TPagePartnerProps, TPagePartnerProps['email']>) => (
          <span>
            {value ? (
              <Link href={`mailto:${value}`}>
                <a>{value}</a>
              </Link>
            ) : null}
            {row.original.phone ? (
              <>&nbsp;/&nbsp;
                <Link href={`tel:${row.original.phone}`}>
                  <a>{row.original.phone}</a>
                </Link>
              </>
            ) : null}
          </span>
        )
      },
      {
        Header: 'Город',
        accessor: 'city',
        Cell: ({ value }: CellProps<TPagePartnerProps, TPagePartnerProps['city']>) => (
          <span>
            {value?.map((city) => (
              <Fragment key={city.id}>{city.prop_value?.value}</Fragment>
            ))}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Менеджер',
        accessor: 'manager',
        Cell: ({ value }: CellProps<TPagePartnerProps, TPagePartnerProps['manager']>) => (
          <span>
            {value?.map((manager) => (
              <Fragment key={manager.id}>
                {manager.subject?.avatar ? (
                  <Avatar
                    size={24}
                    src={
                      <Image
                        src={`http://oasis.retailsuite.ru/${manager.subject.avatar}?width=50`}
                        width={24}
                        height={24}
                        objectFit="cover"
                        alt=""
                      />
                    }
                  />
                ) : (
                  <Avatar size={24} icon={<UserOutlined />} />
                )}
                <span style={{ marginLeft: 8 }}>
                  {manager.subject?.name || ''} {manager.subject?.lastname || ''}
                </span>
              </Fragment>
            ))}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Сфера деятельности',
        accessor: 'SFERA_DEYATELNOSTI',
        width: 200,
        Cell: ({ value }: CellProps<TPagePartnerProps, TPagePartnerProps['SFERA_DEYATELNOSTI']>) => (
          <span>
            {value?.map((area) => (
              <Tag key={area.id} color="orange">{area.prop_value?.value || ''}</Tag>
            ))}
          </span>
        )
      },
      {
        Header: '',
        id: 'action',
        width: 36,
        Cell: () => (
          <span>
            <InfoCircleOutlined style={{ color: '#EB2F96' }} />
          </span>
        )
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const PartnersPageTableQuery: FC<PartnersPageTableQueryProps> = ({ router, ...props }) => {
  const { data: session } = useSession();
  const search = router.query.search ? router.query.search.toString() : undefined;
  const visible = router.query.visible ? router.query.visible.toString() : undefined;
  const status = router.query.status ? +router.query.status : undefined;
  const manager = router.query.manager ? +router.query.manager : undefined;
  const city = router.query.city ? +router.query.city : undefined;
  const area = router.query.area ? +router.query.area : undefined;
  const tag = router.query.tag ? +router.query.tag : undefined;
  const type = router.query.type ? +router.query.type : undefined;
  const branch = router.query.branch ? +router.query.branch : undefined;
  const all = router.query.all?.toString() || null;
  const only1c = router.query.only1c?.toString() || null;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');

  const limit = 40;

  const getVariables = () => {
    const _or: any = [];
    const _and: any = [];
    const _not: any = {};
    let orderBy: any[] = [];

    const where: any = {
      deleted: { _eq: false },
      subject_type: {
        brief: { _in: ['ORGANIZATION', 'PARTNER', 'PROVIDER'] }
      }
    };

    if (type) {
      _and.push({
        props: {_and: {prop_id: {_eq: 11}, prop_value_id: {_eq: type}}}
      });
    }

    if (area) {
      _and.push({
        props: {
          prop_value_id: { _eq: area }
        }
      });
    }

    if (city) {
      _and.push({
        props: {
          prop_value_id: { _eq: city }
        }
      });
    }

    if (tag) {
      _and.push({
        props: {
          prop_value_id: { _eq: tag }
        }
      });
    }

    if (search && !!(all && all === 'no')) {
      _and.push({
        _or: [
          {
            tokens: { token: { _like: `${search.trim().toLowerCase()}%` || null } }
          }
        ]
      });
    }


    if (only1c && only1c === 'yes') {
      console.log("##### only1c", only1c);
      _and.push({
        _or: [
          {
            guid: { _is_null: false }
          }
        ]
      });
    }

    if (search && !(all && all === 'no')) {
      _and.push({
        _or: [
          {
            clear_search_name: { _ilike: (search && `%${(search.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}%`) || null }
          },
          {
            object_subject_links: { link_type_id: { _eq: 1 }, v_subject: { clear_search_name: { _like: (search && `%${(search.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}%`) || null } } }
          }
        ]
      });
    }

    if (!visible) {
      _or.push({
        subject_links: {
          object_id: { _eq: session?.user.subject_id },
          t_link_type: {
            brief: { _eq: 'PARTNER_MANAGER' }
          }
        }
      });
    }

    if (status) {
      where.status_id = {
        _eq: status
      };
    }

    if (manager && manager !== -1) {
      where.subject_links = {
        object_id: { _eq: manager },
        t_link_type: {
          brief: { _eq: 'PARTNER_MANAGER' }
        }
      };
    }

    if (manager === -1) {
      _not.subject_links = {
        t_link_type: {
          brief: { _eq: 'PARTNER_MANAGER' }
        }
      };
    }

    if (branch) {
      _and.push({
        subject_links: {
          t_link_type: {
            brief: { _eq: 'PARTNER_MANAGER' }
          },
          t_object_type: {
            brief: { _eq: 'SUBJECT' }
          },
          subject_object: {
            object_sales_area_link: {
              sales_area_id: { _eq: branch }
            }
          }
        }
      });
    }

    if (sort) {
      orderBy = sort.map((s) => {
        const [ key, value ] = s.split('+');

        if (key === 'city') {
          return { city_name: value };
        }

        if (key === 'manager') {
          return { partner_manager_name: value };
        }

        return { [key]: value };
      });
    }

    if (_and.length) {
      where._and = _and;
    }

    if (_or.length) {
      where._or = _or;
    }

    if (Object.keys(_not).length !== 0) {
      where._not = _not;
    }

    return {
      where,
      limit,
      offset: (page - 1) * limit,
      orderBy
    };
  };

  const { data } = useGetPartnerListForPartnersPageQuery({
    variables: getVariables()
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  const getPartners = async () => {
    const { where, orderBy } = getVariables();

    await fetch('/api/partners-export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        where,
        orderBy,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        }

        const error: any = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "partners.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };

  return (
    <>
      <PartnersPageTable
        id="ID_PARTNERS_PAGE_TABLE"
        data={data?.partner_list || []}
        pagination={{
          current: page,
          total: (data && data?.partner?.aggregate?.count) || 0,
          pageSize: limit,
        }}
        onPaginationChange={onTableChange}
        onSorterChange={onSorterChange}
        initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
        {...props}
      />
      {userHaveRights(session?.user.user_id) && data?.partner_list.length ? (
        <Button type="link" onClick={getPartners}>Выгрузить</Button>
      ) : null}
    </>
  );
};

export default withRouter(PartnersPageTableQuery);

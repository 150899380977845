import { gql } from '@apollo/client';
import { CURRENT_EMPLOYEE_INFO } from '../fragments/employees';

export const GET_EMPLOYEES_LIST = gql`
  query getEmployeesList(
    $where: t_subject_bool_exp = { deleted: { _eq: false } }
    $limit: Int
    $offset: Int
    $orderBy: [t_subject_order_by!] = [{ name: asc }]
  ) {
    employee_list: t_subject(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      subject_id
      id: subject_id
      name
      lastname
      phone
      email
      is_active
      avatar
      sales_areas {
        object_id
        sales_area {
          sales_area_id
        }
      }
      branch: subject_branch
      city: props(where: { prop: { brief: { _eq: "CITY" } } }) {
        prop_value {
          brief
          value
          id: prop_value_id
        }
        prop {
          brief
          name
          id: prop_id
        }
        prop_value_id
        prop_id
        id: subject_prop_id
        subject_prop_id
      }
      dolznost: props(where: { prop: { brief: { _eq: "DOLZHNOST" } } }) {
        prop_value {
          brief
          value
          id: prop_value_id
        }
        prop {
          brief
          name
          id: prop_id
        }
        prop_value_id
        prop_id
        id: subject_prop_id
        subject_prop_id
      }
    }
  }
`;

export const GET_EMPLOYEES_COUNT = gql`
  query getEmployeesCount(
    $where: t_subject_bool_exp = { deleted: { _eq: false } }
  ) {
    employee: t_subject_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CURRENT_EMPLOYEE = gql`
  ${CURRENT_EMPLOYEE_INFO}
  query getCurrentEmployee($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{subject_id: desc}]) {
    employee: t_subject(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...currentEmployeeInfo
    }
  }
`;

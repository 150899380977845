import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Select, Button, Space } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { TasksPageHeaderProps } from './TasksPageHeader.props';
import TasksStatusRadioGroup from '../TasksStatusRadioGroup';
import SubjectSelect from '../../SubjectSelect';
import TasksAddDrawer from '../TasksAddDrawer';

const TasksPageHeader: FC<TasksPageHeaderProps> = ({ router }) => {
  const client = useApolloClient();
  const status = router.query.status ? +router.query.status : undefined;
  const userRole = router.query.role ? router.query.role : undefined;
  const manager = router.query.manager ? +router.query.manager : undefined;
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  return (
    <>
      <PageHeader
        title="Задачи"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Row justify="space-between" align="middle">
          <Space>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
            <Select
              style={{ width: 200 }}
              placeholder="Моя роль"
              allowClear
              defaultValue={userRole}
              onChange={(userType) => onFilter('role', userType)}
            >
              <Select.Option value="owner">
                Я ответственный
              </Select.Option>
              <Select.Option value="creator">
                Я создал
              </Select.Option>
              <Select.Option value="coexecutor">
                Я соисполнитель
              </Select.Option>
              <Select.Option value="watcher">
                Я наблюдатель
              </Select.Option>
            </Select>

            <TasksStatusRadioGroup
              defaultValue={status || 'all'}
              onChange={(e) => onFilter('status', e.target.value)}
            />

            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Менеджер"
              defaultValue={manager}
              nullOption={{ label: 'Без менеджера', value: -1 }}
              onChange={(manager) => onFilter('manager', manager)}
            />
          </Space>

          <Button
            icon={<CalendarOutlined />}
            onClick={() => router.replace(`/tasks/calendar`)}
            type="link"
          >
            Календарь
          </Button>

          <Button
            icon={<ClockCircleOutlined />}
            onClick={() => router.replace(`/tasks/activity`)}
            type="link"
          >
            Активность
          </Button>
        </Row>
      </PageHeader>

      <TasksAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getTaskForTaskListPage', 'getTaskCount']
          });
          setVisibleDrawer(false);
        }}
      />
    </>
  );
};

export default withRouter(TasksPageHeader);

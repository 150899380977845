import { FC } from 'react';
import Link from 'next/link';
import { Card } from 'antd';

import { SubjectInfoProps } from './SubjectInfo.props';
import { useGetSubjectByIdQuery } from '~/apollo/generated/hooks';

const SubjectInfo: FC<SubjectInfoProps> = ({
  task
}) => {
  const { data } = useGetSubjectByIdQuery({
    variables: {
      subjectId: task.object_id
    }
  });

  if (!data) return null;

  const subject = data.subject;

  if (!subject) return null;

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 8 }}
    >
      <Card
        bordered={false}
        className="mini-card"
        title={
          <>
            {subject.subject_type?.brief === 'EMPLOYEE' ? 'Сотрудник:' : null}
            {subject.subject_type?.brief === 'ORGANIZATION' ? 'Организация:' : null}
            {subject.subject_type?.brief === 'CONTACT_PERSON' ? 'Контакт:' : null}
            {subject.subject_type?.brief === 'PARTNER' ? 'Партнер:' : null}
          </>
        }
      >
        {subject.subject_type?.brief === 'EMPLOYEE' ? (
          <Link href={`/employees/${subject.id}`}>
            <a>{ subject.name || '' } { subject.lastname || '' }</a>
          </Link>
        ) : null}
        {subject.subject_type?.brief === 'ORGANIZATION' ? (
          <Link href={`/organizations/${subject.id}`}>
            <a>{ subject.name || '' } { subject.lastname || '' }</a>
          </Link>
        ) : null}
        {subject.subject_type?.brief === 'CONTACT_PERSON' ? (
          <Link href={`/contacts/${subject.id}`}>
            <a>{ subject.name || '' } { subject.lastname || '' }</a>
          </Link>
        ) : null}
        {subject.subject_type?.brief === 'PARTNER' ? (
          <Link href={`/partners/${subject.id}`}>
            <a>{ subject.name || '' } { subject.lastname || '' }</a>
          </Link>
        ) : null}
      </Card>
    </Card>
  );
};

export default SubjectInfo;

import { FC } from 'react';
import { Drawer, Button, Form, DatePicker, Row, Col, Radio, message } from 'antd';

import { ShipmentAddDrawerProps } from './ShipmentAddDrawer.props';
import SubjectSelect from '../../SubjectSelect';
import CitySelect from './CitySelect';
import { useAddTaskFuncLazyQuery } from '~/apollo/generated/hooks';

const ShipmentAddDrawer: FC<ShipmentAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const [form] = Form.useForm();

  const [addTask, { loading, client }] = useAddTaskFuncLazyQuery({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    onCompleted: () => {
      message.success('Задание на отгрузку успешно сохранено');
      form.resetFields();
      client?.refetchQueries({
        include: ['getTaskList', 'getCountTask']
      });
      onSave();
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        addTask({
          variables: {
            params: {
              task: {
                typeId: 3,
                statusId: 7,
                subjectId: values.organization,
                dateStart: values.date ? values.date.format('YYYY.MM.DD') : undefined,
              },
              props: [
                {
                  propId: 5,
                  textVal: values.transport,
                },
                {
                  propId: 6,
                  propValueId: values.city,
                }
              ]
            }
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Новая отгрузка"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="shipment_task_add_form"
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Поставщик" name="organization">
              <SubjectSelect
                typeBrief={['ORGANIZATION']}
                placeholder="Выберите поставщика"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Город" name="city">
              <CitySelect />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Плановая дата отгрузки" name="date">
          <DatePicker />
        </Form.Item>
        <Form.Item label="Транспорт" name="transport">
          <Radio.Group
            defaultValue="Транспортная компания"
            options={[
              { label: 'Транспортная компания', value: 'Транспортная компания' },
              { label: 'Наемный транспорт', value: 'Наемный транспорт' },
              { label: 'Попутный транспорт', value: 'Попутный транспорт' },
            ]}
            buttonStyle="solid"
            optionType="button"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ShipmentAddDrawer;

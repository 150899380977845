import { FC } from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useRemoveFTranMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ doc }) => {
  if (!doc) return null;

  const [removeFTran, { loading }] = useRemoveFTranMutation({
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onConfirm = () => {
    if (!doc.id) return;

    removeFTran({
      variables: {
        ftranId: doc.id
      }
    });
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={loading}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined style={{ color: '#EB2F96' }} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

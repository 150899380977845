import { FC } from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useDeleteDocFTranMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ doc, disabled = false }) => {
  if (!doc) return null;

  const [deleteFTranMutation, { loading }] = useDeleteDocFTranMutation({
    refetchQueries: ['getDocRows', 'getDocsList']
  });

  const onConfirm = () => {
    deleteFTranMutation({
      variables: {
        docId: doc.id
      }
    });
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={disabled || loading}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined
          disabled={disabled}
          style={{ color: disabled ? 'rgba(0, 0, 0, 0.25)' : '#FF4D4F' }}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

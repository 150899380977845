import { useState, FC } from 'react';
import { AutoComplete, Spin } from 'antd';
import { debounce } from 'lodash';

import { useAddPropMutation, useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { CitySelectProps } from './CitySelect.props';
import {Order_By} from "~/interfaces";

const CitySelect: FC<CitySelectProps> = ({
  placeholder = 'Выберите город',
  value = undefined,
  defaultValue = undefined,
  cityId,
  allowClear = true,
  defaultActiveFirstOption = false,
  showSearch = false,
  filterOption = false,
  onSearch,
  onSelect,
  onClear,
  notFoundContent,
  allowAdd = false,
  // onBlur: ((e) => { console.log(e)}),
  ...props
}) => {
  const [customCity, setCustomCity] = useState<{ id: number, value: string }>();
  const getVariables = (likeValue?: string, id?: number) => ({
    where: {
      prop: {
        brief: { _eq: 'CITY' }
      },
      value: likeValue
        ? { _ilike: likeValue }
        : undefined,
      prop_value_id: id
        ? { _eq: id }
        : undefined
    },
    orderBy: {value: Order_By.Asc},
    limit: 20
  });

  const { data, refetch, loading: loadingData } = useGetPropValueListQuery({
    variables: getVariables(value || defaultValue, cityId),
    onCompleted: async (res) => {
      if (cityId) {
        const city = res.t_prop_value.find(city => city.id === cityId);

        if (city?.value && props.onChange) {
          props.onChange(city.value, { label: city.value, value: city.value });
        }
      }

      const isCityText = await res.t_prop_value.find(
        city => city.value?.trim().toLowerCase() === value?.trim().toLowerCase()
      );

      if (!isCityText && value) {
        props.onChange && props.onChange(undefined, { label: undefined, value: undefined });
        // setCustomCity({ id: 0, value: value });
        refetch(getVariables());
      } else {
        setCustomCity(undefined);
      }
    }
  });

  const [addCity] = useAddPropMutation({
    onCompleted: (data) => {
      if (data.value && onSelect) {
        const { id, value } = data.value;

        onSelect(value || '', {
          label: value,
          value,
          key: id,
        });
      }
    }
  });

  const onSearchCity = (val: string) => {
    const searchString = `%${val.trim()}%`;

    refetch({
      where: {
        prop: {
          brief: { _eq: 'CITY' }
        },
        value: searchString
          ? { _ilike: searchString }
          : undefined,
      },
    });
  };

  const onSelectCity = (value: string, option: any) => {
    if (option.key === 0 || option.key === '0') {
      addCity({
        variables: {
          value: {
            prop_id: 6,
            value,
          }
        }
      });
    } else {
      onSelect && onSelect(value, option);
    }
  };

  const onClearInput = () => {
    refetch(getVariables());
  };

  return (
    <AutoComplete
      value={value}
      defaultValue={defaultValue}
      filterOption={filterOption}
      notFoundContent={
        notFoundContent || <>{loadingData ? <Spin size="small" /> : null}</>
      }
      placeholder={placeholder}
      showSearch={showSearch}
      defaultActiveFirstOption={defaultActiveFirstOption}
      allowClear={allowClear}
      onSearch={onSearch || debounce(onSearchCity, 300)}
      onSelect={onSelect || onSelectCity}
      onClear={onClear || onClearInput}
      {...props}
    >
      {customCity && allowAdd ? (
        <AutoComplete.Option key={customCity.id} value={customCity.value || ''}>
          {customCity.value}
        </AutoComplete.Option>
      ) : null}
      {data?.t_prop_value.map((city) => (
        <AutoComplete.Option key={city.id} value={city.value || ''}>
          {city.value}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

export default CitySelect;

import { FC } from 'react';
import { Row } from 'antd';
import { CellProps } from 'react-table';

import styles from './SelectRowCell.module.css';

import IndeterminateCheckbox from '../../IndeterminateCheckbox';

const SelectRowCell: FC<CellProps<any>> = ({ row }) => (
  <label className={styles.button} onClick={(e) => e.stopPropagation()}>
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    </Row>
  </label>
);

export default SelectRowCell;

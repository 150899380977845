import { FC } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import { UnArchiveButtonProps } from './UnArchiveButton.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';

const UnArchiveButton: FC<UnArchiveButtonProps> = ({ order, router }) => {
  const [updateOrder, { loading }] = useUpdateOrderByPkMutation({
    onCompleted: () => {
      router.push('/orders');
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onConfirm = () => {
    updateOrder({
      variables: {
        orderId: order.id,
        set: {
          archived: false
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Вернуть из архива?"
      okText="Восстановить"
      cancelText="Передумал"
      onConfirm={onConfirm}
      disabled={loading}
      placement="bottomRight"
    >
      <Tooltip placement="bottomRight" title="Восстановить">
        <Button icon={<SyncOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default UnArchiveButton;

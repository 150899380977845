import { FC } from 'react';
import { Card, Space } from 'antd';
import { withRouter } from 'next/router';

import { OrderPageBasicCommentsProps } from './OrderPageBasicComments.props';
import Comment from '../../Comment';

const OrderPageBasicComments: FC<OrderPageBasicCommentsProps> = ({
  router, order
}) => {



  return (
    <Card bordered={false}>
      {/*<Space>*/}
      {/*  Статус: <StatusSelect order={order} />*/}
      {/*</Space>*/}

      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Comment
          objectId={order.order_id}
          objectTypeId={2}
          style={{ marginTop: 16 }}
          showForm={!order.archived}
          disabled={!!order.archived}
        />
      </Card>
    </Card>
  );
};

export default withRouter(OrderPageBasicComments);

import { FC } from 'react';
import { Drawer, Button, Form, Input, DatePicker, Row, Col, InputNumber, message } from 'antd';

import { ShipmentRequestDrawerProps } from "./ShipmentRequestDrawer.props";
import SubjectSelect from '../../SubjectSelect';
import CityRadioGroup from './CityRadioGroup';
import { useAddTaskFuncLazyQuery } from '~/apollo/generated/hooks';

const ShipmentRequestDrawer: FC<ShipmentRequestDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({}),
  objectId = null,
}) => {
  const [form] = Form.useForm();

  const [addTask, { loading, client }] = useAddTaskFuncLazyQuery({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    onCompleted: () => {
      message.success('Отгрузка сохранена успешно');
      form.resetFields();
      client?.refetchQueries({
        include: ['getTasksByOrderId', 'getTasksCountByOrderId']
      });
      onSave();
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const [dateStart, dateEnd] = values.date;

        addTask({
          variables: {
            params: {
              task: {
                subjectId: values.organization,
                dateStart: dateStart ? dateStart.format('YYYY.MM.DD') : undefined,
                deadline: dateEnd ? dateEnd.format('YYYY.MM.DD') : undefined,
                description: values.description,
                objectId,
                typeId: 2,
                statusId: 4,
                objectTypeId: 2,
                doc: {
                  document: {
                    number: values.document
                  }
                }
              },
              props: [
                {
                  propId: 6,
                  propValueId: values.shipment || null,
                },
                {
                  propId: 7,
                  intVal: values.cubsQty || null,
                },
                {
                  propId: 8,
                  intVal: values.placeQty || null,
                }
              ]
            }
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Планирование отгрузки"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="order_shipment_request_add_form"
        autoComplete="off"
      >

        <Form.Item label="Поставщик" name="organization">
          <SubjectSelect
            typeBrief={['ORGANIZATION']}
            placeholder="Выберите поставщика"
          />
        </Form.Item>

        <Form.Item label="Номер документа поставщика" name="document">
          <Input />
        </Form.Item>

        <Form.Item label="Дата" name="date" rules={[{ required: true, message: 'Обязательное поле!' }]}>
          <DatePicker.RangePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
        </Form.Item>

        <Row gutter={24}>
          <Col>
            <Form.Item label="Кол-во кубов" name="cubsQty">
              <InputNumber min={1} max={999} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Кол-во мест" name="placeQty">
              <InputNumber min={1} max={999} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Вариант отгрузки" name="shipment">
          <CityRadioGroup />
        </Form.Item>

        <Form.Item label="Комментарий" name="description">
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ShipmentRequestDrawer;

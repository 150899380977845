import { FC } from 'react';
import { useSession } from 'next-auth/react';
import { PageHeader, Button } from 'antd';

import { useUpdateMessagesMutation } from '~/apollo/generated/hooks';

const NotifyArchivePageHeader: FC = () => {
  const { data: session } = useSession();

  const [updateMessages] = useUpdateMessagesMutation();

  const viewAll = () => {
    if (!session || !session.user) return;

    updateMessages({
      variables: {
        where: {
          recipient_id: { _eq: session.user.subject_id },
          see: { _eq: false }
        },
        set: {
          see: true,
          viewed_date: 'now()'
        }
      }
    });
  };

  return (
    <PageHeader
      title="Архив уведомлений"
      ghost={false}
      style={{ marginBottom: 16 }}
      footer={
        <Button type="link" onClick={viewAll}>
          Просмотреть все
        </Button>
      }
    />
  );
};

export default NotifyArchivePageHeader;

import { gql } from '@apollo/client';

export const EDIT_EMPLOYEE = gql`
  mutation editEmployee($subject: t_subject_set_input, $subjectId: Int!) {
    subject: update_t_subject_by_pk(pk_columns:{subject_id: $subjectId}, _set: $subject) {
      subject_id
    }
  }
`;

export const MOVE_EMPLOYEE_MESSAGES = gql`
  mutation moveEmployeeMessages ($oldEmpl: Int, $newEmpl: Int) {
    update_t_message(where: {recipient_id: {_eq: $oldEmpl}}, _set: {recipient_id: $newEmpl}) {
      affected_rows
    }
  }
`;

export const MOVE_EMPLOYEE_PARTNERS = gql`
  mutation moveEmployeePartners ($oldEmpl: Int, $newEmpl: Int) {
    update_t_subject_link(where: {link_type_id: {_in: [8, 11]}, object_id: {_eq: $oldEmpl}},
      _set: {object_id: $newEmpl}) {
      affected_rows
    }
  }
`;

export const SET_EMPLOYEE_INACTIVE = gql`
  mutation setEmployeeInActive ($empl: Int!) {
    update_t_subject_by_pk(pk_columns: {subject_id: $empl}, _set: {is_active: false}) {
      subject_id
    }
  }
`;

import { FC, useMemo } from 'react';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';

import {
  LogisticsPageDeliveryTableProps,
  LogisticsPageDeliveryTableQueryProps
} from './LogisticsPageDeliveryTable.props';
import { Table } from '~/components';
import { TTaskByOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const LogisticsPageDeliveryTable: FC<LogisticsPageDeliveryTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
      },
      {
        Header: 'Текущий статус',
        id: 'status',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original && row.original.status && row.original.status.name}
          </span>
        )
      },
      {
        Header: 'Дата начала',
        accessor: 'date_start_string'
      },
      {
        Header: 'Дедлайн',
        accessor: 'deadline_string'
      },
      {
        Header: 'Получатель',
        accessor: 'provider',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original && row.original.subject && row.original.subject.name}
          </span>
        )
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const LogisticsPageDeliveryTableQuery: FC<LogisticsPageDeliveryTableQueryProps> = ({
  ...props
}) => (
  <LogisticsPageDeliveryTable
    id="ID_LOGISTICS_PAGE_DELIVERY_TABLE"
    data={[]}
    {...props}
  />
);

export default withRouter(LogisticsPageDeliveryTableQuery);

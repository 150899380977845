'use client';

import {useState, FC, useEffect} from 'react';
import {Card, Tooltip, Tag, Button, Space, Input, message} from 'antd';
import {withRouter} from 'next/router';
import {useSession} from 'next-auth/react';
import {FireOutlined, EditOutlined} from '@ant-design/icons';

import {TaskPageContentProps} from './TaskPageContent.props';
import {useUpdateTaskByPkMutation} from '~/apollo/generated/hooks';
import TaskFileList from '../TaskFileList';
import TaskCheckList from '../TaskCheckList';
import Comment from '../../Comment';
import TasksPrioritySelect from '../../tasks/TasksPrioritySelect';
import DeleteButton from './DeleteButton';
import { useApolloClient } from '@apollo/client';
import { GET_CURRENT_TASK } from '~/apollo/queries/tasks';

const TaskPageContent: FC<TaskPageContentProps> = ({ router }) => {
  const client = useApolloClient();
  const {id} = router.query;
  const {data: session} = useSession();
  const [edit, setEdit] = useState(false);
  const [taskPriorityId, setTaskPriorityId] = useState();
  const [taskComment, setTaskComment] = useState<string>();
  const [task, setTask] = useState<any>({});

  const [updateTask, {loading}] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      setEdit(false);
    },
    onError: () => {
      message.error('При сохранении задачи произошла ошибка');
    },
    refetchQueries: ['getTaskForTaskListPage', 'getCurrentTask']
  });

  const getTask = async () => {
    if (!id) return null;

    console.log(1);

    const {data} = await client.query({
      query: GET_CURRENT_TASK,
      variables: {
        taskId: +id,
      }
    });

    if (!data) return null;

    setTask(data.t_task[0]);

    if (!task) return null;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    if (!id) return null;

    getTask();
  }, []);

  const updateTaskInfo = () => {
    updateTask({
      variables: {
        taskId: task.id,
        set: {
          priority_id: taskPriorityId,
          description: taskComment,
        }
      }
    });
  };

  const closeTask = () => {
    updateTask({
      variables: {
        taskId: task.id,
        set: {
          status_id: 11
        }
      }
    });
  };

  const openTask = () => {
    updateTask({
      variables: {
        taskId: task.id,
        set: {
          status_id: 10
        }
      }
    });
  };

  return (
    <Card
      bordered={false}
      title={
        <Space>
          {task.task_priority && task.task_priority.name === 'Высокий' && !edit ? (
            <Tooltip
              title={`${task.task_priority.name || ''} приоритет`}
            >
              <FireOutlined style={{color: 'red'}}/>
            </Tooltip>
          ) : null}
          {edit ? (
            <TasksPrioritySelect
              value={task.priority_id}
              style={{width: 120}}
              onChange={setTaskPriorityId}
            />
          ) : null}
          <span>№{task.id}</span>
          {task.status && task.status.name !== 'Завершена' ? (
            <Tag color="green">
              {(task.status && task.status.name) || ''}
            </Tag>
          ) : (
            <Tag color="red">
              {(task.status && task.status.name) || ''}
            </Tag>
          )}
        </Space>
      }
      headStyle={{padding: '0 16px'}}
      bodyStyle={{padding: '8px 16px'}}
      extra={
        <Space>
          {(session?.user.subject_id === task.owner_id || session?.user.id === task.ins_user) ? (
            <>
              {!edit ? (
                <Button onClick={() => setEdit(true)} icon={<EditOutlined/>}/>
              ) : (
                <Button
                  onClick={() => setEdit(false)}
                  type="link"
                  style={{color: 'red'}}
                >Отмена</Button>
              )}
              {edit ? (
                <Button
                  type="primary"
                  onClick={updateTaskInfo}
                  loading={loading}
                  disabled={loading}
                >
                  Сохранить
                </Button>
              ) : null}
              {task.status && task.status.name !== 'Завершена' ? (
                <Button type="ghost" danger onClick={closeTask}>
                  Завершить
                </Button>
              ) : (
                <Button type="primary" ghost onClick={openTask}>
                  Открыть повторно
                </Button>
              )}
              <DeleteButton task={task}/>
            </>
          ) : null}
        </Space>
      }
    >
      {task.name ? (
        <h1 style={{
          fontSize: 20,
          lineHeight: 1
        }}>
          {task.name}
        </h1>
      ) : null}
      {edit ? (
        <Input.TextArea
          placeholder="Введите описание"
          defaultValue={task.description || ''}
          onChange={(e) => setTaskComment(e.target.value)}
        />
      ) : (
        <p>{task.description}</p>
      )}
      {task.gdrive_file_list && task.gdrive_file_list.length ? (
        <TaskFileList task={task}/>
      ) : null}
      <TaskCheckList taskId={task?.id}/>
      <Comment
        objectId={task?.id}
        objectTypeId={1}
        showForm={task?.status?.name !== 'Завершена'}
        style={{marginTop: 36}}
      />
    </Card>
  );
};

export default withRouter(TaskPageContent);

import { useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import {  Tag } from 'antd';
import { CellProps } from 'react-table';

import { OrganizationsPageTableProps, OrganizationsPageTableQueryProps } from './OrganizationsPageTable.props';
import { Table } from '~/components';
import { useGetCompanyListQuery, useGetCountCompanyQuery } from '~/apollo/generated/hooks';
import { TOrganizationProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const OrganizationsPageTable: FC<OrganizationsPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Наименование',
        accessor: 'name',
        Cell: ({ value, row }: CellProps<TOrganizationProps, TOrganizationProps['name']>) => (
          <span>
            <Link href={`/organizations/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
      },
      {
        Header: 'Адрес',
        accessor: 'address',
      },
      {
        Header: 'ИНН',
        accessor: 'inn',
      },
      {
        Header: 'КПП',
        accessor: 'kpp',
      },
      {
        Header: 'Тип организации',
        accessor: 'type_organization',
        Cell: ({ value }: CellProps<TOrganizationProps, TOrganizationProps['type_organization']>) => (
          <span>
            {value.map((type) => (
              <Tag key={type.id} color="blue">
                {type.prop_value?.value}
              </Tag>
            ))}
          </span>
        )
      },
      {
        Header: 'Менеджер',
        accessor: 'manager',
        Cell: ({ value }: CellProps<TOrganizationProps, TOrganizationProps['manager']>) => (
          <span>
            <Link href={`/employees/${value?.subject_id}`}>
              <a>{ (value?.name) || '' } { (value?.lastname) || '' }</a>
            </Link>
          </span>
        )
      }
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const OrganizationsPageTableQuery: FC<OrganizationsPageTableQueryProps> = ({ router, ...props }) => {
  const { data: session } = useSession();
  const search = router.query.search ? router.query.search.toString() : undefined;
  const visible = router.query.visible ? router.query.visible.toString() : undefined;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      return { [key]: value };
    });
  }

  const { data: dataOrganizations } = useGetCompanyListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'ORGANIZATION' }
        },
        name: { _ilike: (search && `%${search.replace(/(\s|'|")/g, '')}%`) || null },
        _or: visible !== 'all' ? [
          {
            subject_links: {
              object_id: { _eq: session?.user.subject_id },
              t_link_type: {
                brief: { _eq: 'ORGANIZATION_MANAGER' }
              }
            }
          },
          {
            subject_links: {
              object_id: { _eq: session?.user.subject_id },
              t_link_type: {
                brief: { _eq: 'OWNER' }
              }
            }
          }
        ] : undefined
      },
      limit,
      offset: (page - 1) * limit,
      orderBy
    }
  });

  const { data: dataCount } = useGetCountCompanyQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'ORGANIZATION' }
        },
        name: { _ilike: (search && `%${search.replace(/(\s|'|")/g, '')}%`) || null },
        _or: visible !== 'all' ? [
          {
            subject_links: {
              object_id: { _eq: session?.user.subject_id },
              t_link_type: {
                brief: { _eq: 'ORGANIZATION_MANAGER' }
              }
            }
          },
          {
            subject_links: {
              object_id: { _eq: session?.user.subject_id },
              t_link_type: {
                brief: { _eq: 'OWNER' }
              }
            }
          }
        ] : undefined
      }
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <OrganizationsPageTable
      id="ID_ORGANIZATIONS_PAGE_TABLE"
      data={dataOrganizations?.company_list || []}
      pagination={{
        current: page,
        total: dataCount?.company.aggregate?.count || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
      {...props}
    />
  );
};

export default withRouter(OrganizationsPageTableQuery);

import { FC } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'next/router';

import { PartnerTabsProps } from './PartnerTabs.props';
import { getActiveKey } from '~/utils';

const menuRoutes: { [key: string]: string[] } = {
  index: ['/partners/[id]'],
  segments: ['/partners/[id]/segments'],
  contacts: ['/partners/[id]/contacts'],
  organizations: ['/partners/[id]/organizations'],
  tasks: ['/partners/[id]/tasks'],
  orders: ['/partners/[id]/orders'],
  documents: ['/partners/[id]/documents'],
};

const PartnerTabs: FC<PartnerTabsProps> = ({ partner, router }) => {
  const { pathname, query: { id } } = router;

  return (
    <Tabs
      size="small"
      activeKey={getActiveKey(menuRoutes, pathname)}
      onChange={(activeKey) => {
        if (activeKey === 'index') {
          router.replace(`/partners/${id}`);
        } else {
          router.replace(`/partners/${id}/${activeKey}`);
        }
      }}
      animated={{ inkBar: false, tabPane: false }}
    >
      <Tabs.TabPane tab="Основное" key="index" />
      <Tabs.TabPane tab="Сегменты" key="segments" />
      <Tabs.TabPane tab="Контакты" key="contacts" />
      <Tabs.TabPane tab="Организации" key="organizations" />
      <Tabs.TabPane tab="Задачи" key="tasks" />

      {(partner.orders.length > 0) ? (
        <Tabs.TabPane tab="Сделки" key="orders" />
      ) : null}
      {(partner.partner_docs.length > 0) ? (
        <Tabs.TabPane tab="Документы" key="documents" />
      ) : null}
    </Tabs>
  );
};

export default withRouter(PartnerTabs);

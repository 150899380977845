import { useState, FC } from 'react';
import { Drawer, Form, Button, Input, DatePicker, message } from 'antd';
import { useSession } from 'next-auth/react';

import { TasksAddDrawerProps } from './TasksAddDrawer.props';
import TasksPriorityRadio from '../TasksPriorityRadio';
import SubjectSelect from '../../SubjectSelect';
import GooglePicker from '../../GooglePicker';
import CheckList from './CheckList';
import TaskTypeRadio from './TaskTypeRadio';
import { useAddTaskMutation } from '~/apollo/generated/hooks';
import { ADD_TASK_PROP } from '~/apollo/mutations/task_props';
import { ADD_TASK } from '~/apollo/mutations/tasks';

const TasksAddDrawer: FC<TasksAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({}),
  objectId = null,
  objectTypeId = null,
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [addTask, { client }] = useAddTaskMutation({
    onCompleted: async (data) => {
      const taskId = data.insert_t_task?.returning[0].task_id;

      if (form.getFieldValue('type')) {
        const res = await saveType(taskId);

        if (!res) {
          setLoading(false);
          message.error('При сохранении задачи произошла ошибка');
          return;
        }
      }

      const list = form.getFieldValue('checklist');

      if (list && list.length) {
        const res = await saveCheckList(taskId, list);

        if (!res) {
          setLoading(false);
          message.error('При сохранении задачи произошла ошибка');
          return;
        }
      }

      setLoading(false);
      message.success('Задача успешно сохранена');
      form.resetFields();
      onSave();
    },
    onError: (err) => {
      setLoading(false);
      console.log(JSON.parse(JSON.stringify(err)));
      message.error('При сохранении задачи произошла ошибка');
    },
  });

  const saveType = async (taskId: any) => {
    try {
      const taskProp = [{
        task_id: taskId,
        prop_id: 10,
        prop_value_id: form.getFieldValue('type')
      }];

      await client.mutate({
        mutation: ADD_TASK_PROP,
        variables: {
          taskProp
        }
      });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const saveCheckList = async (parentId: any, list: any[]) => {
    try {
      await client.mutate({
        mutation: ADD_TASK,
        variables: {
          objects: list.map((task) => ({
            name: task.title,
            type_id: 5,
            status_id: task.completed ? 13 : 12,
            deadline: task.date,
            deadline_time: task.time,
            priority_id: task.important ? 5 : null,
            parent_id: parentId,
            owner_id: (task.owner && task.owner.id) || session?.user.subject_id,
            ins_user: session?.user.id
          }))
        }
      });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const task: any = {
          description: values.description,
          deadline: values.date ? values.date.format('YYYY-MM-DD') : values.date,
          type_id: 4,
          status_id: 10,
          priority_id: values.priority,
          owner_id: values.ownerId || session?.user.subject_id,
          object_id: objectId,
          object_type_id: objectTypeId,
          ins_user: session?.user.id,
        };

        if (values.files && values.files.length) {
          task.doc = {
            gdrive_file_list: values.files
          };
        }

        setLoading(true);

        addTask({
          variables: {
            objects: [task]
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Добавление задачи"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="task_add_form"
        autoComplete="off"
      >
        <Form.Item label="Тип задачи" name="type">
          <TaskTypeRadio />
        </Form.Item>
        <Form.Item label="Описание задачи" name="description">
          <Input.TextArea
            placeholder="Описание задачи"
            rows={4}
          />
        </Form.Item>
        <Form.Item label="Срок выполнения" name="date">
          <DatePicker
            format="DD.MM.YYYY"
            placeholder="Указать срок выполнения"
          />
        </Form.Item>
        <Form.Item label="Приоритет" name="priority">
          <TasksPriorityRadio />
        </Form.Item>

        <Form.Item label="Прикрепленные файлы" name="files">
          <GooglePicker />
        </Form.Item>
        <Form.Item label="Ответственные" name="ownerId">
          <SubjectSelect
            typeBrief={['EMPLOYEE']}
            placeholder="Выберите ответственного"
          />
        </Form.Item>
        <Form.Item name="checklist">
          <CheckList />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default TasksAddDrawer;

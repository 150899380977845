const getStatusColor = (name: string | null | undefined): string => {
  if (name === 'Запрос') {
    return 'red';
  }
  if (name === 'Закрыт') {
    return 'gray';
  }
  if (name === 'В работе') {
    return 'green';
  }
  if (name === 'Отгружен') {
    return 'blue';
  }
  return '';
};

export default getStatusColor;

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { Row, Col, Tabs } from 'antd';

import { OrderPageProductsTabsProps } from './OrderPageProductsTabs.props';
import { useChangeOrderVersionLazyQuery, useDeleteOrderVersionMutation, useGetCurrentOrderQuery, useGetOrderVersionsQuery } from '~/apollo/generated/hooks';
import OrderPageProductsTable from '../OrderPageProductsTable';
import OrderProductsAddButton from '../OrderProductsAddButton';
import OrderProductsExportButton from '../OrderProductsExportButton';
import VersionsTable from './VersionsTable';
import NewVersionButton from "~/components/order/OrderPageHeader/NewVersionButton";

import styles from './OrderPageProductsTabs.module.css';

const OrderPageProductsTabs: FC<OrderPageProductsTabsProps> = ({ router }) => {
  const { id } = router.query;
  const [currentTab, setCurrentTab] = useState('1');

  if (!id) return null;

  const { data, refetch, client } = useGetOrderVersionsQuery({
    variables: {
      orderId: +id
    }
  });

  const { data: order } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  const [deleteOrderVersion] = useDeleteOrderVersionMutation({
    onCompleted: () => {
      setCurrentTab('1');
      client.refetchQueries({ include: ['getOrderVersions'] });
    }
  });

  const [changeOrderVersion] = useChangeOrderVersionLazyQuery({
    onCompleted: () => {
      setCurrentTab('1');
      client.refetchQueries({ include: ['getOrderVersions'] });
    }
  });

  const onOpenTab = (ver: string) => {
    if (ver === '1') {
      refetch();
    }

    setCurrentTab(ver);
  };

  const disabled = !!(order?.t_order?.length && order.t_order[0].archived);

  return (
    <Tabs
      activeKey={currentTab}
      tabPosition="bottom"
      type="card"
      onTabClick={onOpenTab}
      className={styles.tabs}
      style={{
        marginBottom: 50
      }}
    >
      <Tabs.TabPane key="1" tab="Актуальный">
        <OrderPageProductsTable disabled={disabled} />

        {disabled ? null : (
          <Row gutter={24} align="middle">
            <Col>
              <OrderProductsAddButton />
            </Col>
            <Col>
              <OrderProductsExportButton />
            </Col>
            <Col>
              <NewVersionButton order={{id: order?.t_order[0].order_id as number}}/>
            </Col>
          </Row>
        )}
      </Tabs.TabPane>
      {data?.versions.map((version) => (
        <Tabs.TabPane key={version.ver} tab={version.ver}>
          <VersionsTable
            orderId={+id}
            version={version.ver}
            changeVersion={(orderId, version) => {
              changeOrderVersion({
                variables: { orderId, ver: version }
              });
            }}
            deleteVersion={(orderId, version) => {
              deleteOrderVersion({
                variables: { orderId, ver: version }
              });
            }}
            disabled={disabled}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default withRouter(OrderPageProductsTabs);

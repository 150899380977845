import fetch from 'isomorphic-unfetch';

import { DEFAULT_USER_INFO_QUERY_FIELDS } from '~/apollo/fragments/users';

import { Maybe, Scalars } from '~/interfaces';

const USER_MUTATION = `
  mutation($user: t_user_insert_input!) {
    user: insert_t_user_one(object: $user) {
      ${DEFAULT_USER_INFO_QUERY_FIELDS}
    }
  }
`;

const createUser = async (userData: UserDataProps): Promise<any> => {
  try {
    let headers = {};

    if (process.env.HASURA_SECRET) {
      headers = {
        'x-hasura-admin-secret': process.env.HASURA_SECRET
      };
    }

    const response = await fetch(process.env.GRAPHQL_URL + '/v1/graphql', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: USER_MUTATION,
        variables: {
          user: userData
        }
      })
    });

    const { data, errors } = await response.json();

    if (errors) {
      console.error(errors);
      return { error: errors };
    }

    const user = data.user || null;

    return { user };
  } catch (error: any) {
    console.error(error);
    return { error: error.message };
  }
};

export default createUser;

interface UserDataProps {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  brief?: Maybe<Scalars['String']>;
  subject_id?: Maybe<Scalars['bigint']>;
}

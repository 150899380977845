import { useMemo, FC } from 'react';
import { Modal } from 'antd';
import { Row as RowProps, CellProps } from 'react-table';

import { OrdersTableProps, OrdersModalProps } from './OrdersModal.props';
import { Table } from '~/components';
import { TDocProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { roubleFormatter } from '~/utils';

const OrdersTable: FC<OrdersTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Номер заказа',
        id: 'id',
        Cell: ({ row }: CellProps<TDocProps['ftrans'][0]>) => (
          <span>{row.original.order?.order_id}</span>
        )
      },
      {
        Header: 'Конрагент',
        id: 'customer',
        Cell: ({ row }: CellProps<TDocProps['ftrans'][0]>) => (
          <span>{row.original.order?.customer?.name}</span>
        )
      },
      {
        Header: 'Привязано к заказу, р',
        accessor: 'amount',
        Cell: ({ value }: CellProps<TDocProps['ftrans'][0], TDocProps['ftrans'][0]['amount']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: 'Комментарий',
        accessor: 'description',
      },
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      getRowProps={(row: RowProps<TDocProps['ftrans'][0]>) => ({
        onClick: () => window.open(`/orders/${row.original.order?.order_id}`)
      })}
      {...props}
    />
  );
};

const OrdersModal: FC<OrdersModalProps> = ({
  data,
  visible = false,
  onClose = () => ({}),
  onOk = () => ({})
}) => (
  <Modal
    visible={visible}
    onOk={onOk}
    onCancel={onClose}
    width={650}
  >
    <OrdersTable
      id="ID_PARTNER_PAGE_DOCUMENTS_TABLE_ORDERS_MODAL_TABLE"
      data={data || []}
    />
  </Modal>
);

export default OrdersModal;

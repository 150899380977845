import { FC } from 'react';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';

import { useGetDocumentListQuery } from '~/apollo/generated/hooks';
import { TDocProps } from '~/interfaces';
import { DocumentSelectProps } from './DocumentSelect.props';

const DocumentSelect: FC<DocumentSelectProps> = ({
  value = undefined,
  defaultValue = undefined,
  partner,
  showSearch = true,
  allowClear = true,
  placeholder = 'Выберите документ',
  filterOption = false,
  onSearch,
  onClear,
  notFoundContent,
  loading,
  disabled = false,
  ...props
}) => {


  const getVariables = (selected: TDocProps['doc_id']) => {
    let where: any = {
      deleted: {
        _eq: false
      },
    };

    if (selected) {
      where = { ...where, doc_id: { _eq: selected } };
    }

    if (partner) {
      where = { ...where, partner_id: { _eq: partner } };
    }

    return where;
  };
  
  const { data, refetch, loading: loadingData } = useGetDocumentListQuery({
    variables: { where: getVariables(value || defaultValue)}, // FIX #1343
    skip: disabled
  });

  const onSearchDocument = (value: string) => {
    let searchString = value && value.trim();

    searchString = `%${searchString}%`;

    refetch({
      where: {
        deleted: {
          _eq: false
        },
        partner_id: {
          _eq: partner
        },
        number: {
          _ilike: searchString
        }
      }
    });
  };

  const onClearInput = () => {
    refetch(getVariables(undefined));
  };

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      showSearch={showSearch}
      placeholder={placeholder}
      filterOption={filterOption}
      notFoundContent={
        notFoundContent || <>{loadingData ? <Spin size="small" /> : null}</>
      }
      allowClear={allowClear}
      onClear={onClear || onClearInput}
      onSearch={onSearch || debounce(onSearchDocument, 500)}
      loading={loading || loadingData}
      disabled={disabled}
      {...props}
    >
      {data?.t_doc.map((d) => (
        <Select.Option key={d.id} value={d.id}>
          <strong>№ { d.number } </strong> от { d.oper_date } на { d.amount } руб.
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentSelect;

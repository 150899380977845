import { useState, useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { CellProps, Row as RowProps } from 'react-table';
import moment from 'moment';

import {
  DocProps,
  PartnerPageDocumentsTableProps,
  PartnerPageDocumentsTableQueryProps
} from './PartnerPageDocumentsTable.props';
import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../Table';
import { roubleFormatter } from '~/utils';
import { TDocProps } from '~/interfaces';
import OrdersModal from './OrdersModal';

const PartnerPageDocumentsTable: FC<PartnerPageDocumentsTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();
  const [currentFTrans, setCurrentFTrans] = useState<TDocProps['ftrans'] | null>(null);
  const [showModal, setShowModal] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: "№",
        accessor: "number",
        sorter: true,
      },
      {
        Header: "Сумма",
        accessor: "amount",
        sorter: true,
        Cell: ({ value }: CellProps<DocProps, DocProps['amount']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: "Списано",
        accessor: "expenses",
        sorter: true,
        Cell: ({ value }: CellProps<DocProps, DocProps['expenses']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: "Дата",
        accessor: "ddmmyyyy",
        sorter: true,
        sortType: (rowA: RowProps<DocProps>, rowB: RowProps<DocProps>) => {
          if (!rowA.original.ddmmyyyy) return -1;
          if (!rowB.original.ddmmyyyy) return 1;

          if (rowA.original.ddmmyyyy && rowB.original.ddmmyyyy) {
            const dateA = moment(rowA.original.ddmmyyyy, 'DD.MM.YYYY');
            const dateB = moment(rowB.original.ddmmyyyy, 'DD.MM.YYYY');

            if (moment(dateA).isAfter(moment(dateB))) {
              return 1;
            } else {
              return -1;
            }
          }

          return -1;
        }
      },
    ],
    [data]
  );

  const showFTrans = (ftrans?: TDocProps['ftrans']) => {
    setCurrentFTrans(ftrans || null);
    setShowModal(true);
  };

  const closeFTrans = () => {
    setCurrentFTrans(null);
    setShowModal(false);
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        getRowProps={(row) => ({
          onClick: () => showFTrans(row.original.ftrans)
        })}
        {...props}
      />
      <OrdersModal
        data={currentFTrans}
        visible={showModal}
        onClose={closeFTrans}
        onOk={closeFTrans}
      />
    </>
  );
};

const PartnerPageDocumentsTableQuery: FC<PartnerPageDocumentsTableQueryProps> = ({
  router,
}) => {
  const { id } = router.query;

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = (data && data.partner_list && data.partner_list[0]) || null;

  if (!partner) return null;

  return (
    <PartnerPageDocumentsTable
      id="ID_PARTNER_PAGE_DOCUMENTS_TABLE"
      data={partner.partner_docs || []}
      pagination={false}
    />
  );
};

export default withRouter(PartnerPageDocumentsTableQuery);

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Col, Button } from 'antd';
import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { TasksCalendarPageHeaderProps } from './TasksCalendarPageHeader.props';
import SubjectSelect from '../../SubjectSelect';
import TasksStatusRadioGroup from '../TasksStatusRadioGroup';
import TasksAddDrawer from '../TasksAddDrawer';

const TasksCalendarPageHeader: FC<TasksCalendarPageHeaderProps> = ({ router }) => {
  const client = useApolloClient();
  const statusId = router.query.status ? +router.query.status : undefined;
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  return (
    <>
      <PageHeader
        title="Задачи"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Row gutter={12} justify="space-between">
          <Row gutter={12}>
            <Col>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => ({})}
              >
                Добавить
              </Button>
            </Col>
            <Col>
              <TasksStatusRadioGroup
                value={statusId}
                onChange={(e) => onFilter('status', e.target.value)}
              />
            </Col>
            <Col>
              <SubjectSelect
                typeBrief={['EMPLOYEE']}
                placeholder="Менеджер"
                style={{ width: 180 }}
                onChange={(manager) => onFilter('manager', manager)}
                nullOption={{ label: 'Без менеджера', value: -1 }}
                value={managerId}
              />
            </Col>
          </Row>

          <Button
            icon={<UnorderedListOutlined />}
            onClick={() => router.replace(`/tasks`)}
            type="link"
          >
            Список
          </Button>
        </Row>
      </PageHeader>
      <TasksAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getTaskListCalendar']
          });
          setVisibleDrawer(false);
        }}
      />
    </>
  );
};

export default withRouter(TasksCalendarPageHeader);

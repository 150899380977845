import { FC } from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { CopyButtonProps } from './CopyButton.props';
import { useCopyImportedRowLazyQuery } from '~/apollo/generated/hooks';

const CopyButton: FC<CopyButtonProps> = ({ doc, disabled = false }) => {
  if (!doc) return null;

  const [copyImportedRow, { loading, client }] = useCopyImportedRowLazyQuery({
    onCompleted: () => {
      client?.refetchQueries({
        include: ['getDocRows', 'getDocsList']
      });
    },
  });

  const onConfirm = () => {
    copyImportedRow({
      variables: {
        rowId: doc.id
      }
    });
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={disabled || loading}
    >
      <Tooltip title="Копировать">
        <CopyOutlined
          disabled={disabled}
          style={{ color: disabled ? 'rgba(0, 0, 0, 0.25)' : '#555' }}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default CopyButton;

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { PageHeader, Descriptions, Space, Tag, Button } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { useGetCompanyListQuery } from '~/apollo/generated/hooks';
import { OrganizationPageHeaderProps } from './OrganizationPageHeader.props';
import OrganizationTabs from './OrganizationTabs';
import OrganizationsAddModal from '../../organizations/OrganizationsAddModal';

const OrganizationPageHeader: FC<OrganizationPageHeaderProps> = ({
  router
}) => {
  const { id } = router.query;
  const client = useApolloClient();
  const [visibleModal, setVisibleModal] = useState(false);

  if (!id) return null;

  const { data } = useGetCompanyListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'ORGANIZATION' }
        },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const organization = data.company_list[0];

  if (!organization) return null;

  const onUpdateOrganization = () => {
    setVisibleModal(false);
    client.refetchQueries({ include: ['getCompanyList'] });
  };

  return (
    <>
      <PageHeader
        title={
          <Space>
            {organization.name}
            <CheckOutlined style={{ color: '#17A600' }} />
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setVisibleModal(true)}
            />
          </Space>
        }
        ghost={false}
        style={{ marginBottom: 16 }}
        footer={
          <OrganizationTabs />
        }
        onBack={() => router.back()}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Телефон">
            {organization.phone || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Адрес">
            {organization.address || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {organization.email || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Менеждер">
            <Link href={`'/employees/${organization.manager?.subject_id}`}>
              <a>{organization.manager?.name || '-'} {organization.manager?.lastname || ''}</a>
            </Link>
          </Descriptions.Item>
          {organization.type_organization.length ? (
            <Descriptions.Item label="Тип организации">
              {organization.type_organization.map((type) => (
                <Tag key={type.id} color="blue">
                  {type.prop_value?.value}
                </Tag>
              ))}
            </Descriptions.Item>
          ) : null}
          {organization.partner.length ? (
            <Descriptions.Item label="Партнер">
              {organization.partner.map((partner) => (
                <Link
                  key={partner.subject?.id}
                  href={`/partners/${partner.subject?.id}`}
                >
                  <a>{partner.subject?.name || ''} {partner.subject?.lastname || ''}</a>
                </Link>
              ))}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </PageHeader>

      <OrganizationsAddModal
        organizationId={+id}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onSave={onUpdateOrganization}
      />
    </>
  );
};

export default withRouter(OrganizationPageHeader);

import { useMemo, FC } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { Droppable } from 'react-beautiful-dnd';

import styles from './Head.module.css';

import { HeadProps, ColumnProps } from './Head.props';
import IndeterminateCheckbox from '../IndeterminateCheckbox';

import Td from './Td';

const Head: FC<HeadProps> = ({
  tableId,
  allColumns,
  headerGroups,
  getToggleHideAllColumnsProps,
  getHeaderProps = () => ({}),
  getColumnProps = () => ({}),
  hiddenColumns,
  onToggleHideColumn = () => ({}),
  disableFilters,
  rowId,
}) => {
  const { onChange: onToggleHideAllChange, ...toggleHideAllProps } = getToggleHideAllColumnsProps();

  const onToggleHideOneColumn = (id: string, visible: boolean) => {
    let newHiddenColumns = [];

    if (!visible) {
      newHiddenColumns = hiddenColumns ? [...hiddenColumns, id] : [];
    } else {
      newHiddenColumns = hiddenColumns?.filter((col) => col !== id) || [];
    }

    onToggleHideColumn(newHiddenColumns);
  };

  const onToggleHideAllColumn = (visible: boolean) => {
    let newHiddenColumns = [];

    if (!visible) {
      newHiddenColumns = allColumns.map((col) => col.id);
    } else {
      newHiddenColumns = [];
    }

    onToggleHideColumn(newHiddenColumns);
  };

  const menuId = useMemo(
    () => tableId ? `${tableId}-head-context-menu-${rowId}` : 'same_unique_identifier',
    [rowId]
  );

  return (
    <>
      <ContextMenuTrigger id={menuId}>
        <div className={styles.contextTrigger}>
          {headerGroups.map((headerGroup, i) => (
            <Droppable key={i} droppableId={tableId} direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...headerGroup.getHeaderGroupProps(
                    provided.droppableProps
                  )}
                >
                  {headerGroup.headers.map((column: ColumnProps, k: number) => (
                    <Td
                      column={column}
                      getHeaderProps={getHeaderProps}
                      getColumnProps={getColumnProps}
                      disableFilters={disableFilters}
                      headerGroup={headerGroup}
                      index={k}
                      key={column.id}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={menuId} className={styles.menu}>
        <MenuItem className={styles.menuItem}>
          <label className={styles.menuLabel}>
            <IndeterminateCheckbox
              onChange={(e) => {
                if (typeof onToggleHideAllChange === 'function') {
                  onToggleHideAllChange(e);
                  onToggleHideAllColumn(e.target.checked);
                }
              }}
              {...toggleHideAllProps}
            />{' '}
            Все
          </label>
        </MenuItem>
        {allColumns
          .filter((column) => (column.Header && typeof column.Header === 'string'))
          .map((column) => {
            const { onChange: onToggleHideOneChange, ...toggleHideOneProps } = column.getToggleHiddenProps();

            return (
              <MenuItem key={column.id} className={styles.menuItem}>
                <label className={styles.menuLabel}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onToggleHideOneChange(e);
                      onToggleHideOneColumn(column.id, e.target.checked);
                    }}
                    {...toggleHideOneProps}
                  />{' '}
                  {column.Header}
                </label>
              </MenuItem>
            );
          }
        )}
      </ContextMenu>
    </>
  );
};

export default Head;

import checkVarIsNumber from './checkVarIsNumber';

type StyleType = 'currency' | 'decimal' | 'percent';

const roubleFormatter = (num: any, style: StyleType = 'currency') => {
  if (!checkVarIsNumber(num)) {
    return num;
  }

  const formatter = new Intl.NumberFormat('ru-RU', {
    style,
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(num).replace(/,/g, '.');
};

export default roubleFormatter;

import { FC } from 'react';
import { Card, Divider } from 'antd';
import { withRouter } from 'next/router';

import { useGetCurrentOrderQuery } from '~/apollo/generated/hooks';
import { OrderPageBasicCustomerInfoProps } from './OrderPageBasicCustomerInfo.props';
import Owner from './Owner/Owner';
import Customer from './Customer/Customer';
import Contacts from './Contacts/Contacts';
import Information from './Information/Information';
import Account from './Account/Account';
import UPD from './UPD/UPD';

const OrderPageBasicCustomerInfo: FC<OrderPageBasicCustomerInfoProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  const { data } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  if (!data) return null;

  const order = data.t_order[0];

  if (!order) return null;

  return (
    <Card bordered={false}>
      {order.customer && order.customer.manager[0] ? (
        <>
          Менеджер: {`
            ${order.customer.manager[0].subject?.name }
            ${order.customer.manager[0].subject?.lastname }
          `}
        </>
      ) : null}

      <Owner order={order} />

      <Customer order={order} />

      <Divider style={{ margin: '6px 0' }} />

      <Contacts order={order} />

      <Information order={order} />

      <Account order={order} />

      <UPD order={order} />
    </Card>
  );
};

export default withRouter(OrderPageBasicCustomerInfo);

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { PageHeader, Descriptions, Space, Avatar, Switch, Button, Modal, message } from 'antd';
import { EditOutlined, UserOutlined, CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { useGetContactListQuery } from '~/apollo/generated/hooks';
import { ContactPageHeaderProps } from './ContactPageHeader.props';
import ContactTabs from './ContactTabs';
import ClientsDrawer from '../../clients/ClientsDrawer';
import { UPDATE_SUBJECT_BY_PK } from '~/apollo/mutations/subjects';

const { confirm } = Modal;

const ContactPageHeader: FC<ContactPageHeaderProps> = ({
  router
}) => {
  const { id } = router.query;
  const client = useApolloClient();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  if (!id) return null;

  const { data } = useGetContactListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'CONTACT_PERSON' }
        },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const contact = data.contact_list[0];

  if (!contact) return null;

  // const [deleteContact] = useUpdateSubjectByPkMutation();

  const deleteContact = () => {
    confirm({
      title: `Вы уверены, что хотите удалить контакт "${contact.name}"?`,
      icon: <QuestionCircleOutlined />,
      async onOk() {
        const { errors } = await updateContact({ deleted: true });

        if (errors) {
          message.error('При удалении контакта произошла ошибка. Сообщение ошибки: ' + errors);
          return;
        }

        router.replace('/contacts');
      },
      okText: 'Да',
      cancelText: 'Нет',
      okType: 'danger',
      centered: true,
      onCancel() {
        console.log('cancel');
      },
    });
  };

  const updateStatusContact = async (active: boolean) => {
    setUpdatingStatus(true);

    const { errors } = await updateContact({ is_active: active });

    if (errors) {
      setUpdatingStatus(false);
      message.error('При сохранении контакта произошла ошибка. Сообщение ошибки: ' + errors);
      return;
    }

    setUpdatingStatus(false);
    client.refetchQueries({ include: ['getContactList'] });
  };

  const updateContact = async (subject: any) => {
    console.log(subject);
    return await client.mutate({
      mutation: UPDATE_SUBJECT_BY_PK,
      variables: {
        subjectId: contact.id,
        subject
      }
    });
  };

  const onUpdateContact = () => {
    setVisibleDrawer(false);
    client.refetchQueries({ include: ['getContactList'] });
  };

  return (
    <>
      <PageHeader
        title={
          <Space>
            <Avatar size={40} icon={<UserOutlined />} />
            {contact.name} {contact.lastname || ''}
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setVisibleDrawer(true)}
            />
          </Space>
        }
        ghost={false}
        style={{ marginBottom: 16 }}
        footer={
          <ContactTabs />
        }
        onBack={() => router.back()}
        extra={
          <Space align="center">
            <Switch
              loading={updatingStatus}
              checked={!!contact.is_active}
              checkedChildren={<CheckOutlined style={{ color: 'white' }} />}
              unCheckedChildren={<CloseOutlined style={{ color: 'red' }} />}
              defaultChecked
              onChange={async (active) => {
                await updateStatusContact(!!active);
              }}
            />
            <Button
              onClick={deleteContact}
              type="primary"
              size="small"
              danger
            >
              Удалить
            </Button>
          </Space>
        }
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Телефон">
            {contact.phone || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Адрес">
            {contact.address || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {contact.email || '-'}
          </Descriptions.Item>
          {contact.organization.length ? (
            <Descriptions.Item label="Организация">
              <Space>
                {contact.organization.map((org) => (
                  <Link
                    key={org.subject?.id}
                    href={`/organizations/${org.subject?.id}`}
                  >
                    <a>{org.subject?.name || '-'}</a>
                  </Link>
                ))}
              </Space>
            </Descriptions.Item>
          ) : null}
          {contact.partner.length ? (
            <Descriptions.Item label="Партнер">
              <Space>
                {contact.partner.map((partner) => (
                  <Link
                    key={partner.subject?.id}
                    href={`/partners/${partner.subject?.id}`}
                  >
                    <a>{partner.subject?.name || ''}</a>
                  </Link>
                ))}
              </Space>
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Должность">
            {contact.post || '-'}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <ClientsDrawer
        contactId={+id}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onUpdateContact}
      />
    </>
  );
};

export default withRouter(ContactPageHeader);

import { gql } from '@apollo/client';

export const CURRENT_EMPLOYEE_INFO = gql`
  fragment currentEmployeeInfo on t_subject {
    id: subject_id
    is_active
    subject_id
    name
    lastname
    address
    email
    phone
    avatar
    branch: object_sales_area_link {
      id: sales_area_id
      sales_area {
        name
      }
    }
  }
`;

import { FC } from 'react';
import { Radio } from 'antd';

import { useGetTaskStatusListQuery } from '~/apollo/generated/hooks';
import { TasksStatusRadioGroupProps } from './TasksStatusRadioGroup.props';

const TasksStatusRadioGroup: FC<TasksStatusRadioGroupProps> = ({
  defaultValue,
  value,
  onChange = () => ({}),
}) => {
  const { data } = useGetTaskStatusListQuery({
    variables: {
      where: {
        task_type: {
          brief: { _eq: 'TASK' }
        }
      }
    }
  });

  return (
    <Radio.Group
      defaultValue={defaultValue || 'all'}
      value={value || 'all'}
      buttonStyle="solid"
      onChange={onChange}
    >
      {data?.task_status_list.map((status) => (
        <Radio.Button key={status.id} value={status.id}>
          {status.name === 'Новая'
            ? 'Открытые' : status.name === 'Завершена'
            ? 'Закрытые' : status.name}
        </Radio.Button>
      ))}
      <Radio.Button value="all">Все</Radio.Button>
    </Radio.Group>
  );
};

export default TasksStatusRadioGroup;

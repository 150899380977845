import { FC } from 'react';
import { Card, Input, DatePicker, Form, message } from 'antd';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { UPDProps } from './UPD.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';
import { debounce } from 'lodash';
import { CHECK_ORDER_BY_BILL_NUMBER_AND_YEAR } from '~/apollo/queries/orders';

const validateNumber = (value: any) => {
  if (value && !value.match(/^(ИТ|ТЕ|ЭС)\d{1,4}$/)) {
    return Promise.reject('Неверный формат');
  }

  return Promise.resolve();
};

const UPD: FC<UPDProps> = ({
  order,
  title = 'УПД(последняя, если их несколько)',
  headStyle = { padding: 0, borderBottomWidth: 0 },
  bordered = false,
  bodyStyle = { padding: '6px 0' },
  ...props
}) => {
  const client = useApolloClient();
  const [form] = Form.useForm();

  const [updateBill] = useUpdateOrderByPkMutation({
    onError: (error) => {
      message.error(`
        При обновлении заказа произошла ошибка. Сообщение ошибки: ${error.message}
      `);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onValuesChange = () => {
    form
      .validateFields()
      .then(async (values) => {

        const billNumber = values.number;
        const year = values.date ? Number(values.date.format('YYYY')) : null;

        if (billNumber && year) {
          const { data } = await client.query({
            query: CHECK_ORDER_BY_BILL_NUMBER_AND_YEAR,
            variables: {
              billNumber,
              year,
            },
          });

          if (data.order[0]?.result?.if_exists === true) {
            message.error('Такой УПД уже существует', 10);
            form.setFieldsValue({ number: '' });
            return;
          }
        }

        updateBill({
          variables: {
            set: {
              bill_number: values.number,
              bill_date: values.date ? values.date.format('YYYY-MM-DD') : null
            },
            orderId: order.id
          }
        });
      })
      .catch(() => ({}));
  };

  return (
    <Card
      title={title}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      bordered={bordered}
      {...props}
    >
      <Form
        form={form}
        autoComplete="off"
        onValuesChange={debounce(onValuesChange, 500)}
        initialValues={{
          number: order.bill_number?.toString(),
          date: order.bill_date ? moment(order.bill_date, 'YYYY-MM-DD') : undefined
        }}
      >
        <Form.Item
          name="number"
          style={{ marginBottom: 8 }}
          rules={[{ validator: (_, value) => validateNumber(value) },]}
        >
          <Input placeholder="Номер в формате: 'ИТ25', 'ЭС46', 'ТЕ88'" disabled={!!order.archived} />
        </Form.Item>
        <Form.Item name="date" noStyle={true}>
          <DatePicker format="DD.MM.YYYY" disabled={!!order.archived} />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UPD;

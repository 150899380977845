import { FC } from 'react';
import { Modal, Form, message } from 'antd';

import { MOVE_EMPLOYEE_PARTNERS, SET_EMPLOYEE_INACTIVE } from '~/apollo/mutations/employees';
import { useMoveEmployeeMessagesMutation } from '~/apollo/generated/hooks';
import { EmployeeArchiveAddModalProps } from './EmployeeArchiveAddModal.props';
import { SubjectSelect } from '~/components';

const EmployeeArchiveAddModal: FC<EmployeeArchiveAddModalProps> = ({
  employee,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const [form] = Form.useForm();

  const [moveMessages, { client }] = useMoveEmployeeMessagesMutation({
    onCompleted: async () => {
      await client.mutate({
        mutation: MOVE_EMPLOYEE_PARTNERS,
        variables: {
          newEmpl: null,
          oldEmpl: employee.subject_id
        }
      });

      await client.mutate({
        mutation: SET_EMPLOYEE_INACTIVE,
        variables: {
          empl: employee.subject_id
        }
      });

      form.resetFields();
      client.refetchQueries({
        include: ['getCurrentEmployee']
      });
      onSave();
    },
    onError: () => {
      message.error('При сохранении сотрудника произошла ошибка');
    }
  });

  const moveToArchive = () => {
    form
      .validateFields()
      .then((values) => {
        moveMessages({
          variables: {
            newEmpl: values.employee,
            oldEmpl: employee.subject_id
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Отправить в архив?"
      visible={visible}
      onCancel={onCancel}
      onOk={moveToArchive}
    >
      <Form
        form={form}
        layout="vertical"
        name="employee_archive_add_form"
      >
        <Form.Item
          name="employee"
          label="Выберите заменяющего сотрудника"
        >
          <SubjectSelect
            style={{ width: 175 }}
            placeholder="Выберите ответственного"
            typeBrief={['EMPLOYEE']}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeArchiveAddModal;

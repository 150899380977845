const userHaveRights: funcProps = (id) => {
  if (!id) return false;

  // const ids = [14, 6, 10, 52, 15, 56, 39, 36, 16];
  //  не очень решение прописывать ID, по хорошему над проверять статус в базе
  const ids = [6, 36, 16, 52, 15, 56, 10];
  
  // const ids = [15, 10];

  const found = ids.find(el => el === id);

  if (found) return true;

  return false;
};

export default userHaveRights;

type funcProps = (id?: number) => boolean;

import { FC } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AddButtonProps } from './AddButton.props';
import { useUpdateOrderlineMutation } from '~/apollo/generated/hooks';
import { TOrderlineExpenseProps } from '~/interfaces';

const AddButton: FC<AddButtonProps> = ({
  orderline
}) =>  {
  if (!orderline) return null;

  const [addExpense, { loading }] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onClick = () => {
    let expenses = [];

    if (orderline.jdoc) {
      expenses = orderline.jdoc.expenses || [];
    }

    let fix_cost = 0;

    expenses = [
      ...expenses,
      {
        key: expenses.length + 1,
        name: '',
        qty: 1,
        summ: null,
        price: null,
        docnum: '',
        docsum: ''
      }
    ];

    expenses = expenses.map((exp: TOrderlineExpenseProps) => {
      if (+exp.summ) {
        fix_cost += +exp.summ;
      }

      return exp;
    });

    addExpense({
      variables: {
        orderlineId: orderline.orderline_id,
        set: {
          jdoc: { expenses },
          fixed_cost: fix_cost
        }
      }
    });
  };

  return (
    <Button
      type="link"
      size="small"
      icon={(
        <PlusOutlined
          style={{
            fontSize: 11,
            verticalAlign: 0
          }}
        />
      )}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    >
      Новая строка
    </Button>
  );
};

export default AddButton;

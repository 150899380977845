import { FC } from 'react';
import { Drawer, Button, Form, Input, DatePicker } from 'antd';

import { DeliveryAddDrawerProps } from './DeliveryAddDrawer.props';

const DeliveryAddDrawer: FC<DeliveryAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({}),
}) => {
  const [form] = Form.useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        onSave();
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Планирование доставки"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button onClick={onSubmit} type="primary">
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="delivery_add_form"
        autoComplete="off"
      >
        <Form.Item label="Плановая дата доставки" name="date">
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="Адрес доставки" name="address">
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default DeliveryAddDrawer;

import { useState, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import Image from 'next/image';
import { PageHeader, Button, Descriptions, Avatar, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { EmployeePageHeaderProps } from './EmployeePageHeader.props';
import { useGetCurrentEmployeeQuery } from '~/apollo/generated/hooks';
import EmployeeArchiveAddModal from '../EmployeeArchiveAddModal/EmployeeArchiveAddModal';

const EmployeesPageHeader: FC<EmployeePageHeaderProps> = ({ router }) => {
  const { id } = router.query;
  const [visibleModal, setVisibleModal] = useState(false);

  if (!id) return null;

  const { data } = useGetCurrentEmployeeQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: parseInt(id.toString(), 10) }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const employee = (data && data.employee && data.employee[0]) || null;

  return (
    <>
      <PageHeader
        title={
          <>
            {employee.avatar ? (
              <Avatar
                size={100}
                src={
                  <Image
                    src={`http://oasis.retailsuite.ru/${employee.avatar}?width=100`}
                    width={100}
                    height={100}
                    alt=""
                  />
                }
              />
            ) : (
              <Avatar size={100} icon={<UserOutlined />} />
            )}
            <span>{ employee.name } { employee.lastname || '' }</span>
          </>
        }
        ghost={false}
        style={{ marginBottom: 16 }}
        footer={
          <Tabs
            defaultActiveKey="tasks"
            onChange={(activeKey) => {
              router.replace(`/employees/${id}/${activeKey}`);
            }}
          >
            <Tabs.TabPane tab="Задачи" key="tasks" />
          </Tabs>
        }
        extra={
          <>
            {employee.is_active ? (
              <Button
                type="primary"
                danger
                onClick={() => setVisibleModal(true)}
              >
                В архив
              </Button>
            ): null}
            {!employee.is_active ? (
              <div>В архиве</div>
            ): null}
          </>
        }
        onBack={() => router.back()}
      >
        <Descriptions size="small" column={4}>
          <Descriptions.Item label="Телефон">
            { employee.phone || '-' }
          </Descriptions.Item>
          <Descriptions.Item label="Адрес">
            { employee.address || '-' }
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            { employee.email || '-' }
          </Descriptions.Item>
          <Descriptions.Item label="Филиал">
            {(employee.branch && employee.branch.id) ? (
              <Link href={`/branches/${employee.branch.id}`}>
                <a>{(employee.branch && employee.branch.sales_area && employee.branch.sales_area.name) || '-' }</a>
              </Link>
            ) : (
              <span>-</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Link href={`/orders?managers=${employee.subject_id}`}><a>Сделки</a></Link>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Link href={`/tasks?status=10&manager=${employee.subject_id}`}><a>Задачи</a></Link>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Link href={`/partners/?full=true&manager=${employee.subject_id}&visible=all`}><a>Партнеры</a></Link>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <EmployeeArchiveAddModal
        employee={employee}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onSave={() => setVisibleModal(false)}
      />
    </>
  );
};

export default withRouter(EmployeesPageHeader);

import { UploadFile } from 'antd/lib/upload/interface';

const getFileAsBase64 = (file: UploadFile['originFileObj']): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export default getFileAsBase64;

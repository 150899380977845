import { useState, MouseEvent, ChangeEvent, FC } from 'react';
import NumberFormat from 'react-number-format';
import moment, { Moment } from 'moment';

import { DatePickerCellProps } from './DatePickerCell.props';
import { limitNumber } from '~/utils';

const dateFormat = (val: string) => {
  const day = limitNumber(val.substring(0, 2), '31');
	const month = limitNumber(val.substring(2, 4), '12');
  const year = val.substring(4, 8);

  return day + (month.length ? '.' + month : '') + (year.length ? '.' + year : '');
};

const DatePickerCell: FC<DatePickerCellProps<Moment | undefined>> = ({
	value,
  cell,
  onUpdateTableData = () => ({}),
  inputProps = {},
  editable = true,
  children,
}) => {
	const [edit, setEdit] = useState(false);

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    let isNewValue = false;

    if (e.target.value && value) {
      const newDate = moment(e.target.value, 'DD.MM.YYYY');

      if (moment(newDate).isSame(value)) {
        isNewValue = false;
      } else {
        isNewValue = true;
      }
    } else if (!e.target.value && !value) {
      isNewValue = false;
    } else {
      isNewValue = true;
    }

    if (isNewValue) {
      onUpdateTableData(cell, e.target.value ? moment(e.target.value, 'DD.MM.YYYY') : null);
    }

    setEdit(false);
  };

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    setEdit(true);
  };

	return (
		<>
      {edit ? (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <NumberFormat
            format={dateFormat}
            defaultValue={value ? moment(value).format('DD.MM.YYYY') : ''}
            autoFocus
            onBlur={onBlur}
            className="ant-input"
            style={{
              width: '100%',
              height: '100%',
              fontSize: 12,
              borderRadius: 0
            }}
            {...inputProps}
          />
        </div>
      ) : (
        <span
          {...(editable ? {
            className: 'editable-cell',
            onClick: onClick
          }: {})}
        >
          {children || (value ? moment(value).format('DD.MM.YYYY') : value)}
        </span>
      )}
    </>
	);
};

export default DatePickerCell;

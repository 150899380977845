import { useState, FC, useEffect } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Form, Row, Col, Button, Input, Checkbox, Divider, message, Space } from 'antd';
import { isString } from 'lodash';
import { useSession } from 'next-auth/react';
import { EditOutlined } from '@ant-design/icons';

import {
  useNotifyManagersLazyQuery,
  useGetDocQuery,
  useGetDocsListQuery,
  useHoldDocMutation,
  useSetDocPartnerMutation,
  useSetDocsLazyQuery,
} from '~/apollo/generated/hooks';
import {
  ContractorDocRowsPageHeaderProps,
  ContractorDocRowsPageHeaderQueryProps
} from './ContractorDocRowsPageHeader.props';
import { TDocReturnedProps } from '~/interfaces';
import { SubjectSelect, DocumentSelect, DocumentsDrawer } from '~/components';
import { roubleFormatter, userHaveRights, userHaveAdmins } from '~/utils';

const ContractorDocRowsPageHeader: FC<ContractorDocRowsPageHeaderProps> = ({
  router,
  title,
  partner,
}) => {
  const { id } = router.query;
  const [form] = Form.useForm();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { data: session } = useSession();
  const active = userHaveRights(session?.user.user_id);
  const userHaveAdmin = userHaveAdmins(session?.user.user_id);
  const [edit, setEdit] = useState(false);
  const [isHold, setIsHold] = useState(false);

  const manager = !router.query.manager
    ? session?.user.subject_id
    : router.query.manager && router.query.manager === 'all'
    ? undefined : +router.query.manager;

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  const [holdDocMutation, { client }] = useHoldDocMutation({
    refetchQueries: ['getDocRows', 'getDocsList'],
  });

  const [setDocs] = useSetDocsLazyQuery({
    onCompleted: async () => {
      await client?.refetchQueries({
        include: ['getDocRows', 'getDocsList']
      });
    },
    onError: (err) => {
      console.log(err);
    }
  });

  const [notifyManagers, { loading: notifing }] = useNotifyManagersLazyQuery({
    variables: {
      queryGuid: id?.toString()
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      message.success('Менеджерам разосланы уведомления');
    }
  });

  const { data: contractDoc } = useGetDocsListQuery({
    variables: {
      where: {
        query_guid: {
          _eq: id?.toString()
        }
      }
    },
    onCompleted: (data) => {
      if (data.rows.length && data.rows[0].is_hold) {
        setIsHold(true);
      } else {
        setIsHold(false);
      }
    }
  });

  const onAddNewDocument = (docId?: TDocReturnedProps['id']) => {
    setDocs({ variables: { queryGuid: id?.toString(), docId } });

    setVisibleDrawer(false);
  };

  const [setPartner] = useSetDocPartnerMutation({
    onCompleted: () => {
      setEdit(false);
    },
    refetchQueries: ['getDoc', 'getDocsList']
  });

  const onUpdateTableData = (value: any) => {
    if (!id) return;

    setPartner({
      variables: {
        queryGuid: id.toString(),
        partnerId: value || null,
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      manager
    });
  }, [manager]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            {title}
            {!edit && partner ? (
              <EditOutlined
                style={{ fontSize: 16, color: 'green' }}
                onClick={() => setEdit(true)}
              />
            ) : (
              <SubjectSelect
                defaultValue={partner}
                typeBrief={['ORGANIZATION', 'PARTNER']}
                placeholder="Укажите подрядчика"
                style={{ width: 180, border: !partner ? '1px solid red': ''}}
                onChange={(val) => onUpdateTableData(val)}
              />
            )}
          </Space>
        }
        ghost={false}
        style={{ marginBottom: 4 }}
        extra={
          <Button
            loading={notifing}
            disabled={notifing}
            onClick={() => notifyManagers()}
          >Уведомить менеджеров</Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={24} style={{ marginBottom: -12 }}>
            <Col>
              <Form.Item
                label="Менеджер"
                name="manager"
                style={{ marginBottom: 12 }}
              >
                <SubjectSelect
                  style={{ width: 160 }}
                  typeBrief={['EMPLOYEE']}
                  placeholder="Выберите менеджера"
                  onChange={(id) => onFilter('manager', id ? id : 'all')}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Номер документа"
                name="document"
                style={{ marginBottom: 12 }}
              >
                <DocumentSelect
                  style={{ width: 160 }}
                  partner={partner}
                  onChange={(docId) => {
                    setDocs({ variables: { queryGuid: id?.toString(), docId } });
                  }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '2px 0' }} />
                      <div style={{ padding: 5 }}>
                        <Button onClick={() => setVisibleDrawer(true)} block>Добавить</Button>
                      </div>
                    </div>
                  )}
                  disabled={isHold || !userHaveAdmin}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Сумма таблицы">
                <Input value={roubleFormatter(contractDoc?.rows[0]?.sum, 'decimal')} disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Не привязано">
                <Input value={roubleFormatter(contractDoc?.rows[0]?.sum_free || 0, 'decimal')} disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Привязано к заказам">
                <Input value={roubleFormatter(contractDoc?.rows[0]?.sum_by_orders, 'decimal')} disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Сумма ТН">
                <Input value={roubleFormatter(contractDoc?.rows[0]?.sum_bill, 'decimal')} disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Сумма акта">
                <Input value={roubleFormatter(contractDoc?.rows[0]?.sum_akt, 'decimal')} disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 12 }} label="Блокировка">
                <Checkbox
                  onChange={(e) => {
                    if (!id) return;

                    holdDocMutation({
                      variables: {
                        queryGuid: id.toString(),
                        hold: e.target.checked
                      }
                    });
                  }}
                  checked={isHold}
                  disabled={!active}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>

      <DocumentsDrawer
        partner={partner}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onAddNewDocument}
      />
    </>
  );
};

const ContractorDocRowsPageHeaderQuery: FC<ContractorDocRowsPageHeaderQueryProps> = ({
  router
}) => {
  const { id } = router.query;

  const { data } = useGetDocQuery({
    variables: {
      queryGuid: id?.toString()
    },
    skip: !id
  });

  const row = data?.rows[0];
  const title = `${(row && row.doc_date) || ''} ${(row && row.partner && row.partner.name) || ''}`;
  const partner = row?.partner_id;

  if (!id || !isString(id)) return null;

  return (
    <ContractorDocRowsPageHeader title={title} partner={partner} router={router} />
  );
};

export default withRouter(ContractorDocRowsPageHeaderQuery);

import { FC } from 'react';
import { withRouter } from 'next/router';
import { Form, Card, message } from 'antd';
import { useApolloClient } from '@apollo/client';

import { PartnerPageSegmentsFieldsProps } from './PartnerPageSegmentsFields.props';
import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
import PartnerAreaSelect from '../PartnerAreaSelect';
import PartnerTagSelect from '../PartnerTagSelect';
import InteractionStatusSelect from './InteractionStatusSelect';
import { ADD_SUBJECT_PROP, ADD_SUBJECT_PROP_LIST, DELETE_SUBJECT_PROP, UPDATE_SUBJECT_PROP } from '~/apollo/mutations/subject_props';
import { GET_PROP_VALUE_LIST } from '~/apollo/queries/prop_values';

const PartnerPageSegmentsFields: FC<PartnerPageSegmentsFieldsProps> = ({
  router
}) => {
  const { id } = router.query;
  const [form] = Form.useForm();
  const client = useApolloClient();

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = data.partner_list[0];

  if (!partner) return null;

  const deleteProp = async (idList: any[]) => {
    const { errors } = await client.mutate({
      mutation: DELETE_SUBJECT_PROP,
      variables: {
        where: {
          subject_prop_id: { _in: idList }
        }
      }
    });

    if (errors) {
      console.log(errors);
    }
  };

  const saveProp = async (objects: any) => {
    if (!Array.isArray(objects)) {
      console.error('Prop list is not array');
      return false;
    }

    const { errors } = await client.mutate({
      mutation: ADD_SUBJECT_PROP_LIST,
      variables: { objects }
    });

    if (errors) {
      console.log(errors);
    }
  };

  const onChangeArea = async (ids: any[]) => {
    if (partner.SFERA_DEYATELNOSTI.length) {
      const idList = await partner.SFERA_DEYATELNOSTI.map(sfera => sfera.id);
      await deleteProp(idList);
    }

    if (ids.length) {
      const idList = await ids.map(id => ({
        prop_id: 15,
        prop_value_id: id,
        subject_id: partner.id
      }));
      await saveProp(idList);
    }

    await client.refetchQueries({
      include: ['getPartnerList']
    });
  };

  const onChangeStatus = async (id: number) => {
    const propId = partner.cooper_status.length ? partner.cooper_status[0].prop_id : null;

    if (partner.cooper_status.length && partner.cooper_status[0].prop_value_id) {
      const { errors } = await client.mutate({
        mutation: UPDATE_SUBJECT_PROP,
        variables: {
          where: {
            prop_id: { _eq: propId },
            subject_id: { _eq: partner.id },
            prop_value_id: { _eq: partner.cooper_status[0].prop_value_id }
          },
          set: {
            prop_value_id: id
          }
        }
      });

      if (errors) {
        console.log(errors);
        message.error('При сохранении статуса произошла ошибка. Сообщение ошибки: ' + errors[0].message, 10);
      }
    }

    const { errors } = await client.mutate({
      mutation: ADD_SUBJECT_PROP,
      variables: {
        prop: {
          subject_id: partner.id,
          prop_id: propId,
          prop_value_id: id
        }
      }
    });

    if (errors) {
      console.log(errors);
      message.error('При сохранении статуса произошла ошибка. Сообщение ошибки: ' + errors[0].message, 10);
    }

    client.refetchQueries({
      include: ['getPartnerList']
    });
  };

  const onChangeTags = async (ids: any[]) => {
    if (partner.tag_list) {
      const idList = await partner.tag_list.map(tag => tag.id);
      await deleteProp(idList);
    }

    if (ids?.length) {
      const { data, error } = await client.query({
        query: GET_PROP_VALUE_LIST,
        variables: {
          where: {
            prop: {
              brief: { _eq: 'TAGS' }
            },
            prop_value_id: {
              _in: ids
            }
          }
        }
      });

      if (error) {
        console.log(error);
      }

      const idList = data?.t_prop_value?.map((p: any) => ({
        prop_id: p.prop_id,
        subject_id: partner.id,
        prop_value_id: p.id
      }));

      await saveProp(idList);
    }

    await client.refetchQueries({
      include: ['getPartnerList']
    });
  };

  const onValuesChange = (values: any) => {
    if (values.area) {
      onChangeArea(values.area);
    }

    if (values.status) {
      onChangeStatus(values.status);
    }

    if (values.tags) {
      onChangeTags(values.tags);
    }
  };

  return (
    <Card bordered={false}>
      <Form
        form={form}
        layout="vertical"
        name="segments_edit_form"
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{
          area: partner.SFERA_DEYATELNOSTI.map((o) => o.prop_value_id),
          status: (partner.cooper_status.length && partner.cooper_status[0].prop_value_id) || null,
          tags: partner.tag_list.map((o) => o.prop_value_id),
        }}
      >
        <Form.Item name="area" label="Сфера деятельности">
          <PartnerAreaSelect mode="tags" />
        </Form.Item>
        <Form.Item name="status" label="Статус взаимодействия">
          <InteractionStatusSelect
            placeholder="Выберите статус взаимодействия"
            allowClear={false}
          />
        </Form.Item>
        <Form.Item name="tags" label="Теги">
          <PartnerTagSelect
            mode="multiple"
            placeholder="Выберите теги"
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default withRouter(PartnerPageSegmentsFields);

import { FC } from 'react';
import { Select } from 'antd';

import { useGetSubjectListQuery } from '~/apollo/generated/hooks';
import { Maybe, Order_By } from '~/interfaces';
import { ContactSelectProps } from './ContactSelect.props';

const ContactSelect: FC<ContactSelectProps> = ({
  showSearch = true,
  allowClear = true,
  placeholder = 'Выберите контакт',
  optionFilterProp = 'children',
  mode = 'multiple',
  maxTagCount = 'responsive',
  order,
  ...props
}) => {
  const subjectIds = order.contact_list
    .map(contact => contact.subject_id) as Maybe<number[]> | undefined;

  const { data, loading } = useGetSubjectListQuery({
    variables: {
      where: {
        _or: [
          {
            subject_id: {
              _in: subjectIds
            }
          },
          {
            deleted: { _eq: false },
            is_active: { _eq: true },
            subject_type: {
              brief: { _eq: 'CONTACT_PERSON' }
            },
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              object_id: { _eq: order.customer_id }
            }
          }
        ]
      },
      orderBy: [{ name: Order_By.Asc }]
    }
  });

  return (
    <Select
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      allowClear={allowClear}
      loading={loading}
      mode={mode}
      maxTagCount={maxTagCount}
      {...props}
    >
      {data?.subject_list.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          { c.name || '' } { c.lastname || '' } { (c.post && `(${c.post})`) || '' }
        </Select.Option>
      ))}
    </Select>
  );
};

export default ContactSelect;

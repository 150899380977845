import { useState, FC } from 'react';
import { Card, List, Space, Button, Form, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { SubjectSelect } from '~/components';
import { OwnerProps } from './Owner.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';

const Owner: FC<OwnerProps> = ({
  order,
  bordered = false,
  bodyStyle = { padding: '6px 0' },
  ...props
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const [updateOwner, { loading }] = useUpdateOrderByPkMutation({
    onCompleted: () => {
      setEdit(false);
    },
    onError: (error) => {
      message.error(`
        При обновлении заказа произошла ошибка. Сообщение ошибки: ${error.message}
      `);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onSave = (values: any) => {
    if (values.owner === order.deal_owner_id) {
      setEdit(false);
      return;
    }

    updateOwner({
      variables: {
        set: {
          deal_owner_id: values.owner,
          upd_date: 'now()'
        },
        orderId: order.id
      }
    });
  };

  const onCancel = () => {
    form.resetFields();
    setEdit(false);
  };

  return (
    <Card bordered={bordered} bodyStyle={bodyStyle} {...props}>
      <div>Ответственный:</div>
      {(!edit && order.deal_owner) ? (
        <List
          itemLayout="horizontal"
          dataSource={[order.deal_owner]}
          renderItem={(_, i) => (
            <List.Item
              style={{ padding: '6px 0' }}
              actions={[
                <Button
                  key="edit_owner_icon"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => setEdit(true)}
                  size="small"
                  disabled={!!order.archived}
                />
              ]}
              key={i}
            >
              { order.deal_owner?.name } { order.deal_owner?.lastname }
            </List.Item>
          )}
          style={{ marginRight: -12 }}
        >
        </List>
      ) : (
        <Form
          form={form}
          autoComplete="off"
          onFinish={onSave}
          initialValues={{
            owner: order.deal_owner_id
          }}
        >
          <Form.Item name="owner" style={{ marginBottom: 8 }}>
            <SubjectSelect
              typeBrief={['EMPLOYEE']}
              style={{ width: '100%' }}
              autoFocus
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Сохранить
              </Button>
              <Button
                type="primary"
                danger
                onClick={onCancel}
              >
                Отменить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

export default Owner;

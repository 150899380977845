import { FC } from 'react';
import { withRouter } from 'next/router';
import { Tabs } from 'antd';

import { LogisticsTabsProps } from './LogisticsTabs.props';
import { getActiveKey } from '~/utils';

const menuRoutes: { [key: string]: string[] } = {
  index: ['/logistics'],
  delivery_request: ['/logistics/delivery_request'],
  shipments: ['/logistics/shipments'],
  delivery: ['/logistics/delivery'],
};

const LogisticsTabs: FC<LogisticsTabsProps> = ({ router }) => {
  const { pathname } = router;

  return (
    <Tabs
      size="small"
      activeKey={getActiveKey(menuRoutes, pathname)}
      onChange={(activeKey) => {
        if (activeKey === 'index') {
          router.replace(`/logistics`);
        } else {
          router.replace(`/logistics/${activeKey}`);
        }
      }}
      animated={{ inkBar: false, tabPane: false }}
    >
      <Tabs.TabPane tab="Заявки на отгрузку" key="index" />
      <Tabs.TabPane tab="Заявки на доставку" key="delivery_request" />
      <Tabs.TabPane tab="Отгрузки" key="shipments" />
      <Tabs.TabPane tab="Маршруты" key="delivery" />
    </Tabs>
  );
};

export default withRouter(LogisticsTabs);

import { useState, useEffect, FC } from 'react';
import { Drawer, Form, Button, Row, Col, Input, Switch, message } from 'antd';
import { CheckOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useAddSubjectMutation, useGetSubjectByIdLazyQuery, useUpdateSubjectByPkMutation } from '~/apollo/generated/hooks';
import { ADD_SUBJECT_LINK_LIST, DELETE_SUBJECT_LINK_BY_PK } from '~/apollo/mutations/subject_links';
import { ClientsDrawerProps } from './ClientsDrawer.props';
import SubjectSelect from '../../SubjectSelect';
import OrganizationsAddModal from '../../organizations/OrganizationsAddModal';
import { TSubjectByIdProps } from '~/interfaces';
import PhoneInput from '~/components/PhoneInput';

const ClientsDrawer: FC<ClientsDrawerProps> = ({
  organizationId,
  contactId,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const [addingNewClientLinks, setAddingNewClientLinks] = useState(false);
  const [updatingClientLinks, setUpdatingClientLinks] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [form] = Form.useForm();
  const [currentClient, setCurrentClient] = useState<TSubjectByIdProps | null | undefined>();

  const [getClient] = useGetSubjectByIdLazyQuery({
    onCompleted: (data) => {
      if (data.subject) {
        form.setFieldsValue({
          active: data.subject.is_active ? true : false,
          name: data.subject.name,
          phone: data.subject.phone,
          info: data.subject.info,
          organization: (data.subject?.organization_link?.length && data.subject?.organization_link[0].subject_object?.subject_id) || null,
          email: data.subject.email,
          post: data.subject.post,
        });
        setCurrentClient(data.subject);
      }
    },
    fetchPolicy: 'network-only'
  });

  const [addClient, { client, loading: addingClient }] = useAddSubjectMutation({
    onCompleted: async (res) => {
      setAddingNewClientLinks(true);

      if (organizationId || form.getFieldValue('organization')) {
        const { errors } = await client.mutate({
          mutation: ADD_SUBJECT_LINK_LIST,
          variables: {
            linkList: [{
              subject_id: res.subject?.id,
              object_id: organizationId || form.getFieldValue('organization'),
              link_type_id: 1,
              object_type_id: 3
            }]
          }
        });

        if (errors) {
          setAddingNewClientLinks(false);
          message.error('При сохранении контакта произошла ошибка');
          return;
        }
      }

      setAddingNewClientLinks(false);
      message.success('Контакт успешно сохранен');
      form.resetFields();
      setCurrentClient(null);
      onSave(res.subject?.id);
    },
    onError: () => {
      message.error('При сохранении контакта произошла ошибка');
    }
  });

  const [updateClient, { loading: updatingClient }] = useUpdateSubjectByPkMutation({
    onCompleted: async (res) => {
      setUpdatingClientLinks(true);

      if (
        (currentClient?.organization_link[0].subject_id && form.getFieldValue('organization') !== currentClient?.organization_link[0].subject_id) ||
        (!currentClient?.organization_link[0].subject_id && form.getFieldValue('organization'))
      ) {
        const linkId = (currentClient?.organization_link?.length && currentClient.organization_link[0].id) || null;

        if (linkId) {
          const { errors: errorsDeleteLinks } = await deleteLink(linkId);

          if (errorsDeleteLinks) {
            setUpdatingClientLinks(false);
            message.error('При обновлении контакта произошла ошибка');
            return;
          }
        }

        const { errors: errorsAddLinks } = await saveLink(contactId);

        if (errorsAddLinks) {
          setUpdatingClientLinks(false);
          message.error('При обновлении контакта произошла ошибка');
          return;
        }
      }

      setUpdatingClientLinks(false);
      message.success('Контакт успешно обновлен');
      form.resetFields();
      setCurrentClient(null);
      onSave(res.subject?.id);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const deleteLink = async (linkId: any) => {
    return await client.mutate({
      mutation: DELETE_SUBJECT_LINK_BY_PK,
      variables: { linkId }
    });
  };

  const saveLink = async (subjectId: any) => {
    return await client.mutate({
      mutation: ADD_SUBJECT_LINK_LIST,
      variables: {
        linkList: [{
          subject_id: subjectId,
          object_id: form.getFieldValue('organization'),
          link_type_id: 1,
          object_type_id: 3
        }]
      }
    });
  };

  const onAddOrganization = (id?: number) => {
    form.setFieldsValue({
      organization: id
    });
    setVisibleModal(false);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (contactId) {
          updateClient({
            variables: {
              subjectId: contactId,
              subject: {
                name: values.name,
                phone: values.phone,
                info: values.info,
                address: currentClient?.address,
                email: values.email,
                type_id: currentClient?.type_id,
                is_active: values.active,
                doc: {
                  post: values.post
                }
              },
            }
          });
        } else {
          addClient({
            variables: {
              subject: {
                name: values.name,
                phone: values.phone,
                email: values.email,
                info: values.info,
                type_id: 6,
                is_active: values.active,
                doc: {
                  post: values.post
                }
              }
            }
          });
        }
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    setCurrentClient(null);
    onClose();
  };

  useEffect(() => {
    if (!visible) return;

    if (contactId) {
      getClient({
        variables: {
          subjectId: contactId
        }
      });
    }
  }, [visible]);

  return (
    <Drawer
      title={contactId ? 'Редактирование контактного лица' : 'Добавление контактного лица'}
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            loading={addingClient || addingNewClientLinks || updatingClient || updatingClientLinks}
            disabled={addingClient || addingNewClientLinks || updatingClient || updatingClientLinks}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="partner_add_form"
        autoComplete="off"
        initialValues={{
          active: true
        }}
      >
        <Form.Item name="active" valuePropName="checked" label="Активный">
          <Switch
            checkedChildren={<CheckOutlined style={{ color: 'white' }} />}
            unCheckedChildren={<CloseOutlined style={{ color: 'red' }} />}
            defaultChecked
          />
        </Form.Item>
        <Form.Item name="name" label="Имя">
          <Input placeholder="Введите имя" />
        </Form.Item>
        <Form.Item name="phone" label="Телефон">
          <PhoneInput placeholder="+7 (912) 345-78-90" />
        </Form.Item>
        <Form.Item name="info" label="Информация о контакте">
          <Input placeholder="Значимые заметки о контакте" />
        </Form.Item>
        {!organizationId ? (
          <Form.Item label="Организация">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="organization" noStyle>
                  <SubjectSelect
                    typeBrief={['ORGANIZATION', 'PARTNER']}
                    placeholder="Выберите организацию"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => setVisibleModal(true)}
                  block
                >
                  Создать новую
                </Button>

                <OrganizationsAddModal
                  visible={visibleModal}
                  onClose={() => setVisibleModal(false)}
                  onSave={onAddOrganization}
                />
              </Col>
            </Row>
          </Form.Item>
        ) : null}
        <Form.Item name="email" label="Электронная почта">
          <Input placeholder="Введите электронную почту" />
        </Form.Item>
        <Form.Item name="post" label="Должность">
          <Input placeholder="Введите должность" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ClientsDrawer;

import { FC } from 'react';
import { Select } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { Order_By } from '~/interfaces';
import { PartnerTagSelectProps } from './PartnerTagSelect.props';

const PartnerTagSelect: FC<PartnerTagSelectProps> = ({
  showSearch = true,
  allowClear = true,
  placeholder = 'Тег',
  optionFilterProp = 'children',
  ...props
}) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'TAGS' }
        },
      },
      orderBy: {
        value: Order_By.Asc
      }
    }
  });

  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      {...props}
    >
      {data?.t_prop_value.map((b) => (
        <Select.Option key={b.id} value={b.id}>
          {b.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PartnerTagSelect;

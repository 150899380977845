import {useState, useMemo, FC} from 'react';
import {withRouter} from 'next/router';
import {useSession} from 'next-auth/react';
import {Row, Popconfirm, Button, Space} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Cell, CellProps} from 'react-table';

import {useGetCountDocumentsQuery, useGetDocumentListQuery, useUpdateDocumentMutation} from '~/apollo/generated/hooks';
import {PaymentsPageTableProps, VendorDocsPageTableQueryProps} from './PaymentsPageTable.props';
import {Table, DocumentsDrawer} from '~/components';
import PaymentsOrdersModal from '~/components/PaymentsOrderModal/PaymentsOrdersModal';
import OrdersModal from './OrdersModal/OrdersModal';
import DeleteButton from './DeleteButton/DeleteButton';
import {Order_By, TDocProps, TDocumentProps} from '~/interfaces';
import {useAppContext} from '~/contexts/AppContext';
import {roubleFormatter, userHaveRights} from '~/utils';
import Link from "next/link";
import {REMOVE_FTRAN} from "~/apollo/mutations/ftrans";
import {UPDATE_DOC} from "~/apollo/mutations/documents";
import {GetCountDocumentsQuery} from "~/apollo/generated/operations";

const PaymentsPageTable: FC<PaymentsPageTableProps> = ({data, refetchDocs, ...props}) => {
  const {state} = useAppContext();
  const [currentDocId, setCurrentDocId] = useState<any>(null);
  const [currentDoc, setCurrentDoc] = useState<TDocProps | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [visibleDoc, setVisibleDoc] = useState(false);
  const [openedDoc, setOpenedDoc] = useState<TDocumentProps | null>();
  const [deletingOrder, setDeletingOrder] = useState(false);


  const localTableSettings = state.tables.find((table) => table.id === props.id);


  const columns = useMemo(
    () => [
      {
        Header: 'Номер платежки',
        accessor: 'number',
        width: 120,
        sorter: true,
      },
      {
        Header: 'Номер заказа 1С',
        // accessor: 'description',
        accessor: 'num_order',
        sorter: true,
      },
      {
        Header: 'Дата',
        accessor: 'ddmmyyyy',
        sorter: true,
      },
      {
        Header: 'Партнер',
        accessor: 'partner',
        width: 300,
        Cell: ({value}: CellProps<TDocProps, TDocProps['partner']>) => (
          <span>{value?.name}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Сумма Платежа, ₽',
        accessor: 'amount',
        Cell: ({value}: CellProps<TDocProps, TDocProps['amount']>) => (
          <span>{roubleFormatter(value)}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Сумма Заказа, ₽',
        accessor: 'order_amount',
        Cell: ({row}: CellProps<TDocumentProps>) => {
          return <span>{row?.original?.t_order?.order_sum}</span>
        },
        sorter: true,
      },
      {
        // Header: 'Заказ, ₽',
        Header: 'Сделка',
        accessor: 'order_id',
        Cell: ({row}: CellProps<TDocumentProps>) => (
          <span>
          <Row
            align="middle"
            justify="center"
            style={{lineHeight: 'normal', height: '100%'}}
          >
            <Space align="center">
              {row.original.order_id
                ? (
                  <>
                    <Link href={`/orders/${row.original.order_id}/`}>
                      <a target="_blank">{row.original.order_id}</a>
                    </Link>
                    <Button
                      onClick={() => deleteDocOrder(row.original)}
                      size="small"
                      icon={<DeleteOutlined style={{ color: '#EB2F96' }}/>}
                      disabled={deletingOrder || row.original.is_hold || false}
                    >
                      Отвязать заказ
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => showOrderList(row.original)}
                    size="small"
                    icon={<PlusOutlined twoToneColor="red" />}
                    disabled={row.original.is_hold || false}
                  >
                    Указать заказ
                  </Button>
                )
              }
            </Space>
          </Row>
        </span>
        ),

        sorter: true,
      },
      {
        Header: 'Партнер 1с',
        accessor: 'partner_1c',
        sorter: true,
        // accessor: 'payment_partner_name',
        // Cell: ({ row }: CellProps<TDocProps>) => (
        //   <span>{roubleFormatter(row.original.exp?.doc_rest_expenses)}</span>
        // ),
        // canSort: true,
      },

      // {
      //   Header: 'Менеджер',
      //   accessor: 'manager',
      //   Cell: ({ value }: CellProps<TDocProps, TDocProps['manager']>) => (
      //     <span>
      //       {value ? <>{ value.name } { value.lastname }</> : null}
      //     </span>
      //   ),
      //   sorter: true,
      // },
      {
        Header: 'Действие',
        id: 'action',
        width: 80,
        // Cell: ({row}: CellProps<TDocProps>) => (
        //   <Row align="middle" justify="center" style={{height: '100%'}} onClick={(e) => e.stopPropagation()}>
        //     <Popconfirm
        //       title="Редактировать документ?"
        //       placement="left"
        //       okText="Да"
        //       cancelText="Нет"
        //       onConfirm={() => showDoc(row.original)}
        //     >
        //       <EditOutlined style={{color: '#1EB328'}}/>
        //     </Popconfirm>
        //     &nbsp;
        //     {!row.original.ftrans.length ? (
        //       <DeleteButton id={row.original.id}/>
        //     ) : null}
        //   </Row>
        // )
      }
    ],
    [data]
  );


  const [setDocMutation] = useUpdateDocumentMutation({
    onCompleted: () => {
      // refetchDocs();
    },
    refetchQueries: ['getDocumentList', 'getCountDocuments'],
  });



  const showFTrans = (id: any) => {
    setCurrentDocId(id || null);
    setShowModal(true);
  };

  const closeFTrans = () => {
    setCurrentDocId(null);
    setShowModal(false);
  };

  const showDoc = (doc?: TDocProps) => {
    setCurrentDoc(doc || null);
    setShowDrawer(true);
  };

  const closeDoc = () => {
    setCurrentDoc(null);
    setShowDrawer(false);
  };

  const closeOrderList = () => {
    console.log('closeOrderList');
    console.log('openedDoc', openedDoc);
    setOpenedDoc(null);
    setVisibleDoc(false);
  };

  const showOrderList = (doc: TDocumentProps) => {
    setOpenedDoc(doc);
    setVisibleDoc(true);
  };

  const deleteDocOrder = async (doc: TDocumentProps) => {

    setDocMutation({
      variables: {
        docId: doc.id,
        // doc_rest_expenses: row.original.doc_rest_expenses
        set: {
          order_id: null,
        }
      }
    });
  };


  return (
    <>
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        getCellProps={(cell: Cell<TDocProps>) => ({
          onClick: () => {
            // showFTrans(cell.row.original.id);
          }
        })}
        {...props}
      />
      <OrdersModal
        docId={currentDocId}
        visible={showModal}
        onClose={closeFTrans}
        onOk={closeFTrans}
      />
      <PaymentsOrdersModal
        visible={visibleDoc}
        onOk={closeOrderList}
        onCancel={closeOrderList}
        doc={openedDoc}
        hide={() => {
          setVisibleDoc(false)
        }}
        refetchDocs={()=>refetchDocs()}
      />
      <DocumentsDrawer
        docId={currentDoc?.id}
        visible={showDrawer}
        onClose={closeDoc}
        onSave={closeDoc}
      />
    </>
  );
};

const VendorDocsPageTableQuery: FC<VendorDocsPageTableQueryProps> = ({router, ...props}) => {
  const {data: session} = useSession();
  const search = router.query.search ? router.query.search : undefined;
  const partner1c = router.query.partner1c ? router.query.partner1c : undefined;
  const partnerId = router.query.partner ? +router.query.partner : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const restExpense = router.query.restExpense?.toString() || null;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 40;
  let data: any = [];
  let dataCount: any = [];

  let where: any = {
    doc_type_id: {_eq: 3},
    deleted: {_neq: true},
  };
  let orderBy: any[] = [];

  if (restExpense === 'yes') {
    // where = { exp: { doc_rest_expenses: { _neq: 0 } }, ...where };
    where = {
      ...where,
      order_id: {_is_null: true}
    };
  }

  if (partnerId) {
    where = {
      ...where,
      partner_id: {_eq: partnerId}
    };
  }

  if (managerId) {
    where = {
      ...where,
      manager_id: {_eq: managerId}
    };
  }

  if (dateFrom) {
    where = {
      ...where,
      oper_date: {
        _gte: dateFrom
      }
    };
  }

  if (dateTo) {
    where = {
      ...where,
      oper_date: {
        ...where.oper_date,
        _lte: dateTo,
      },
    };
  }

  if (search) {
    where = {
      ...where,
      // number: {_similar: `${search}`}
      _or: [
        {
          number: {_ilike: `%${search}%`},
        },
        {
          num_order: {_ilike: `%${search}%`},
        },
        {
          order_id: {_eq: `${search}`},
        },
      ],
    };
  }

  if (partner1c) {
    where = {
      ...where,
      partner_1c: {_ilike: `%${partner1c}%`}
    };
  }

  if (sort) {
    orderBy = sort.map((s) => {
      const [key, value] = s.split('+');

      if (key === 'partner') {
        return {partner: {name: value}};
      }

      if (key === 'ddmmyyyy') {
        return {oper_date: value};
      }

      if (key === 'manager') {
        return {ins_user: value};
      }

      if (key === 'expenses') {
        return {
          t_ftrans_aggregate: {
            sum: {amount: value}
          }
        };
      }

      if (key === 'not_expenses') {
        return {
          exp: {
            doc_rest_expenses: value
          }
        };
      }

      return {[key]: value};
    });
  }else {
    orderBy = [{ oper_date: Order_By.Desc }, {number: Order_By.Desc}];
  }


  const reftchDocs = () => {
    const {data: _dataCount} = useGetCountDocumentsQuery({
      variables: {
        where
      }
    });

    dataCount = _dataCount;

    const {data: _data} = useGetDocumentListQuery({
      variables: {
        where,
        offset: (page - 1) * limit,
        limit,
        orderBy,
      }
    });

    data = _data;

    console.log('docs in payments', data);

  };

  reftchDocs();
  const callRefetchDocs = () => {
    // reftchDocs();
    console.log('call refetch');
  }

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  const getDocs = async () => {
    await fetch('/api/documents-export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        where,
        orderBy,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        }

        const error: any = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "documents.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };

  return (
    <>
      <PaymentsPageTable
        id="ID_PARTNER_PAYMENTS_PAGE_TABLE"
        data={data?.t_doc || []}
        refetchDocs={()=>callRefetchDocs()}
        pagination={{
          current: page,
          total: (dataCount && dataCount.t_doc_aggregate.aggregate?.count) || 0,
          pageSize: limit,
        }}
        onPaginationChange={onTableChange}
        onSorterChange={onSorterChange}
        initialSortBy={sort?.map((s) => ({id: s.split('+')[0], desc: s.split('+')[1] === 'desc'}))}
        {...props}
      />
      {userHaveRights(session?.user.user_id) && data?.t_doc.length ? (
        <Button type="link" onClick={getDocs}>Выгрузить</Button>
      ) : null}
    </>
  );
};

export default withRouter(VendorDocsPageTableQuery);

import { useState, MouseEvent, ChangeEvent, FC } from 'react';
import NumberFormat from 'react-number-format';

import { PhoneInputCellProps } from './PhoneInputCell.props';
import { checkCellVarIsEqual } from '~/utils';

const PhoneInputCell: FC<PhoneInputCellProps> = ({
  value,
  cell,
  onUpdateTableData = () => ({}),
  inputProps = {},
  editable = true,
  children,
}) => {
  const [edit, setEdit] = useState(false);

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkCellVarIsEqual(value, e.target.value)) {
      onUpdateTableData(cell, e.target.value || null);
    }

    setEdit(false);
  };

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <NumberFormat
            defaultValue={value}
            autoFocus
            onBlur={onBlur}
            className="ant-input"
            format="# (###) ###-##-##"
            allowEmptyFormatting
            mask="_"
            {...inputProps}
          />
        </div>
      ) : (
        <span
          {...(editable ? {
            className: 'editable-cell',
            onClick: onClick
          }: {})}
        >
          {children || value}
        </span>
      )}
    </>
  );
};

export default PhoneInputCell;

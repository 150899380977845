import { gql } from '@apollo/client';

export const ADD_TASK_PROP = gql`
  mutation addTaskProp($taskProp: [t_task_prop_insert_input!]!) {
    insert_t_task_prop(objects: $taskProp) {
      returning {
        task_prop_id
      }
    }
  }
`;

import { gql } from '@apollo/client';
import { DEFAULT_CURRENT_ORDER_INFO, DEFAULT_ORDER_INFO } from '../fragments/orders';

export const GET_ORDER_LIST = gql`
  query getOrderList(
    $where: t_order_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [t_order_order_by!]
  ) {
    order_list: t_order(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      archived
      marzh_order_fakt
      order_expenses_fakt
      marzh_order_fakt_perc
      invoice_date
      invoice_number
      bill_number
      bill_date
      deal_owner {
        name
        lastname
      }
      id: order_id
      order_id
      customer_id
      format_ins_date
      customer {
        name
        lastname
        subject_id
      }
      description
      order_sum
      orderline {
        id: orderline_id
        orderline_id
      }
      status_id
      status {
        name
      }
      order_quarter
      manager: customer {
        subject_links(
          where: { t_link_type: { brief: { _in: ["PARTNER_MANAGER"] } } }
        ) {
          subject_object {
            avatar
            lastname
            name
          }
        }
      }
      priority: props(where: { prop: { brief: { _eq: "ORDER_PRIORITY" } } }) {
        id: order_prop_id
        order_prop_id
        prop_id
        prop_value_id
        prop {
          name
        }
        prop_value {
          value
        }
      }
    }
    order_list_count: t_order_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CURRENT_ORDER = gql`
  ${DEFAULT_CURRENT_ORDER_INFO}
  query getCurrentOrder($orderId: Int!, $limit: Int) {
    t_order(where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}, limit: $limit) {
      ...defaultCurrentOrderInfo
    }
  }
`;

export const GET_COUNT_ORDERS = gql`
  query getCountOrders($customerId: Int) {
    t_order_aggregate(where: {deleted: {_eq: false}, customer_id: {_eq: $customerId}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ORDER_LIST_DASHBOARD = gql`
  ${DEFAULT_ORDER_INFO}
  query getOrderListDashboard($where: t_order_bool_exp, $orderBy: [t_order_order_by!] = [{ order_id: asc }], $limit: Int, $offset: Int) {
    t_order(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...defaultOrderInfo
    }
  }
`;

export const GET_ORDER_COUNT = gql`
  query getOrderCount($where: t_order_bool_exp ) {
    t_order_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ORDER_DOC_TOTALS = gql`
  query getOrderDocTotals($orderId: bigint) {
    data: get_order_doc_totals(args: {order_id: $orderId}) {
      totals:json
    }
  }
`;

export const ADD_ORDER_COPY = gql`
  query addOrderCopy($orderId: Int) {
    orders: copy_order_wrap(args: { order_id: $orderId }) {
      order_id
    }
  }
`;

export const NEW_ORDER_VERSION = gql`
  query newOrderVersion($orderId: bigint) {
    orders: new_order_version_wrap(args: { order_id: $orderId }) {
      ver
    }
  }
`;

export const GET_ORDER_VERSIONS = gql`
  query getOrderVersions($orderId: Int) {
    versions: v_order_versions(
      where: { order_id: { _eq: $orderId }, ver: { _is_null: false } }
      order_by: { ins_date: desc }
    ) {
      ver
    }
  }
`;

export const GET_ORDER_VERSION_TOTALS = gql`
  query getOrderVersionTotals($orderId: bigint, $version: String) {
    data: get_order_version_totals(
      args: { order_id: $orderId, ver: $version }
    ) {
      totals: json
    }
  }
`;

export const CHANGE_ORDER_VERSION = gql`
  query changeOrderVersion($orderId: bigint, $ver: String) {
    orders: change_order_version_wrap(
      args: { order_id: $orderId, source_ver: $ver }
    ) {
      ver
    }
  }
`;

export const CHECK_ORDER_REST = gql`
  query checkOrderRest($orderid: bigint) {
    order_doc_rest_expenses(args:{orderid: $orderid}) {
      result: json
    }
  }
`;

export const CHECK_ORDER_DOC_UNCLOSED = gql`
  query checkOrderDocUnclosed($orderid: bigint) {
    order_doc_unclosed(args:{orderid: $orderid}) {
      result: json
    }
  }
`;

export const CHECK_ORDER_BY_INVOICE_NUMBER_AND_YEAR = gql`
  query CheckOrderByInvoiceNumberAndYear($invoiceNumber: String, $year: Int) {
    order: check_order_by_invoice_number_and_year(args: {invoice_number: $invoiceNumber, invoice_year: $year}) {
      result: json
    }
  }
`;

export const CHECK_ORDER_BY_BILL_NUMBER_AND_YEAR = gql`
  query CheckOrderByBillNumberAndYear($billNumber: String, $year: Int) {
    order: check_order_by_bill_number_and_year(args: {bill_number: $billNumber, bill_year: $year}) {
      result: json
    }
  }
`;

export const GET_PAYMENTS_BY_ORDER = gql`
  query getPaymentsByOrder($orderId: Int!, $limit: Int) {
    t_order(where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}, limit: $limit) {
     customer {
      partner_docs(where: {doc_type_id: {_eq: 3}}) {
        doc_id
        ID: doc_id
        amount
        posted
        partner_id
        order_id
        oper_date
        number
      }
    }
    }
  }
`;

import { Fragment, FC } from 'react';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { List, Avatar, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { useGetContentListQuery, useDeleteContentMutation } from '~/apollo/generated/hooks';

const FeedsPageList: FC = () => {
  const { data: session } = useSession();

  const { data, loading, error } = useGetContentListQuery();

  const [deleteFeed] = useDeleteContentMutation({
    onCompleted: () => {
      message.success('Публикация успешно удалена');
    },
    onError: () => {
      message.error('При удалении публикации произошла ошибка');
    },
    refetchQueries: ['getContentList']
  });

  if (error) {
    message.error('Произошла ошибка при загрузке');
  }

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data?.t_content || []}
      renderItem={item => (
        <List.Item
          actions={[
            <Fragment key="feed_actions">
              {item.owner_id && (session?.user.subject_id === item.owner_id) ? (
                <>
                  <EditOutlined />
                  <DeleteOutlined
                    onClick={() => deleteFeed({
                      variables: { contentId: item.id }
                    })}
                  />
                </>
              ) : null}
            </Fragment>
          ]}
        >
          <List.Item.Meta
            avatar={
              <>
                {item?.image[0]?.file?.file_path ? (
                  <Avatar
                    size={24}
                    src={
                      <Image
                        src={`http://oasis.retailsuite.ru/${item?.image[0]?.file?.file_path}`}
                        width={24}
                        height={24}
                        alt=""
                      />
                    }
                  />
                ) : (
                  <Avatar src="" />
                )}
              </>
            }
            title={item.title}
            description={
              <div>
                {item.owner_subject ? (
                  <>
                    Автор: {item.owner_subject.name || '' } { item.owner_subject.lastname || '' }<br />
                  </>
                ) : null}
                {item.description}
              </div>
            }
          />
          <div>{item.ins_date_string} в {item.ins_time_string}</div>
        </List.Item>
      )}
    >
    </List>
  );
};

export default FeedsPageList;

import { FC } from 'react';
import { Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useUpdateOrderlineMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ id }) => {
  if (!id) return null;

  const [updateOrderline, { loading }] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => updateOrderline({ variables: {
        orderlineId: id,
        set: { deleted: true }
      } })}
      disabled={loading}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined style={{ color: '#EB2F96' }} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

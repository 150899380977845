import { FC } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'next/router';

import { OrderTabsProps } from './OrderTabs.props';
import { getActiveKey } from '~/utils';

const menuRoutes: { [key: string]: string[] } = {
  index: ['/orders/[id]'],
  products: ['/orders/[id]/products'],
  tasks: ['/orders/[id]/tasks'],
  costs: ['/orders/[id]/costs'],
  delivery: ['/orders/[id]/delivery'],
  payments: ['/orders/[id]/payments'],
};

const OrderTabs: FC<OrderTabsProps> = ({ router }) => {
  const { pathname, query: { id } } = router;

  return (
    <Tabs
      size="small"
      activeKey={getActiveKey(menuRoutes, pathname)}
      onChange={(activeKey) => {
        if (activeKey === 'index') {
          router.replace(`/orders/${id}`);
        } else {
          router.replace(`/orders/${id}/${activeKey}`);
        }
      }}
      animated={{ inkBar: false, tabPane: false }}
    >
      <Tabs.TabPane tab="Основное" key="index" />
      <Tabs.TabPane tab="Товары и услуги" key="products" />
      <Tabs.TabPane tab="Задачи" key="tasks" />
      <Tabs.TabPane tab="Расходы" key="costs" />
      <Tabs.TabPane tab="Логистика" key="delivery" />
      <Tabs.TabPane tab="Оплаты" key="payments" />
    </Tabs>
  );
};

export default withRouter(OrderTabs);

import { useMemo, FC } from 'react';
import { message } from 'antd';
import { Cell, CellProps } from 'react-table';

import { useUpdateOrderlineMutation } from '~/apollo/generated/hooks';
import { TOrderlineExpenseProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { ExpenseTableProps } from './ExpenseTable.props';
import { Table } from '~/components';
import DeleteButton from './DeleteButton/DeleteButton';
import AddButton from './AddButton/AddButton';

const { Cells: { TextInputCell, NumberInputCell, PriceInputCell } } = Table;

const ExpenseTable: FC<ExpenseTableProps> = ({ rowId, data: orderline, disabled = false }) => {
  const { state } = useAppContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Наименование расхода',
        accessor: 'name',
        Cell: ({ ...props }: CellProps<TOrderlineExpenseProps['name']>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<TextInputCell {...props} />)}
          </>
        )
      },
      {
        Header: 'Кол-во',
        accessor: 'qty',
        Cell: ({ ...props }: CellProps<TOrderlineExpenseProps['qty']>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<NumberInputCell {...props} />)}
          </>
        )
      },
      {
        Header: 'Цена',
        accessor: 'price',
        Cell: ({ ...props }: CellProps<TOrderlineExpenseProps['price']>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell {...props} />)}
          </>
        )
      },
      {
        Header: 'Сумма',
        accessor: 'summ',
        Cell: ({ ...props }: CellProps<TOrderlineExpenseProps['summ']>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell {...props} />)}
          </>
        )
      },
      {
        Header: '',
        id: 'action',
        width: 36,
        Cell: ({ row }: CellProps<TOrderlineExpenseProps>) => (
          <span>
            {disabled ? null : (<DeleteButton expense={row.original} orderline={orderline} />)}
          </span>
        )
      }
    ],
    [orderline, disabled]
  );

  const localTableSettings = state.tables.find((table) => table.id === 'ID_ORDER_PAGE_PRODUCTS_TABLE_EXPENSE_TABLE');

  const [updateOrderline] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onUpdateTableData = (cell: Cell<TOrderlineExpenseProps>, value: any) => {
    let expenses = orderline.jdoc.expenses || [];
    const expense = {
      ...cell.row.original,
      [cell.column.id]: value
    };

    if (cell.column.id !== 'summ' && +expense.qty && +expense.price) {
      expense.summ = expense.qty * expense.price;
    }

    let fix_cost = 0;

    expenses = expenses.map((exp: TOrderlineExpenseProps) => {
      if (expense.key == exp.key) {
        if (+expense.summ) {
          fix_cost += +expense.summ;
        }
      } else if (+exp.summ) {
        fix_cost += +exp.summ;
      }

      if (expense.key == exp.key) {
        return { ...expense };
      }

      return exp;
    });

    updateOrderline({
      variables: {
        orderlineId: orderline.orderline_id,
        set: {
          jdoc: { expenses },
          fixed_cost: fix_cost
        }
      }
    });
  };

  return (
    <>
      <Table
        id="ID_ORDER_PAGE_PRODUCTS_TABLE_EXPENSE_TABLE"
        data={orderline.jdoc?.expenses || []}
        columns={columns}
        pagination={false}
        onUpdateTableData={onUpdateTableData}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        rowId={rowId}
      />
      {disabled ? null : <AddButton orderline={orderline} />}
    </>
  );
};

export default ExpenseTable;

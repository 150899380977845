import { useState, FC } from 'react';
import { useSession } from 'next-auth/react';
import { message } from 'antd';

import { TaskCheckListProps, TaskCheckListQueryProps } from "./TaskCheckList.props";
import { ChangeItemProps } from '../../CheckList/CheckList.props';
import {
  useGetCheckListQuery,
  useUpdateTaskMutation,
  useUpdateTaskByPkMutation,
  useAddTaskOneMutation,
} from '~/apollo/generated/hooks';
import CheckList from '../../CheckList';

const getFormattedData = (task: any) => {
  let data = {
    id: task.id,
    important: task.priority_id ? true : false,
    title: task.name,
    completed: task.status?.name === 'Завершена',
    date: task.deadline,
    time: task.deadline_time,
    owner: {},
  };

  if (task.owner_id) {
    data = {
      ...data,
      owner: {
        id: task.owner_id,
        name: task.subject_owner?.name,
        lastname: task.subject_owner?.lastname,
      }
    };
  }

  return data;
};

const TaskCheckList: FC<TaskCheckListProps> = ({
  taskId,
  data
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const { data: session } = useSession();

  const [updateTask] = useUpdateTaskMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении задачи');
    },
    refetchQueries: ['getCheckList']
  });

  const [updateTaskByPk] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      message.success('Успешно обновлено!');
      setActiveItemId(null);
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении задачи');
    },
    refetchQueries: ['getCheckList']
  });

  const [addTask] = useAddTaskOneMutation({
    onCompleted: () => {
      message.success('Задача успешно сохранена');
      setShowAddForm(false);
    },
    onError: () => {
      message.error('Произошла ошибка при добавлении задачи');
    },
    refetchQueries: ['getCheckList']
  });

  const onSelect = (record: any, selected: any) => {
    if (taskId) {
      updateTask({
        variables: {
          taskId: record.id,
          statusId: selected ? 13 : 12,
          updUser: session?.user.id
        }
      });
    }
  };

  const getSelectedRows = () => data
    .filter((t: any) => t.status?.name === 'Завершена')
    .map((t: any) => t.id);

  const onChangeTask = (values: ChangeItemProps) => {
    updateTaskByPk({
      variables: {
        taskId: values.id,
        set: {
          name: values.title,
          deadline: values.date ? values.date.format('DD.MM.YYYY') : values.date,
          deadline_time: values.time ? values.time.format('HH:mm') : values.time,
          owner_id: values.ownerId || null,
          priority_id: values.important ? 5 : null,
          upd_user: session?.user.id,
        }
      }
    });
  };

  const onAddTask = (values: ChangeItemProps) => {
    addTask({
      variables: {
        task: {
          name: values.title,
          type_id: 5,
          status_id: 12,
          owner_id: values.ownerId || null,
          deadline: values.date ? values.date.format('DD.MM.YYYY') : values.date,
          deadline_time: values.time ? values.time.format('HH:mm') : values.time,
          priority_id: values.important ? 5 : null,
          parent_id: taskId,
          ins_user: session?.user.id,
        }
      }
    });
  };

  const onRemoveTask = (id: string) => {
    updateTaskByPk({
      variables: {
        taskId: id,
        set: { deleted: true }
      }
    });
  };

  return (
    <CheckList
      dataSource={data.map(getFormattedData)}
      onSelect={onSelect}
      onChangeItem={onChangeTask}
      onAddItem={onAddTask}
      onRemove={onRemoveTask}
      selectedRowKeys={getSelectedRows()}
      activeItemId={activeItemId}
      showAddForm={showAddForm}
      onOpenChangeForm={(id) => {
        setActiveItemId(id);
        setShowAddForm(false);
      }}
      onCancelChangeForm={() => setActiveItemId(null)}
      onOpenAddForm={() => {
        setActiveItemId(null);
        setShowAddForm(true);
      }}
      onCancelAddForm={() => setShowAddForm(false)}
    />
  );
};

const TaskCheckListQuery: FC<TaskCheckListQueryProps> = ({
  taskId
}) => {
  const { data } = useGetCheckListQuery({
    variables: {
      parentId: taskId,
    }
  });

  if (!data) return null;

  const tasks = data.t_task;

  return (
    <TaskCheckList taskId={taskId} data={tasks} />
  );
};

export default TaskCheckListQuery;

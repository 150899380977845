const getNormalPrice = (price?: any) => {
  const res = price
    ? price
      .toString()
      .replace('₽ ', '')
      .replace( /\s/g, '')
      .replaceAll(',','.')
    : price;

  return res;
};

export default getNormalPrice;

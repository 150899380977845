import { FC } from 'react';
import { Button, Popconfirm, message } from 'antd';

import { DeleteButtonProps } from './DeleteButton.props';
import { useUpdateSubjectByPkMutation, useGetOrderListLazyQuery, useGetDocumentListLazyQuery } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({ partner, router }) => {
  const [updatePartner, { loading }] = useUpdateSubjectByPkMutation({
    onCompleted: () => {
      message.success('Партнер успешно удален');
      router.replace('/partners');
    },
    onError: (err) => {
      message.error('Произошла ошибка при удалении партнера. Сообщение ошибки: ' + err.message);
    },
    refetchQueries: ['getPartnerList']
  });

  const [getOrders] = useGetOrderListLazyQuery({
    onCompleted: (data) => {
      if (data.order_list.length > 0) {
        return message.error('Данного партнера нельзя удалить, тк к нему привязаны Сделки!');
      }
      console.log('can delete');
      updatePartner({
        variables: {
          subjectId: partner.id,
          subject: {
            deleted: true
          }
        }
      });
    },
    fetchPolicy: 'network-only'
  });

  const [getDocuments] = useGetDocumentListLazyQuery({
    onCompleted: (data) => {
      if (data.t_doc.length > 0) {
        return message.error('Данного партнера нельзя удалить, тк к нему привязаны Документы!');

      } else {
        getOrders({
          variables: {
            where: {
              deleted: { _eq: false },
              customer_id: {
                _eq: partner.id
              }
            }
          }
        });

      }
    },
    fetchPolicy: 'network-only'
  });

  const onConfirm = async () => {
    // getOrders({
    //   variables: {
    //     where: {
    //       deleted: { _eq: false },
    //       customer_id: {
    //         _eq: partner.id
    //       }
    //     }
    //   }
    // });

    getDocuments({
      variables: {
        where: {
          deleted: { _eq: false },
          partner_id: {
            _eq: partner.id
          }
        }
      }
    });
  };

  return (
    <Popconfirm
      title={`Вы уверены, что хотите удалить парнтера "${partner.name}"?`}
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={loading}
    >
      <Button type="primary" danger size="small">Удалить</Button>
    </Popconfirm>
  );
};

export default DeleteButton;

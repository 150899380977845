import { useState, useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Tag, Card, Space, Button } from 'antd';
import { useApolloClient } from '@apollo/client';

import {
  PartnerPageOrganizationsTableProps,
  PartnerPageOrganizationsTableQueryProps,
} from './PartnerPageOrganizationsTable.props';
import { useGetCompanyListQuery, useGetCountCompanyQuery } from '~/apollo/generated/hooks';
import { TOrganizationProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../Table';
import PartnerOrganizationAddModal from '../PartnerOrganizationAddModal';
import OrganizationsAddModal from '../../organizations/OrganizationsAddModal';
import DeleteButton from './DeleteButton';

const PartnerPageOrganizationsTable: FC<PartnerPageOrganizationsTableProps> = ({
  data,
  partnerId,
  ...props
}) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleSelectModal, setVisibleSelectModal] = useState(false);
  const [visibleAddModal, setVisibleAddModal] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'subject_id',
        key: 'subject_id',
        Cell: ({ value, row }: CellProps<TOrganizationProps, TOrganizationProps['subject_id']>) => (
          <span>
            <Link href={`/organizations/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Наименование',
        accessor: 'name',
        Cell: ({ value, row }: CellProps<TOrganizationProps, TOrganizationProps['name']>) => (
          <span>
            <Link href={`/organizations/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
      },
      {
        Header: 'Адрес',
        accessor: 'address',
      },
      {
        Header: 'ИНН',
        accessor: 'inn',
      },
      {
        Header: 'КПП',
        accessor: 'kpp',
      },
      {
        Header: 'Тип организации',
        accessor: 'type_organization',
        Cell: ({ value }: CellProps<TOrganizationProps, TOrganizationProps['type_organization']>) => (
          <span>
            {value.map((type) => (
              <Tag key={type.id} color="blue">
                {type.prop_value?.value}
              </Tag>
            ))}
          </span>
        )
      },
      {
        Header: 'Менеджер',
        accessor: 'manager',
        Cell: ({ value }: CellProps<TOrganizationProps, TOrganizationProps['manager']>) => (
          <span>
            <Link href={`/employees/${value?.subject_id}`}>
              <a>{ (value?.name) || '' } { (value?.lastname) || '' }</a>
            </Link>
          </span>
        )
      },
      {
        Header: 'Действие',
        id: 'operation',
        Cell: ({ row }: CellProps<TOrganizationProps>) => (
          <span>
            {partnerId ? (
              <DeleteButton
                partnerId={partnerId}
                organizationId={row.original.id}
              />
            ) : null}
          </span>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <Card bordered={false} size="small">
        <Space style={{ marginBottom: 24 }}>
          <Button
            type="primary"
            onClick={() => setVisibleAddModal(true)}
          >
            Добавить
          </Button>
          {partnerId ? (
            <Button
              type="primary"
              onClick={() => setVisibleSelectModal(true)}
            >
              Выбрать из списка
            </Button>
          ) : null}
        </Space>
        <Table
          data={data}
          columns={columns}
          initialHiddenColumns={localTableSettings?.hiddenColumns}
          initialColumnOrder={localTableSettings?.columnOrder}
          {...props}
        />
      </Card>
      {partnerId ? (
        <PartnerOrganizationAddModal
          partnerId={partnerId}
          visible={visibleSelectModal}
          onClose={() => setVisibleSelectModal(false)}
          onSave={() => {
            client.refetchQueries({
              include: ['getCompanyList', 'getCountCompany']
            });
            setVisibleSelectModal(false);
          }}
        />
      ) : null}
      <OrganizationsAddModal
        partnerId={partnerId}
        visible={visibleAddModal}
        onClose={() => setVisibleAddModal(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getCompanyList', 'getCountCompany']
          });
          setVisibleAddModal(false);
        }}
      />
    </>
  );
};

const PartnerPageOrganizationsTableQuery: FC<PartnerPageOrganizationsTableQueryProps> = ({
  router
}) => {
  const partnerId = router.query.id ? +router.query.id : undefined;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      return { [key]: value };
    });
  }

  const { data: dataOrganizations } = useGetCompanyListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'ORGANIZATION' }
        },
        object_subject_links: {
          subject_id: { _eq: partnerId },
          t_object_type: { brief: { _eq: 'SUBJECT' } },
          t_link_type: { brief: { _eq: 'PARTNER_ORGANIZATION' } }
        }
      },
      limit,
      offset: (page - 1) * limit,
      orderBy
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: dataCount } = useGetCountCompanyQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'ORGANIZATION' }
        },
        object_subject_links: {
          subject_id: { _eq: partnerId },
          t_object_type: { brief: { _eq: 'SUBJECT' } },
          t_link_type: { brief: { _eq: 'PARTNER_ORGANIZATION' } }
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <PartnerPageOrganizationsTable
      id="ID_PARTNER_PAGE_ORGANIZATIONS_TABLE"
      data={dataOrganizations?.company_list || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.company.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
      partnerId={partnerId}
    />
  );
};

export default withRouter(PartnerPageOrganizationsTableQuery);

import { gql } from '@apollo/client';

export const GET_PROP_VALUE_LIST = gql`
  query getPropValueList($where: t_prop_value_bool_exp, $limit: Int, $orderBy: [t_prop_value_order_by!]) {
    t_prop_value(where: $where, limit: $limit, order_by: $orderBy) {
      brief
      prop_id
      value
      prop_value_id
      id: prop_value_id
      prop {
        brief
        name
      }
    }
  }
`;

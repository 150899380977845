import { useState, useMemo, FC } from 'react';
import { CellProps } from 'react-table';
import { useApolloClient } from '@apollo/client';

import { DocsTableProps } from './DocsTable.props';
import { Table } from '~/components';
import DeleteButton from './DeleteButton/DeleteButton';
import { TOrderlineDocProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import AddButton from './AddButton/AddButton';
import CostsAddDrawer from '../../../costs/CostsAddDrawer';

const DocsTable: FC<DocsTableProps> = ({ rowId, orderId, data: orderline, disabled = false }) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === 'ID_ORDER_PAGE_PRODUCTS_TABLE_DOCS_TABLE');

  const columns = useMemo(
    () => [
      {
        Header: 'Документ',
        accessor: 'number',
        Cell: ({ value, row }: CellProps<TOrderlineDocProps>) => (
          <span>
            {value} / {row.original.partner_name}
          </span>
        )
      },
      {
        Header: 'ДатаДок',
        accessor: 'oper_date',
      },
      {
        Header: 'Сумма',
        accessor: 'summ',
      },
      {
        Header: 'СуммаДок',
        accessor: 'amount',
      },
      {
        Header: 'Описание',
        accessor: 'description',
      },
      {
        Header: '',
        id: 'action',
        width: 36,
        Cell: ({ row }: CellProps<TOrderlineDocProps>) => (
          <span>
            {disabled ? null : <DeleteButton doc={row.original} />}
          </span>
        )
      }
    ],
    [orderline, disabled]
  );

  return (
    <>
      <Table
        id="ID_ORDER_PAGE_PRODUCTS_TABLE_DOCS_TABLE"
        data={orderline.expenses || []}
        columns={columns}
        pagination={false}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        rowId={rowId}
      />
      {disabled ? null : <AddButton onClick={() => setVisibleDrawer(true)} />}
      <CostsAddDrawer
        orderId={orderId}
        orderlineId={orderline.id}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getCurrentOrder', 'getOrderlineList']
          });
          setVisibleDrawer(false);
        }}
      />
    </>
  );
};

export default DocsTable;

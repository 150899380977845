import { useState, FC } from 'react';
import Link from 'next/link';
import { AutoComplete } from 'antd';
import { debounce } from 'lodash';

import { PartnerNameInputProps } from './PartnerNameInput.props';
import { useGetPartnerListForPartnersPageLazyQuery } from '~/apollo/generated/hooks';
import { Order_By } from '~/interfaces';

const PartnerNameInput: FC<PartnerNameInputProps> = ({
  value,
  defaultValue,
  placeholder = 'Наименование',
  onChange = () => ({}),
  ...props
}) => {
  const [options, setOptions] = useState<any[]>();

  const [searchPartners] = useGetPartnerListForPartnersPageLazyQuery({
    onCompleted: (data) => {
      if (!value) {
        setOptions(undefined);
        return;
      }

      setOptions(data.partner_list);
    }
  });

  const onSearchPartner = (val: any) => {
    const searchString = `%${(val.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}%`;

    searchPartners({
      variables: {
        where: {
          deleted: { _eq: false },
          clear_search_name: { _ilike: searchString },
          subject_type: {
            brief: { _eq: 'PARTNER' }
          }
        },
        limit: 10,
        orderBy: {
          name: Order_By.Asc
        }
      }
    });
  };

  return (
    <AutoComplete
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={debounce(onSearchPartner, 300)}
      onClear={() => setOptions([])}
      {...props}
    >
      {options?.map((partner) => (
        <AutoComplete.Option key={partner.id} value={partner.id} disabled={true}>
          <Link href={`/partners/${partner.id}`}>
            <a>{partner.name}</a>
          </Link>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

export default PartnerNameInput;

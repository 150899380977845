import { gql } from '@apollo/client';

export const UPDATE_SUBJECT_BY_PK = gql`
  mutation updateSubjectByPk($subject: t_subject_set_input, $subjectId: Int!, $append: t_subject_append_input) {
    subject: update_t_subject_by_pk(pk_columns: {subject_id: $subjectId}, _set: $subject, _append: $append) {
      id: subject_id
    }
  }
`;

export const ADD_SUBJECT = gql`
  mutation addSubject($subject: t_subject_insert_input!) {
    subject: insert_t_subject_one(object: $subject) {
      id: subject_id
    }
  }
`;

import { useMemo, FC } from 'react';
import { Table as AntTable } from 'antd';
import { useRouter } from 'next/router';
import { OrderPagePaymentsTableProps } from './OrderPagePaymentsTable.props';
import { useAppContext } from '~/contexts/AppContext';
import { Table, HorizontalLine } from '~/components';
import {useGetDocumentListQuery} from "~/apollo/generated/hooks";
import {roubleFormatter} from "~/utils";

const OrderPagePaymentsTable: FC<OrderPagePaymentsTableProps> = ({  order, data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№ платежа',
        accessor: 'number',
      },
      {
        Header: '№ заказа',
        // accessor: 'description',
        accessor: 'num_order',
      },
      {
        Header: 'Дата платежа',
        accessor: 'oper_date',
        Cell: ({ row }: { row: any }) => {
          const [yyyy, mm, dd] = row.original.oper_date?.split('-');
          return `${dd}.${mm}.${yyyy}`;
        }
      },
      {
        Header: '% оплаты',
        accessor: 'percent_paymnet', // сумма платежа (amount) / сумма заказа (order.order_sum) * 100 )  округлить до 1
        Cell: ({ row }: { row: any }) => {
          const amount = row.original.amount;
          const orderSum = order?.order_sum ?? 0;
          if (orderSum > 0) {
            const paymentPercentage = (amount / orderSum) * 100;
            return `${paymentPercentage.toFixed(1)}%`;
          }
          return '';
        }
      },
      {
        Header: 'Сумма',
        accessor: 'amount',
        Cell: ({row} : {row: any}) => {
          return roubleFormatter(row.original.amount);
        }
      },
      // {
      //   Header: 'СуммаЗаказ',
      //   accessor: 'order_amount',
      //   Cell: ({row} : {row: any}) => {
      //     return roubleFormatter(row.original?.t_order?.order_sum);
      //   }
      // },
    ],
    []
  );

  // Вычисление итоговых данных
  const totals = useMemo(() => {
    const count = data.length;
    const amount = data.reduce((sum: number, item: any) => sum + item.amount, 0);
    const percentage =
      order?.order_sum > 0 ? (amount / order?.order_sum) * 100 : 0 ?? 0;

    return {
      count,
      amount,
      percentage,
    };
  }, [data, order]);
  

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        pagination={false}
        {...props}
      />
      <HorizontalLine />
      ИТОГО ОПЛАТ ({totals.count}): {roubleFormatter(totals.amount)} ({totals.percentage.toFixed(1)}%)
    </div>
    );
};

const OrderPagePaymentsTableQuery = (props: any) => {
  const router = useRouter();


  const where: any = {
    doc_type_id: {_eq: 3},
    deleted: { _neq: true },
    order_id: { _eq:  router?.query?.id}
  };
  const orderBy: any[] = [];

  const limit = 40;

  const { data } = useGetDocumentListQuery({
    variables: {
      where,
      // offset: (page - 1) * limit,
      limit,
      orderBy,
    }
  });

  console.log('data in order payments', data);

  return (
    <OrderPagePaymentsTable
      id="ID_ORDER_PAGE_PAYMENTS_TABLE"
      data={data?.t_doc || []}
      order={props.order}
    />
  );
};

export default OrderPagePaymentsTableQuery;

import { gql } from '@apollo/client';

export const ADD_FTRAN = gql`
  mutation addFTran($documentId: bigint, $amount: numeric, $subjectId: Int, $orderId: Int, $description: String, $orderlineId: bigint) {
    insert_t_ftran(objects: {doc_id: $documentId, amount: $amount, subject_id: $subjectId, order_id: $orderId, description: $description, orderline_id: $orderlineId}) {
      returning {
        amount
        subject_id
        ftran_id
        id: ftran_id
        doc_id
        orderline_id
        doc: t_doc {
          number
          oper_date
          partner {
            name
          }
        }
      }
    }
  }
`;

export const REMOVE_FTRAN = gql`
  mutation removeFTran($ftranId: bigint!) {
    delete_t_ftran_by_pk(ftran_id: $ftranId) {
      ftran_id
    }
  }
`;

export const REMOVE_DOCUMENT_FTRAN = gql`
  mutation removeDocumentFTran($docId: bigint) {
    delete_t_ftran(where: { doc_id: { _eq: $docId } }) {
      affected_rows
    }
  }
`;

export const UPDATE_FTRAN = gql`
  mutation updateFTran($ftranId: bigint!, $amount: numeric, $description: String) {
    update_t_ftran_by_pk( pk_columns: { ftran_id: $ftranId } _set: { amount: $amount, description: $description }) {
      ftran_id
      amount
      description
    }
  }
`;

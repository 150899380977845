import { useMemo, FC } from 'react';
import { Tag, Space } from 'antd';
import { CellProps } from 'react-table';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { OrderPageProductsTableProps } from './OrderProductsTable.props';
import Table from '../../Table';
import { useOrderlineRowNumUpLazyQuery, useOrderlineRowNumDownLazyQuery } from '~/apollo/generated/hooks';
import { TOrderlineProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import DeleteButton from './DeleteButton/DeleteButton';
import { checkVarIsUrl } from '~/utils';

const { Cells: { TextInputCell, NumberInputCell, ExpanderCell, PriceInputCell } } = Table;

export const OrderPageProductsTable: FC<OrderPageProductsTableProps> = ({
  data,
  disabled = false,
  ...props
}) => {
  const client = useApolloClient();
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const [moveRowUp] = useOrderlineRowNumUpLazyQuery({
    onCompleted: async () => {
      console.log('completed row up');
      await client?.refetchQueries({
        include: ['getCurrentOrder', 'getOrderlineList']
      });
    },
    fetchPolicy: 'network-only'
  });

  const [moveRowDown] = useOrderlineRowNumDownLazyQuery({
    onCompleted: async () => {
      console.log('completed row down');
      await client?.refetchQueries({
        include: ['getCurrentOrder', 'getOrderlineList']
      });
    },
    fetchPolicy: 'network-only'
  });

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        width: 41,
        Cell: ({ ...props }: CellProps<any>) => (
          <ExpanderCell {...props} />
        ),
      },
      {
        Header: '',
        id: 'id',
        width: 50,
        Cell: ({ row }: CellProps<TOrderlineProps>) => (
          <span>{row.index + 1}</span>
        )
      },
      {
        Header: 'Наименование',
        accessor: 'caption',
        width: 300,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<TextInputCell {...props} />)}
          </>
        )
      },
      {
        Header: 'Кол-во',
        accessor: 'quantity',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<NumberInputCell {...props} />)}
          </>
        )
      },
      {
        Header: 'Цена',
        accessor: 'price',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell showCurrency={false} {...props} />)}
          </>
        )
      },
      {
        Header: 'Сумма',
        accessor: 'amount',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell showCurrency={false} {...props} />)}
          </>
        )
      },
      {
        Header: 'Поставщик',
        accessor: 'provider',
        width: 140,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {/*надо в disabled чтобы работала ссылка */}
            {disabled ? (
              <>
                {checkVarIsUrl(props.value) ? (
                  <a href={props.value} target="_blank">{props.value}</a>
                ) : <>{props.value}</>}
              </>

            ) : (
              <TextInputCell {...props}>
                {checkVarIsUrl(props.value) ? (
                  <a href={props.value} target="_blank">{props.value}</a>
                ) : <>{props.value}</>}
              </TextInputCell>
            )}
          </>
        )
      },
      {
        Header: 'Цена закуп.',
        accessor: 'purchase_price',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell showCurrency={false} {...props} />)}
          </>
        )
      },
      {
        Header: 'Сумма закуп.',
        accessor: 'purchase_amount',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell showCurrency={false} {...props} />)}
          </>
        )
      },
      {
        Header: 'Прибыль, руб.',
        accessor: 'marzh',
        width: 80,
        Cell: ({ ...props }: CellProps<any>) => (
          <>
            {disabled ? (<span>{props.value}</span>) : (<PriceInputCell showCurrency={false} {...props} />)}
          </>
        )
      },
      {
        Header: 'Прибыль.%',
        accessor: 'marzh_percent',
        width: 80,
        Cell: ({ value }: CellProps<TOrderlineProps, TOrderlineProps['marzh_percent']>) => (
          <span>
            <Tag color={value > 20 ? 'blue' : 'red'}>{value} %</Tag>
          </span>
        )
      },
      {
        Header: 'Сумма расходов',
        id: 'sumout',
        width: 80,
        Cell: ({ row }: CellProps<TOrderlineProps>) => (
          <span>
            {(Math.round(row.original.fixed_cost * 100) / 100 + row.original.purchase_amount).toLocaleString('ru-RU') }
          </span>
        )
      },
      {
        Header: 'Себест.',
        id: 'perpiece',
        Cell: ({ row }: CellProps<TOrderlineProps>) => {
          const { fixed_cost, purchase_amount, quantity } = row.original;

          if (!quantity) return <span>0</span>;

          return (
            <span>
              { (Math.round((+fixed_cost + +purchase_amount) / +quantity * 100) / 100).toLocaleString('ru-RU') }
            </span>
          );
        }
      },
      {
        Header: '',
        id: 'action',
        width: 108,
        Cell: ({ row }: CellProps<TOrderlineProps>) => (
          <>
            {disabled ? (<span></span>) : (
              <span>
                <Space size={24}>
                  <DeleteButton id={row.original.orderline_id} />
                  <ArrowUpOutlined
                    onClick={() => {
                      moveRowUp({ variables: { rowId: row.original.id } });
                    }}
                  />
                  <ArrowDownOutlined
                    onClick={() => {
                      moveRowDown({ variables: { rowId: row.original.id } });
                    }}
                  />
                </Space>
              </span>
            )}
          </>
        )
      }
    ],
    [data, disabled]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

export default OrderPageProductsTable;

import { FC, useMemo } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Progress, Tag } from 'antd';

import {
  LogisticsPageShipmentsTableProps,
  LogisticsPageShipmentsTableQueryProps
} from './LogisticsPageShipmentsTable.props';
import { Table } from '~/components';
import { useGetCountTaskQuery, useGetTaskListQuery } from '~/apollo/generated/hooks';
import { TTaskProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const LogisticsPageShipmentsTable: FC<LogisticsPageShipmentsTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['id']>) => (
          <span>
            <Link href={`/shipments/${value}`}>
              <a>{value}</a>
            </Link>
          </span>
        )
      },
      {
        Header: 'Поставщик',
        id: 'provider',
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {(row.original.subject && row.original.subject.name) || '' }
            &nbsp;
            { (row.original.subject && row.original.subject.surname) || ''}
          </span>
        )
      },
      {
        Header: 'Тип транспорта',
        id: 'tip_transporta',
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {(row.original.task_props && row.original.task_props.find(taskProp => taskProp.prop?.brief === 'TIP_TRANSPORTA')
              && row.original.task_props.find(taskProp => taskProp.prop?.brief === 'TIP_TRANSPORTA')?.text_val) || ''}
          </span>
        )
      },
      {
        Header: 'Дата отгрузки',
        id: 'date_start_string',
      },
      {
        Header: 'Город назначения',
        id: 'city',
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {(row.original.task_props && row.original.task_props.find(taskProp => taskProp.prop?.brief === 'CITY')
              && row.original.task_props.find(taskProp => taskProp.prop?.brief === 'CITY')?.prop_value
              && row.original.task_props.find(taskProp => taskProp.prop?.brief === 'CITY')?.prop_value?.value) || '-'}
          </span>
        )
      },
      {
        Header: 'Статус',
        id: 'status',
        Cell: ({ row }: CellProps<TTaskProps>) => (
          <span>
            {row.original.status && row.original.status.name && row.original.status.name === 'Новый' ? (
              <Tag color="green">{(row.original.status && row.original.status.name) || ''}</Tag>
            ) : (
              <Tag color="blue">{(row.original.status && row.original.status.name) || ''}</Tag>
            )}

            {row.original.status && row.original.status.name && row.original.status.name === 'Новый' ? (
              <Progress showInfo={false} percent={0} size="small" status="active" />
            ) : null}
            {row.original.status && row.original.status.name && row.original.status.name === 'Запланирован' ? (
              <Progress showInfo={false} percent={50} size="small" status="active" />
            ) : null}
            {row.original.status && row.original.status.name && row.original.status.name === 'Отгружен' ? (
              <Progress showInfo={false} percent={100} size="small" status="active" />
            ) : null}
          </span>
        )
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const LogisticsPageShipmentsTableQuery: FC<LogisticsPageShipmentsTableQueryProps> = ({
  router,
  ...props
}) => {
  const page = router.query.page_shipment ? +router.query.page_shipment : 1;
  const limit = 10;

  const { data } = useGetTaskListQuery({
    variables: {
      type: 'SHIPMENT_TASK',
      offset: (page && ((page - 1) * limit)) || 0,
      limit
    }
  });

  const { data: dataCount } = useGetCountTaskQuery({
    variables: {
      type: 'SHIPMENT_TASK',
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <LogisticsPageShipmentsTable
      id="ID_LOGISTICS_PAGE_SHIPMENTS_TABLE"
      data={data?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      {...props}
    />
  );
};

export default withRouter(LogisticsPageShipmentsTableQuery);

import { FC } from 'react';
import { Select } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { Order_By } from '~/interfaces';
import { JobTitleSelectProps } from './JobTitleSelect.props';

const JobTitleSelect: FC<JobTitleSelectProps> = ({
  onChange = () => ({})
}) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'DOLZHNOST' },
        },
      },
      orderBy: [{
        value: Order_By.Asc
      }]
    }
  });

  return (
    <Select
      showSearch
      placeholder="Выберите должность"
      optionFilterProp="children"
      onChange={onChange}
    >
      {data?.t_prop_value.map((d) => (
        <Select.Option key={d.id} value={d.id}>
          {d.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default JobTitleSelect;

import { FC } from 'react';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { TdProps } from './Td.props';

import styles from './Td.module.css';

const Td: FC<TdProps> = ({
  column,
  getColumnProps,
  getHeaderProps,
  disableFilters,
  headerGroup,
  index,
}) => (
  <Draggable draggableId={column.id} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...column.getHeaderProps([
          {
            className: cn(column.className, styles.th),
            style: column.style,
          },
          getColumnProps(column),
          getHeaderProps(column),
          {...provided.draggableProps},
        ])}
      >
        <div
          className={cn(styles.thTitle, {
            [styles.lastCol]: 0 === headerGroup.headers.length - 1,
            [styles.sorter]: column?.sorter || false
          })}
        >
          <div {...provided.dragHandleProps}>
            {column?.sorter ? (
              <div {...column.getSortByToggleProps()}>
                {column.isSorted ? (
                  <span className={cn(styles.arrow, {
                    [styles.arrowDesc]: column.isSortedDesc === true,
                    [styles.arrowAsc]: column.isSortedDesc === false,
                  })}></span>
                ) : null }
                {column.render('Header')}
              </div>
            ) : (
              <div>
                {column.render('Header')}
              </div>
            )}
          </div>
          <div
            {...column.getResizerProps()}
            className={cn(styles.resizer, {
              [styles.resizerActive]: column.isResizing,
            })}
          />
        </div>
        {disableFilters ? <></> : (
          <>
            {column.canFilter ? (
              <div
                className={styles.thFilterBlock}
                onContextMenu={(e) => e.stopPropagation()}
              >
                {column.render('Filter')}
              </div>
            ) : (
              <div
                className={styles.thFilterEmptyBlock}
                onContextMenu={(e) => e.stopPropagation()}
              ></div>
            )}
          </>
        )}
      </div>
    )}
  </Draggable>
);

export default Td;

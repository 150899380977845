import { gql } from '@apollo/client';

export const DEFAULT_USER_INFO_QUERY_FIELDS = `
  id: user_id
  user_id
  email
  is_admin
  surname
  login
  name
  phone
  subject_id
  subject {
    email
    address
    lastname
    ins_date
    inn
    ins_date_format
    name
    phone
    subject_id
    id: subject_id
    surname
    type_id
    subject_type {
      brief
      name
      subject_type_id
    }
    avatar
    deleted
  }
`;

export const DEFAULT_USER_INFO = gql`
  fragment defaultUserInfo on t_user {
    id: user_id
    user_id
    email
    is_admin
    surname
    login
    name
    phone
    subject_id
    subject {
      email
      address
      lastname
      ins_date
      inn
      ins_date_format
      name
      phone
      subject_id
      id: subject_id
      surname
      type_id
      subject_type {
        brief
        name
        subject_type_id
      }
      avatar
      deleted
    }
  }
`;

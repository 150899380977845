import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Avatar, List, Spin, Tooltip } from 'antd';
import { UserOutlined, EditOutlined, MessageOutlined, BellOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useApolloClient } from '@apollo/client';

import { NotifyArchivePageListProps } from './NotifyArchivePageList.props';
import { useGetMessageListSubscription, useGetMessagesCountQuery, useGetMessagesListQuery } from '~/apollo/generated/hooks';
import TaskPriorityIcon from '../../TaskPriorityIcon';

import styles from './NotifyArchivePageList.module.css';
import { UPDATE_MESSAGE } from '~/apollo/mutations/messages';
import { TMessageProps } from '~/interfaces';

const NotifyArchivePageList: FC<NotifyArchivePageListProps> = ({ router }) => {
  const { data: session } = useSession();
  const client = useApolloClient();
  const [limit, setLimit] = useState(15);
  const [messages, setMessages] = useState<TMessageProps[]>([]);

  const { data: total, refetch: refetchTotal } = useGetMessagesCountQuery({
    variables: {
      where: {
        recipient_id: {
          _eq: session?.user.subject_id
        }
      }
    }
  });

  const { fetchMore } = useGetMessagesListQuery({
    variables: {
      limit,
      offset: 0,
      where: {
        recipient_id: {
          _eq: session?.user.subject_id
        }
      }
    },
    onCompleted: (data) => {
      const newMessages = data.messages as TMessageProps[];
      setMessages(newMessages);
    }
  });

  const loadMore = () => {
    const currentLength =  messages.length;

    fetchMore({
      variables: {
        offset: currentLength,
        limit: 15,
      }
    }).then(fetchMoreResult => {
      // Update variables.limit for the original query to include
      // the newly added feed items.
      const newMessages = fetchMoreResult.data.messages as TMessageProps[];
      setMessages([...messages, ...newMessages]);
      setLimit(currentLength + fetchMoreResult.data.messages.length);
    });
  };

  useGetMessageListSubscription({
    variables: {
      limit: 1,
      where: {
        recipient_id: {
          _eq: session?.user.subject_id
        }
      }
    },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.messages) {
        const el = messages.find((m) => m.id === data.subscriptionData.data?.messages[0].id);

        if (!el) {
          const newMessage = data.subscriptionData.data?.messages[0] as TMessageProps;
          setMessages([newMessage, ...messages]);
        }
      }
      refetchTotal();
    }
  });

  const updateNotice = async (id: any) => {
    await client.mutate({
      mutation: UPDATE_MESSAGE,
      variables: {
        set: {
          see: true,
          viewed_date: 'now()'
        },
        messageId: id
      },
    });
  };

  const openNotice = async (notice: any) => {
    if (notice.external_url) {
      console.log('open external url', notice);
      window.open(notice.external_url);
    }

    if (!notice.url) { return; }

    if (!notice.see) {
      await updateNotice(notice.id);
    }

    router.push(notice.url);
  };

  console.log('message', messages);
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 700,
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={messages.length || 0}
        next={loadMore}
        hasMore={(messages.length && total?.messages.aggregate?.count) && messages.length < total?.messages.aggregate?.count || false}
        loader={<div className={styles.loader}><Spin /></div>}
        scrollableTarget="scrollableDiv"
      >
        <List<TMessageProps>
          dataSource={messages}
          renderItem={(notice) => (
            <List.Item
              key={notice.id}
              className={styles.item}
              onClick={() => openNotice(notice)}
            >
              {notice['msg_type']=='task' ? (
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: notice.see ? 100 : 500 }}>
                      {notice.title}
                    </div>
                  }
                  description={
                    <>
                      <a href={`/partners/${notice?.doc?.object_id}`}>{notice.body}</a>
                    </>
                  }
                />
              ) : null}

              {notice.link ? (
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: notice.see ? 100 : 500 }}>
                      {notice.title}
                    </div>
                  }
                  avatar={
                    <Avatar size={20} icon={<BellOutlined />} />
                  }
                />
              ) : null}

              {notice.comment ? (
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: notice.see ? 100 : 500 }}>
                      {notice.title}
                    </div>
                  }
                  description={<span>{notice.body}</span>}
                  avatar={
                    <Avatar size={20} icon={<MessageOutlined />} />
                  }
                />
              ) : null}

              {notice.task_update ? (
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: notice.see ? 100 : 500 }}>
                      {notice.title}
                    </div>
                  }
                  description={<span>{notice.body}</span>}
                  avatar={
                    <Avatar size={20} icon={<EditOutlined />} />
                  }
                />
              ) : null}

              {notice.subject_link ? (
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: notice.see ? 100 : 500 }}>
                      {notice.title}
                    </div>
                  }
                  description={<span>{notice.body}</span>}
                  avatar={
                    <Avatar size={20} icon={<UserOutlined />} />
                  }
                />
              ) : null}

              <div>
                {notice.ins_date_string} {notice.ins_time_string}
                {notice.see ? (
                  <>
                    <br />
                    <span style={{ color: 'rgb(23, 166, 0)' }}>
                      Просмотрено
                    </span>
                  </>
                ) : null}
              </div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default withRouter(NotifyArchivePageList);

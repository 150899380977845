import { gql } from '@apollo/client';

export const FILE_UPLOAD = gql`
  mutation fileUpload($objectId: Int, $objectTypeId: Int, $linkTypeId: Int, $fileType: String, $userId: Int, $base64str: String!, $fileName: String!, $mimeType: String!) {
    fileUpload(objectId: $objectId, linkTypeId: $linkTypeId, fileType: $fileType, userId: $userId, objectTypeId: $objectTypeId, base64str: $base64str, fileName: $fileName, mimeType: $mimeType) {
      file_id
      file_name
      file_path
      file_type
      ins_date
      mime_type
      user_id
      deleted
    }
  }
`;

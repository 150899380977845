import { useState, useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Row, Popconfirm, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Cell, CellProps } from 'react-table';

import { useGetCountDocumentsQuery, useGetDocumentListQuery } from '~/apollo/generated/hooks';
import { VendorDocsPageTableProps, VendorDocsPageTableQueryProps } from './VendorDocsPageTable.props';
import { Table, DocumentsDrawer } from '~/components';
import OrdersModal from './OrdersModal/OrdersModal';
import DeleteButton from './DeleteButton/DeleteButton';
import { TDocProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { roubleFormatter, userHaveRights } from '~/utils';

const VendorDocsPageTable: FC<VendorDocsPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();
  const [currentDocId, setCurrentDocId] = useState<any>(null);
  const [currentDoc, setCurrentDoc] = useState<TDocProps | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
        width: 120,
        sorter: true,
      },
      {
        Header: 'Дата',
        accessor: 'ddmmyyyy',
        sorter: true,
      },
      {
        Header: 'Партнер',
        accessor: 'partner',
        width: 300,
        Cell: ({ value }: CellProps<TDocProps, TDocProps['partner']>) => (
          <span>{value?.name}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Сумма, ₽',
        accessor: 'amount',
        Cell: ({ value }: CellProps<TDocProps, TDocProps['amount']>) => (
          <span>{roubleFormatter(value)}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Привязано, ₽',
        accessor: 'expenses',
        Cell: ({ value }: CellProps<TDocProps, TDocProps['expenses']>) => (
          <span>{roubleFormatter(value)}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Не привязано, ₽',
        id: 'not_expenses',
        Cell: ({ row }: CellProps<TDocProps>) => (
          <span>{roubleFormatter(row.original.exp?.doc_rest_expenses)}</span>
        ),
        canSort: true,
        sorter: true,
      },
      {
        Header: 'Комментарий',
        accessor: 'description',
        sorter: true,
      },
      {
        Header: 'Менеджер',
        accessor: 'manager',
        Cell: ({ value }: CellProps<TDocProps, TDocProps['manager']>) => (
          <span>
            {value ? <>{ value.name } { value.lastname }</> : null}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Действие',
        id: 'action',
        width: 80,
        Cell: ({ row }: CellProps<TDocProps>) => (
          <Row align="middle" justify="center" style={{ height: '100%' }} onClick={(e) => e.stopPropagation()}>
            {row?.original?.can_edit_doc1 === true ?
              <Popconfirm
                title="Редактировать документ?"
                placement="left"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => showDoc(row.original)}
              >
                <EditOutlined style={{ color: '#1EB328' }} />
              </Popconfirm>
              : null}
            &nbsp;
            {!row.original.ftrans.length && row?.original?.can_edit_doc1 === true ? (
              <DeleteButton id={row.original.id} />
            ) : null}
          </Row>
        )
      }
    ],
    [data]
  );

  const showFTrans = (id: any) => {
    setCurrentDocId(id || null);
    setShowModal(true);
  };

  const closeFTrans = () => {
    setCurrentDocId(null);
    setShowModal(false);
  };

  const showDoc = (doc?: TDocProps) => {
    setCurrentDoc(doc || null);
    setShowDrawer(true);
  };

  const closeDoc = () => {
    setCurrentDoc(null);
    setShowDrawer(false);
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        getCellProps={(cell: Cell<TDocProps>) => ({
          onClick: () => {
            showFTrans(cell.row.original.id);
          }
        })}
        {...props}
      />
      <OrdersModal
        docId={currentDocId}
        visible={showModal}
        onClose={closeFTrans}
        onOk={closeFTrans}
      />
      <DocumentsDrawer
        docId={currentDoc?.id}
        visible={showDrawer}
        onClose={closeDoc}
        onSave={closeDoc}
      />
    </>
  );
};

const VendorDocsPageTableQuery: FC<VendorDocsPageTableQueryProps> = ({ router, ...props }) => {
  const { data: session } = useSession();
  const search = router.query.search ? router.query.search : undefined;
  const partnerId = router.query.partner ? +router.query.partner : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const restExpense = router.query.restExpense?.toString() || null;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 40;

  let where: any = {
    doc_type_id : { _eq: 1},
    deleted: { _neq: true },
  };
  let orderBy: any[] = [];

  if (restExpense === 'yes') {
    // where = { exp: { doc_rest_expenses: { _neq: 0 } }, ...where };
    where = { rest_expenses: { rest: { _neq: 0 } }, ...where };
  }

  if (partnerId) {
    where = {
      ...where,
      partner_id: { _eq: partnerId }
    };
  }

  if (managerId) {
    where = {
      ...where,
      manager_id: { _eq: managerId }
    };
  }

  if (dateFrom) {
    where = {
      ...where,
      oper_date: {
        _gte: dateFrom
      }
    };
  }

  if (dateTo) {
    where = {
      ...where,
      oper_date: {
        ...where.oper_date,
        _lte: dateTo,
      },
    };
  }

  if (search) {
    where = {
      ...where,
      number: { _similar: `${search}` }
    };
  }

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'partner') {
        return { partner: { name: value } };
      }

      if (key === 'ddmmyyyy') {
        return { oper_date: value };
      }

      if (key === 'manager') {
        return { ins_user: value };
      }

      if (key === 'expenses') {
        return {
          t_ftrans_aggregate: {
            sum: { amount: value }
          }
        };
      }

      if (key === 'not_expenses') {
        return {
          exp: {
            doc_rest_expenses: value
          }
        };
      }

      return { [key]: value };
    });
  }
  // else {
  //   orderBy = [{ order_id: Order_By.Desc }];
  // }

  const { data } = useGetDocumentListQuery({
    variables: {
      where,
      offset: (page - 1) * limit,
      limit,
      orderBy,
    }
  });

  const { data: dataCount } = useGetCountDocumentsQuery({
    variables: {
      where
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  const getDocs = async () => {
    await fetch('/api/documents-export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        where,
        orderBy,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        }

        const error: any = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "documents.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };

  return (
    <>
      <VendorDocsPageTable
        id="ID_VENDOR_DOCS_PAGE_TABLE"
        data={data?.t_doc || []}
        pagination={{
          current: page,
          total: (dataCount && dataCount.t_doc_aggregate.aggregate?.count) || 0,
          pageSize: limit,
        }}
        onPaginationChange={onTableChange}
        onSorterChange={onSorterChange}
        initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
        {...props}
      />
      {userHaveRights(session?.user.user_id) && data?.t_doc.length ? (
        <Button type="link" onClick={getDocs}>Выгрузить</Button>
      ) : null}
    </>
  );
};

export default withRouter(VendorDocsPageTableQuery);

import { FC } from 'react';
import { Popconfirm, message } from 'antd';

import { DeleteButtonProps } from './DeleteButton.props';
import { useDeleteSubjectLinkMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({
  partnerId,
  organizationId
}) => {
  const [deleteLink, { loading }] = useDeleteSubjectLinkMutation({
    onCompleted: () => {
      message.success('Компания успешно удалена');
    },
    onError: () => {
      message.error('При удалении компании партнера произошла ошибка');
    },
    refetchQueries: ['getCompanyList', 'getCountCompany']
  });

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => {
        deleteLink({
          variables: {
            where: {
              subject_id: { _eq: partnerId },
              object_id: { _eq: organizationId },
              t_object_type: {
                brief: { _eq: 'SUBJECT' }
              },
              t_link_type: {
                brief: { _eq: 'PARTNER_ORGANIZATION' }
              }
            }
          }
        });
      }}
      disabled={loading}
    >
      <span style={{ color: '#1890ff' }}>Удалить</span>
    </Popconfirm>
  );
};

export default DeleteButton;

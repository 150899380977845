import { FC } from 'react';
import { Select } from 'antd';

import { useGetStatusSubjectListQuery } from '~/apollo/generated/hooks';
import { Order_By } from '~/interfaces';
import { PartnerStatusSelectProps } from './PartnerStatusSelect.props';

const PartnerStatusSelect: FC<PartnerStatusSelectProps> = ({
  onChange = () => ({}),
  showSearch = true,
  allowClear = true,
  placeholder = 'Статус',
  optionFilterProp = 'children',
  filterOption,
  ...props
}) => {
  const { data } = useGetStatusSubjectListQuery({
    variables: {
      where: {
        type: {
          brief: { _eq: 'PARTNER' }
        },
        is_disabled: {_neq: true}
      },
      orderBy: {
        subject_status_id: Order_By.Asc
      }
    }
  });

  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      onChange={onChange}
      {...props}
    >
      {data?.subject_status_list.map((b) => (
        <Select.Option key={b.id} value={b.id}>
          {b.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PartnerStatusSelect;

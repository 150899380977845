import { FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';

import { OrderProductsExportButtonProps } from './OrderProductsExportButton.props';

const OrderProductsExportButton: FC<OrderProductsExportButtonProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  return (
    <Link href={`/api/xls-export/?id=${id}`}>
      <a target="_blank">Выгрузить в XLS</a>
    </Link>
  );
};

export default withRouter(OrderProductsExportButton);

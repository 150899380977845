import { useMemo, FC } from 'react';
import { Button } from 'antd';
import { CellProps } from 'react-table';

import { DocsTableProps } from './DocsTable.props';
import { TDocDrawerTableProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { Table } from '~/components';

const DocsTable: FC<DocsTableProps> = ({
  data,
  onSelect = () => ({})
}) => {
  const { state } = useAppContext();
  const columns = useMemo(
    () => [
      {
        Header: "Номер",
        accessor: "number",
      },
      {
        Header: "Дата",
        accessor: "oper_date",
        width: 90,
      },
      {
        Header: "Сумма",
        accessor: "amount",
      },
      {
        Header: "Комментарий",
        accessor: "description",
      },
      {
        Header: "",
        id: "action",
        width: 90,
        Cell: ({ row }: CellProps<TDocDrawerTableProps>) => (
          <Button
            type="link"
            onClick={() => onSelect(row.original.doc_id)}
          >
            Выбрать
          </Button>
        )
      },
    ],
    [data]
  );

  const localTableSettings = state.tables.find((table) => table.id === 'ID_DOCUMENTS_DRAWER_DOCS_TABLE');

  return (
    <Table
      id="ID_DOCUMENTS_DRAWER_DOCS_TABLE"
      data={data || []}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
    />
  );
};

export default DocsTable;

import { FC } from 'react';
import Link from 'next/link';
import { Card } from 'antd';

import { ObjectInfoProps } from './ObjectInfo.props';
import { useGetTaskObjectQuery } from '~/apollo/generated/hooks';

const ObjectInfo: FC<ObjectInfoProps> = ({
  task
}) => {
  const { data } = useGetTaskObjectQuery({
    variables: {
      taskId: task.object_id
    }
  });

  if (!data) return null;

  const object = data.task;

  if (!object) return null;

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 8 }}
    >
      <Card
        bordered={false}
        className="mini-card"
        title="Задача:"
      >
        <Link href={`/tasks/${object.id}`}>
          <a>№{ object.id } { object.name || object.description || '' }</a>
        </Link>
      </Card>
    </Card>
  );
};

export default ObjectInfo;

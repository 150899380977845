import fetch from 'isomorphic-unfetch';

import { DEFAULT_USER_INFO_QUERY_FIELDS } from '~/apollo/fragments/users';

const USER_QUERY = `
  query($where: t_user_bool_exp) {
    user: t_user(where: $where, limit: 1) {
      ${DEFAULT_USER_INFO_QUERY_FIELDS}
    }
  }
`;

const AUTH_QUERY = `
  query($args: auth_user_args!, $where: t_user_bool_exp){
    user: auth_user(args: $args, limit: 1, where: $where) {
      ${DEFAULT_USER_INFO_QUERY_FIELDS}
    }
  }
`;

const findUser = async (email: string, password: string | null): Promise<any> => {
  try {
    let params = null;

    if (password) {
      params = {
        query: AUTH_QUERY,
        variables: {
          args: {
            pas: password,
            eml: email
          },
          where: {
            subject: {
              deleted: { _eq: false }
            }
          }
        }
      };
    } else {
      params = {
        query: USER_QUERY,
        variables: {
          where: {
            email: { _eq: email },
            password: { _is_null: true },
            deleted: { _eq: false },
            subject: {
              deleted: { _eq: false }
            }
          }
        }
      };
    }

    let headers = {};

    if (process.env.HASURA_SECRET) {
      headers = {
        'x-hasura-admin-secret': process.env.HASURA_SECRET
      };
    }

    const response = await fetch(process.env.GRAPHQL_URL + '/v1/graphql', {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    });

    const { data, errors } = await response.json();

    if (errors) {
      console.error(errors);
      return { error: errors };
    }

    const user = (data.user && data.user[0]) || null;

    return { user };
  } catch (error: any) {
    console.error(error);
    return { error: error.message };
  }
};

export default findUser;

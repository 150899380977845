import { useState, useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Button, Tag } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { OrderPageShipmentsTableProps, OrderPageShipmentsTableQueryProps } from './OrderPageShipmentsTable.props';
import { ShipmentRequestDrawer, Table } from '~/components';
import { useGetCurrentOrderQuery, useGetTasksByOrderIdQuery, useGetTasksCountByOrderIdQuery } from '~/apollo/generated/hooks';
import { TTaskByOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const OrderPageShipmentsTable: FC<OrderPageShipmentsTableProps> = ({
  order,
  data,
  ...props
}) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
      },
      {
        Header: 'Текущий статус',
        id: 'status',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {row.original.selected_shipment_task
              && row.original.selected_shipment_task[0]
              && row.original.selected_shipment_task[0].status
              && row.original.selected_shipment_task[0].status.name
              ? (
                <Tag color="blue">{row.original.selected_shipment_task[0].status.name}</Tag>
              ) : '-'
            }
          </span>
        )
      },
      {
        Header: 'Дата начала',
        accessor: 'date_start_string'
      },
      {
        Header: 'Дедлайн',
        accessor: 'deadline_string'
      },
      {
        Header: 'Поставщик',
        id: 'provider',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>{ row.original && row.original.subject && row.original.subject.name }</span>
        )
      },
      {
        Header: 'Номер документа поставщика',
        accessor: 'document',
        Cell: ({ value }: CellProps<TTaskByOrderProps, TTaskByOrderProps['document']>) => (
          <span>{(value && value.number) || '-'}</span>
        )
      },
      {
        Header: 'Кол-во кубов',
        id: 'qty_cubs',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_KUBOV')
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_KUBOV')?.int_val
            ) || '-'}
          </span>
        )
      },
      {
        Header: 'Кол-во мест',
        accessor: 'qty_places',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_MEST')
              && row.original.task_props.find(p => p.prop?.brief === 'KOLICHESTVO_MEST')?.int_val
            ) || '-'}
          </span>
        )
      },
      {
        Header: 'Вариант отгрузки',
        accessor: 'variant',
        Cell: ({ row }: CellProps<TTaskByOrderProps>) => (
          <span>
            {(row.original.task_props
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')?.prop_value
              && row.original.task_props.find(p => p.prop?.brief === 'CITY')?.prop_value?.value
            ) || '-' }
          </span>
        )
      },
      {
        Header: 'Комментарий',
        accessor: 'description'
      }
    ],
    [data]
  );

  return (
    <>
      {order?.archived ? null : (
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          style={{ marginBottom: 16 }}
          onClick={() => setVisibleDrawer(true)}
        >
          Запланировать отгрузку
        </Button>
      )}
      <Table
        data={data}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <ShipmentRequestDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => {
          client.refetchQueries({
            include: ['getTasksByOrderId', 'getTasksCountByOrderId']
          });
          setVisibleDrawer(false);
        }}
        objectId={order?.id}
      />
    </>
  );
};

const OrderPageShipmentsTableQuery: FC<OrderPageShipmentsTableQueryProps> = ({ router }) => {
  const { id } = router.query;
  const page = router.query.page_shipment ? +router.query.page_shipment : 1;
  const limit = 10;

  if (!id) return null;

  const { data: order } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  const { data: dataShipments } = useGetTasksByOrderIdQuery({
    variables: {
      orderId: id,
      limit,
      offset: (page - 1) * limit,
      taskTypeBrief: 'SHIPMENT_REQUEST',
      objectTypeBrief: 'ORDER',
    }
  });

  const { data: dataCount } = useGetTasksCountByOrderIdQuery({
    variables: {
      taskTypeBrief: 'SHIPMENT_REQUEST',
      objectTypeBrief: 'ORDER',
      orderId: id
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  return (
    <OrderPageShipmentsTable
      id="ID_ORDER_PAGE_SHIPMENTS_TABLE"
      order={order?.t_order[0]}
      data={dataShipments?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
    />
  );
};

export default withRouter(OrderPageShipmentsTableQuery);

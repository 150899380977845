import { FC } from 'react';
// import { ApolloClient } from '@apollo/client';
import { Drawer, Button, Form, Input } from 'antd';

// import { useAddEmployeeMutation } from '~/apollo/generated/hooks';
// import { ADD_SALES_AREA_LINKS, DELETE_SALES_AREA_LINKS } from '~/apollo/mutations/sales_areas';
// import { ADD_SUBJECT_PROP, DELETE_SUBJECT_PROP } from '~/apollo/mutations/subject_props';
import { EmployeesAddDrawerProps } from './EmployeesAddDrawer.props';
import JobTitleSelect from './JobTitleSelect/JobTitleSelect';
import PartnerSelect from './PartnerSelect/PartnerSelect';
import PhoneInput from '~/components/PhoneInput';

const EmployeesAddDrawer: FC<EmployeesAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  // onSave = () => ({})
}) => {
  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);

  // const saveProp = async (
  //   client: ApolloClient<any>,
  //   subjectId?: any,
  //   propId?: number,
  //   propValueId?: number
  // ) => {
  //   try {
  //     await client.mutate({
  //       mutation: DELETE_SUBJECT_PROP,
  //       variables: {
  //         propId,
  //         subjectId,
  //       },
  //     });

  //     const { data } = await client.mutate({
  //       mutation: ADD_SUBJECT_PROP,
  //       variables: {
  //         prop: {
  //           prop_id: propId,
  //           prop_value_id: propValueId,
  //           subject_id: subjectId,
  //         }
  //       }
  //     });

  //     return {
  //       subjectPropId:
  //         data.insert_t_subject_prop.returning &&
  //         data.insert_t_subject_prop.returning[0].subject_prop_id,
  //     };
  //   } catch (error) {
  //     return { error };
  //   }
  // };

  // const addSalesAreaLinks = async (
  //   client: ApolloClient<any>,
  //   subjectId: any
  // ) => {
  //   try {
  //     await client.mutate({
  //       mutation: DELETE_SALES_AREA_LINKS,
  //       variables: {
  //         subjectId: undefined,
  //       }
  //     });

  //     const { data } = await client.mutate({
  //       mutation: ADD_SALES_AREA_LINKS,
  //       variables: {
  //         linkList: {
  //           sales_area_id: form.getFieldValue('branchId'),
  //           object_id: subjectId,
  //           object_type_id: 3,
  //           link_type_id: 14,
  //         }
  //       }
  //     });

  //     return { salesAreaLinkList: data && data.sales_area_link_list };
  //   } catch (error: any) {
  //     return { error: error.message };
  //   }
  // };

  // const [addEmployee, { loading: loadingAddEmployee, client }] = useAddEmployeeMutation({
  //   onCompleted: async (data) => {
  //     setLoading(true);

  //     const subjectId =
  //       data.insert_t_subject?.returning &&
  //       data.insert_t_subject.returning[0].subject_id;

  //     if (form.getFieldValue('dolznost')) {
  //       const { error } = await saveProp(client, subjectId, 9, form.getFieldValue('dolznost'));

  //       if (error) {
  //         setLoading(false);
  //         message.error('При сохранении сотрудника произошла ошибка');
  //         return;
  //       }
  //     }

  //     if (form.getFieldValue('branchId')) {
  //       const { error } = await addSalesAreaLinks(client, subjectId);

  //       if (error) {
  //         setLoading(false);
  //         message.error('При сохранении сотрудника произошла ошибка');
  //         return;
  //       }
  //     }

  //     setLoading(false);
  //     message.success('Сотрудник успешно сохранен');
  //     form.resetFields();
  //     client.refetchQueries({
  //       include: ['getEmployeesList', 'getEmployeesCount']
  //     });
  //     onSave();
  //   },
  //   onError: () => {
  //     message.error('При сохранении сотрудника произошла ошибка');
  //   },
  // });

  // const onSubmit = () => {
  //   form
  //     .validateFields()
  //     .then((values: any) => {
  //       addEmployee({
  //         variables: {
  //           subject: {
  //             name: values.name,
  //             lastname: values.lastname,
  //             phone: values.phone,
  //             email: values.email,
  //             type_id: 5
  //           }
  //         }
  //       });
  //     })
  //     .catch(() => ({}));
  // };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Добавление сотрудника"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          {/* <Button onClick={onSubmit} disabled={loading || loadingAddEmployee} loading={loading || loadingAddEmployee} type="primary">
            Сохранить
          </Button> */}
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="employee_add_form"
      >
        <Form.Item
          name="name"
          label="Имя"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item
          name="lastname"
          label="Фамилия"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input placeholder="Введите фамилию" />
        </Form.Item>

        <Form.Item
          name="branchId"
          label="Филиал"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <PartnerSelect />
        </Form.Item>

        <Form.Item name="dolznost" label="Должность">
          <JobTitleSelect />
        </Form.Item>

        <Form.Item name="phone" label="Телефон">
          <PhoneInput placeholder="+7 (912) 345-78-90" />
        </Form.Item>

        <Form.Item name="email" label="Электронная почта">
          <Input placeholder="Введите электронную почту" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EmployeesAddDrawer;

/**
 * TODO: проблемка
 */

import { useState, useEffect, FC } from 'react';
import Link from 'next/link';
import { Card, Button, Space, Form, Avatar, List, message } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { ObserverCardProps } from './ObserverCard.props';
import { ADD_ARRAY_TASK_LINK, DELETE_ARRAY_TASK_LINK } from '~/apollo/mutations/task_links';
import SubjectSelect from '../../../../SubjectSelect';

const ObserverCard: FC<ObserverCardProps> = ({
  user,
  task,
}) => {
  const client = useApolloClient();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onOk = () => {
    form.resetFields();
    setEdit(false);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        if (task.watchers.length) {
          try {
            await client.mutate({
              mutation: DELETE_ARRAY_TASK_LINK,
              variables: {
                linksId: task.watchers.map((s) => s.id)
              }
            });
          } catch (error) {
            message.error('Произошла ошибка при сохранении');
            console.log(error);
            setLoading(false);
            return;
          }
        }

        if (values.observer && values.observer.length) {
          try {
            await client.mutate({
              mutation: ADD_ARRAY_TASK_LINK,
              variables: {
                links: values.observer.map((id: any) => ({
                  task_id: task.id,
                  object_id: id,
                  object_type_id: 3,
                  link_type_id: 9
                }))
              }
            });
          } catch (error) {
            message.error('Произошла ошибка при сохранении');
            console.log(error);
            setLoading(false);
            return;
          }
        }

        setLoading(false);
        client.refetchQueries({
          include: ['getCurrentTask']
        });
        onOk();
      })
      .catch(() => ({}));
  };

  useEffect(() => {
    form.setFieldsValue({
      observer: task.watchers.map((w) => w.object_id)
    });
  }, [task, edit]);

  return (
    <Card
      bordered={false}
      title="Наблюдатели:"
      className="mini-card"
      extra={
        <>
          {user.subject_id === task.owner_id || user.id === task.ins_user ? (
            <EditOutlined onClick={() => setEdit(true)} />
          ) : null}
        </>
      }
    >
      {edit ? (
        <Form
          form={form}
          layout="horizontal"
          autoComplete="off"
        >
          <Form.Item name="observer">
            <SubjectSelect
              typeBrief={['EMPLOYEE']}
              mode="multiple"
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            >
              Сохранить
            </Button>
            <Button
              disabled={loading}
              onClick={onOk}
            >
              Отменить
            </Button>
          </Space>
        </Form>
      ) : (
        <>
          {task.watchers && task.watchers.length ? (
            <List
              itemLayout="horizontal"
              dataSource={task.watchers}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Link href={`/employees/${item.subject?.id}`}>
                        <a>{ item.subject?.name || '' } { item.subject?.lastname || '' }</a>
                      </Link>
                    }
                    avatar={
                      <Avatar size={24} icon={<UserOutlined />} />
                    }
                    description={(item.subject?.post.length && item.subject.post[0].prop_value && item.subject.post[0].prop_value.value) || null}
                  />
                </List.Item>
              )}
            />
          ) : null}
        </>
      )}
    </Card>
  );
};

export default ObserverCard;

import { FC } from 'react';
import { Modal, Form, Input, Checkbox, message } from 'antd';
import { useSession } from 'next-auth/react';

import { TasksAddReportModalProps } from './TasksAddReportModal.props';
import { useAddCommentListMutation, useUpdateTaskByPkMutation } from '~/apollo/generated/hooks';

const TasksAddReportModal: FC<TasksAddReportModalProps> = ({
  visible,
  task = null,
  onOk = () => ({}),
  onCancel = () => ({})
}) => {
  const [form] = Form.useForm();
  const { data: session } = useSession();

  const [updateTask] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      message.success('Отчет успешно сохранен');
      form.resetFields();
      onOk();
    },
    onError: (err) => {
      message.error('При сохранении отчета произошла ошибка. Сообщение ошибки: ' + err);
    }
  });

  const [addComment] = useAddCommentListMutation({
    onCompleted: () => {
      if (form.getFieldValue('close')) {
        updateTask({
          variables: {
            taskId: task?.id,
            set: { status_id: 11 }
          }
        });
      } else {
        message.success('Отчет успешно сохранен');
        form.resetFields();
        onOk();
      }
    },
    onError: (err) => {
      message.error('При сохранении отчета произошла ошибка. Сообщение ошибки: ' + err);
    },
    refetchQueries: ['getTaskListDashboard', 'getTaskCount']
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        addComment({
          variables: {
            commentList: [{
              text: values.text,
              object_id: task?.id,
              object_type_id: 1,
              ins_user: session?.user.subject_id
            }]
          }
        });
      })
      .catch(() => ({}));
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={`Добавление отчета к задаче №${task?.id}`}
      visible={visible}
      width={600}
      onOk={onSubmit}
      onCancel={onClose}
      centered
      okText="Сохранить"
    >
      <Form
        form={form}
        name="task_add_report_form"
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[{
            required: true,
            validator: (_, value) => {
              if (!value || value.toString().trim() === '') {
                return Promise.reject('Обязательное поле!');
              }

              return Promise.resolve();
            }
          }]}
        >
          <Input.TextArea
            placeholder="Введите сюда отчет"
            rows={8}
          />
        </Form.Item>
        <Form.Item name="close" valuePropName="checked" noStyle>
          <Checkbox>Закрыть задачу</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TasksAddReportModal;

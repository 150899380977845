import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { Button, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
import { PartnerPageBasicContactsProps } from './PartnerPageBasicContacts.props';
import ContactList from './ContactList';
import ClientsDrawer from '../../clients/ClientsDrawer';

const PartnerPageBasicContacts: FC<PartnerPageBasicContactsProps> = ({
  router
}) => {
  const { id } = router.query;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const client = useApolloClient();

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = data.partner_list[0];

  if (!partner) return null;

  const onAddNewContact = () => {
    client.refetchQueries({
      include: ['getPartnerList', 'getContactList']
    });
    setVisibleDrawer(false);
  };

  return (
    <div>
      <div>
        <Space align="center">
          <h4 style={{ marginBottom: 0 }}>Контакты</h4>
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => setVisibleDrawer(true)}
          >
            Добавить
          </Button>
        </Space>
      </div>

      <ContactList partner={partner} />

      <ClientsDrawer
        organizationId={+id}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onAddNewContact}
      />
    </div>
  );
};

export default withRouter(PartnerPageBasicContacts);

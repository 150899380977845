const getExcerpt = ({ text, size = 100} : { text?: any, size?: number }): null | { isExcerpt: boolean, text: string } => {
  if (!text) return null;

  const isExcerpt = (text.length > size);

  if (isExcerpt) {
    return {
      isExcerpt,
      text: text.substr(0, size) + '...'
    };
  }

  return { isExcerpt, text };
};

export default getExcerpt;

const checkCellVarIsEqual = (oldValue: any, newValue: any) => {
  if (!oldValue && !newValue) {
    return true;
  } else if (!oldValue || !newValue) {
    return false;
  }

  return oldValue.toString() === newValue.toString();
};

export default checkCellVarIsEqual;

import { FC } from 'react';
import { Select } from 'antd';

import { useGetSalesAreaForPartnersPageQuery } from '~/apollo/generated/hooks';
import { PartnerSelectProps } from './PartnerSelect.props';

const PartnerSelect: FC<PartnerSelectProps> = ({
  onChange = () => ({})
}) => {
  const { data } = useGetSalesAreaForPartnersPageQuery({
    variables: {
      where: {
        is_active: { _eq: true },
      },
    }
  });

  return (
    <Select
      showSearch
      placeholder="Выберите филиал"
      optionFilterProp="children"
      onChange={onChange}
    >
      {data?.sales_area.map((b) => (
        <Select.Option key={b.id} value={b.id}>
          {b.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PartnerSelect;

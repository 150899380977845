import { FC } from 'react';
import { Select } from 'antd';

import { useGetPriorityListQuery } from '~/apollo/generated/hooks';
import { TasksPrioritySelectProps } from './TasksPrioritySelect.props';

const TasksPrioritySelect: FC<TasksPrioritySelectProps> = ({
  ...props
}) => {
  const { data } = useGetPriorityListQuery({
    variables: {
      taskTypeBrief: 'TASK'
    }
  });

  return (
    <Select {...props}>
      {data?.t_task_priority.map((p) => (
        <Select.Option key={p.id} value={p.id}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TasksPrioritySelect;

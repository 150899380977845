

import { FC } from 'react';
import { Progress, Button, Table, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { CheckListProps } from "./CheckList.props";
import ListItem from './ListItem';
import Form from './Form';

import styles from './CheckList.module.css';

const CheckList: FC<CheckListProps> = ({
  rowKey = 'id',
  dataSource,
  selectedRowKeys,
  onSelect = () => ({}),
  onChangeItem = () => ({}),
  onAddItem = () => ({}),
  onRemove = () => ({}),
  onOpenChangeForm = () => ({}),
  onCancelChangeForm = () => ({}),
  onOpenAddForm = () => ({}),
  onCancelAddForm = () => ({}),
  activeItemId = null,
  showAddForm = false,
}) => {
  const getProgressValue = (): number => {
    if (selectedRowKeys && dataSource) {
      return Math.floor(selectedRowKeys.length * 100 / dataSource.length);
    }

    return 0;
  };

  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      {dataSource && dataSource.length > 0 ? (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <div>
            <Space>
              <span style={{ fontSize: 13, fontWeight: 700 }}>
                Чек-лист
              </span>
              <div style={{ width: 100 }}>
                <Progress percent={getProgressValue()} size="small" />
              </div>
            </Space>
          </div>

          <Table
            rowKey={rowKey}
            dataSource={dataSource}
            showHeader={false}
            pagination={false}
            rowClassName={styles.row}
            rowSelection={{
              selectedRowKeys,
              onSelect
            }}
          >
            <Table.Column
              key="name"
              dataIndex="name"
              render={(_, record: any) => (
                <ListItem
                  data={record}
                  opened={activeItemId === record.id}
                  onCancel={onCancelChangeForm}
                  onOpen={onOpenChangeForm}
                  onSave={onChangeItem}
                  onRemove={onRemove}
                />
              )}
            />
          </Table>

          {!showAddForm ? (
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={onOpenAddForm}
              size="small"
            >
              Добавить пункт
            </Button>
          ) : null}
        </Space>
      ) : null}

      {showAddForm ? (
        <Form
          onCancel={onCancelAddForm}
          onSave={onAddItem}
          onRemove={onRemove}
        />
      ) : null}

      {(dataSource && dataSource.length > 0 || showAddForm) ? null : (
        <Button
          type="link"
          onClick={onOpenAddForm}
          size="small"
        >
          Добавить чеклист
        </Button>
      )}
    </Space>
  );
};

export default CheckList;

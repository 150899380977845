import { useState, useEffect, FC } from 'react';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { PageHeader, Row, Col, Statistic, Space, Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useGetCurrentOrderQuery } from '~/apollo/generated/hooks';
import { getExcerpt, userHaveAdmins } from '~/utils';
import { OrderPageHeaderProps } from './OrderPageHeader.props';
import OrderTabs from './OrderTabs/OrderTabs';
import ArchiveButton from './ArchiveButton';
import UnArchiveButton from './UnArchiveButton';
import DeleteButton from './DeleteButton';
import CopyButton from './CopyButton';
// import NewVersionButton from './NewVersionButton';

import styles from './OrderPageHeader.module.css';

const OrderPageHeader: FC<any> = ({ id, order, router }: any) => {
  const { data: session } = useSession();

  const userHaveAdmin = userHaveAdmins(session?.user.user_id);

  const orderTitle = getExcerpt({ text: order?.customer?.name, size: 40 });

  // useEffect(() => {
  // }, [id, order, session]);

  return (
    <>
      <PageHeader
        ghost={false}
        style={{ marginBottom: 16 }}
        footer={<OrderTabs />}
      >
        <Row justify="space-between" align="middle">
          <Col className={styles.titleWrapper}>
            <Space size={16}>
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                className={styles.back}
                onClick={() => router.push('/orders')}
              />
              {(orderTitle?.isExcerpt)
                ? (
                  <Tooltip placement="bottom" title={order?.customer?.name}>
                    <div className={styles.title}>Сделка №{id} ({orderTitle?.text})</div>
                  </Tooltip>
                ) : <div className={styles.title}>Сделка №{id} ({orderTitle?.text})</div>
              }
            </Space>
          </Col>
          <Col>
            <Space align="center" direction="vertical" size={0}>
              <div className={styles.sumTitle}>Сумма заказа</div>
              <Statistic
                value={order.order_sum || 0}
                groupSeparator=" "
                precision={2}
                valueStyle={{
                  fontSize: 30
                }}
              />
            </Space>
          </Col>
          <Col>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th></th>
                  <th>Расходы</th>
                  <th>Прибыль</th>
                  <th>Маржа</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>План</td>
                  <td>
                    <Statistic
                      value={order.cost_amount_order || 0}
                      groupSeparator=" "
                      precision={2}
                    />
                  </td>
                  <td>
                    <Statistic
                      value={order.marzh || 0}
                      groupSeparator=" "
                      precision={2}
                    />
                  </td>
                  <td>
                    <Statistic
                      value={order.marzh_percent || 0}
                      groupSeparator=" "
                      precision={2}
                      suffix="%"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Факт</td>
                  <td>
                    <Statistic
                      value={order.order_expenses_fakt || 0}
                      groupSeparator=" "
                      precision={2}
                    />
                  </td>
                  <td>
                    <Statistic
                      value={order.marzh_order_fakt || 0}
                      groupSeparator=" "
                      precision={2}
                    />
                  </td>
                  <td>
                    <Statistic
                      value={order.marzh_order_fakt_perc || 0}
                      groupSeparator=" "
                      precision={2}
                      suffix="%"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <Space size={16}>
              <CopyButton order={order} router={router} />
              {/*<NewVersionButton order={order} />*/}
              {order?.archived ? null : (
                <ArchiveButton order={order} router={router} />
              )}
              {userHaveAdmin && order?.archived ? (
                <UnArchiveButton order={order} router={router} />
              ) : null }
              {!order.archived ? (
                <DeleteButton order={order} router={router} />
              ) : null}
            </Space>
          </Col>
        </Row>
      </PageHeader>
    </>
  );
};

const OrderPageHeaderQuery: FC<OrderPageHeaderProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  const { data } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  if (!data) return null;

  const order = data.t_order[0];

  if (!order) return null;

  return <OrderPageHeader id={id} order={order} router={router} />;
};

export default withRouter(OrderPageHeaderQuery);

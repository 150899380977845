import { useState, FC } from 'react';
import { message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { useGetFtranListLazyQuery, useUpdateDocumentMutation } from '~/apollo/generated/hooks';
import { REMOVE_DOCUMENT_FTRAN } from '~/apollo/mutations/ftrans';

const DeleteButton: FC<DeleteButtonProps> = ({ id }) => {
  if (!id) return null;

  const [loadingFTran, setLoadingFTran] = useState(false);

  const [updateDocument, { client, loading }] = useUpdateDocumentMutation({
    onCompleted: async () => {
      setLoadingFTran(true);

      await client.mutate({
        mutation: REMOVE_DOCUMENT_FTRAN,
        variables: {
          docId: id
        },
      });

      client.refetchQueries({
        include: ['getDocumentList', 'getCountDocuments']
      });

      setLoadingFTran(false);
    }
  });

  const [getDocumentCosts, { loading: checking }] = useGetFtranListLazyQuery({
    onCompleted: (data) => {
      if (data.t_ftran.length > 0) {
        return message.error('Данный документ нельзя удалить, тк к ней привязаны Расходы!');
      }

      updateDocument({
        variables: {
          docId: id,
          set: { deleted: true }
        }
      });
    },
    fetchPolicy: 'network-only'
  });

  const onConfirm = () => {
    getDocumentCosts({
      variables: {
        where: {
          doc_id: {
            _eq: id
          }
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Удалить документ?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={loading || loadingFTran || checking}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined style={{ color: '#EB2F96' }} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

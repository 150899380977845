import { useState, useEffect, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Form, Input, Button, Checkbox, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSession } from 'next-auth/react';
import { useApolloClient } from '@apollo/client';

import { useGetPartnerListForPartnersPageQuery } from '~/apollo/generated/hooks';
import { PartnersPageHeaderProps } from './PartnersPageHeader.props';
import PartnerStatusSelect from '../../partner/PartnerStatusSelect';
import { SubjectSelect } from '~/components';
import CitySelect from './CitySelect/CitySelect';
import PartnerAreaSelect from '../../partner/PartnerAreaSelect/PartnerAreaSelect';
import PartnerTagSelect from '../../partner/PartnerTagSelect/PartnerTagSelect';
import OrganizationsTypeSelect from '../../organizations/OrganizationsTypeSelect';
import BranchSelect from './BranchSelect/BranchSelect';
import PartnersDrawer from '../../partners/PartnersDrawer';

const { Search } = Input;

const PartnersPageHeader: FC<PartnersPageHeaderProps> = ({
  router
}) => {
  const client = useApolloClient();
  const [form] = Form.useForm();
  const { data: session } = useSession();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const search = router.query.search?.toString();
  const visible = router.query.visible?.toString();
  const status = router.query.status ? +router.query.status : undefined;
  const manager = router.query.manager ? +router.query.manager : undefined;
  const city = router.query.city ? +router.query.city : undefined;
  const only1c = router.query.only1c ? router.query.only1c : undefined;
  const area = router.query.area ? +router.query.area : undefined;
  const tag = router.query.tag ? +router.query.tag : undefined;
  const type = router.query.type ? +router.query.type : undefined;
  const branch = router.query.branch ? +router.query.branch : undefined;
  const all = router.query.all?.toString() || null;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');

  const limit = 40;

  const onFilter = (queryName: string, value?: any) => {
    let query = { [queryName]: value || undefined, page: undefined };

    // для данных фильтров сбросить Мои на Все
    if (queryName === 'manager' || queryName === 'type') {
      query = {...query, visible: 'all'};
    }

    router.replace({
      query: {
        ...router.query,
        ...query,
        page: 1,
      }
    });
  };

  const onSearch = (text: string) => {
    if (text === search) return;

    if (text) {
      router.replace({
        query: {
          ...router.query,
          search: text,
          visible: 'all',
          page: 1,
        }
      });
    } else {
      router.replace({
        query: {
          ...router.query,
          search: undefined,
          page: 1,
        }
      });
    }
  };

  const onChangeAll = (e: CheckboxChangeEvent) => {
    router.replace({
      query: {
        ...router.query,
        page: 1,
        all: e.target.checked ? 'yes' : 'no',
      }
    });
  };

  const onChangeOnly1c = (e: CheckboxChangeEvent) => {
    router.replace({
      query: {
        ...router.query,
        page: 1,
        only1c: e.target.checked ? 'yes' : 'no',
      }
    });
    // onFilter('only1c', only1c);
  };

  const onChangeVisible = (value: string) => {
    router.replace({
      query: {
        ...router.query,
        visible: value === 'my' ? undefined : value,
        page: 1,
        manager: undefined,
      }
    });
  };

  const getVariables = () => {
    const _or: any = [];
    const _and: any = [];
    const _not: any = {};
    let orderBy: any[] = [];

    const where: any = {
      deleted: { _eq: false },
      subject_type: {
        brief: { _in: ['ORGANIZATION', 'PARTNER', 'PROVIDER'] }
      }
    };

    if (type) {
      _and.push({
        props: {_and: {prop_id: {_eq: 11}, prop_value_id: {_eq: type}}}
      });
    }

    if (area) {
      _and.push({
        props: {
          prop_value_id: { _eq: area }
        }
      });
    }

    if (city) {
      _and.push({
        props: {
          prop_value_id: { _eq: city }
        }
      });
    }

    if (tag) {
      _and.push({
        props: {
          prop_value_id: { _eq: tag }
        }
      });
    }

    if (search && !!(all && all === 'no')) {
      _and.push({
        _or: [
          {
            tokens: { token: { _like: `${search.trim().toLowerCase()}%` || null } }
          }
        ]
      });
    }

    if (only1c && only1c === 'yes') {
      console.log("##### only1c", only1c);
      _and.push({
        _or: [
          {
            guid: { _is_null: false }
          }
        ]
      });
    }

    if (search && !(all && all === 'no')) {
      _and.push({
        _or: [
          {
            clear_search_name: { _ilike: (search && `%${(search.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}%`) || null }
          },
          {
            object_subject_links: { link_type_id: { _eq: 1 }, v_subject: { clear_search_name: { _like: (search && `%${(search.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}%`) || null } } }
          }
        ]
      });
    }

    if (!visible) {
      _or.push({
        subject_links: {
          object_id: { _eq: session?.user.subject_id },
          t_link_type: {
            brief: { _eq: 'PARTNER_MANAGER' }
          }
        }
      });
    }

    if (status) {
      where.status_id = {
        _eq: status
      };
    }

    if (manager && manager !== -1) {
      where.subject_links = {
        object_id: { _eq: manager },
        t_link_type: {
          brief: { _eq: 'PARTNER_MANAGER' }
        }
      };
    }

    if (manager === -1) {
      _not.subject_links = {
        t_link_type: {
          brief: { _eq: 'PARTNER_MANAGER' }
        }
      };
    }

    if (branch) {
      _and.push({
        subject_links: {
          t_link_type: {
            brief: { _eq: 'PARTNER_MANAGER' }
          },
          t_object_type: {
            brief: { _eq: 'SUBJECT' }
          },
          subject_object: {
            object_sales_area_link: {
              sales_area_id: { _eq: branch }
            }
          }
        }
      });
    }

    if (sort) {
      orderBy = sort.map((s) => {
        const [ key, value ] = s.split('+');

        if (key === 'city') {
          return { city_name: value };
        }

        if (key === 'manager') {
          return { partner_manager_name: value };
        }

        return { [key]: value };
      });
    }

    if (_and.length) {
      where._and = _and;
    }

    if (_or.length) {
      where._or = _or;
    }

    if (Object.keys(_not).length !== 0) {
      where._not = _not;
    }

    return {
      where,
      limit,
      offset: (page - 1) * limit,
      orderBy
    };
  };

  console.log("filter variables", getVariables());

  const { data } = useGetPartnerListForPartnersPageQuery({
    variables: getVariables()
  });

  const onAddNewPartner = () => {
    setVisibleDrawer(false);
    client.refetchQueries({
      include: ['getPartnerListForPartnersPage']
    });
  };

  useEffect(() => {
    console.log("set filter fields, only1c", only1c);

    form.setFieldsValue({
      search,
      all: all === 'yes',
      visible: visible || 'my',
      only1c: only1c === 'yes',
      status,
      manager,
      city,
      area,
      tag,
      type,
      branch
    });
  }, [router.query]);

  return (
    <>
      <PageHeader
        title={`Партнеры (${(data && data?.partner?.aggregate?.count) || 0})`}
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Form
          form={form}
          layout="inline"
          style={{ marginBottom: -12 }}
        >
          <Form.Item style={{ marginBottom: 12 }}>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="search">
            <Search
              style={{ width: 250 }}
              allowClear
              placeholder="Поиск"
              onSearch={onSearch}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="all" valuePropName="checked">
            <Checkbox onChange={onChangeAll}>
              Все
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="visible">
            <Select
              style={{ width: 80 }}
              onChange={onChangeVisible}
            >
              <Select.Option value="all">Все</Select.Option>
              <Select.Option value="my">Мои</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="status">
            <PartnerStatusSelect
              style={{ width: 150 }}
              onChange={(status) => onFilter('status', status)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="manager">
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Менеджер"
              nullOption={{ label: 'Без менеджера', value: -1 }}
              onChange={(manager) => onFilter('manager', manager)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="area">
            <PartnerAreaSelect
              style={{ width: 150 }}
              onChange={(area) => onFilter('area', area)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="tag">
            <PartnerTagSelect
              style={{ width: 150 }}
              onChange={(tag) => onFilter('tag', tag)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="type">
            <OrganizationsTypeSelect
              style={{ width: 150 }}
              onChange={(type) => onFilter('type', type)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="branch">
            <BranchSelect
              style={{ width: 150 }}
              onChange={(branch) => onFilter('branch', branch)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="city">
            <CitySelect
              style={{ width: 200 }}
              onChange={(city) => onFilter('city', city)}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }} name="only1c" valuePropName="checked">
            <Checkbox onChange={onChangeOnly1c}>
              Только 1с
            </Checkbox>
          </Form.Item>
        </Form>
      </PageHeader>

      <PartnersDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onAddNewPartner}
      />
    </>
  );
};

export default withRouter(PartnersPageHeader);

import { gql } from '@apollo/client';

export const ADD_ORDER = gql`
  mutation addOrder($cartId: bigint, $comment: String, $customerId: Int, $description: String, $firmId: bigint, $managerId: Int, $number: String, $type: Int, $partnerId: bigint, $status: Int, $userId: Int, $insDate: timestamp) {
    insert_t_order(objects: {cart_id: $cartId, comment: $comment, customer_id: $customerId, description: $description, firm_id: $firmId, manager_id: $managerId, number: $number, order_type_id: $type, partner_id: $partnerId, status_id: $status, user_id: $userId, ins_date: $insDate}) {
      returning {
        order_id
      }
    }
  }
`;

export const ADD_ORDER_ONE = gql`
  mutation addOrderOne($order: t_order_insert_input!) {
    order: insert_t_order_one(object: $order) {
      id: order_id
    }
  }
`;

export const ADD_ORDER_ONE_PROP = gql`
  mutation addOrderOneProp($propList: [t_order_prop_insert_input!]!) {
    order_prop_list: insert_t_order_prop(objects: $propList) {
      returning {
        id: order_prop_id
      }
    }
  }
`;

export const ADD_NEW_ORDER = gql`
  mutation addNewOrder($description: String!, $customer_id: Int, $oper_date: date) {
    newOrder: insert_t_order(objects: {description: $description, customer_id: $customer_id, delivery_date: $oper_date}) {
      returning {
        order_id
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($orderId: Int!, $description: String!, $customer_id: Int, $oper_date: date) {
    updateOrder: update_t_order(_set: {description: $description, delivery_date: $oper_date, customer_id: $customer_id},
      where: {order_id: {_eq: $orderId}}) {
      returning {
        order_id
      }
    }
  }
`;

export const UPDATE_ORDER_BY_PK = gql`
  mutation updateOrderByPk($set: t_order_set_input, $orderId: Int!) {
    update_t_order_by_pk(
      pk_columns: { order_id: $orderId }
      _set: $set
    ) {
      id: order_id
      status_id
    }
  }
`;

export const DELETE_ORDER_VERSION = gql`
  mutation deleteOrderVersion($orderId: Int, $ver: String) {
    delete_t_orderline(where: {_and: {order_id: {_eq: $orderId}}, ver: {_eq: $ver}}) {
      affected_rows
    }
  }
`;

export const ADD_ORDERS_TO_ARCHIVE = gql`
  mutation addOrdersToArchive($orderIds: [Int!]) {
    update_t_order(
      where: { order_id: { _in: $orderIds } }
      _set: { archived: true }
    ) {
      affected_rows
    }
  }
`;

import { useState, useEffect, FC } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useSession } from 'next-auth/react';

import { OrganizationsAddModalProps } from './OrganizationsAddModal.prop';
import SubjectSelect from '../../SubjectSelect';
import OrganizationsTypeSelect from '../../organizations/OrganizationsTypeSelect';
import { useAddSubjectMutation, useGetCompanyListLazyQuery, useUpdateSubjectByPkMutation } from '~/apollo/generated/hooks';
import { ADD_SUBJECT_LINK_ONE, DELETE_SUBJECT_LINK_BY_PK, UPDATE_SUBJECT_LINK_BY_PK } from '~/apollo/mutations/subject_links';
import { ADD_SUBJECT_PROP_LIST, DELETE_SUBJECT_PROP } from '~/apollo/mutations/subject_props';
import { TOrganizationProps } from '~/interfaces';
import PhoneInput from '~/components/PhoneInput';

const OrganizationsAddModal: FC<OrganizationsAddModalProps> = ({
  organizationId,
  partnerId,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();
  const [addingOrganizationLinks, setAddingOrganizationLinks] = useState(false);
  const [updatingOrganizationLinks, setUpdatingOrganizationLinks] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState<TOrganizationProps | null | undefined>();

  const [getOrganization] = useGetCompanyListLazyQuery({
    onCompleted: (data) => {
      if (data.company_list.length) {
        const organization = data.company_list[0];

        const obj: any = {
          name: organization.name,
          email: organization.email,
          phone: organization.phone,
          address: organization.address,
          kpp: organization.kpp,
          inn: organization.inn,
        };

        if (organization?.type_organization?.length) {
          obj.types = organization.type_organization[0].prop_value_id;
        }

        if (organization?.manager?.subject_id) {
          obj.manager = organization?.manager?.subject_id;
        }

        form.setFieldsValue(obj);
        setCurrentOrganization(organization);
      }
    },
    fetchPolicy: 'network-only'
  });

  const [addOrganization, { loading: creating, client }] = useAddSubjectMutation({
    onCompleted: async (data) => {
      setAddingOrganizationLinks(true);

      if (!data || !data.subject || !data.subject.id) {
        setAddingOrganizationLinks(false);
        return;
      }

      const subjectId = data.subject.id;

      await saveLink({
        subject_id: subjectId,
        object_id: session?.user.subject_id,
        object_type_id: 3,
        link_type_id: 8
      });

      if (form.getFieldValue('types')?.length) {
        await saveProp(subjectId);
      }

      if (form.getFieldValue('manager')) {
        await saveLink({
          subject_id: subjectId,
          object_id: form.getFieldValue('manager'),
          object_type_id: 3,
          link_type_id: 6
        });
      }

      if (partnerId) {
        await saveLink({
          subject_id: partnerId,
          object_id: subjectId,
          object_type_id: 3,
          link_type_id: 10
        });
      }

      setAddingOrganizationLinks(false);
      message.success('Организация успешно добавлена');
      form.resetFields();
      setCurrentOrganization(null);
      onSave(data.subject.id);
    },
    onError: () => {
      message.error('При сохранении организации произошла ошибка');
      setAddingOrganizationLinks(false);
    }
  });

  const [updateOrganization, { loading: updating }] = useUpdateSubjectByPkMutation({
    onCompleted: async (data) => {
      setUpdatingOrganizationLinks(true);

      if (currentOrganization?.type_organization.length) {
        await deleteProp();
      }

      if (form.getFieldValue('types')?.length) {
        await saveProp(organizationId);
      }

      if (currentOrganization?.manager && form.getFieldValue('manager') && currentOrganization.manager.subject_id !== form.getFieldValue('manager')) {
        await updateLink(currentOrganization.manager.subject_link_id, {
          object_id: form.getFieldValue('manager')
        });
      } else if (currentOrganization?.manager && !form.getFieldValue('manager')) {
        await deleteLink(currentOrganization.manager.subject_link_id);
      } else if (!currentOrganization?.manager && form.getFieldValue('manager')) {
        await saveLink({
          subject_id: organizationId,
          object_id: form.getFieldValue('manager'),
          object_type_id: 3,
          link_type_id: 6
        });
      }

      setUpdatingOrganizationLinks(false);
      message.success('Организация успешно обновлена');
      form.resetFields();
      setCurrentOrganization(null);
      onSave(data.subject?.id);
    },
    onError: () => {
      message.error('При обновлении организации произошла ошибка');
    }
  });

  const deleteLink = async (subjectLinkId: any) => {
    const { errors } = await client.mutate({
      mutation: DELETE_SUBJECT_LINK_BY_PK,
      variables: { subjectLinkId }
    });

    if (errors) {
      setAddingOrganizationLinks(false);
      setUpdatingOrganizationLinks(false);
    }
  };

  const updateLink = async (subjectLinkId: any, set: any) => {
    const { errors } = await client.mutate({
      mutation: UPDATE_SUBJECT_LINK_BY_PK,
      variables: { subjectLinkId, set }
    });

    if (errors) {
      setAddingOrganizationLinks(false);
      setUpdatingOrganizationLinks(false);
    }
  };

  const saveLink = async (link: any) => {
    const { errors } = await client.mutate({
      mutation: ADD_SUBJECT_LINK_ONE,
      variables: { link }
    });

    if (errors) {
      setAddingOrganizationLinks(false);
      setUpdatingOrganizationLinks(false);
    }
  };

  const deleteProp = async () => {
    const { errors } = await client.mutate({
      mutation: DELETE_SUBJECT_PROP,
      variables: {
        where: {
          subject_prop_id: {
            _in: currentOrganization?.type_organization.map((type) => { return type.id; })
          }
        }
      }
    });

    if (errors) {
      setAddingOrganizationLinks(false);
      setUpdatingOrganizationLinks(false);
    }
  };

  const saveProp = async (subjectId: any) => {
    const props = form.getFieldValue('types')?.map((typeId: any) => ({
      prop_id: 11,
      subject_id: subjectId,
      prop_value_id: typeId
    }));

    const { errors } = await client.mutate({
      mutation: ADD_SUBJECT_PROP_LIST,
      variables: { objects: props }
    });

    if (errors) {
      setAddingOrganizationLinks(false);
      setUpdatingOrganizationLinks(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (organizationId) {
          updateOrganization({
            variables: {
              subject: {
                name: values.name,
                email: values.email,
                phone: values.phone,
                address: values.address,
                kpp: values.kpp,
                inn: values.inn,
              },
              subjectId: organizationId,
            }
          });
        } else {
          addOrganization({
            variables: {
              subject: {
                name: values.name,
                email: values.email,
                phone: values.phone,
                address: values.address,
                kpp: values.kpp,
                inn: values.inn,
                type_id: 4
              }
            }
          });
        }
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    setCurrentOrganization(null);
    onClose();
  };

  useEffect(() => {
    if (!visible) return;

    if (organizationId) {
      getOrganization({
        variables: {
          where: {
            deleted: { _eq: false },
            subject_type: {
              brief: { _eq: 'ORGANIZATION' }
            },
            subject_id: { _eq: organizationId }
          },
          limit: 1
        }
      });
    }
  }, [visible]);

  return (
    <Modal
      title={organizationId ? 'Редактирование организации' : 'Добавление организации'}
      visible={visible}
      cancelText="Отмена"
      okText="Сохранить"
      onCancel={onCancel}
      onOk={onSubmit}
      centered
      confirmLoading={creating || updating || addingOrganizationLinks || updatingOrganizationLinks}
    >
      <Form
        form={form}
        layout="vertical"
        name="organization_add_edit_form"
      >
        <Form.Item
          name="name"
          label="Название"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input placeholder="Наименование организации" />
        </Form.Item>
        <Form.Item name="email" label="Электронная почта">
          <Input placeholder="mail@mail.ru" />
        </Form.Item>
        <Form.Item name="phone" label="Номер телефона">
          <PhoneInput placeholder="+7 (912) 345-78-90" />
        </Form.Item>
        <Form.Item name="address" label="Адрес">
          <Input placeholder="Чебоксары, Чувашская республика" />
        </Form.Item>
        <Form.Item name="kpp" label="КПП">
          <Input placeholder="771301001" />
        </Form.Item>
        <Form.Item
          name="inn"
          label="ИНН"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input placeholder="0123456789" />
        </Form.Item>
        <Form.Item name="types" label="Тип организации">
          <OrganizationsTypeSelect
            mode="multiple"
            placeholder="Выберите тип организации"
          />
        </Form.Item>
        <Form.Item name="manager" label="Менеджер">
          <SubjectSelect
            typeBrief={['EMPLOYEE']}
            placeholder="Выберите менеджера"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrganizationsAddModal;

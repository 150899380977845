import { FC } from 'react';
import { IMaskInput, IMask } from 'react-imask';

import { PhoneInputProps } from './PhoneInput.props';

// const mask = '+{7} (000) 000-00-00';

const mask = IMask.createMask({
  mask: '+{7} (000) 000-00-00',
  lazy: true,
});

const PhoneInput: FC<PhoneInputProps> = ({
  className = 'ant-input',
  placeholder = '',
  ...props
}) => {
  return (
    <IMaskInput
      mask={mask}
      className={className}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default PhoneInput;

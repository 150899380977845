import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Col, Input, Button, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import { ContactsPageHeaderProps } from './ContactsPageHeader.props';
import ClientsDrawer from '../../clients/ClientsDrawer';

const { Search } = Input;

const ContactsPageHeader: FC<ContactsPageHeaderProps> = ({
  router
}) => {
  const client = useApolloClient();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const search = router.query.search?.toString();
  const status = router.query.status?.toString() || null;

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  const onSearch = (text: string) => {
    onFilter('search', text || undefined);
  };

  const onChangeStatus = (value: string) => {
    onFilter('status', value);
  };

  const onAddNewContact = () => {
    client.refetchQueries({
      include: ['getContactList', 'getCountContact']
    });
    setVisibleDrawer(false);
  };

  return (
    <>
      <PageHeader
        title="Контакты"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Row align="middle" gutter={[12, 0]} style={{ marginBottom: -12 }}>
          <Col style={{ marginBottom: 12 }}>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
          </Col>
          <Col style={{ marginBottom: 12 }}>
            <Search
              defaultValue={search}
              style={{ width: 250 }}
              allowClear
              placeholder="Поиск"
              onSearch={onSearch}
            />
          </Col>
          <Col style={{ marginBottom: 12 }}>
            <Select
              style={{ width: 150 }}
              defaultValue={status}
              onChange={onChangeStatus}
              allowClear
              placeholder="Статус"
            >
              <Select.Option value="active">Активные</Select.Option>
              <Select.Option value="inactive">Не активные</Select.Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>

      <ClientsDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={onAddNewContact}
      />
    </>
  );
};

export default withRouter(ContactsPageHeader);

import { useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { matchSorter } from 'match-sorter';
import cn from 'classnames';
import { Row as RowProps, Cell, CellProps, IdType, FilterValue } from 'react-table';
import { Row, Button, Tooltip } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useSession } from 'next-auth/react';

import styles from './ContractorDocsPageTable.module.css';

import { ContractorDocsPageTableProps, ContractorDocsPageTableQueryProps, DocumentProps } from './ContractorDocsPageTable.props';
import { useGetDocsListQuery, useHoldDocMutation, useSetDocPartnerMutation } from '~/apollo/generated/hooks';
import { Table } from '~/components';
import { roubleFormatter, userHaveRights } from '~/utils';
import { useAppContext } from '~/contexts/AppContext';

const filterPartner = (
  rows: Array<RowProps>,
  columnIds: Array<IdType<string>>,
  filterValue: FilterValue
) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[columnIds[0]]?.name] });
};

const ContractorDocsPageTable: FC<ContractorDocsPageTableProps> = ({ data, ...props }) => {
  const { data: session } = useSession();
  const { state } = useAppContext();
  const active = userHaveRights(session?.user.user_id);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'is_hold',
      width: 36,
      Cell: ({ value }: CellProps<DocumentProps, DocumentProps['is_hold']>) => (
        <span style={{ textOverflow: 'unset' }}>
          {value ? <LockOutlined /> : null}
        </span>
      )
    },
    {
      Header: 'Дата загрузки',
      accessor: 'doc_date',
      sorter: true,
    },
    {
      Header: 'Подрядчик',
      accessor: 'partner',
      Cell: ({ value }: CellProps<DocumentProps, DocumentProps['partner']>) => (
        <span>
          {value?.name}
        </span>
      ),
      filter: filterPartner,
      sorter: true,
    },
    {
      Header: 'Сумма акта',
      accessor: 'sum',
      Cell: (cell: CellProps<DocumentProps, DocumentProps['sum']>) => (
        <span>
          {roubleFormatter(cell.value)}
        </span>
      ),
      sorter: true,
    },
    {
      Header: 'Привязано к заказам',
      accessor: 'sum_by_orders',
      Cell: (cell: CellProps<DocumentProps, DocumentProps['sum_by_orders']>) => (
        <span>
          {roubleFormatter(cell.value)}
        </span>
      ),
      sorter: true,
    },
    {
      Header: 'Нужны товарки',
      accessor: 'sum_bill',
      Cell: (cell: CellProps<DocumentProps, DocumentProps['sum_bill']>) => (
        <span>
          {roubleFormatter(cell.value)}
        </span>
      ),
      sorter: true,
    },
    {
      Header: 'Не разнесенная сумма',
      accessor: 'sum_free',
      Cell: (cell: CellProps<DocumentProps, DocumentProps['sum_free']>) => (
        <span>
          {roubleFormatter(cell.value)}
        </span>
      ),
      sorter: true,
    },
    {
      Header: 'Действия',
      accessor: 'actions',
      width: 80,
      Cell: ({ row }: CellProps<DocumentProps>) => (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          {!row.original.is_hold ? (
            <Tooltip placement="right" title="Заблокировать">
              <Button
                onClick={() => holdDoc({
                  queryGuid: row.original.query_guid,
                  hold: true
                })}
                icon={<LockOutlined/>}
                type="link"
                size="small"
                disabled={!active}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="right" title="Разблокировать">
              <Button
                onClick={() => holdDoc({
                  queryGuid: row.original.query_guid,
                  hold: false
                })}
                icon={<UnlockOutlined/>}
                type="link"
                size="small"
                disabled={!active}
              />
            </Tooltip>
          )}
        </Row>
      )
    }
  ], [data]);

  const [holdDocMutation] = useHoldDocMutation({
    refetchQueries: ['getDocsList']
  });

  const holdDoc = (variables: any) => {
    holdDocMutation({
      variables,
    });
  };

  return (
    <Table
      data={data}
      columns={columns}
      getRowProps={row => ({
        className: cn(row.getRowProps().className, {
          [styles.hold]: row.original.is_hold
        })
      })}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const ContractorDocsPageTableQuery: FC<ContractorDocsPageTableQueryProps> = ({ router }) => {
  const partner = router.query.partner ? +router.query.partner : undefined;
  const archived = router.query.archived?.toString() || null;
  const sort = router.query.sort ? router.query.sort.toString().split(',') : undefined;

  let orderBy: any[] = [];
  let _and: any = [];

  if (sort && sort.length > 0) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'partner') {
        return { partner: { name: value } };
      }

      if (key === 'doc_date') {
        return { ins_date: value };
      }

      return { [key]: value };
    });
  }

  if (partner) {
    _and = [
      {
        partner_id: { _eq: partner },
      }
    ];
  }

  if (archived !== 'yes') { // FIX #1136
    _and = [
      ..._and,
      {
        is_archive: { _neq: true}
      },
    ];
  } else {
    _and = [
      ..._and,
      {
        // is_hold: { _eq: true },
        // _or: [ {sum_free: { _eq: 0 }}, {sum_free: {_is_null: true}} ],
        // doc_not_exist: { _is_null: false },
        is_archive: { _eq: true}
      },
    ];
  }

  const { data } = useGetDocsListQuery({
    variables: {
      where: {
        _and
      },
      orderBy
    },
    fetchPolicy: 'network-only'
  });

  const [setPartner] = useSetDocPartnerMutation({
    refetchQueries: ['getDocsList']
  });

  const onUpdateTableData = (cell: Cell<DocumentProps>, value: any) => {
    if (!cell.row.original.query_guid) return;

    setPartner({
      variables: {
        queryGuid: cell.row.original.query_guid,
        partnerId: value || null,
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <ContractorDocsPageTable
      id="ID_CONTRACTOR_DOCS_PAGE_TABLE"
      data={data?.rows || []}
      getRowProps={(row) => ({
        onDoubleClick: () => window.open(`/accounting/${row.original.query_guid}`)
      })}
      pagination={false}
      onUpdateTableData={onUpdateTableData}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
    />
  );
};

export default withRouter(ContractorDocsPageTableQuery);

import { useState, useEffect, FC } from 'react';
import { List, Button } from 'antd';
import { GoogleOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons';

import { GooglePickerProps } from './GooglePicker.props';

const GooglePicker: FC<GooglePickerProps> = ({
  showList = true,
  onChange = () => ({}),
}) => {
  // const appId = process.env.GDRIVE_APP_ID;
  // const clientId = process.env.GDRIVE_CLIENT_ID;
  // const developerKey = process.env.GDRIVE_DEVELOPER_KEY;
  // const scope = ['https://www.googleapis.com/auth/drive.readonly'];
  // const [token, setToken] = useState('');
  // const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<any[]>();

  const loadPicker = () => {
    // window.gapi.load('auth', { callback: onAuthApiLoad });
    // window.gapi.load('picker', { callback: onPickerApiLoad });
  };

  // const onAuthApiLoad = () => {
  //   window.gapi.auth.authorize(
  //     {
  //       client_id: clientId,
  //       scope,
  //       immediate: false
  //     },
  //     handleAuthResult
  //   );
  // };

  // const onPickerApiLoad = () => {
  //   setLoaded(true);
  //   createPicker();
  // };

  // const handleAuthResult = (authResult: any) => {
  //   if (authResult && !authResult.error) {
  //     setToken(authResult.access_token);
  //     createPicker();
  //   }
  // };

  // const createPicker = () => {
  //   if (loaded && token && appId && developerKey) {
  //     const picker = new google.picker.PickerBuilder()
  //       .enableFeature(google.picker.Feature.NAV_HIDDEN)
  //       .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
  //       .setAppId(appId)
  //       .setOAuthToken(token)
  //       .addView(google.picker.ViewId.DOCS)
  //       .addView(new google.picker.DocsUploadView())
  //       .setDeveloperKey(developerKey)
  //       .setCallback(pickerCallback)
  //       .build();
  //     picker.setVisible(true);
  //   }
  // };

  // const pickerCallback = (data: any) => {
  //   if (data.action === google.picker.Action.PICKED) {
  //     setData(data.docs);
  //   }
  // };

  const onDeleteFile = (id: any) => {
    setData((files) => files?.filter((f) => f.id !== id));
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    if (!window.gapi) {
      throw new Error('"https://apis.google.com/js/api:client.js" needs to be included as a <script>.');
    }
  }, []);

  return (
    <div>
      <Button
        icon={<GoogleOutlined />}
        onClick={loadPicker}
      >
        Выбрать на Google Disk
      </Button>
      {data && data.length && showList ? (
        <List
          style={{ marginTop: 10 }}
          rowKey="id"
          size="small"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <DeleteOutlined onClick={() => onDeleteFile(item.id)} />
              ]}
            >
              <LinkOutlined /> {item.name}
            </List.Item>
          )}
        />
      ) : null}
    </div>
  );
};

export default GooglePicker;

import { FC } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { CopyButtonProps } from './CopyButton.props';
import { useAddOrderCopyLazyQuery } from '~/apollo/generated/hooks';

const { confirm } = Modal;

const CopyButton: FC<CopyButtonProps> = ({ order, router }) => {
  const [copyOrder, { loading }] = useAddOrderCopyLazyQuery({
    onCompleted: (data) => {
      confirm({
        title: 'Копия сделки создана',
        content: 'Открыть новую копию заказа или остаться здесь?',
        okText: 'Открыть',
        cancelText: 'Остаться',
        onOk: () => {
          router.push(`/orders/${data.orders[0].order_id}/products`);
        }
      });
    },
    fetchPolicy: 'network-only',
  });

  const onClick = () => {
    copyOrder({
      variables: { orderId: order.id }
    });
  };

  return (
    <Tooltip placement="bottom" title="Копировать">
      <Button
        onClick={onClick}
        loading={loading}
        disabled={loading}
        icon={<CopyOutlined />}
      />
    </Tooltip>
  );
};

export default CopyButton;

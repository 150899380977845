import { useState, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { Row, Form, Checkbox, Button, Input, AutoComplete } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { FormProps, LoginFormProps } from './LoginForm.props';

const LoginForm: FC<LoginFormProps> = ({ router }) => {
  const [form] = Form.useForm();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [examplesEmail, setExamplesEmail] = useState<{ value: string }[]>([]);

  const onSubmit = async (values: FormProps) => {
    setLoadingAuth(true);

    await signIn('local-login', {
      ...values,
      redirect: false,
    });

    setLoadingAuth(false);
    router.push('/accounting');
  };

  const onChangeEmail = (value: string) => {
    const emails =
      !value || value.includes('@')
      ? []
      : [{ value: `${value}@gmail.com` }, { value: `${value}@yandex.ru` }];

    setExamplesEmail(emails);
  };

  return (
    <Form
      form={form}
      name="user_login_form"
      onFinish={onSubmit}
      style={{ width: 350 }}
      autoComplete="off"
    >
      <p>Войти</p>
      <Form.Item name="email">
        <AutoComplete
          options={examplesEmail}
          onChange={onChangeEmail}
        >
          <Input
            placeholder="Логин"
            prefix={<UserOutlined />}
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item name="password">
        <Input
          type="password"
          placeholder="Пароль"
          autoComplete="off"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox>Входить автоматически</Checkbox>
        <Link href="/profile">
          <a>Забыли пароль?</a>
        </Link>
      </Form.Item>
      <Form.Item>
        <Row justify="space-between" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingAuth}
            disabled={loadingAuth}
            style={{ flexGrow: 1 }}
          >
            Войти
          </Button>
          <Link href="/register">
            <a style={{ lineHeight: 1.499, padding: '0 15px' }}>
              Регистрация
            </a>
          </Link>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default withRouter(LoginForm);

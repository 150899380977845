import { FC } from 'react';
import {
  PhoneOutlined,
  EnvironmentOutlined,
  BookOutlined,
  CalendarOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { TaskPriorityIconProps } from './TaskPriorityIcon.props';

const TaskPriorityIcon: FC<TaskPriorityIconProps> = ({
  priority,
  ...props
}) => {
  if (!priority) return null;

  switch (priority) {
    case 'ZVONOK':
      return <PhoneOutlined {...props} />;
    case 'VSTRECHA':
      return <EnvironmentOutlined {...props} />;
    case 'NAPOMINANIE':
      return <BookOutlined {...props} />;
    case 'SOBITIE':
      return <CalendarOutlined {...props} />;
    case 'PISMO':
      return <MailOutlined {...props} />;
    default:
      return null;
  }
};

export default TaskPriorityIcon;

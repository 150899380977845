import { useEffect, useMemo, useRef } from 'react';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useFilters,
  useSortBy,
  usePagination,
  CellProps,
  useExpanded,
  useRowSelect,
  useColumnOrder,
} from 'react-table';
import { Row } from 'antd';
import cn from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';

import styles from './Table.module.css';

import { TableProps } from './Table.props';
import Head from '../Head';
import Body from '../Body';
import Pagination from '../Pagination';
import { ColumnFilter } from '../filters';
import * as Cells from '../cells';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import { useAppContext } from '~/contexts/AppContext';

const DEFAULT_PAGE_SIZE = 10;

const Table = ({
  id: tableId,
  data = [],
  columns = [],
  className,
  onUpdateTableData = () => ({}),
  getHeaderProps = () => ({}),
  getColumnProps = () => ({}),
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  pagination,
  onPaginationChange,
  onSorterChange,
  onFilterChange,
  renderRowSubComponent = () => null,
  disabledTable = false,
  rowSelection,
  getRowId = (row, relativeIndex, parent) => parent ? [parent.id, relativeIndex].join('.') : relativeIndex.toString(),
  initialSortBy = [],
  initialHiddenColumns = [],
  initialColumnOrder = [],
  disableFilters = true,
  rowId,
}: TableProps) => {
  const { state, resizeColumn, hideColumn, reorderColumn } = useAppContext();
  const notInitialSelectRender = useRef(false);
  const notInitialSorterRender = useRef(false);
  const notInitialFilterRender = useRef(false);
  const notInitialResizeRender = useRef(false);
  let localSelectedRowIds = {};
  let paginationState = {};
  let pageCount = 1;
  const total = (pagination && pagination.total) ? pagination.total : data.length;
  const pageSize = (pagination && pagination.pageSize) ? pagination.pageSize : DEFAULT_PAGE_SIZE;

  const localTableSettings = state.tables.find((table) => table.id === tableId);

  if (pagination !== false) {
    paginationState = {
      pageIndex: 0,
      pageSize,
    };
    pageCount = Math.ceil(total / pageSize);
  }

  if (rowSelection && typeof rowSelection === 'object' && rowSelection.selectedRowIds) {
    localSelectedRowIds = rowSelection.selectedRowIds;
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
      Cell: (cell: CellProps<any>) => (
        <span>
          {cell.value}
        </span>
      )
    }),
    []
  );

  const updatedColumns = useMemo(() => {
    if (!tableId || !localTableSettings) return columns;

    const newColumns = columns?.map((column) => {
      const colId = column.accessor || column.id;

      if (colId && typeof colId === 'string' && localTableSettings?.columnWidths?.[colId]) {
        return {
          ...column,
          width: localTableSettings.columnWidths[colId]
        };
      } else {
        return column;
      }
    });

    return newColumns;
  }, [columns]);

	const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    page,
    gotoPage,
    selectedFlatRows,
    state: {
      pageIndex,
      selectedRowIds,
      sortBy,
      filters,
      columnResizing,
      hiddenColumns,
    },
    visibleColumns,
    setColumnOrder,
  } = useTable(
    {
      columns: updatedColumns,
      data,
      defaultColumn,
      onUpdateTableData,
      disabledTable,
      initialState: {
        ...paginationState,
        selectedRowIds: localSelectedRowIds,
        sortBy: initialSortBy,
        hiddenColumns: initialHiddenColumns,
        columnOrder: initialColumnOrder,
      },
      pageCount,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      getRowId,
      manualSortBy: typeof onSorterChange === 'function',
      manualFilters: typeof onFilterChange === 'function',
      manualPagination: typeof pagination === 'object',
      disableFilters,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    hooks => {
      if (!rowSelection) return;

      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <label style={{ display: 'block', width: '100%', height: 30, cursor: 'pointer' }}>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </Row>
            </label>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          width: 40,
          Cell: ({ ...props }) => (
            <Cells.SelectRowCell {...props} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const currentPage = (pagination && pagination.current) ? pagination.current : pageIndex + 1;

  const onPaginate = (current: number) => {
    if (typeof onPaginationChange === 'function') {
      onPaginationChange({ current, total, pageSize });
    }
    gotoPage(current - 1);
  };

  const onSelectedRowIdsChange = () => {
    if (rowSelection && typeof rowSelection === 'object' && rowSelection.onChange && typeof rowSelection.onChange === 'function') {
      rowSelection.onChange(Object.keys(selectedRowIds), selectedFlatRows);
    }
  };

  const onSizeColumnsChange = (data: any) => {
    if (tableId) {
      resizeColumn(tableId, data.columnWidths);
    }
  };

  const onToggleHideColumn = (columns: string[]) => {
    if (tableId) {
      hideColumn(tableId, columns);
    }
  };

  useEffect(() => {
    if (notInitialSelectRender.current) {
      onSelectedRowIdsChange();
    } else {
      notInitialSelectRender.current = true;
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (notInitialSorterRender.current) {
      if (typeof onSorterChange === 'function') {
        onSorterChange(sortBy);
      }
    } else {
      notInitialSorterRender.current = true;
    }
  }, [sortBy]);

  useEffect(() => {
    if (notInitialFilterRender.current) {
      if (typeof onFilterChange === 'function') {
        onFilterChange(filters);
      }
    } else {
      notInitialFilterRender.current = true;
    }
  }, [filters]);

  useEffect(() => {
    if (notInitialResizeRender.current && columnResizing.isResizingColumn) {
      onSizeColumnsChange(columnResizing);
    } else {
      notInitialResizeRender.current = true;
    }
  }, [columnResizing]);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      // если перемещения не было, то ничего не делаем
      return;
    }

    if (destination.index === source.index) {
      // если колонку переместили туда,
      // где она была изначально, то ничего не делаем
      return;
    }

    const newColumnIds = visibleColumns.map((col) => col.id);

    newColumnIds.splice(source.index, 1);
    newColumnIds.splice(destination.index, 0, draggableId);

    setColumnOrder(() => newColumnIds);
    reorderColumn(tableId, newColumnIds);
  };

  const showTopPagination = useMemo(
    () => data.length > 0 && pagination !== false && pagination?.showTop === true,
    [data, pagination]
  );

  const showBottomPagination = useMemo(
    () => data.length > 0 && pagination !== false && pagination?.showBottom !== false,
    [data, pagination]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {showTopPagination ? (
            <div className={styles.footer}>
              <Pagination
                current={currentPage}
                total={total}
                pageSize={pageSize}
                onChange={onPaginate}
              />
            </div>
          ) : <></>}

          <div
            {...getTableProps()}
            className={cn(styles.table, className)}
          >
            <Head
              tableId={tableId}
              allColumns={allColumns}
              headerGroups={headerGroups}
              getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              getHeaderProps={getHeaderProps}
              getColumnProps={getColumnProps}
              hiddenColumns={hiddenColumns}
              onToggleHideColumn={onToggleHideColumn}
              disableFilters={disableFilters}
              rowId={rowId}
            />

            <Body
              getBodyProps={getTableBodyProps}
              rows={pagination !== false ? page : rows}
              getRowProps={getRowProps}
              getColumnProps={getColumnProps}
              getCellProps={getCellProps}
              prepareRow={prepareRow}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>

          {showBottomPagination ? (
            <div className={styles.footer}>
              <Pagination
                current={currentPage}
                total={total}
                pageSize={pageSize}
                onChange={onPaginate}
              />
            </div>
          ) : null}
        </div>
      </div>
    </DragDropContext>
  );
};

Table.Cells = Cells;

export default Table;

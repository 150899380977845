import { useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Badge, Tooltip } from 'antd';
import { CellProps } from 'react-table';

import { ContactsPageTableProps, ContactsPageTableQueryProps } from './ContactsPageTable.props';
import { Table } from '~/components';
import { useGetContactListQuery, useGetCountContactQuery } from '~/apollo/generated/hooks';
import { TContactProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const ContactsPageTable: FC<ContactsPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Имя',
        accessor: 'name',
        Cell: ({ value, row }: CellProps<TContactProps>) => (
          <span>
            <Tooltip title={row.original.is_active ? 'Активный' : 'Не активный'}>
              <Badge status={row.original.is_active ? 'success' : 'error'} />
            </Tooltip>
            <Link href={`/contacts/${row.original.id}`}>
              <a>{value} {row.original.lastname || ''}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Дата создания',
        accessor: 'ins_date_format',
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
        Cell: ({ value }: CellProps<TContactProps>) => (
          <span>
            <a href={`tel:${value}`}>{value}</a>
          </span>
        )
      },
      {
        Header: 'Партнер',
        accessor: 'partner',
        Cell: ({ value }: CellProps<TContactProps, TContactProps['partner']>) => (
          <span>
            {value.map((partner) => (
              <Link
                href={`/partners/${partner.subject?.id}`}
                key={partner.subject?.id}
              >
                <a>{partner.subject?.name || '-'}</a>
              </Link>
            ))}
          </span>
        )
      },
      {
        Header: 'Организация',
        accessor: 'organization',
        Cell: ({ value }: CellProps<TContactProps, TContactProps['organization']>) => (
          <span>
            {value.map((organization) => (
              <Link
                href={`/partners/${organization.subject?.id}`}
                key={organization.subject?.id}
              >
                <a>{organization.subject?.name || '-'}</a>
              </Link>
            ))}
          </span>
        )
      },
      {
        Header: 'Почта',
        accessor: 'email',
        Cell: ({ value }: CellProps<TContactProps>) => (
          <span>
            <a href={`mailto:${value}`}>{value}</a>
          </span>
        )
      },
      {
        Header: 'Должность',
        accessor: 'post',
      }
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const ContactsPageTableQuery: FC<ContactsPageTableQueryProps> = ({ router, ...props }) => {
  const search = router.query.search ? router.query.search.toString() : undefined;
  const status = router.query.status?.toString();
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      return { [key]: value };
    });
  }

  const where = {
    deleted: { _eq: false },
    subject_type: {
      brief: { _eq: 'CONTACT_PERSON' }
    },
    name: { _ilike: (search && `%${search.replace(/(\s|'|")/g, '')}%`) || null },
    is_active: status === 'active' ? {
      _eq: true
    } : status === 'inactive' ? {
      _eq: false
    } : undefined
  };

  const { data: dataContacts } = useGetContactListQuery({
    variables: {
      where,
      limit,
      offset: (page - 1) * limit,
      orderBy
    }
  });

  const { data: dataCount } = useGetCountContactQuery({
    variables: { where }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <ContactsPageTable
      id="ID_CONTACTS_PAGE_TABLE"
      data={dataContacts?.contact_list || []}
      pagination={{
        current: page,
        total: dataCount?.contact.aggregate?.count || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
      {...props}
    />
  );
};

export default withRouter(ContactsPageTableQuery);

import { useState, useEffect, FC } from 'react';
import {
  Drawer,
  Form,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Alert,
  message,
  notification,
} from 'antd';
import { useSession } from 'next-auth/react';
import moment from 'moment';

import {
  useAddDocumentMutation,
  useUpdateDocumentMutation,
  useCheckDocumentExistLazyQuery,
  useGetDocumentByIdLazyQuery,
} from '~/apollo/generated/hooks';
import { DocumentsDrawerProps } from './DocumentsDrawer.props';
import SubjectSelect from '../../SubjectSelect';
import DatePicker from '../../OldDatePicker';
import DocsTable from './DocsTable/DocsTable';
import PriceInput from '../../PriceInput';
import { TDocDrawerTableProps } from '~/interfaces';
import { getNormalPrice } from '~/utils';

const { TextArea } = Input;

const DocumentsDrawer: FC<DocumentsDrawerProps> = ({
  partner: selectedPartner,
  docId,
  visible = false,
  draft = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();
  const [existDocs, setExistDocs] = useState<TDocDrawerTableProps[]>([]);

  const [getDocument] = useGetDocumentByIdLazyQuery({
    onCompleted: (data) => {
      if (data.doc) {
        form.setFieldsValue({
          number: data.doc.number,
          date: data.doc.date ? moment(data.doc.date, 'YYYY-MM-DD') : undefined,
          amount: data.doc?.amount ? data.doc.amount.toString().replaceAll('.',',') : data.doc.amount,
          partner: data.doc.partner?.id,
          managers: data.doc.manager ? [data.doc.manager.id] : undefined,
          description: data.doc.description,
        });
      }
    },
    fetchPolicy: 'network-only'
  });

  const [addDocument, { loading: creating }] = useAddDocumentMutation({
    onCompleted: (data) => {
      message.success({ content: 'Документ успешно добавлен' });
      setExistDocs([]);
      form.resetFields();
      onSave(data.insert_t_doc?.returning[0].id);
    },
    refetchQueries: selectedPartner ? [] : ['getDocumentList', 'getCountDocuments']
  });

  const [updateDocument, { loading: updating }] = useUpdateDocumentMutation({
    onCompleted: () => {
      message.success({ content: 'Документ успешно обновлен' });
      setExistDocs([]);
      form.resetFields();
      onSave();
    },
    refetchQueries: selectedPartner ? [] : ['getDocumentList', 'getCountDocuments']
  });

  const [searchDocumentExist] = useCheckDocumentExistLazyQuery({
    onCompleted: (data) => {
      if ([...data.docs].length > 0) {
        setExistDocs(data.docs);
      } else {
        setExistDocs([]);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });

  const [checkDocumentExist, { loading: checking }] = useCheckDocumentExistLazyQuery({
    onCompleted: (data) => {
      if ([...data.docs].length > 0) {
        notification.warn({
          message: 'Документ c таким номером, датой и поставщиком существует.'
        });

        return;
      }

      const {
        number,
        date,
        amount,
        partner,
        // managers,
        description
      } = form.getFieldsValue();

      const normalAmount = getNormalPrice(amount);

      addDocument({
        variables: {
          docTypeId: 1,
          number: number || null,
          date: date ? date.format('YYYY-MM-DD') : date,
          amount: normalAmount || null,
          partnerId: partner || null,
          managerId: session?.user.subject_id,
          description: description || null,
          insUser: session?.user.id,
        }
      });
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const amount = getNormalPrice(values.amount);

        if (docId) {
          updateDocument({
            variables: {
              docId: docId,
              set: {
                amount,
                number: values.number,
                oper_date: values.date
                  ? values.date.format('YYYY-MM-DD')
                  : values.date,
                description: values.description,
              }
            }
          });
        } else {
          checkDocumentExist({
            variables: {
              partnerId: values.partner,
              operDate: values.date
                ? values.date.format('YYYY-MM-DD')
                : values.date,
              // amount,
              number: values.number,
            }
          });
        }
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    setExistDocs([]);
    form.resetFields();
    onClose();
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (!docId && 'date' in changedValues) {
      if (!changedValues.date || !allValues.partner) {
        setExistDocs([]);
      } else if (allValues.partner) {
        searchDocumentExist({
          variables: {
            operDate: changedValues.date
              ? changedValues.date.format('YYYY-MM-DD')
              : changedValues.date,
            partnerId: allValues.partner
          }
        });
      }
    }

    if (!docId && 'partner' in changedValues) {
      if (!changedValues.partner || !allValues.date) {
        setExistDocs([]);
      } else if (allValues.date) {
        searchDocumentExist({
          variables: {
            operDate: allValues.date
              ? allValues.date.format('YYYY-MM-DD')
              : allValues.date,
            partnerId: changedValues.partner
          }
        });
      }
    }
  };

  const onSelect = (id: TDocDrawerTableProps['doc_id']) => {
    setExistDocs([]);
    form.resetFields();
    onSave(id);
  };

  useEffect(() => {
    if (!visible) return;

    if (selectedPartner) {
      form.setFieldsValue({
        partner: selectedPartner,
      });
    }

    if (docId) {
      getDocument({
        variables: {
          id: docId
        }
      });
    }
  }, [visible]);

  return (
    <Drawer
      title={docId ? 'Редактирование документа' : 'Добавление документа'}
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            loading={checking || creating || updating}
            disabled={checking || creating || updating}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="vendor_doc_add_form"
        autoComplete="off"
        onValuesChange={onValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Номер(только цифры!)"
              name="number"
              rules={draft ? [] : [{ required: true, message: 'Обязательное поле!' }]}
              normalize={(value => value ? value.toString() : value)}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Введите номер, только цифры!"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Дата"
              name="date"
              rules={draft ? [] : [{ required: true, message: 'Обязательное поле!' }]}
            >
              <DatePicker
                placeholder="__.__.____"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Сумма"
              name="amount"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <PriceInput placeholder="Введите сумму" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Партнер"
              name="partner"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <SubjectSelect
                placeholder="Выберите поставщика"
                typeBrief={['ORGANIZATION', 'PARTNER', 'PROVIDER']}
                typeOrg={[25]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Выбор сотрудников, которым придет уведомление о новом документе"
          name="managers"
        >
          <SubjectSelect
            typeBrief={['EMPLOYEE']}
            mode="multiple"
            placeholder="Выберите сотрудников"
          />
        </Form.Item>

        <Form.Item
          label="Комментарий"
          name="description"
        >
          <TextArea
            placeholder="Комментарий"
            rows={6}
          />
        </Form.Item>

        {!docId && existDocs && existDocs.length ? (
          <Form.Item noStyle>
            <Alert
              message="
                Внимание! Проверьте, не добавлялся ли уже ваш документ.
                Если он есть в списке- выберите его.
              "
              type="error"
              style={{ marginBottom: 12 }}
            />
            <DocsTable data={existDocs || []} onSelect={onSelect} />
          </Form.Item>
        ) : null}
      </Form>
    </Drawer>
  );
};

export default DocumentsDrawer;

import { FC } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'next/router';

import { OrganizationTabsProps } from './OrganizationTabs.props';
import { getActiveKey } from '~/utils';

const menuRoutes: { [key: string]: string[] } = {
  index: ['/organizations/[id]'],
  segments: ['/organizations/[id]/segments'],
  contacts: ['/organizations/[id]/contacts'],
  orders: ['/organizations/[id]/orders'],
  tasks: ['/organizations/[id]/tasks'],
  logistics: ['/organizations/[id]/logistics'],
};

const OrganizationTabs: FC<OrganizationTabsProps> = ({ router }) => {
  const { pathname, query: { id } } = router;

  return (
    <Tabs
      size="small"
      activeKey={getActiveKey(menuRoutes, pathname)}
      onChange={(activeKey) => {
        if (activeKey === 'index') {
          router.replace(`/organizations/${id}`);
        } else {
          router.replace(`/organizations/${id}/${activeKey}`);
        }
      }}
      animated={{ inkBar: false, tabPane: false }}
    >
      <Tabs.TabPane tab="Основное" key="index" />
      <Tabs.TabPane tab="Сегменты" key="segments" />
      <Tabs.TabPane tab="Контакты" key="contacts" />
      <Tabs.TabPane tab="Сделки" key="orders" />
      <Tabs.TabPane tab="Задачи" key="tasks" />
      <Tabs.TabPane tab="Логистика" key="logistics" />
    </Tabs>
  );
};

export default withRouter(OrganizationTabs);

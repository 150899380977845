import { FC } from 'react';
import { withRouter } from 'next/router';
import { Form, Card, Select } from 'antd';

import { OrganizationPageSegmentsFieldsProps } from './OrganizationPageSegmentsFields.props';

const OrganizationPageSegmentsFields: FC<OrganizationPageSegmentsFieldsProps> = () => {
  const [form] = Form.useForm();

  return (
    <Card bordered={false}>
      <Form
        form={form}
        layout="vertical"
        name="segments_edit_form"
        autoComplete="off"
      >
        <Form.Item name="area" label="Сфера деятельности">
          <Select mode="tags">
            <Select.Option value={1}>Строительство</Select.Option>
            <Select.Option value={2}>Медицина</Select.Option>
            <Select.Option value={3}>Производство</Select.Option>
            <Select.Option value={4}>Электроника</Select.Option>
            <Select.Option value={5}>Пищевая промышленность</Select.Option>
            <Select.Option value={6}>Услуги</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="count" label="Количество сотрудников">
          <Select mode="tags">
            <Select.Option value={1}>до 10</Select.Option>
            <Select.Option value={2}>10 - 100</Select.Option>
            <Select.Option value={3}>100 - 500</Select.Option>
            <Select.Option value={4}>500 - 1000</Select.Option>
            <Select.Option value={5}>1000 и более</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="potential" label="Потенциал">
          <Select mode="tags">
            <Select.Option value={1}>до 100к</Select.Option>
            <Select.Option value={2}>100к - 500к</Select.Option>
            <Select.Option value={3}>500к - 1м</Select.Option>
            <Select.Option value={4}>1м и более</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default withRouter(OrganizationPageSegmentsFields);

import { gql } from '@apollo/client';

export const TASK_INFO_DEFAULT = gql`
  fragment taskInfoDefault on t_task {
    id: task_id
    task_id
    ins_date
    deleted
    deadline: deadline_string
    manager_id
    name
    description
    status_id
    type_id
    date_start_string
    ins_date_string
    ins_time_string
    task_type {
      id: task_type_id
      task_type_id
      name
      brief
    }
    task_priority {
      name
    }
    task_props {
      id: task_prop_id
      task_prop_id
      prop_id
      prop_value_id
      text_val
      bigint_val
      datetime_val
      int_val
      prop {
        brief
        name
      }
      prop_value {
        value
        brief
      }
    }
    tip_zadachi: task_props(where: {
      prop: { brief: { _eq: "TIP_ZADACHI" } },
    }) {
      id: task_prop_id
      prop_value_id
      prop_value {
        id: prop_value_id
        value
        brief
      }
    }
    status {
      name
      task_status_id
      id: task_status_id
    }
    subject_id
    subject {
      id: subject_id
      subject_id
      full_name
      name
      surname
    }
    subject_owner {
      id: subject_id
      subject_id
      full_name
      name
      surname
      lastname
    }
    subject_manager {
      lastname
      name
      subject_id
      id: subject_id
    }
    contact_person: task_links(
      where: {
        object_type: { brief: { _eq: "SUBJECT" } }
        link_type: { brief: { _eq: "CONTACT_PERSON" } }
      }
    ) {
      id: task_link_id
      task_link_id
      object_id
      subject: object_subject {
        id: subject_id
        subject_id
        name
        lastname
      }
    }
  }
`;

export const SHIPMENT_REQUEST_INFO = gql`
  fragment shipmentRequestInfo on t_task {
    name
    task_id
    id: task_id
    date_start_string
    selected_shipment_task {
      task_id
      status {
        name
      }
    }
    document: doc(path: "document")
    task_props {
      bigint_val
      datetime_val
      decimal_val
      int_val
      object_id
      prop_id
      prop_value_id
      text_val
      prop {
        brief
        name
      }
      prop_value {
        value
        brief
      }
    }
    ins_date_string
    status_id
    status {
      name
    }
    subject_id
    subject {
      lastname
      surname
      phone
      full_name
      name
      subject_id
    }
    object_order {
      order_sum
      order_id
      id: order_id
      order_type_id
      order_type {
        name
      }
      status {
        name
      }
      orderline_aggregate {
        aggregate {
          sum {
            quantity
            price
          }
        }
      }
      manager {
        surname
        phone
        name
      }
      manager_id
      format_ins_date
      customer {
        full_name
        surname
        name
      }
      customer_id
    }
  }
`;

export const CURRENT_TASK_INFO = gql`
  fragment currentTaskInfo on t_task {
    deadline
    deadline_string
    deadline_time_string
    description
    doc
    priority_id
    task_priority {
      name
      task_priority_id
    }
    gdrive_file_list: doc(path: "gdrive_file_list")
    tags: doc(path: "tags")
    status {
      name
      task_status_id
      id: task_status_id
    }
    task_type {
      brief
      name
      task_type_id
      id: task_type_id
    }
    task_id
    id: task_id
    type_id
    subject_id
    status_id
    ins_date_string
    ins_time_string
    ins_user
    user_created {
      id: user_id
      subject_id
      subject {
        id: subject_id
        name
        lastname
      }
    }
    subject_owner {
      lastname
      email
      name
      phone
      subject_id
      id: subject_id
    }
    owner_id
    manager_id
    subject_manager {
      name
      lastname
      subject_id
      id: subject_id
    }
    name
    subject {
      lastname
      name
      subject_id
      id: subject_id
    }
    object_id
    object_type {
      brief
      object_type_id
    }
    watchers: task_links(where: {link_type: {brief: {_eq: "WATCHER"}}, object_type: {brief: {_eq: "SUBJECT"}}}, order_by: {object_subject: {subject_id: asc}}) {
      id: task_link_id
      task_link_id
      link_type_id
      object_id
      subject: object_subject {
        subject_id
        id: subject_id
        name
        lastname
        post: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}, limit: 1) {
          subject_prop_id
          id: subject_prop_id
          prop_value {
            value
            prop_value_id
          }
          prop {
            name
            prop_id
          }
        }
      }
    }
    soispolnitelList: task_links(where: {link_type: {brief: {_eq: "SOISPOLNITEL"}}, object_type: {brief: {_eq: "SUBJECT"}}}, order_by: {object_subject: {subject_id: asc}}) {
      id: task_link_id
      task_link_id
      link_type_id
      object_id
      subject: object_subject {
        subject_id
        id: subject_id
        name
        lastname
        post: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}, limit: 1) {
          subject_prop_id
          id: subject_prop_id
          prop_value {
            value
            prop_value_id
          }
          prop {
            name
            prop_id
          }
        }
      }
    }
  }
`;

export const CHECK_LIST_TASK_INFO = gql`
  fragment checkListTaskInfo on t_task {
    id: task_id
    task_id
    name
    owner_id
    subject_owner {
      id: subject_id
      subject_id
      name
      lastname
    }
    deadline: deadline_string
    deadline_time: deadline_time_string
    parent_id
    priority_id
    task_priority {
      name
      task_priority_id
      id: task_priority_id
    }
    task_type {
      brief
      name
      task_type_id
      id: task_type_id
    }
    status_id
    status {
      name
      task_status_id
      id: task_status_id
    }
  }
`;

export const CALENDAR_TASK_INFO = gql`
  fragment calendarTaskInfo on t_task {
    id: task_id
    task_id
    name
    deadline
    description
    deadline_time
    ins_date
    object_subject {
      id: subject_id
      subject_id
      full_name
      name
      surname
    }
    status {
      name
      task_status_id
      id: task_status_id
    }
  }
`;

export const TASK_FOR_TASK_LIST_PAGE_INFO = gql`
  fragment taskForTaskListPageInfo on t_task {
    id: task_id
    last_comment
    task_id
    ins_date
    deleted
    deadline: deadline_string
    manager_id
    name
    description
    status_id
    type_id
    date_start_string
    ins_date_string
    ins_time_string
    percent_completed_check_list
    task_type {
      id: task_type_id
      task_type_id
      name
      brief
    }
    task_priority {
      name
    }
    task_props {
      id: task_prop_id
      task_prop_id
      prop_id
      prop_value_id
      text_val
      bigint_val
      datetime_val
      int_val
      prop {
        brief
        name
      }
      prop_value {
        value
        brief
      }
    }
    tip_zadachi: task_props(where: {
      prop: { brief: { _eq: "TIP_ZADACHI" } },
    }) {
      id: task_prop_id
      prop_value_id
      prop_value {
        id: prop_value_id
        value
        brief
      }
    }
    status {
      name
      task_status_id
      id: task_status_id
    }
    subject_id
    subject {
      id: subject_id
      subject_id
      full_name
      name
      surname
    }
    object_id
    subject_owner {
      id: subject_id
      subject_id
      full_name
      name
      surname
      lastname
    }
    subject_manager {
      lastname
      name
      subject_id
      id: subject_id
    }
    object_type {
      brief
      object_type_id
      id: object_type_id
    }
    object_order {
      id: order_id
      order_id
    }
    object_subject {
      name
      id: subject_id
      subject_id
      lastname
      subject_type {
        brief
      }
    }
    object_task {
      id: task_id
      task_id
    }
  }
`;

import { FC } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'next/router';

import { ContactTabsProps } from './ContactTabs.props';
import { getActiveKey } from '~/utils';

const menuRoutes: { [key: string]: string[] } = {
  index: ['/contacts/[id]'],
  tasks: ['/contacts/[id]/tasks'],
};

const ContactTabs: FC<ContactTabsProps> = ({ router }) => {
  const { pathname, query: { id } } = router;

  return (
    <Tabs
      size="small"
      activeKey={getActiveKey(menuRoutes, pathname)}
      onChange={(activeKey) => {
        if (activeKey === 'index') {
          router.replace(`/contacts/${id}`);
        } else {
          router.replace(`/contacts/${id}/${activeKey}`);
        }
      }}
      animated={{ inkBar: false, tabPane: false }}
    >
      <Tabs.TabPane tab="Основное" key="index" />
      <Tabs.TabPane tab="Задачи" key="tasks" />
    </Tabs>
  );
};

export default withRouter(ContactTabs);

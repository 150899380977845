import { Fragment, useMemo, FC } from 'react';
import { Button, Tag } from 'antd';
import { FireOutlined, FileProtectOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';
import moment from 'moment';

import { OrdersTableProps } from './OrdersTable.props';
import { TOrderProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import { Table } from '~/components';
import { getStatusColor, roubleFormatter } from '~/utils';

const OrdersTable: FC<OrdersTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'priority',
        width: 64,
        Cell: ({ value, row }: CellProps<TOrderProps, TOrderProps['priority']>) => (
          <span>
            {value.length ? (
              <>
                {value.map((p) => (
                  <Fragment key={p.id}>
                    {(p.prop_value && p.prop_value.value === 'Важный') ? (
                      <FireOutlined style={{ color: 'red' }} />
                    ) : null}
                  </Fragment>
                ))}
              </>
            ) : null}
            {row.original.archived ? (
              <FileProtectOutlined />
            ) : null}
          </span>
        )
      },
      {
        Header: '№ счета',
        // accessor: 'invoice_number',
        accessor: 'list_numbers_invoice',
        sorter: true,
      },
      {
        Header: 'Дата счета',
        // accessor: 'invoice_date',
        accessor: 'max_date_invoice',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['invoice_date']>) => (
          <span>{value ? moment(value).format('DD.MM.YYYY') : value}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Клиент',
        accessor: 'customer',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['customer']>) => {
          if (!value) return <span></span>;

          return (
            <Button type="link" style={{ fontSize: 12 }}>
              {(value && value.name) || ""} {(value && value.lastname) || ""}
            </Button>
          );
        },
        width: 300,
        sorter: true,
      },
      {
        Header: 'Cумма',
        accessor: 'order_sum',
        width: 80,
        Cell: (cell: CellProps<TOrderProps, TOrderProps['order_sum']>) => (
          <span>
            {roubleFormatter(cell.value)}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Расходы',
        accessor: 'order_expenses_fakt',
        width: 90,
        Cell: (cell: CellProps<TOrderProps, TOrderProps['order_expenses_fakt']>) => (
          <span>
            {roubleFormatter(cell.value)}
          </span>
        ),
      },
      {
        Header: 'Прибыль',
        accessor: 'marzh_order_fakt',
        width: 120,
        Cell: ({ value, row }: CellProps<TOrderProps, TOrderProps['marzh_order_fakt']>) => {
          if (!value) return <span></span>;

          return (
            <span>
              {value} ({row.original.marzh_order_fakt_perc || ''}%)
            </span>
          );
        },
      },
      {
        Header: 'Дата УПД',
        // accessor: 'bill_date',
        accessor: 'max_date_upd',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['bill_date']>) => {
          if (!value) return <span></span>;

          return (
            <span>
              {moment(value).format('DD.MM.YYYY')}
            </span>
          );
        },
        sorter: true,
      },
      {
        Header: 'Сделка',
        accessor: 'order_id',
        width: 80,
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['order_id']>) => {
          if (!value) return <span></span>;

          return (
            <Button type="link" style={{ fontSize: 12 }}>№{ value }</Button>
          );
        },
        sorter: true,
      },
      {
        Header: 'Статус',
        accessor: 'status',
        width: 95,
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['status']>) => {
          if (!value) return <span></span>;

          return (
            <span style={{ textOverflow: 'unset' }}>
              <Tag
                color={getStatusColor(value.name)}
                style={{
                  borderWidth: 0
                }}
              >
                { value.name }
              </Tag>
            </span>
          );
        },
        sorter: true,
      },
      {
        Header: 'Ответственный',
        accessor: 'deal_owner',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['deal_owner']>) => {
          if (!value) return <span></span>;

          return (
            <span>{ value.name } {value.lastname}</span>
          );
        },
        sorter: true,
      },
      {
        Header: 'Информация',
        accessor: 'description',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['description']>) => (
          <span>{value}</span>
        ),
        sorter: true,
      },
      {
        Header: '№ УПД',
        // accessor: 'bill_number',
        accessor: 'list_numbers_upd',
        Cell: ({ value }: CellProps<TOrderProps, TOrderProps['bill_number']>) => (
          <span>{value}</span>
        ),
        sorter: true,
      }
    ],
    []
  );

  // НЕЛЬЗЯ ЗАДАВАТЬ ТУТ ID ТАБЛИЦЫ,
  // Т.К. ЭТОТ КОМПОНЕНТ ИСПОЛЬЗУЕТСЯ ДРУГИМИ КОМПОНЕНТАМИ!
  return (
    <Table
      data={data || []}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

export default OrdersTable;

import { FC } from 'react';
import { useSession } from 'next-auth/react';
import { Card, Divider } from 'antd';

import { TaskInfoProps } from './TaskInfo.props';
import DeadlineCard from './DeadlineCard';
import TagsCard from './TagsCard';
import AuthorCard from './AuthorCard';
import CrucialCard from './CrucialCard';
import CoExecutorCard from './CoExecutorCard';
import ObserverCard from './ObserverCard';

const TaskInfo: FC<TaskInfoProps> = ({
  task
}) => {
  const { data: session } = useSession();

  if (!session?.user) return null;

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 8 }}
    >
      <Card
        bordered={false}
        title="Задача поставлена:"
        className="mini-card"
      >
        <b>
          { task.ins_date_string || '' }
          { task.ins_time_string ? ` в ${task.ins_time_string}` : ''}
        </b>
      </Card>

      <Divider style={{ margin: '16px 0' }} />

      <DeadlineCard task={task} user={session.user} />

      <Divider style={{ margin: '16px 0' }} />

      <TagsCard task={task} user={session.user} />

      {task.user_created ? (
        <>
          <Divider style={{ margin: '16px 0' }} />

          <AuthorCard task={task} />
        </>
      ) : null}

      <Divider style={{ margin: '16px 0' }} />

      <CrucialCard task={task} user={session.user} />

      <Divider style={{ margin: '16px 0' }} />

      <CoExecutorCard task={task} user={session.user} />

      <Divider style={{ margin: '16px 0' }} />

      <ObserverCard task={task} user={session.user} />
    </Card>
  );
};

export default TaskInfo;

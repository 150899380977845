// superAdmin: olga@grasias.ru, maria_grom@bk.ru , maria@grasias.ru
const userHaveAdmins: funcProps = (id) => {
  if (!id) return false;
  
  // 6  for check
  const found = [15, 10, 56].find(el => el === id);

  if (found) return true;

  return false;
};

export default userHaveAdmins;

type funcProps = (id?: number) => boolean;

import { useState, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Col, Button, Input, DatePicker, Checkbox } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { InvoicesPageHeaderProps } from './InvoicesPageHeader.props';
import { DocumentsDrawer, SubjectSelect } from '~/components';

const InvoicesPageHeader: FC<InvoicesPageHeaderProps> = ({ router }) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const search = router.query.search?.toString();
  const partner = router.query.partner ? +router.query.partner : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const manager = router.query.manager ? +router.query.manager : undefined;
  const restExpense = router.query.restExpense?.toString() || null;

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  return (
    <>
      <PageHeader
        title="Счета на оплату"
        ghost={false}
        style={{ marginBottom: 16 }}
      >
        <Row align="middle" gutter={[12, 0]}>
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ marginRight: 8 }}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
          </Col>
          <Col>
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['ORGANIZATION', 'PARTNER', 'PROVIDER']}
              typeOrg={[25]}
              placeholder="Партнер"
              defaultValue={partner}
              onChange={(id) => onFilter('partner', id)}
            />
          </Col>
          <Col>
            <Input
              placeholder="Номер документа"
              name="searchText"
              allowClear
              defaultValue={search}
              onChange={(e) => onFilter('search', e.target.value)}
            />
          </Col>
          <Col>
            <DatePicker
              placeholder="С..."
              name="dateFrom"
              defaultValue={(dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : undefined)}
              onChange={(dateFrom) => onFilter('dateFrom', (dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined))}
            />
          </Col>
          <Col>
            <DatePicker
              placeholder="По..."
              name="dateTo"
              defaultValue={(dateTo ? moment(dateTo, 'YYYY-MM-DD') : undefined )}
              onChange={(dateTo) => onFilter('dateTo', (dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined))}
            />
          </Col>
          <Col>
            <SubjectSelect
              style={{ width: 180 }}
              typeBrief={['EMPLOYEE']}
              placeholder="Кто добавил"
              defaultValue={manager}
              onChange={(id) => onFilter('manager', id)}
            />
          </Col>
          <Col>
            <Checkbox
              onChange={(e) => onFilter('restExpense', e.target.checked ? 'yes' : 'no')}
              defaultChecked={restExpense === 'yes' ? true : false}
            >
              не привязано
            </Checkbox>
          </Col>
        </Row>
      </PageHeader>

      <DocumentsDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => setVisibleDrawer(false)}
      />
    </>
  );
};

export default withRouter(InvoicesPageHeader);

import { FC } from 'react';
import Link from 'next/link';
import { Card, Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { AuthorCardProps } from './AuthorCard.props';

const AuthorCard: FC<AuthorCardProps> = ({
  task,
}) => (
  <Card
    bordered={false}
    title="Автор:"
    className="mini-card"
  >
    <Space align="center">
      <Avatar size={24} icon={<UserOutlined />} />
      <Link href={`/employees/${task.user_created?.subject_id}`}>
        <a>
          { task.user_created?.subject?.name || '' } { task.user_created?.subject?.lastname || '' }
        </a>
      </Link>
    </Space>
  </Card>
);

export default AuthorCard;

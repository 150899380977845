import { FC } from 'react';
import { Space } from 'antd';
import { withRouter } from 'next/router';

import { TaskPageInfoProps } from "./TaskPageInfo.props";
import { useGetCurrentTaskQuery } from '~/apollo/generated/hooks';
import OrderInfo from './OrderInfo';
import SubjectInfo from './SubjectInfo';
import ObjectInfo from './ObjectInfo';
import TaskInfo from './TaskInfo';

const TaskPageInfoWithData = ({ id }: any) => {
  const { data } = useGetCurrentTaskQuery({
    variables: {
      taskId: +id,
    }
  });

  if (!data) return null;

  const task = data.t_task[0];

  if (!task) return null;

  return (
    <div style={{ marginTop: -8 }}>
      <Space
        direction="vertical"
        size={16}
        style={{ width: '100%' }}
      >
        {task.object_type && (task.object_type.brief === 'ORDER') ? (
          <OrderInfo task={task} />
        ) : null}
        {task.object_type && (task.object_type.brief === 'SUBJECT') ? (
          <SubjectInfo task={task} />
        ) : null}
        {task.object_type && (task.object_type.brief === 'TASK') ? (
          <ObjectInfo task={task} />
        ) : null}
        <TaskInfo task={task} />
      </Space>
    </div>
  );
};

const TaskPageInfo: FC<TaskPageInfoProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  return <TaskPageInfoWithData id={id} />;
};

export default withRouter(TaskPageInfo);

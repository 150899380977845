import { useState, MouseEvent, useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import cn from 'classnames';
import { isString } from 'lodash';
import { CellProps, Cell, Row as RowProps } from 'react-table';
import { Row, Button, Checkbox, Space, Divider } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSession } from 'next-auth/react';

import styles from './ContractorDocRowsPageTable.module.css';

import { ContractorDocRowsPageTableProps, ContractorDocRowsPageTableQueryProps } from './ContractorDocRowsPageTable.props';
import { useGetDocRowsQuery, useUpdateDocMutation, useUpdateImportedRowLazyQuery } from '~/apollo/generated/hooks';
import { Order_By, TDocProps, TDocReturnedProps, TDocumentProps } from '~/interfaces';
import { Table, ContractorDocOrdersModal, DocumentSelect, DocumentsDrawer } from '~/components';
import CopyButton from './CopyButton/CopyButton';
import DeleteButton from './DeleteButton/DeleteButton';
import { REMOVE_FTRAN } from '~/apollo/mutations/ftrans';
import { UPDATE_DOC } from '~/apollo/mutations/documents';
import { userHaveRights } from '~/utils';
import { useAppContext } from '~/contexts/AppContext';

const {
  Cells: {
    SubjectSelectCell,
    DatePickerCell,
    TextInputCell,
    NumberInputCell,
  }
} = Table;

const ContractorDocRowsPageTable: FC<ContractorDocRowsPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();
  const { data: session } = useSession();
  const active = userHaveRights(session?.user.user_id);
  const [openedDoc, setOpenedDoc] = useState<TDocumentProps | null>();
  const [visibleDoc, setVisibleDoc] = useState(false);
  const [visibleAddDocDrawer, setVisibleAddDocDrawer] = useState(false);
  const [deletingOrder, setDeletingOrder] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const [updateDoc, { client }] = useUpdateDocMutation({
    onError: (err) => {
      console.log(err);
    },
    refetchQueries: ['getDocRows', 'getDocsList'],
  });

  const [updateImportedRow] = useUpdateImportedRowLazyQuery({
    onCompleted: async () => {
      await client?.refetchQueries({
        include: ['getDocRows', 'getDocsList']
      });
    },
    onError: (err) => {
      console.log(err);
    }
  });

  const onSelectDocument = (rowId: TDocumentProps['id'], docId: TDocProps['id']) => {
    updateImportedRow({
      variables: { docId, rowId }
    });
  };

  const deleteDocOrder = async (order: TDocumentProps) => {
    setDeletingOrder(true);

    await client?.mutate({
      mutation: REMOVE_FTRAN,
      variables: {
        ftranId: order.ftran_id
      },
    });

    await client?.mutate({
      mutation: UPDATE_DOC,
      variables: {
        id: order.id,
        set: {
          ftran_id: null
        }
      }
    });

    setDeletingOrder(false);

    client?.refetchQueries({
      include: ['getDocRows', 'getDocsList']
    });
  };

  const columns = useMemo(() => [
    {
      Header: 'Дата заказа',
      accessor: 'order_date_2',
      Cell: ({ value, ...props }: CellProps<TDocumentProps>) => (
        <DatePickerCell
          // value={value ? moment(value, 'DD.MM.YYYY') : undefined}
          value={value ? moment(value, 'YYYY-MM-DD') : undefined}
          editable={props.row.original.is_hold || !active ? false : true}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Менеджер',
      accessor: 'manager_id',
      Cell: ({ value, row, ...props }: CellProps<TDocumentProps, TDocumentProps['manager_id']>) => (
        <SubjectSelectCell
          editable={row.original.is_hold || !active ? false : true}
          value={value || undefined}
          row={row}
          {...props}
        >
          {row.original.manager_id ? (
            <b>{row.original.owner?.lastname || ''} {row.original.owner?.name || ''}</b>
          ) : row.original.manager}
        </SubjectSelectCell>
      ),
      sorter: true
    },
    {
      Header: 'Товар/услуга',
      accessor: 'product',
      Cell: ({ ...props }: CellProps<TDocumentProps, TDocumentProps['product']>) => (
        <TextInputCell
          editable={props.row.original.is_hold || !active ? false : true}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Макет',
      accessor: 'maket',
      Cell: ({ ...props }: CellProps<TDocumentProps, TDocumentProps['maket']>) => (
        <TextInputCell
          editable={props.row.original.is_hold || !active ? false : true}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Тираж',
      accessor: 'tirazh',
      width: 80,
      Cell: ({ ...props }: CellProps<TDocumentProps, TDocumentProps['tirazh']>) => (
        <NumberInputCell
          editable={props.row.original.is_hold || !active ? false : true}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Цена',
      accessor: 'price',
      width: 80,
      Cell: ({ ...props }: CellProps<TDocumentProps>) => (
        <NumberInputCell
          editable={props.row.original.is_hold || !active ? false : true}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Итого',
      accessor: 'amount',
      width: 80,
      Cell: ({ row, ...props }: CellProps<TDocumentProps, TDocumentProps['amount']>) => (
        <NumberInputCell
          editable={row.original.is_hold || !active || row.original.ftran?.t_order?.order_id ? false : true}
          row={row}
          {...props}
        />
      ),
      sorter: true
    },
    {
      Header: 'Номер док',
      id: 'doc_number',
      Cell: ({ row }: CellProps<TDocumentProps>) => {
        const [edit, setEdit] = useState(false);

        const onClick = (e: MouseEvent) => {
          e.stopPropagation();
          setEdit(true);
        };

        return (
          <>
            { edit && !row.original.is_hold && active ? (
              <DocumentSelect
                onBlur={() => setTimeout(() => setEdit(false), 500)}
                style={{ width: '100%', height: '100%' }}
                partner={row.original.partner_id}
                onChange={(value) => onSelectDocument(row.original.id, value)}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '2px 0' }} />
                    <div style={{ padding: 5 }}>
                      <Button onClick={() => showAddDocDrawer(row.original)} block>Добавить</Button>
                    </div>
                  </div>
                )}
                autoFocus
                defaultOpen
              />
            ) : (
              <span
                onClick={onClick}
              >
                {(row.original.ftran?.t_doc?.number && row.original.ftran?.t_doc?.oper_date) ? (
                  `${row.original.ftran?.t_doc?.number} от ${row.original.ftran?.t_doc?.oper_date}`
                ) : <div>...</div>}
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: 'Дата',
      accessor: 'date',
      width: 100,
    },
    {
      Header: 'Коментарий менеджера',
      accessor: 'manager_comment',
      Cell: ({ ...props }: CellProps<TDocumentProps, TDocumentProps['manager_comment']>) => (
        <TextInputCell
          editable={props.row.original.is_hold ? false : true}
          {...props}
        />
      ),
    },
    {
      Header: 'Нужна ТН',
      accessor: 'bill_required',
      width: 110,
      Cell: ({ value, row }: CellProps<TDocumentProps, TDocumentProps['bill_required']>) => (
        <>
          {row.original.ftran_id ? (
            <Row justify="center">
              <Checkbox
                defaultChecked={value ? true : false}
                onChange={(e) => {
                  updateDoc({
                    variables: {
                      id: row.original.id,
                      set: {
                        bill_required: e.target.checked
                      }
                    }
                  });
                }}
                disabled={row.original.is_hold || false}
              />
            </Row>
          ) : null}
        </>
      ),
    },
    {
      Header: 'Заказ',
      id: 'order_num',
      width: 220,
      Cell: ({ row }: CellProps<TDocumentProps>) => (
        <span>
          <Row
            align="middle"
            justify="center"
            style={{ lineHeight: 'normal', height: '100%' }}
          >
            <Space align="center">
              {row.original.ftran?.t_order?.order_id
                ? (
                  <>
                    <Link href={`/orders/${row.original.ftran?.t_order?.order_id}/`}>
                      <a target="_blank">{row.original.ftran?.t_order?.order_id}</a>
                    </Link>
                    <Button
                      onClick={() => deleteDocOrder(row.original)}
                      size="small"
                      icon={<DeleteOutlined />}
                      disabled={deletingOrder || row.original.is_hold || false}
                    >
                      Удалить заказ
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => showOrderList(row.original)}
                    size="small"
                    icon={<PlusOutlined />}
                    disabled={row.original.is_hold || false}
                  >
                    Указать заказ
                  </Button>
                )
              }
            </Space>
          </Row>
        </span>
      ),
    },
    {
      Header: 'Действие',
      id: 'action',
      width: 80,
      Cell: ({ row }: CellProps<TDocumentProps>) => (
        <Row
          align="middle"
          justify="center"
          style={{ lineHeight: 'normal', height: '100%' }}
        >
          {active ? (
            <Space align="center">
              <CopyButton
                doc={row.original}
                disabled={row.original.is_hold || false}
              />
              <DeleteButton
                doc={row.original}
                disabled={row.original.is_hold || false}
              />
            </Space>
          ) : null}
        </Row>
      )
    }
  ], [data]);

  const showOrderList = (doc: TDocumentProps) => {
    setOpenedDoc(doc);
    setVisibleDoc(true);
  };

  const closeOrderList = () => {
    console.log('closeOrderList');
    setOpenedDoc(null);
    setVisibleDoc(false);
  };

  const showAddDocDrawer = (doc: TDocumentProps) => {
    setOpenedDoc(doc);
    setVisibleAddDocDrawer(true);
  };

  const closeAddDocDrawer = () => {
    setOpenedDoc(null);
    setVisibleAddDocDrawer(false);
  };

  const onAddNewDocument = (id?: TDocReturnedProps['id']) => {
    console.log('onAddNewDocument');
    if (openedDoc) {
      onSelectDocument(openedDoc.id, id);
    }
    closeAddDocDrawer();
  };

  const onUpdateTableData = (cell: Cell<TDocumentProps>, value: any) => {
    if (cell.column.id === 'order_date') {
      updateDoc({
        variables: {
          id: cell.row.original.id,
          set: {
            [cell.column.id]: value ? value.format('DD.MM.YYYY') : null
          }
        }
      });
    } else {
      updateDoc({
        variables: {
          id: cell.row.original.id,
          set: {
            [cell.column.id]: value
          }
        }
      });
    }
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        getRowProps={(row: RowProps<TDocumentProps>) => ({
          className: cn(row.getRowProps().className, {
            [styles.hold]: row.original.bill_required,
            [styles.warning]: !row.original.ftran_id && !row.original.bill_required,
          })
        })}
        onUpdateTableData={onUpdateTableData}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />

      <ContractorDocOrdersModal
        visible={visibleDoc}
        onOk={closeOrderList}
        onCancel={closeOrderList}
        doc={openedDoc}
        hide={()=>{setVisibleDoc(false)}}
      />

      <DocumentsDrawer
        partner={openedDoc?.partner_id}
        visible={visibleAddDocDrawer}
        onClose={closeAddDocDrawer}
        onSave={onAddNewDocument}
      />
    </>
  );
};

const ContractorDocRowsPageTableQuery: FC<ContractorDocRowsPageTableQueryProps> = ({ router }) => {
  const { id } = router.query;
  const { data: session } = useSession();

  const manager = !router.query.manager
    ? session?.user.subject_id
    : router.query.manager && router.query.manager === 'all'
    ? undefined : +router.query.manager;
  const sort = router.query.sort?.toString().split(',');

  const getVariables = () => {
    let orderBy: any[] = [];
    let where: any = { query_guid: { _eq: id } };

    if (sort) {
      console.log('sort_by', sort);
      orderBy = sort.map((s) => {
        const [ key, value ] = s.split('+');

        if (key === 'manager_id') {
          return { owner: { lastname: value } };
        }

        return { [key]: value };
      });
    } else {
      orderBy = [{ ftran: { order_id: Order_By.AscNullsFirst } }];
    }

    if (manager) {
      where = {
        ...where,
        manager_id: {
          _eq: manager
        }
      };
    }

    return {
      orderBy,
      where,
    };
  };

  const { data } = useGetDocRowsQuery({
    variables: getVariables(),
    fetchPolicy: 'cache-and-network',
    skip: !id
  });

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  if (!id || !isString(id)) return <span></span>;

  return (
    <ContractorDocRowsPageTable
      id="ID_CONTRACTOR_DOC_ROWS_PAGE_TABLE"
      data={data?.rows || []}
      pagination={false}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
    />
  );
};

export default withRouter(ContractorDocRowsPageTableQuery);

import { useEffect, FC } from 'react';
import { Modal, Form, Input, message } from 'antd';

import { EditFtranAmountModalProps } from './EditFtranAmountModal.props';
import { useUpdateFTranMutation } from '~/apollo/generated/hooks';

const EditFtranAmountModal: FC<EditFtranAmountModalProps> = ({
  ftran = null,
  visible = false,
  onSave = () => ({}),
  onCancel = () => ({})
}) => {
  const [form] = Form.useForm();

  const [updateFTran, { loading }] = useUpdateFTranMutation({
    onCompleted: () => {
      message.success('Данные успешно сохранены');
      form.resetFields();
      onSave();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        updateFTran({
          variables: {
            ftranId: ftran?.id,
            amount: values.amount
              ? values.amount.toString().replaceAll(',', '.')
              : values.amount,
            description: values.description,
          }
        });
      })
      .catch(() => ({}));
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (!visible) return;

    console.log(ftran);

    form.setFieldsValue({
      amount: ftran?.amount,
      description: ftran?.description,
    });
  }, [visible]);

  // 1132 и 2681 (смотри комментарий)
  const allowPartnerId = [75565, 79287, 80091, 80092, 79395, 80166];

  return (
    <Modal
      title="Изменить сумму списания"
      visible={visible}
      width={720}
      onOk={onSubmit}
      onCancel={onClose}
      centered
      okText="Сохранить"
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
    >
      <Form
        form={form}
        name="ftran_edit_amount_form"
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="amount"
          label="Сумма к списанию"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input
            placeholder="Введите сюда отчет"
            prefix="₽"
            disabled={ftran?.doc_import ? true : false}
          />
        </Form.Item>
        <Form.Item name="description" label="Комментарий">
          <Input.TextArea 
            // disabled={ftran?.doc_import?.partner_id == 79287} // 79287 - НАША КО (ДИЗАЙНЕРЫ таска 1132)
            disabled={ftran?.doc_import?.partner_id && allowPartnerId.includes(ftran?.doc_import?.partner_id)}
            placeholder="Комментарий" rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFtranAmountModal;

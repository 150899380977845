import { useState, useEffect, FC } from 'react';
import { Card, Button, Space, Form, Tag, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { TagsCardProps } from './TagsCard.props';
import { useUpdateTaskByPkMutation } from '~/apollo/generated/hooks';
import { getRandomColor } from '~/utils';
import TagsSelect from '../../../../TagsSelect';

const TagsCard: FC<TagsCardProps> = ({
  user,
  task,
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const onOk = () => {
    form.resetFields();
    setEdit(false);
  };

  const [updateTask, { loading }] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      onOk();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentTask']
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const set = task.doc ? undefined : { doc: { tags: values.tags } };
        const append = task.doc ? { doc: { tags: values.tags } } : undefined;

        updateTask({
          variables: {
            taskId: task.id,
            set,
            append,
          }
        });
      })
      .catch(() => ({}));
  };

  useEffect(() => {
    if (task.tags) {
      form.setFieldsValue({
        tags: task.tags
      });
    }
  }, [task, edit]);

  return (
    <Card
      bordered={false}
      title="Теги:"
      className="mini-card"
      extra={
        <>
          {user.subject_id === task.owner_id || user.id === task.ins_user ? (
            <EditOutlined onClick={() => setEdit(true)} />
          ) : null}
        </>
      }
    >
      {edit ? (
        <Form
          form={form}
          layout="horizontal"
          autoComplete="off"
        >
          <Form.Item name="tags">
            <TagsSelect style={{ width: 200 }} />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            >
              Сохранить
            </Button>
            <Button
              disabled={loading}
              onClick={onOk}
            >
              Отменить
            </Button>
          </Space>
        </Form>
      ) : (
        <Space wrap size={8}>
          {task.tags?.map((t: any, i: number) => (
            <Tag key={i} color={getRandomColor()} style={{ margin: 0 }}>
              {t}
            </Tag>
          ))}
        </Space>
      )}
    </Card>
  );
};

export default TagsCard;

import { gql } from '@apollo/client';

export const UPDATE_MESSAGE = gql`
  mutation updateMessage($set: t_message_set_input, $messageId: bigint!) {
    update_t_message_by_pk(_set: $set, pk_columns: {message_id: $messageId}) {
      message_id
    }
  }
`;

export const UPDATE_MESSAGES = gql`
  mutation updateMessages($where: t_message_bool_exp!, $set: t_message_set_input) {
    update_t_message(where: $where, _set: $set) {
      affected_rows
      returning {
        message_id
      }
    }
  }
`;

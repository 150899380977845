import { gql } from '@apollo/client';

export const ADD_PROP = gql`
  mutation addProp($value: t_prop_value_insert_input!) {
    value: insert_t_prop_value_one(object: $value) {
      brief
      prop_id
      value
      prop_value_id
      id: prop_value_id
    }
  }
`;

import { useState, FC } from 'react';
import Image from 'next/image';
import { Modal, Button, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { FileUploadProps } from './FileUpload.props';
import { getFileAsBase64 } from '~/utils';

const FileUpload: FC<FileUploadProps> = ({
  label,
  icon,
  onChange = () => ({}),
  onPreview,
  ...props
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreivewImage] = useState<string | undefined>();
  const [previewTitle, setPreviewTitle] = useState<string | undefined>();

  const [count, setCount] = useState(0);

  const onShowPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getFileAsBase64(file.originFileObj);
    }

    setPreviewVisible(true);
    setPreivewImage(file.url || file.preview);
    setPreviewTitle(file.name || file?.url?.substring(file.url.lastIndexOf('/') + 1));
  };

  const onClosePreview = () => setPreviewVisible(false);

  const onInfoChange = (info: UploadChangeParam) => {
    onChange(info);
    setCount(info.fileList.length);
  };

  return (
    <>
      <Upload
        onPreview={
          onPreview
          ? onPreview
          : props.listType === 'picture-card'
          ? onShowPreview
          : undefined
        }
        onChange={onInfoChange}
        {...props}
      >
        {props.maxCount && count >= props.maxCount ? null : (
          <>
            {props.listType === 'picture-card' ? (
              <div>
                {icon ? icon : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>
                  {label === false ? '' : label || 'Загрузить'}
                </div>
              </div>
            ): (
              <Button icon={icon ? icon : <UploadOutlined />}>
                {label === false ? '' : label || 'Загрузить'}
              </Button>
            )}
          </>
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={onClosePreview}
      >
        {previewImage ? (
          <Image
            src={previewImage}
            width="100%"
            alt=""
          />
        ) : null}
      </Modal>
    </>
  );
};

export default FileUpload;

import { useState, useMemo, FC } from 'react';
import { withRouter } from 'next/router';
import { Popconfirm, Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';

import { OrderPageCostsTableProps, OrderPageCostsTableQueryProps } from './OrderPageCostsTable.props';
import { useGetCurrentOrderQuery, useGetFtranCountQuery, useGetFtranListQuery } from '~/apollo/generated/hooks';
import { TDocProps, TFTranProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../Table';
import OrdersModal from '../../vendor_docs/VendorDocsPageTable/OrdersModal/OrdersModal';
import EditFtranAmountModal from '../../costs/EditFtranAmountModal';
import DocumentsDrawer from '../../documents/DocumentsDrawer';
import CostsAddDrawer from '../../costs/CostsAddDrawer';
import DeleteButton from './DeleteButton';
import { roubleFormatter } from '~/utils';
// import { T_Ftran } from '~/apollo/generated/schemas';

const OrderPageCostsTable: FC<OrderPageCostsTableProps> = ({ order, data, ...props }) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [currentFTran, setCurrentFTran] = useState<TFTranProps | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [docId, setDocId] = useState<TDocProps['id'] | null>(null);
  const [showDocDrawer, setShowDocDrawer] = useState(false);
  const [showCostDrawer, setShowCostDrawer] = useState(false);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: 'Номер документа',
        id: 'id',
        accessor: 'id',
        Cell: ({ row }: CellProps<TFTranProps>) => (
          <span>
            {row.original.t_doc?.number}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Дата',
        id: 'date',
        accessor: 'date',
        Cell: ({ row }: CellProps<TFTranProps>) => (
          <span>
            {row.original.t_doc?.oper_date
              ? moment(row.original.t_doc?.oper_date).format('DD.MM.YYYY')
              : row.original.t_doc?.oper_date
            }
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Партнер',
        id: 'partner',
        accessor: 'partner',
        Cell: ({ row }: CellProps<TFTranProps>) => (
          <span>
            {row.original.t_doc ? (
              <>
                {(row.original.t_doc?.partner?.name) || ''}
                {(row.original.t_doc?.partner?.surname) || ''}
              </>
            ) : (
              <>
                {row.original.doc_import?.partner?.name}
              </>
            )}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Сумма документа, р',
        id: 'doc_amount',
        accessor: 'doc_amount',
        Cell: ({ value, row }: CellProps<TFTranProps, any>) => (
          <span>
            {row.original.t_doc?.partner && row.original.t_doc.partner.subject_id !== 75565 ? (
              <>
                {roubleFormatter(row.original.t_doc?.amount)}
                &nbsp;
                (<span style={{ color: 'red' }}>
                  <b>
                    {roubleFormatter(row.original.t_doc.doc_rest_expenses)}
                  </b>
                </span>)
              </>
            ) : null}
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Комментарий',
        accessor: 'description',
        sorter: true,
      },
      {
        Header: 'Привязано к заказу, р',
        accessor: 'amount',
        Cell: ({ value, row }: CellProps<TFTranProps, TFTranProps['amount']>) => (
          <span style={{ position: 'relative' }}>
            <span>{value}</span>
            {(order?.archived || order?.status_id == 4) ? null : (
              <span style={{ position: 'absolute', right: 10, cursor: 'pointer', width: 20 }}>
                <EditOutlined
                  style={{ color: 'green' }}
                  onClick={() => showFTrans(row.original)}
                />
              </span>
            )}
          </span>
        ),
        sorter: true,
      },
      {
        Header: '',
        id: 'action',
        Cell: ({ row }: CellProps<TFTranProps, TFTranProps['amount']>) => (
          <span>
            {!row.original.doc_import ? (
              <>
                <Popconfirm
                  title="Изменить документ?"
                  placement="left"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => showDoc(row.original.doc_id)}
                >
                  <Tooltip title="Редактировать">
                    <EditOutlined style={{ color: 'green' }} />
                  </Tooltip>
                </Popconfirm>
                &nbsp;
                &nbsp;
                <DeleteButton id={row.original.id} />
              </>
            ) : null}
          </span>
        )
      },
    ],
    [data]
  );

  const showCostDocs = (doc_id: number) => {
    console.log("doc_id", doc_id);
    setCurrentDocId(doc_id);
    setShowOrderModal(true);
  }
  const showFTrans = (ftran?: TFTranProps) => {
    setCurrentFTran(ftran || null);
    setShowModal(true);
  };

  const closeFTrans = () => {
    setCurrentFTran(null);
    setShowModal(false);
  };

  const showDoc = (id?: any) => {
    setDocId(id || null);
    setShowDocDrawer(true);
  };

  const closeDoc = () => {
    setDocId(null);
    setShowDocDrawer(false);
  };

  // function sorter(args) {
  //   console.log("sorter args", args);
  // }

  return (
    <>
      {!order?.archived && order?.status_id !== 4 ? (
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          style={{ marginBottom: 16 }}
          onClick={() => setShowCostDrawer(true)}
        >
          Добавить
        </Button>
      ) : null}
      <Table
        data={data}
        columns={columns}
        // initialSortBy={[{id: "id", desc: true}]}
        // onSorterChange={sorter}
        getRowProps={(row:any) => ({
          onDoubleClick: () => { row?.original?.doc_import?.query_guid
                                    ? window.open(`/accounting/${row?.original?.doc_import?.query_guid}`)
                                    : showCostDocs(row.original?.doc_id)}
        })}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <EditFtranAmountModal
        ftran={currentFTran}
        visible={showModal}
        onCancel={closeFTrans}
        onSave={() => {
          client.refetchQueries({
            include: ['getFtranList', 'getOrderDocTotals']
          });
          closeFTrans();
        }}
      />
      <DocumentsDrawer
        docId={docId}
        visible={showDocDrawer}
        onClose={closeDoc}
        onSave={() => {
          client.refetchQueries({
            include: ['getFtranList', 'getOrderDocTotals']
          });
          closeDoc();
        }}
      />

      {order ? (
        <CostsAddDrawer
          orderId={order.id}
          visible={showCostDrawer}
          onClose={() => setShowCostDrawer(false)}
          onSave={() => {
            client.refetchQueries({
              include: ['getFtranList', 'getOrderDocTotals']
            });
            setShowCostDrawer(false);
          }}
        />
      ) : null}

      <OrdersModal
        docId={currentDocId}
        visible={showOrderModal}
        onClose={()=>setShowOrderModal(false)}
        onOk={()=>setShowOrderModal(false)}
      />
    </>
  );
};

const OrderPageCostsTableQuery: FC<OrderPageCostsTableQueryProps> = ({ router }) => {
  const { id } = router.query;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 50;

  let orderBy: any[] = [];

  if (!id) return null;

  const { data: order } = useGetCurrentOrderQuery({
    variables: {
      orderId: +id,
    }
  });

  if (sort) {
    console.log('sort by', sort);
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'doc_amount') {
        return { t_doc: { amount: value }};
      }

      if (key === 'partner') {
        return { t_doc: { partner: { name : value }}};
      }

      if (key === 'date'){
        return { t_doc: { oper_date : value }};
      }

      if (key === 'id'){
        return { t_doc: { number : value }};
      }

      return { [key]: value };
    });
  }

  const { data: costs } = useGetFtranListQuery({
    variables: {
      offset: (page - 1) * limit,
      limit,
      where: {
        order_id: {
          _eq: +id
        },
      },
      orderBy
    }
  });

  const { data: dataCount } = useGetFtranCountQuery({
    variables: {
      where: {
        order_id: {
          _eq: +id
        }
      },
    }
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <OrderPageCostsTable
      id="ID_ORDER_PAGE_COSTS_TABLE"
      order={order?.t_order[0]}
      data={costs?.t_ftran || []}
      onSorterChange={onSorterChange}
      pagination={{
        current: page,
        total: dataCount?.t_ftran_aggregate.aggregate?.count || 0,
        pageSize: limit,
      }}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
      onPaginationChange={onTableChange}
      initialHiddenColumns={(order?.t_order[0].archived || order?.t_order[0].status_id == 4) ? ['action'] : []}
    />

  );
};

export default withRouter(OrderPageCostsTableQuery);

import { useState, useMemo, FC, Fragment } from 'react';
import Link from 'next/link';
import { Row, Button, Tooltip, Space } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';
import { useApolloClient } from '@apollo/client';

import { useGetTaskListPartnerQuery } from '~/apollo/generated/hooks';
import { TaskListProps, TaskListQueryProps } from './TaskList.props';
import { TTaskParterProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../../Table';
import TaskPriorityIcon from '../../../TaskPriorityIcon';
import TasksAddReportModal from '../../../tasks/TasksAddReportModal';

const TaskList: FC<TaskListProps> = ({ data, ...props }) => {
  const client = useApolloClient();
  const { state } = useAppContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentTask, setCurrentTask] = useState<TTaskParterProps | null>(null);

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'icon',
        width: 40,
        Cell: ({ value, row }: CellProps<TTaskParterProps, TTaskParterProps['tip_zadachi']>) => (
          <span>
            <Space>
              {value?.map((tip) => (
                <Fragment key={tip.id}>
                  {tip.prop_value ? (
                    <Tooltip
                      title={tip.prop_value.value}
                    >
                      <TaskPriorityIcon priority={tip.prop_value.brief} />
                    </Tooltip>
                  ) : null}
                </Fragment>
              ))}
              {row.original.task_priority?.name === 'Высокий' ? (
                <FireOutlined style={{ color: 'red' }} />
              ) : null}
            </Space>
          </span>
        )
      },
      {
        Header: 'Описание',
        accessor: 'description',
        Cell: ({ value, row }: CellProps<TTaskParterProps, TTaskParterProps['description']>) => (
          <span>
            <Link href={`/tasks/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
      },
      {
        Header: 'Ответственный',
        accessor: 'subject_owner',
        Cell: ({ value }: CellProps<TTaskParterProps, TTaskParterProps['subject_owner']>) => (
          <span>
            {value ? (
              <Link href={`/employees/${value.id}`}>
                <a>
                  { value?.name || '' } { value?.lastname || '' }
                </a>
              </Link>
            ) : null}
          </span>
        )
      },
      {
        Header: 'Контактное лицо',
        accessor: 'contact_person',
        Cell: ({ value }: CellProps<TTaskParterProps, TTaskParterProps['contact_person']>) => (
          <span>
            {value.map((contact) => (
              <Link key={contact.id} href={`/contacts/${contact.object_id}`}>
                <a>{contact.subject?.name || '' } { contact.subject?.lastname || '' }</a>
              </Link>
            ))}
          </span>
        )
      },
      {
        Header: 'Действия',
        id: 'action',
        Cell: ({ row }: CellProps<TTaskParterProps>) => (
          <span>
            <Row align="middle" justify="center" style={{ height: '100%' }}>
              <Button
                size="small"
                onClick={() => showModal(row.original)}
              >
                Добавить отчет
              </Button>
            </Row>
          </span>
        )
      }
    ],
    [data]
  );

  const showModal = (task?: TTaskParterProps) => {
    setCurrentTask(task || null);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setCurrentTask(null);
    setVisibleModal(false);
  };

  if (!data?.length) return <></>;

  return (
    <div>
      <h4>Актуальные задачи</h4>
      <Table
        data={data || []}
        columns={columns}
        initialHiddenColumns={localTableSettings?.hiddenColumns}
        initialColumnOrder={localTableSettings?.columnOrder}
        {...props}
      />
      <TasksAddReportModal
        visible={visibleModal}
        onOk={() => {
          client.refetchQueries({
            include: ['getTaskListPartner']
          });
          closeModal();
        }}
        onCancel={() => closeModal()}
        task={currentTask}
      />
    </div>
  );
};

const TaskListQuery: FC<TaskListQueryProps> = ({ partnerId }) => {
  const { data } = useGetTaskListPartnerQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        task_type: {
          brief: { _eq: 'TASK' }
        },
        status: {
          name: { _neq: 'Завершена' }
        },
        object_id: { _eq: partnerId },
        object_type: {
          brief: { _eq: 'SUBJECT' }
        }
      }
    }
  });

  return (
    <TaskList
      id="ID_PARTNER_PAGE_BASIC_CONTENT_TASK_LIST"
      data={data?.task_list}
    />
  );
};

export default TaskListQuery;

import { FC } from 'react';
import { Select } from 'antd';

import { useGetOrderTypeListQuery } from '~/apollo/generated/hooks';
import { StatusSelectProps } from './StatusSelect.props';

const StatusSelect: FC<StatusSelectProps> = ({
  showSearch = true,
  allowClear = true,
  placeholder = 'Выберите статус',
  optionFilterProp = 'children',
  ...props
}) => {
  const { data } = useGetOrderTypeListQuery();

  return (
    <Select
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      allowClear={allowClear}
      {...props}
    >
      {data?.t_order_type
        .find((t) => t.id === 1)?.order_statuses
        .map((s) => (
          <Select.Option key={s.id} value={s.id}>
            {s.name}
          </Select.Option>
        )
      )}
    </Select>
  );
};

export default StatusSelect;

import { useState, ChangeEvent, FC } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Row, Col, Button, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { EmployeesPageHeaderProps } from './EmployeesPageHeader.props';
import { EmployeesAddDrawer } from '~/components';

const { Search } = Input;

const EmployeesPageHeader: FC<EmployeesPageHeaderProps> = ({ router }) => {
  const search = router.query.search ? router.query.search : undefined;
  const archive = router.query.archive?.toString() || null;
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const text = e.target.value;

      if (!text) {
        router.replace({
          query: {
            ...router.query,
            page: 1,
            search: undefined
          }
        });
      }
    }
  };

  const onSearch = (text: string) => {
    router.replace({
      query: {
        ...router.query,
        page: 1,
        search: text || undefined
      }
    });
  };

  return (
    <>
      <PageHeader
        title="Сотрудники"
        ghost={false}
        style={{ marginBottom: 16 }}
        extra={
          <>
            {archive === 'yes' ? (
              <Button
                type="link"
                onClick={() => onFilter('archive', false)}
              >
                Актуально
              </Button>
            ) : null}
            {archive !== 'yes' ? (
              <Button
                type="link"
                onClick={() => onFilter('archive', true)}
              >
                Архив
              </Button>
            ) : null}
          </>
        }
      >
        <Row align="middle" gutter={[12, 0]}>
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ marginRight: 8 }}
              type="primary"
              onClick={() => setVisibleDrawer(true)}
            >
              Добавить
            </Button>
          </Col>
          <Col>
            <Search
              defaultValue={search}
              style={{ width: 250 }}
              allowClear
              placeholder="Поиск"
              onChange={onChange}
              onSearch={onSearch}
            />
          </Col>
        </Row>
      </PageHeader>

      <EmployeesAddDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={() => setVisibleDrawer(false)}
      />
    </>
  );
};

export default withRouter(EmployeesPageHeader);

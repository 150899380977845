import { FC } from 'react';
import { Select } from 'antd';

import { useGetSalesAreaForPartnersPageQuery } from '~/apollo/generated/hooks';
import { BranchSelectProps } from './BranchSelect.props';

const BranchSelect: FC<BranchSelectProps> = ({
  onChange = () => ({}),
  showSearch = true,
  allowClear = true,
  placeholder = 'Филиал',
  optionFilterProp = 'children',
  ...props
}) => {
  const { data } = useGetSalesAreaForPartnersPageQuery({
    variables: {
      where: {
        is_active: { _eq: true }
      }
    }
  });

  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      onChange={onChange}
      {...props}
    >
      {data?.sales_area.map((b) => (
          <Select.Option key={b.id} value={b.id}>
            {b.name}
          </Select.Option>
        )
      )}
    </Select>
  );
};

export default BranchSelect;

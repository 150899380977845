import { useState, FC } from 'react';
import { useSession } from 'next-auth/react';
import { Form, Input, Row, Col, Space, Button, message, DatePicker, TimePicker } from 'antd';

import { ReminderFormProps } from './ReminderForm.props';
import ContactSelect from '../ContactSelect';
import SubjectSelect from '../../../SubjectSelect';
import { useAddTaskOneMutation } from '~/apollo/generated/hooks';
import { ADD_TASK_PROP } from '~/apollo/mutations/task_props';
import { ADD_ARRAY_TASK_LINK } from '~/apollo/mutations/task_links';

const ReminderForm: FC<ReminderFormProps> = ({
  objectId,
  objectTypeId,
  entityType = null,
  onAddTask = () => ({}),
}) => {
  const [loading, setLoading] = useState(false);
  const { data: session } = useSession();
  const [form] = Form.useForm();

  const [saveTask, { client }] = useAddTaskOneMutation({
    onCompleted: async (data) => {
      const prop = {
        task_id: data.insert_t_task_one?.id,
        prop_id: 10,
        prop_value_id: 7
      };

      const { errors: savePropErrors } = await client.mutate({
        mutation: ADD_TASK_PROP,
        variables: {
          taskProp: [prop]
        }
      });

      if (savePropErrors) {
        setLoading(false);
        message.error('Произошла ошибка при сохранении напоминания.');
      }

      if (form.getFieldValue('contactId') && entityType === 'PARTNER') {
        const link = {
          task_id: data.insert_t_task_one?.id,
          object_id: form.getFieldValue('contactId'),
          object_type_id: 3,
          link_type_id: 1
        };

        const { errors: saveLinkErrors } = await client.mutate({
          mutation: ADD_ARRAY_TASK_LINK,
          variables: {
            links: [link]
          }
        });

        if (saveLinkErrors) {
          setLoading(false);
          message.error('Произошла ошибка при сохранении напоминания.');
        }
      }

      setLoading(false);
      message.success('Напоминание сохранено успешно');
      form.resetFields();
      onAddTask(data.insert_t_task_one?.id);
    },
    onError: (err) => {
      setLoading(false);
      message.error('Произошла ошибка при сохранении напоминания. Сообщение ошибки: ' + err.message);
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const task = {
          type_id: 4,
          status_id: 10,
          description: values.text,
          deadline: values.date ? values.date.format('DD.MM.YYYY') : values.date,
          deadline_time: values.time ? values.time.format('HH:mm') : values.time,
          object_id: objectId,
          object_type_id: objectTypeId,
          ins_user: session?.user.id,
          owner_id: values.ownerId,
        };

        setLoading(true);

        saveTask({
          variables: { task }
        });
      })
      .catch(() => ({}));
  };

  return (
    <Form
      form={form}
      initialValues={{
        ownerId: session?.user.subject_id
      }}
    >
      <Form.Item name="text">
        <Input.TextArea
          placeholder="Введите комментарий"
          rows={2}
        />
      </Form.Item>
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Col>
          <Space>
            <Form.Item name="date">
              <DatePicker format="DD.MM.YYYY" placeholder="Дата" />
            </Form.Item>
            <Form.Item name="time">
              <TimePicker format="HH:mm" placeholder='Время' />
            </Form.Item>
            <Form.Item name="ownerId">
              <SubjectSelect
                typeBrief={['EMPLOYEE']}
                style={{ width: 240 }}
              />
            </Form.Item>
            {entityType === 'PARTNER' ? (
              <Form.Item name="contactId">
                <ContactSelect
                  objectId={objectId}
                  style={{ width: 240 }}
                />
              </Form.Item>
            ) : null}
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              htmlType="reset"
              disabled={loading}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            >
              Создать
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ReminderForm;

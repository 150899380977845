import { FC } from 'react';
import { Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DeleteButtonProps } from './DeleteButton.props';
import { TOrderlineExpenseProps } from '~/interfaces';
import { useUpdateOrderlineMutation } from '~/apollo/generated/hooks';

const DeleteButton: FC<DeleteButtonProps> = ({
  expense,
  orderline
}) => {
  if (!expense || !orderline) return null;

  const [deleteExpense, { loading }] = useUpdateOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  const onConfirm = () => {
    let expenses = orderline.jdoc.expenses || [];

    expenses = expenses.filter((exp: TOrderlineExpenseProps) => {
      return exp.key !== expense.key;
    });

    let fix_cost = 0;

    expenses = expenses.map((exp: TOrderlineExpenseProps) => {
      if (+exp.summ) {
        fix_cost += +exp.summ;
      }

      return exp;
    });

    deleteExpense({
      variables: {
        orderlineId: orderline.orderline_id,
        set: {
          jdoc: { expenses },
          fixed_cost: fix_cost
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      placement="left"
      okText="Да"
      cancelText="Нет"
      onConfirm={onConfirm}
      disabled={loading}
    >
      <Tooltip title="Удалить">
        <DeleteOutlined style={{ color: '#EB2F96' }} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteButton;

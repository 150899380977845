import { useState, useEffect, FC } from 'react';
import { Card, Button, Space, Form, DatePicker, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

import { DeadlineCardProps } from './DeadlineCard.props';
import { useUpdateTaskByPkMutation } from '~/apollo/generated/hooks';

const DeadlineCard: FC<DeadlineCardProps> = ({
  user,
  task,
}) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const onOk = () => {
    form.resetFields();
    setEdit(false);
  };

  const [updateTask, { loading }] = useUpdateTaskByPkMutation({
    onCompleted: () => {
      onOk();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
    refetchQueries: ['getCurrentTask']
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateTask({
          variables: {
            taskId: task.id,
            set: {
              deadline: values.date ? values.date.format('DD.MM.YYYY') : null,
              deadline_time: values.time ? values.time.format('HH:mm') : null,
            }
          }
        });
      })
      .catch(() => ({}));
  };

  useEffect(() => {
    form.setFieldsValue({
      date: moment(task.deadline_string || new Date(), 'DD.MM.YYYY'),
      time: moment(task.deadline_time_string || '00:00', 'HH:mm'),
    });
  }, [task, edit]);

  return (
    <Card
      bordered={false}
      title="Дата завершения:"
      className="mini-card"
      extra={
        <>
          {user.subject_id === task.owner_id || user.id === task.ins_user ? (
            <EditOutlined onClick={() => setEdit(true)} />
          ) : null}
        </>
      }
    >
      {edit ? (
        <Form
          form={form}
          layout="horizontal"
          autoComplete="off"
        >
          <Form.Item label="Дата" name="date">
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>
          <Form.Item label="Время" name="time">
            <DatePicker picker="time" format="HH:mm" />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            >
              Сохранить
            </Button>
            <Button
              disabled={loading}
              onClick={onOk}
            >
              Отменить
            </Button>
          </Space>
        </Form>
      ) : (
        <b>
          { task.deadline_string || '' }
          { task.deadline_time_string ? ` в ${ task.deadline_time_string }` : null}
        </b>
      )}
    </Card>
  );
};

export default DeadlineCard;

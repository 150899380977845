// const getQuarterInterval = (quarter?: string) => {
//   let dateQuarter = null;

//   switch (quarter) {
//       case 'Q121':
//         dateQuarter = { _gte: '01.01.2021', _lte: '31.03.2021' };
//         break;
//       case 'Q221':
//         dateQuarter = { _gte: '01.04.2021', _lte: '30.06.2021' };
//         break;
//       case 'Q321':
//         dateQuarter = { _gte: '01.07.2021', _lte: '30.09.2021' };
//         break;
//       case 'Q421':
//         dateQuarter = { _gte: '01.10.2021', _lte: '31.12.2021' };
//         break;
//       case 'Q122':
//         dateQuarter = { _gte: '01.01.2022', _lte: '31.03.2022' };
//         break;
//       case 'Q222':
//         dateQuarter = { _gte: '01.04.2022', _lte: '30.06.2022' };
//         break;
//       case 'Q322':
//         dateQuarter = { _gte: '01.07.2022', _lte: '30.09.2022' };
//         break;
//       case 'Q422':
//         dateQuarter = { _gte: '01.10.2022', _lte: '31.12.2022' };
//         break;
//       case 'Q123':
//         dateQuarter = { _gte: '01.01.2023', _lte: '31.03.2023' };
//         break;
//       case 'Q223':
//         dateQuarter = { _gte: '01.04.2023', _lte: '30.06.2023' };
//         break;
//       case 'Q323':
//         dateQuarter = { _gte: '01.07.2023', _lte: '30.09.2023' };
//         break;
//       case 'Q423':
//         dateQuarter = { _gte: '01.10.2023', _lte: '31.12.2023' };
//         break;
//       case 'Q124':
//         dateQuarter = { _gte: '01.01.2024', _lte: '31.03.2024' };
//         break;
//       case 'Q224':
//         dateQuarter = { _gte: '01.04.2024', _lte: '30.06.2024' };
//         break;
//       case 'Q324':
//         dateQuarter = { _gte: '01.07.2024', _lte: '30.09.2024' };
//         break;
//       case 'Q424':
//         dateQuarter = { _gte: '01.10.2024', _lte: '31.12.2024' };
//         break;
//       default:
//         break;
//     }

//   return dateQuarter;
// };

// export default getQuarterInterval;

/**
 * Рассчитаем интервал дат квартала из кода квартала
 * для квартала 1 - 01.01.20YY - 31.03.20YY
 * для квартала 2 - 01.04.20YY - 30.06.20YY
 * для квартала 3 - 01.07.20YY - 30.09.20YY
 * для квартала 4 - 01.10.20YY - 31.12.20YY
 * @param quarterCode - строка с кодом квартала QNYY, где N - номер квартала (1,2,3,4), YY - год (21,22,23,24,...)
 * @returns 
 */
const getQuarterInterval = (quarterCode?: string) => {

  // диапазон дат квартала
  const quarterStart = [ '01.01', '01.04', '01.07', '01.10' ];
  const quarterEnd = [ '31.03', '30.06', '30.09', '31.12' ];

  // определим год квартала
  const quarterYear = quarterCode?.slice(2, 4);
  
  // определим номер квартала
  const quarterNumber = Number(quarterCode?.slice(1, 2));

  // рассчитаем интервал дат для заданного квартала
  const quarterInterval = { 
    _gte: `${quarterStart[quarterNumber - 1]}.20${quarterYear}`, 
    _lte: `${quarterEnd[quarterNumber - 1]}.20${quarterYear}`,
  };
  return quarterInterval;
};

export default getQuarterInterval;

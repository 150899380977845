import { gql } from '@apollo/client';

export const ADD_TASK = gql`
  mutation addTask($objects: [t_task_insert_input!]!) {
    insert_t_task(objects: $objects) {
      returning {
        task_id
      }
    }
  }
`;

export const ADD_TASK_ONE = gql`
  mutation addTaskOne($task: t_task_insert_input!) {
    insert_t_task_one(object: $task) {
      task_id
      id: task_id
    }
  }
`;

export const UPDATE_TASK_BY_PK = gql`
  mutation updateTaskByPk($taskId: bigint!, $set: t_task_set_input, $append: t_task_append_input) {
    task: update_t_task_by_pk(pk_columns: {task_id: $taskId}, _set: $set, _append: $append) {
      task_id
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation updateTask($taskId: bigint, $statusId: Int, $updUser: Int) {
    update_t_task(where: {task_id: {_eq: $taskId}}, _set: {status_id: $statusId, upd_user: $updUser}) {
      returning {
        task_id
      }
    }
  }
`;

import { useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Avatar } from 'antd';
import { CellProps } from 'react-table';

import { TasksActivityPageTableProps, TasksActivityPageTableQueryProps } from './TasksActivityPageTable.props';
import { useGetCommentActivityQuery } from '~/apollo/generated/hooks';
import Table from '../../Table';
import { Order_By, TCommentActivityProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';

const TasksActivityPageTable: FC<TasksActivityPageTableProps> = ({ data, ...props }) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: 'Дата',
        accessor: 'char_date',
        width: 150,
        sorter: true,
      },
      {
        Header: 'Партнер',
        accessor: 'partner_name',
        width: 300,
        Cell: ({ value, row }: CellProps<TCommentActivityProps, TCommentActivityProps['partner_name']>) => (
          <span>
            <Link href={`/partners/${row.original.subject_id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Комментарий',
        accessor: 'text',
        width: 400
      },
      {
        Header: 'Менеджер',
        accessor: 'manager',
        Cell: ({ value }: CellProps<TCommentActivityProps, TCommentActivityProps['manager']>) => (
          <span>
            {value && value.avatar ? (
              <Avatar
                src={`http://oasis.retailsuite.ru/${value.avatar}?width=50`}
              />
            ) : null}
            &nbsp;{ value && value.name || "" } { value && value.lastname || "" }
          </span>
        )
      }
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const TasksActivityPageTableQuery: FC<TasksActivityPageTableQueryProps> = ({
  router,
  ...props
}) => {
  const managerId = router.query.manager ? +router.query.manager : undefined;
  const partnerId = router.query.partner ? +router.query.partner : undefined;
  const dateFrom = router.query.dateFrom;
  const dateTo = router.query.dateTo;
  const sort = router.query.sort?.toString().split(',');

  let where: any = {};
  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      return { [key]: value };
    });
  } else {
    orderBy = [{ comment_id: Order_By.Desc }];
  }

  if (managerId) {
    where = { ins_user: { _eq: managerId } };
  }

  if (partnerId) {
    where = { ...where, subject_id: { _eq: partnerId } };
  }

  if (dateFrom) {
    where = { ...where, ins_date: { _gte: dateFrom } };
  }

  if (dateTo) {
    where = { ...where, ins_date: { ...where.ins_date, _lte: dateTo } };
  }

  const { data } = useGetCommentActivityQuery({
    variables: {
      where,
      limit: 100,
      offset: 0,
      orderBy,
    },
    fetchPolicy: 'cache-and-network'
  });

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <TasksActivityPageTable
      id="ID_TASKS_ACTIVITY_PAGE_TABLE"
      data={data?.comments || []}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
      {...props}
    />
  );
};

export default withRouter(TasksActivityPageTableQuery);

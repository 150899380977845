import { FC } from 'react';
import { Radio } from 'antd';

import { useGetPriorityListQuery } from '~/apollo/generated/hooks';
import { TasksPriorityRadioProps } from './TasksPriorityRadio.props';

const TasksPriorityRadio: FC<TasksPriorityRadioProps> = ({
  ...props
}) => {
  const { data } = useGetPriorityListQuery({
    variables: {
      taskTypeBrief: 'TASK'
    }
  });

  return (
    <Radio.Group {...props}>
      {data?.t_task_priority.map((p) => (
        <Radio.Button key={p.id} value={p.id}>
          {p.name}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default TasksPriorityRadio;

import { FC } from 'react';
import { withRouter } from 'next/router';
import { Card } from 'antd';

import { ContactPageBasicContentProps } from './ContactPageBasicContent.props';
import Comment from '../../Comment';

const ContactPageBasicContent: FC<ContactPageBasicContentProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  return (
    <Card bordered={false}>
      <Comment
        objectId={+id}
        objectTypeId={3}
      />
    </Card>
  );
};

export default withRouter(ContactPageBasicContent);

import { FC } from 'react';
import { useSession } from 'next-auth/react';
import { ApolloClient } from '@apollo/client';
import { Drawer, Form, Button, Upload, Input, Select, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import { FeedsAddDrawerProps } from './FeedsAddDrawer.props';
import { SubjectSelect, FileUpload } from '~/components';
import { useAddContentMutation } from '~/apollo/generated/hooks';
import { FILE_UPLOAD } from '~/apollo/mutations/files';
import { getFileAsBase64 } from '~/utils';

const { TextArea } = Input;

const FeedsAddDrawer: FC<FeedsAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();

  const savePhoto = async (client: ApolloClient<any>, objectId: number) => {
    const photos = form.getFieldValue('photo');
    const base64str = await getFileAsBase64(photos[0].originFileObj);

    try {
      const { data } = await client.mutate({
        mutation: FILE_UPLOAD,
        variables: {
          objectId,
          objectTypeId: 4,
          linkTypeId: 4,
          fileType: 'Image',
          mimeType: photos[0].type,
          fileName: photos[0].name,
          base64str,
        }
      });

      return { file_id: data.file_id };
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

  const saveFiles = async (client: ApolloClient<any>, objectId: number) => {
    const files = form.getFieldValue('files');

    try {
      await Promise.all(files.map(async (f: UploadFile) => {
        const base64str = await getFileAsBase64(f.originFileObj);

        await client.mutate({
          mutation: FILE_UPLOAD,
          variables: {
            objectId,
            objectTypeId: 4,
            linkTypeId: 5,
            fileType: 'File',
            mimeType: f.type,
            fileName: f.name,
            base64str,
          }
        });
      }));

      return {};
    } catch (error) {
      return { error };
    }
  };

  const [addContent, { client }] = useAddContentMutation({
    onCompleted: async (data) => {
      if (!data.insert_t_content_one) {
        return message.error('При сохранении публикации произошла ошибка');
      }

      if (form.getFieldValue('photo') && form.getFieldValue('photo').length > 0) {
        const res = await savePhoto(client, data.insert_t_content_one.id);

        if (res.error) {
          return message.error('При сохранении публикации произошла ошибка');
        }
      }

      if (form.getFieldValue('files') && form.getFieldValue('files').length > 0) {
        const res = await saveFiles(client, data.insert_t_content_one.id);

        if (res.error) {
          return message.error('При сохранении публикации произошла ошибка');
        }
      }

      message.success('Публикация сохранена успешно');
      form.resetFields();
      client.refetchQueries({
        include: ['getContentList']
      });
      onSave();
    }
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;

    return e && e.fileList;
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('Вы можете загрузить только файл с расширением JPG или PNG!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Изображение должно быть меньше 2 МБ!');
    }

    return (isJpgOrPng && isLt2M) ? true : Upload.LIST_IGNORE;
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        addContent({
          variables: {
            description: values.description,
            title: values.title,
            ownerId: session?.user.subject_id || null,
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      title="Добавление публикации"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button onClick={onSubmit} type="primary">
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="feed_add_form"
      >
        <Form.Item
          name="photo"
          label="Фотография"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <FileUpload
            name="photo"
            listType="picture-card"
            beforeUpload={beforeUpload}
            maxCount={1}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Название"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="employees" label="Уведомить">
          <SubjectSelect typeBrief={['EMPLOYEE']} mode="multiple" />
        </Form.Item>
        <Form.Item
          name="files"
          label="Прикрепить файл"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <FileUpload
            name="files"
            multiple={true}
            maxCount={10}
          />
        </Form.Item>
        <Form.Item name="tags" label="Теги">
          <Select mode="tags" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FeedsAddDrawer;

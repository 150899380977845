import { FC } from 'react';
import { Select } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { CitySelectProps } from './CitySelect.props';

const CitySelect: FC<CitySelectProps> = ({
  onChange = () => ({}),
  showSearch = true,
  allowClear = true,
  placeholder = 'Выберите город',
  optionFilterProp = 'children',
  ...props
}) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'CITY' }
        }
      }
    }
  });

  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      onChange={onChange}
      {...props}
    >
      {data?.t_prop_value.map((b) => (
        <Select.Option key={b.id} value={b.id}>
          {b.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CitySelect;

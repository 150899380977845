import { useState, useEffect, FC } from 'react';
import { useApolloClient } from '@apollo/client';

import { GET_CURRENT_EMPLOYEE } from '~/apollo/queries/employees';
import CheckList from '../../../CheckList';
import { ChangeItemProps } from '../../../CheckList/CheckList.props';
import { TasksDrawerCheckListProps } from './CheckList.props';

const TasksDrawerCheckList: FC<TasksDrawerCheckListProps> = ({
  value,
  onChange = () => ({}),
}) => {
  const client = useApolloClient();
  const [showAddForm, setShowAddForm] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const [data, setData] = useState(value || []);

  const getEmployee = async (employeeId: any) => {
    return await client.query({
      query: GET_CURRENT_EMPLOYEE,
      variables: {
        where: {
          subject_id: { _eq: employeeId }
        }
      }
    });
  };

  const getSelectedRows = () => data
    ?.filter((t: any) => t.completed)
    .map((t: any) => t.id);

  const onSelect = (record: any, selected: any) => {
    setData((tasks) => tasks.map(task => {
      if (task.id === record.id) {
        return {
          ...task,
          completed: selected,
        };
      } else {
        return task;
      }
    }));
  };

  const onChangeTask = async (values: ChangeItemProps) => {
    const newData = await Promise.all(data?.map(async task => {
      if (task.id === values.id) {
        let owner;

        if (task.owner?.id && values.ownerId && task.owner.id === values.ownerId) {
          owner = task.owner;
        } else if (values.ownerId) {
          const { data } = await getEmployee(values.ownerId);

          if (data && data.employee && data.employee.length) {
            owner = {
              id: data.employee[0].id,
              name: data.employee[0].name,
              lastname: data.employee[0].lastname,
            };
          }
        }

        return {
          id: values.id,
          important: values.important,
          title: values.title,
          completed: task.completed,
          date: values.date ? values.date.format('DD.MM.YYYY') : values.date,
          time: values.time ? values.time.format('HH:mm') : values.time,
          owner,
        };
      } else {
        return task;
      }
    }));

    setData(newData);
    setActiveItemId(null);
  };

  const onAddTask = async (values: ChangeItemProps) => {
    const newData = [...data];
    let owner;

    if (values.ownerId) {
      const { data } = await getEmployee(values.ownerId);

      if (data && data.employee && data.employee.length) {
        owner = {
          id: data.employee[0].id,
          name: data.employee[0].name,
          lastname: data.employee[0].lastname,
        };
      }
    }

    newData.push({
      id: (data.length + 1).toString(),
      important: values.important,
      title: values.title,
      completed: values.completed,
      date: values.date ? values.date.format('DD.MM.YYYY') : undefined,
      time: values.time ? values.time.format('HH:mm') : undefined,
      owner,
    });

    setData(newData);
    setShowAddForm(false);
  };

  const onRemoveTask = (id: string) => {
    setData((tasks) => tasks?.filter(task => task.id !== id));
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <CheckList
      dataSource={data}
      onSelect={onSelect}
      onChangeItem={onChangeTask}
      onAddItem={onAddTask}
      onRemove={onRemoveTask}
      selectedRowKeys={getSelectedRows()}
      activeItemId={activeItemId}
      showAddForm={showAddForm}
      onOpenChangeForm={(id) => {
        setActiveItemId(id);
        setShowAddForm(false);
      }}
      onCancelChangeForm={() => setActiveItemId(null)}
      onOpenAddForm={() => {
        setActiveItemId(null);
        setShowAddForm(true);
      }}
      onCancelAddForm={() => setShowAddForm(false)}
    />
  );
};

export default TasksDrawerCheckList;

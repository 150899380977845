import { FC } from 'react';
import { Select } from 'antd';

import { useGetSubjectListQuery } from '~/apollo/generated/hooks';
import { ContactSelectProps } from './ContactSelect.props';

const ContactSelect: FC<ContactSelectProps> = ({
  objectId,
  allowClear = true,
  placeholder = 'Выберите контакт',
  ...props
}) => {
  const { data, loading } = useGetSubjectListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_type: {
          brief: { _eq: 'CONTACT_PERSON' }
        },
        _or: [
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              subject_object: {
                subject_type: {
                  brief: { _eq: 'ORGANIZATION' }
                },
                object_subject_links: {
                  t_link_type: { brief: { _eq: 'PARTNER_ORGANIZATION' } },
                  t_object_type: { brief: { _eq: 'SUBJECT' } },
                  subject_id: { _eq: objectId }
                }
              }
            }
          },
          {
            subject_links: {
              t_link_type: { brief: { _eq: 'CONTACT_PERSON' } },
              t_object_type: { brief: { _eq: 'SUBJECT' } },
              object_id: { _eq: objectId }
            }
          }
        ]
      }
    }
  });

  return (
    <Select
      placeholder={placeholder}
      allowClear={allowClear}
      loading={loading}
      {...props}
    >
      {data?.subject_list.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          { c.name || '' } { c.lastname || '' } { (c.post && `(${c.post})`) || '' }
        </Select.Option>
      ))}
    </Select>
  );
};

export default ContactSelect;

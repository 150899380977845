import { FC } from 'react';
import { Button, message } from 'antd';
import { withRouter } from 'next/router';

import { useAddOrderlineMutation } from '~/apollo/generated/hooks';
import { OrderProductsAddButtonProps } from './OrderProductsAddButton.props';

const OrderProductsAddButton: FC<OrderProductsAddButtonProps> = ({
  router
}) => {
  const { id } = router.query;

  if (!id) return null;

  const [addOrderline, { loading }] = useAddOrderlineMutation({
    onError: () => {
      message.error('Произошла ошибка при добавлении новой позиции в заказ');
    },
    refetchQueries: ['getCurrentOrder', 'getOrderlineList']
  });

  return (
    <Button
      type="primary"
      onClick={() => addOrderline({ variables: { orderId: +id } })}
      loading={loading}
      disabled={loading}
    >
      Добавить строку
    </Button>
  );
};

export default withRouter(OrderProductsAddButton);

import { FC } from 'react';
import Link from 'next/link';
import { Card, Divider, List, Avatar } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { OrderInfoProps } from './OrderInfo.props';
import { useGetTaskOrderQuery } from '~/apollo/generated/hooks';

const OrderInfo: FC<OrderInfoProps> = ({
  task
}) => {
  const { data } = useGetTaskOrderQuery({
    variables: {
      orderId: task.object_id
    }
  });

  if (!data) return null;

  const order = data.t_order[0];

  if (!order) return null;

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 8 }}
    >
      <Card
        title="Заказ:"
        bordered={false}
        className="mini-card"
        extra={
          <EditOutlined />
        }
      >
        <Link href={`/orders/${task.object_id}`}>
          <a>№{ task.object_id }</a>
        </Link>
      </Card>

      <Divider style={{ margin: '16px 0' }} />

      <Card
        bordered={false}
        title="Клиент:"
        className="mini-card"
      >
        <List
          itemLayout="horizontal"
          dataSource={(order.customer && [order.customer]) || []}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Link href="https://www.antdv.com/">
                    <a>{item.name}</a>
                  </Link>
                }
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                description={(item.dolznost[0] && item.dolznost[0].prop_value && item.dolznost[0].prop_value.value) || ''}
              />
            </List.Item>
          )}
        />
      </Card>

      <Divider style={{ margin: '16px 0' }} />

      <Card
        bordered={false}
        title="Организация:"
        className="mini-card"
      >
        <Link href={`/organizations/${order.firm_id}`}>
          <a>{(order.firm && order.firm.name) || ''}</a>
        </Link>
      </Card>

      <Divider style={{ margin: '16px 0' }} />

      <Card
        bordered={false}
        title="Контактные лица:"
        className="mini-card"
      >
        {(order.firm && order.firm.contact_list.length) ? (
          <List
            itemLayout="horizontal"
            dataSource={(order.firm && order.firm.contact_list) || []}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link href={`/contacts/${item.subject_object?.id}`}>
                      <a>{item.subject_object?.name || '' } { item.subject_object?.lastname || '' }</a>
                    </Link>
                  }
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  description={item.subject_object?.phone}
                />
              </List.Item>
            )}
          />
        ) : null}
      </Card>
    </Card>
  );
};

export default OrderInfo;

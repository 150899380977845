const checkVarIsNumber = (num: any) => {
  if (num === 0 || num === '0') {
    return true;
  }

  if (!num) {
    return false;
  }

  if (String(parseFloat(num)) === String(num)) {
    return true;
  }

  return false;
};

export default checkVarIsNumber;

import { useState, FC } from 'react';
import { useSession } from 'next-auth/react';
import { Form, Input, Row, Col, Space, Button, message, DatePicker, TimePicker } from 'antd';

import { TaskFormProps } from './TaskForm.props';
import ContactSelect from '../ContactSelect';
import SubjectSelect from '../../../SubjectSelect';
import { useAddTaskOneMutation } from '~/apollo/generated/hooks';
import { ADD_ARRAY_TASK_LINK } from '~/apollo/mutations/task_links';

const TaskForm: FC<TaskFormProps> = ({
  objectId,
  objectTypeId,
  entityType = null,
  onAddTask = () => ({}),
}) => {
  const [loading, setLoading] = useState(false);
  const { data: session } = useSession();
  const [form] = Form.useForm();

  const [saveTask, { client }] = useAddTaskOneMutation({
    onCompleted: async (data) => {
      if (form.getFieldValue('contactId') && entityType === 'PARTNER') {
        const link = {
          task_id: data.insert_t_task_one?.id,
          object_id: form.getFieldValue('contactId'),
          object_type_id: 3,
          link_type_id: 1
        };

        const { errors } = await client.mutate({
          mutation: ADD_ARRAY_TASK_LINK,
          variables: {
            links: [link]
          }
        });

        if (errors) {
          setLoading(false);
          message.error('Произошла ошибка при сохранении задачи.');
        }
      }

      setLoading(false);
      message.success('Задача сохранена успешно');
      form.resetFields();
      onAddTask(data.insert_t_task_one?.id);
    },
    onError: (err) => {
      setLoading(false);
      message.error('Произошла ошибка при сохранении задачи. Сообщение ошибки: ' + err.message);
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const task = {
          type_id: 4,
          status_id: 10,
          description: values.text,
          deadline: values.date ? values.date.format('DD.MM.YYYY') : values.date,
          deadline_time: values.time ? values.time.format('HH:mm') : values.time,
          object_id: objectId,
          object_type_id: objectTypeId,
          ins_user: session?.user.id,
          owner_id: values.ownerId,
        };

        setLoading(true);

        saveTask({
          variables: { task }
        });
      })
      .catch(() => ({}));
  };

  const checkDate = (date: any, time: any) => {
    if (date && time) {
      date.hours(time.hours());
      date.minutes(time.minutes());

      if (date.isBefore()) {
        return false;
      }
    }

    return true;
  };

  return (
    <Form
      form={form}
      initialValues={{
        ownerId: session?.user.subject_id
      }}
      onValuesChange={() => {
        form
          .validateFields()
          .catch(({ values }) => {
            const { date, time } = values;

            if (!checkDate(date, time)) {
              message.error('Выбранная дата напоминания меньше текущей');
            }
          });
      }}
    >
      <Form.Item name="text">
        <Input.TextArea
          placeholder="Введите комментарий"
          rows={2}
        />
      </Form.Item>
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Col>
          <Space>
            <Form.Item
              name="date"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!checkDate(value, getFieldValue('time'))) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  }
                }),
              ]}
            >
              <DatePicker format="DD.MM.YYYY" placeholder="Дата" />
            </Form.Item>
            <Form.Item
              name="time"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!checkDate(getFieldValue('date'), value)) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  }
                }),
              ]}
            >
              <TimePicker format="HH:mm" placeholder='Время' />
            </Form.Item>
            <Form.Item name="ownerId">
              <SubjectSelect
                typeBrief={['EMPLOYEE']}
                style={{ width: 240 }}
              />
            </Form.Item>
            {entityType === 'PARTNER' ? (
              <Form.Item name="contactId">
                <ContactSelect
                  objectId={objectId}
                  style={{ width: 240 }}
                />
              </Form.Item>
            ) : null}
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              htmlType="reset"
              disabled={loading}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            >
              Создать
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default TaskForm;

import { useState, FC } from 'react';
import { Drawer, Form, Button, Row, Col, Input, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useAddFTranMutation } from '~/apollo/generated/hooks';
import { CostsAddDrawerProps } from './CostsAddDrawer.props';
import { TDocReturnedProps } from '~/interfaces';

import SubjectSelect from '../../SubjectSelect';
import DocumentSelect from '../../DocumentSelect';
import DocumentsDrawer from '../../documents/DocumentsDrawer';
import PriceInput from '../../PriceInput';
import { getNormalPrice } from '~/utils';

const { TextArea } = Input;

const validatePrice = (value: any) => {
  if (!value || value.toString().length === 0) {
    return Promise.reject('Обязательное поле!');
  }

  const val = value.toString()
    .replace('₽ ', '')
    .replace( /\s/g, '')
    .replaceAll(',','.');

  if ((!isNaN(val)) || val === '' || val === '-') {
    return Promise.resolve();
  } else {
    return Promise.reject('Сумма указана некорректно');
  }
};

const CostsAddDrawer: FC<CostsAddDrawerProps> = ({
  orderId,
  orderlineId = null,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  if (!orderId) return null;

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [showDocField, setShowDocField] = useState(false);

  const [form] = Form.useForm();

  const [addFTran, { loading }] = useAddFTranMutation({
    onCompleted: () => {
      message.success('Данные успешно сохранены');
      form.resetFields();
      onSave();
    },
    onError: () => {
      message.error('Произошла ошибка при сохранении');
    },
  });

  const onValuesChange = (changedValues: any) => {
    if ('partner' in changedValues) {
      if (changedValues.partner) {
        setShowDocField(true);
      } else {
        setShowDocField(false);
      }
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const amount = getNormalPrice(values.price);

        addFTran({
          variables: {
            amount,
            documentId: values.document,
            // subjectId: null,
            orderId: orderId,
            description: values.description,
            orderlineId: orderlineId,
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onAddNewDocument = (id?: TDocReturnedProps['id']) => {
    form.setFieldsValue({
      document: id,
    });

    setVisibleDrawer(false);
  };

  return (
    <Drawer
      title="Добавление расхода"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="orderline_doc_add_form"
        autoComplete="off"
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Партнер"
          name="partner"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <SubjectSelect
            placeholder="Выберите поставщика"
            typeBrief={['ORGANIZATION', 'PARTNER', 'PROVIDER']}
            typeOrg={[25]}
          />
        </Form.Item>

        {showDocField ? (
          <Form.Item label="Документ">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="document"
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  noStyle
                >
                  <DocumentSelect partner={form.getFieldValue('partner')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => setVisibleDrawer(true)}
                  block
                >
                  Создать новый
                </Button>
              </Col>
            </Row>

            <DocumentsDrawer
              partner={form.getFieldValue('partner')}
              visible={visibleDrawer}
              onClose={() => setVisibleDrawer(false)}
              onSave={onAddNewDocument}
              draft
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label="Сумма к списанию"
          name="price"
          rules={[{
            required: true,
            validator: (_, value) => validatePrice(value)
          }]}
        >
          <PriceInput maskOptions={{ prefix: '₽ ' }} />
        </Form.Item>

        <Form.Item label="Комментарий" name="description">
          <TextArea
            placeholder="Комментарий"
            rows={6}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CostsAddDrawer;

import { useEffect, FC } from 'react';
import { Form, Input, Button, Space, Row, DatePicker, TimePicker, Checkbox } from 'antd';
import moment from 'moment';

import { FormProps } from './Form.props';
import SubjectSelect from '../../SubjectSelect';

const CustomForm: FC<FormProps> = ({
  data,
  onCancel = () => ({}),
  onSave = () => ({}),
  onRemove = () => ({}),
}) => {
  const [form] = Form.useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (data) {
          onSave({
            ...values,
            id: data.id.toString(),
          });

          return;
        }

        onSave(values);
      })
      .catch(() => ({}));
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.title,
        ownerId: data.owner?.id,
        date: (data.date && moment(data.date, 'DD.MM.YYYY')) || null,
        time: (data.time && moment(data.time, 'HH:mm')) || null,
        important: data.important
      });
    }
  }, [data]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="title"
        style={{ marginBottom: 12 }}
        rules={[{ required: true, message: 'Обязательное поле!' }]}
      >
        <Input
          placeholder="Название пункта"
          autoFocus
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Space size={8}>
          <Form.Item name="ownerId">
            <SubjectSelect
              typeBrief={['EMPLOYEE']}
              placeholder="Выберите ответственного"
              style={{ width: 175 }}
            />
          </Form.Item>
          <Form.Item name="date">
            <DatePicker
              format="DD.MM.YYYY"
              placeholder="Дата"
              style={{ width: 140 }}
            />
          </Form.Item>
          <Form.Item name="time">
            <TimePicker
              format="HH:mm"
              placeholder="Время"
              style={{ width: 140 }}
            />
          </Form.Item>
          <Form.Item name="important" valuePropName="checked">
            <Checkbox>Важно</Checkbox>
          </Form.Item>
        </Space>
      </Form.Item>
      <Row align="middle">
        <Button
          type="primary"
          onClick={onSubmit}
        >
          Сохранить
        </Button>
        <Button
          onClick={onCancel}
          style={{ marginLeft: 8 }}
        >
          Отмена
        </Button>
        {data ? (
          <Button
            type="link"
            danger
            onClick={() => onRemove(data.id)}
            style={{ marginLeft: 'auto' }}
          >
            Удалить
          </Button>
        ) : null}
      </Row>
    </Form>
  );
};

export default CustomForm;

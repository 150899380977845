import { useState, useEffect, FC } from 'react';
import { Drawer, Form, Button, Row, Col, Input, message } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useSession } from 'next-auth/react';

import { PartnersDrawerProps } from './PartnersDrawer.props';
import PartnerStatusSelect from '../../partner/PartnerStatusSelect';
import OrganizationsTypeSelect from '../../organizations/OrganizationsTypeSelect';
import PartnerAreaSelect from '../../partner/PartnerAreaSelect';
import TagsSelect from '../../TagsSelect';
import PartnerNameInput from './PartnerNameInput';
import SubjectSelect from '../../SubjectSelect';
import CitySelect from '../../CitySelect';
import ClientsDrawer from '../../clients/ClientsDrawer';
import { useAddSubjectMutation, useGetPartnerListLazyQuery, useUpdateSubjectByPkMutation } from '~/apollo/generated/hooks';
import { ADD_SUBJECT_LINK_LIST, DELETE_SUBJECT_LINK_BY_PK, UPDATE_SUBJECT_LINK_BY_PK } from '~/apollo/mutations/subject_links';
import { ADD_SUBJECT_PROP_LIST, DELETE_SUBJECT_PROP } from '~/apollo/mutations/subject_props';
import { TPartnerProps } from '~/interfaces';
import { GET_PROP_VALUE_LIST } from '~/apollo/queries/prop_values';
import { userHaveRights } from '~/utils';
import { useGetPartnerListForPartnersPageLazyQuery } from '~/apollo/generated/hooks';
import { Order_By } from '~/interfaces';
import PhoneInput from '~/components/PhoneInput';

const PartnersDrawer: FC<PartnersDrawerProps> = ({
  partnerId,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();
  const [addingNewPartnerLinks, setAddingNewPartnerLinks] = useState(false);
  const [updatingNewPartnerLinks, setUpdatingNewPartnerLinks] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [showManagerField, setShowManagerField] = useState(true);
  const [enableNew, setEnableNew] = useState(!partnerId); // если добавляем нового партнера / проверяем нет ли такого уже в бд - если есть то не разрешаем
  const [currentPartner, setCurrentPartner] = useState<TPartnerProps | null | undefined>();

  const [searchPartners] = useGetPartnerListForPartnersPageLazyQuery({ // #fix #1137
    onCompleted: (data) => {
      setEnableNew(false);
      console.log("enableNew ->", enableNew);
      console.log("partner list", data.partner_list);
      console.log('modal visible', visible, visibleDrawer);
      if (data?.partner_list.length < 1) {
        setEnableNew(false);
      } else {
        if (!partnerId) {
          setEnableNew(true);
          if (visible) message.warn("Партнер с таким именем уже существует!");
        }
      }
      console.log("enableNew ->", enableNew);
    }
  });

  const onSearchPartner = () => {

    console.log(form.getFieldValue('name'));
    const val = form.getFieldValue('name');
    if (!val) return;
    const searchString = `${(val.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '')).toLowerCase()}`;

    console.log("call searchPartners");
    searchPartners({
      variables: {
        where: {
          deleted: { _eq: false },
          clear_name: { _eq: searchString },
          subject_type: {
            brief: { _eq: 'PARTNER' }
          }
        },
        limit: 10,
        orderBy: {
          name: Order_By.Asc
        }
      }
    });
  };

  const [getPartner] = useGetPartnerListLazyQuery({
    onCompleted: (data) => {
      if (data.partner_list.length) {
        const partner = data.partner_list[0];

        const obj: any = {
          name: partner.name,
          address: partner.address,
          types: partner.type_organization.map((type) => type.prop_value_id),
          website: partner.website,
          status: partner.status_id === 5 ? 1 : partner.status_id,
          info: partner.info,
          city: (partner.city && partner.city.length && partner.city[0].prop_value?.value) || undefined,
        };

        if (partner.email || partner.phone) {
          obj.extras = [{ email: partner.email, phone: partner.phone }];
        }

        if (partner.email2 || partner.phone2) {
          obj.extras[1] = { email: partner.email2, phone: partner.phone2 };
        }

        if (partner.email3 || partner.phone3) {
          obj.extras[2] = { email: partner.email3, phone: partner.phone3 };
        }

        const manager = (
          partner.manager
          && partner.manager[0]
          && partner.manager[0].subject
        ) || null;

        if (manager) {
          obj.manager = manager.id;
        }

        form.setFieldsValue(obj);
        setCurrentPartner(partner);
        setShowManagerField(isShowManagerField(partner.type_organization));
      }
    },
    fetchPolicy: 'network-only'
  });

  const [addPartner, { loading: addingPartner, client }] = useAddSubjectMutation({
    onCompleted: async (res) => {
      setAddingNewPartnerLinks(true);

      await saveLinks([{
        subject_id: res.subject?.id,
        object_id: session?.user.subject_id,
        link_type_id: 8,
        object_type_id: 3
      }]);

      if (form.getFieldValue('manager')) {
        const link = {
          subject_id: res.subject?.id,
          object_id: form.getFieldValue('manager'),
          link_type_id: 11,
          object_type_id: 3
        };

        const { errors } = await saveLinks([link]);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('types')?.length) {
        const props = form.getFieldValue('types').map((typeId: any) => ({
          prop_id: 11,
          subject_id: res.subject?.id,
          prop_value_id: typeId
        }));

        const { errors } = await saveProps(props);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('city')) {
        const { data, error } = await client.query({
          query: GET_PROP_VALUE_LIST,
          variables: {
            where: {
              prop: {
                brief: { _eq: 'CITY' }
              },
              value: { _ilike: form.getFieldValue('city') }
            }
          }
        });

        if (error) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }

        const props = data?.t_prop_value?.map((p: any) => ({
          prop_id: 6,
          subject_id: res.subject?.id,
          prop_value_id: p.id
        }));

        const { errors } = await saveProps(props);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('tags')?.length) {
        const { data, error } = await client.query({
          query: GET_PROP_VALUE_LIST,
          variables: {
            where: {
              prop: {
                brief: { _eq: 'TAGS' }
              },
              value: {
                _in: form.getFieldValue('tags')
              }
            }
          }
        });

        if (error) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }

        const props = data?.t_prop_value?.map((p: any) => ({
          prop_id: p.prop_id,
          subject_id: res.subject?.id,
          prop_value_id: p.id
        }));

        const { errors } = await saveProps(props);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      await saveProps([{
        prop_id: 14,
        subject_id: res.subject?.id,
        prop_value_id: 53
      }]);

      if (form.getFieldValue('contact')) {
        const link = {
          subject_id: form.getFieldValue('contact'),
          object_id: res.subject?.id,
          link_type_id: 1,
          object_type_id: 3
        };

        const { errors } = await saveLinks([link]);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('area')) {
        const props = [{
          prop_id: 15,
          subject_id: res.subject?.id,
          prop_value_id: form.getFieldValue('area')
        }];

        const { errors } = await saveProps(props);

        if (errors) {
          setAddingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }
      }

      setAddingNewPartnerLinks(false);
      message.success('Партнер успешно сохранен');
      form.resetFields();
      setCurrentPartner(null);
      onSave(res.subject?.id);
    },
    onError: () => {
      message.error('При сохранении партнера произошла ошибка');
    }
  });

  const [updatePartner, { loading: updatingPartner }] = useUpdateSubjectByPkMutation({
    onCompleted: async () => {
      setUpdatingNewPartnerLinks(true);

      if (currentPartner?.type_organization.length) {
        const idList = await currentPartner?.type_organization.map(
          (type) => {
            return type.id;
          }
        );

        const { errors } = await deleteProp(idList);

        if (errors) {
          setUpdatingNewPartnerLinks(false);
          message.error('При обновлении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('types')?.length) {
        const props = form.getFieldValue('types').map((typeId: any) => ({
          prop_id: 11,
          subject_id: partnerId,
          prop_value_id: typeId
        }));

        const { errors } = await saveProps(props);

        if (errors) {
          setUpdatingNewPartnerLinks(false);
          message.error('При обновлении партнера произошла ошибка');
          return;
        }
      }

      if (currentPartner?.city.length) {
        const idList = await currentPartner.city.map((city) => {
          return city.id;
        });

        const { errors } = await deleteProp(idList);

        if (errors) {
          setUpdatingNewPartnerLinks(false);
          message.error('При обновлении партнера произошла ошибка');
          return;
        }
      }

      if (form.getFieldValue('city')) {
        const { data, error } = await client.query({
          query: GET_PROP_VALUE_LIST,
          variables: {
            where: {
              prop: {
                brief: { _eq: 'CITY' }
              },
              value: { _ilike: form.getFieldValue('city') }
            }
          }
        });

        if (error) {
          setUpdatingNewPartnerLinks(false);
          message.error('При сохранении партнера произошла ошибка');
          return;
        }

        const props = data?.t_prop_value?.map((p: any) => ({
          prop_id: 6,
          subject_id: partnerId,
          prop_value_id: p.id
        }));

        const { errors } = await saveProps(props);

        if (errors) {
          setUpdatingNewPartnerLinks(false);
          message.error('При обновлении партнера произошла ошибка');
          return;
        }
      }

      if (isShowManagerField()) {
        if (currentPartner?.manager.length && form.getFieldValue('manager')) {
          const { errors } = await client.mutate({
            mutation: UPDATE_SUBJECT_LINK_BY_PK,
            variables: {
              subjectLinkId: currentPartner.manager[0].id,
              set: {
                object_id: form.getFieldValue('manager')
              }
            }
          });

          if (errors) {
            setUpdatingNewPartnerLinks(false);
            message.error('При обновлении партнера произошла ошибка');
            return;
          }
        } else if (currentPartner?.manager.length && !form.getFieldValue('manager')) {
          const { errors } = await client.mutate({
            mutation: DELETE_SUBJECT_LINK_BY_PK,
            variables: {
              linkId: currentPartner.manager[0].id
            }
          });

          if (errors) {
            setUpdatingNewPartnerLinks(false);
            message.error('При обновлении партнера произошла ошибка');
            return;
          }
        } else if (!currentPartner?.manager.length && form.getFieldValue('manager')) {
          const { errors } = await client.mutate({
            mutation: ADD_SUBJECT_LINK_LIST,
            variables: {
              linkList: [{
                subject_id: partnerId,
                object_id: form.getFieldValue('manager'),
                object_type_id: 3,
                link_type_id: 11
              }]
            }
          });

          if (errors) {
            setUpdatingNewPartnerLinks(false);
            message.error('При обновлении партнера произошла ошибка');
            return;
          }
        }
      }

      setUpdatingNewPartnerLinks(false);
      message.success('Партнер успешно обновлен');
      form.resetFields();
      onSave(partnerId);
    },
    onError: (err) => {
      console.log(JSON.parse(JSON.stringify(err)));
      message.error('При обновлении партнера произошла ошибка');
      setUpdatingNewPartnerLinks(false);
    }
  });

  const saveLinks = async (linkList: any[]) => {
    return await client.mutate({
      mutation: ADD_SUBJECT_LINK_LIST,
      variables: { linkList }
    });
  };

  const deleteProp = async (idList: any[]) => {
    return await client.mutate({
      mutation: DELETE_SUBJECT_PROP,
      variables: {
        where: {
          subject_prop_id: {
            _in: idList
          }
        }
      }
    });
  };

  const saveProps = async (props: any[]) => {
    return await client.mutate({
      mutation: ADD_SUBJECT_PROP_LIST,
      variables: { objects: props }
    });
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (partnerId) {
          const subject: any = {
            name: values.name,
            type_id: 7,
            status_id: values.status,
            info: values.info,
            address: values.address
          };

          values.extras?.filter((contact: any) => !!contact).map(({ email, phone }: any, index: number) => {
            const suffix = `${index > 0 ? index + 1 : ''}`;

            if (email && typeof email === 'string') {
              subject[`email${suffix}`] = email.trim();
            } else {
              subject[`email${suffix}`] = null;
            }

            if (phone) {
              subject[`phone${suffix}`] = phone;
            } else {
              subject[`phone${suffix}`] = null;
            }
          });

          for (let i = values.extras.length; i < 3; i++) {
            const suffix = `${i > 0 ? i + 1 : ''}`;

            subject[`email${suffix}`] = null;
            subject[`phone${suffix}`] = null;
          }

          updatePartner({
            variables: {
              subject,
              subjectId: partnerId,
              append: {
                doc: {
                  website: values.website,
                }
              }
            }
          });
        } else {
          const subject: any = {
            name: values.name,
            type_id: 7,
            status_id: values.status,
            full_name: null,
            info: values.info,
            address: values.address,
            created_by: session?.user.subject_id,
            doc: {
              website: values.website
            }
          };

          values.extras?.map(({ email, phone }: any, index: number) => {
            const suffix = `${index > 0 ? index + 1 : ''}`;
            subject[`email${suffix}`] = email;
            subject[`phone${suffix}`] = phone;
          });

          addPartner({
            variables: { subject }
          });
        }
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    setCurrentPartner(null);
    onClose();
  };

  const isShowManagerField = (types?: any) => {
    console.log(types);

    if (!userHaveRights(session?.user.user_id)) {
      return false;
    }

    return true;
    // if (types && types.length && !types.includes(27)) {
    //   return false;
    // }

    // return !!(!partnerId || (partnerId && session?.user.is_admin));
  };

  const onValuesChange = (changedValues: any) => {
    if ('types' in changedValues) {
      setShowManagerField(isShowManagerField(changedValues.types));
    }
  };

  const onAddNewContact = (id: number) => {
    form.setFieldsValue({
      contact: id,
    });

    setVisibleDrawer(false);
  };

  useEffect(() => {
    if (!visible) return;

    if (partnerId) {
      getPartner({
        variables: {
          where: {
            subject_id: { _eq: partnerId }
          }
        }
      });
    }
  }, [visible]);

  return (
    <Drawer
      title={partnerId ? 'Редактирование партнера' : 'Добавление партнера'}
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            loading={addingPartner || addingNewPartnerLinks || updatingPartner || updatingNewPartnerLinks}
            disabled={ addingPartner || addingNewPartnerLinks || updatingPartner || updatingNewPartnerLinks || enableNew }
            type="primary"
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="partner_add_form"
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{
          status: 1,
          types: [27],
          manager: !partnerId ? session?.user.subject_id : undefined
        }}
      >
        <Form.Item name="status" label="Статус">
          <PartnerStatusSelect
            style={{ width: 200 }}
            placeholder="Выберите статус"
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Наименование"
          rules={[{
            required: true,
            message: 'Для создания партнера необходимо заполнить наименование'
          }]}
        >
          <PartnerNameInput onBlur={onSearchPartner}
            placeholder="Введите наименование партнера"
          />
        </Form.Item>
        <Form.Item name="info" label="Информация о партнере">
          <Input
            placeholder="Укажите информацию о партнере"
          />
        </Form.Item>
        <Form.List
          name="extras"
          initialValue={[{email: '', phone: ''}]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row key={key} gutter={24} align="bottom">
                  <Col span={fields.length > 1 ? 11 : 12}>
                    <Form.Item
                      name={[name, 'email']}
                      label={index !== 0 ? 'Дополнительная почта' : 'Электронная почта'}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            const extras = getFieldValue('extras');

                            if (extras[name]) {
                              const phone = extras[name]?.phone;

                              if ((!phone?.match(/^\+7\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$/)) && (!value || value.toString().trim() === '')) {
                                return Promise.reject('Для создания партнера необходимо заполнить почту');
                              }
                            } else {
                              return Promise.reject('Для создания партнера необходимо заполнить почту');
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                      {...restField}
                    >
                      <Input placeholder="mail@mail.ru" />
                    </Form.Item>
                  </Col>
                  <Col span={fields.length > 1 ? 11 : 12}>
                    <Form.Item
                      name={[name, 'phone']}
                      label={index !== 0 ? 'Дополнительный телефон' : 'Номер телефона'}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            const extras = getFieldValue('extras');

                            if (extras[name]) {
                              const email = extras[name]?.email;

                              if ((!email || email.toString().trim() === '') && !value?.match(/^\+7\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$/)) {
                                return Promise.reject('Для создания партнера необходимо заполнить телефон');
                              }
                            } else {
                              return Promise.reject('Для создания партнера необходимо заполнить телефон');
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                      {...restField}
                    >
                      <PhoneInput placeholder="+7 (912) 345-78-90" />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 ? (
                    <Col span={2}>
                      <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>
              ))}
              {fields.length < 3 ? (
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                    Добавить почту и телефон
                  </Button>
                </Form.Item>
              ) : null}
            </>
          )}
        </Form.List>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="website"
              label="Сайт, vk, tg, inst"
              rules={[{
                required: true,
                message: 'Для создания партнера необходимо заполнить сайт, vk, tg, inst'
              }]}
            >
              <Input placeholder="Введите сайт партнера" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={
                <>
                  Город&nbsp;<span style={{ color: '#bfbfbf' }}>(Выбрать 1 из 8)</span>
                </>
              }
              rules={[{
                required: true,
                message: 'Для создания партнера необходимо выбрать город'
              }]}
            >
              <CitySelect autoComplete="nope" allowAdd={false} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="address"
          label="Адрес"
          rules={[{
            required: true,
            message: 'Для создания партнера необходимо заполнить адрес'
          }]}
        >
          <Input placeholder="Чебоксары, Чувашская республика" />
        </Form.Item>
        <Row gutter={24}>
          {showManagerField ? (
            <Col span={12}>
              <Form.Item name="manager" label="Менеджер">
                <SubjectSelect
                  typeBrief={['EMPLOYEE']}
                  placeholder="Выберите менеджера"
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={12}>
            <Form.Item name="types" label="Тип партнера">
              <OrganizationsTypeSelect
                mode="multiple"
                placeholder="Выберите тип партнера"
              />
            </Form.Item>
          </Col>
        </Row>
        {!partnerId ? (
          <>
            <Form.Item label="Контактное лицо">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="contact" noStyle>
                    <SubjectSelect
                      typeBrief={['CONTACT_PERSON']}
                      placeholder="Создайте контактное лицо"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={() => setVisibleDrawer(true)}
                    block
                  >
                    Создать контактное лицо
                  </Button>
                </Col>
              </Row>

              <ClientsDrawer
                visible={visibleDrawer}
                onClose={() => setVisibleDrawer(false)}
                onSave={onAddNewContact}
              />
            </Form.Item>
            <Form.Item name="area" label="Сфера деятельности">
              <PartnerAreaSelect
                placeholder="Выберите сферу деятельности"
              />
            </Form.Item>
            <Form.Item name="tags" label="Теги">
              <TagsSelect style={{ width: 200 }} />
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Drawer>
  );
};

export default PartnersDrawer;

import { useState, FC } from 'react';
import { message } from 'antd';
import { IMaskInput, IMask } from 'react-imask';

import styles from './PartnerEditableInput.module.css';

import { PartnerEditableInputProps } from './PartnerEditableInput.props';
import { useUpdateSubjectByPkMutation } from '~/apollo/generated/hooks';

const mask = IMask.createMask({
  mask: '+{7} (000) 000-00-00',
  lazy: true,
});

const PartnerEditableInput: FC<PartnerEditableInputProps> = ({
  defaultValue,
  column,
  subjectId,
  onChange = () => ({}),
  style = {}
}) => {
  const [ edit, setEdit] = useState(false);

  const [updateSubject, { loading }]= useUpdateSubjectByPkMutation({
    onCompleted: () => {
      setEdit(false);
      onChange();
    },
    onError: (err) => {
      setEdit(false);
      message.error('При обновлении произошла ошибка. Сообщение ошибки: ' + err, 10);
    }
  });

  const onSave = (e: any) => {
    const { value } = e.target;
    const subject: any = {};

    subject[column] = value;

    updateSubject({
      variables: {
        subjectId,
        subject,
      }
    });
  };

  const onKeyPress = (e: any) => {
    if(e.key === 'Enter') {
      onSave(e);
    }
  };

  return (
    <>
      {edit && !loading ? (
        <IMaskInput
          defaultValue={defaultValue}
          onBlur={() => setEdit(false)}
          onKeyPress={onKeyPress}
          autoFocus
          mask={mask}
          className="ant-input ant-input-sm"
          style={{
            width: 'auto',
            backgroundColor: '#fff',
            ...style
          }}
        />
      ) : (
        <div
          onClick={() => setEdit(true)}
          className={styles.text}
        >
          {defaultValue}
        </div>
      )}
    </>
  );
};

export default PartnerEditableInput;

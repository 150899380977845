import { useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Tag } from 'antd';
import { FileProtectOutlined } from '@ant-design/icons';
import { CellProps } from 'react-table';
import moment from 'moment';

import {
  OrderProps,
  PartnerPageOrdersTableProps,
  PartnerPageOrdersTableQueryProps
} from './PartnerPageOrdersTable.props';
import { useGetPartnerListQuery } from '~/apollo/generated/hooks';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../Table';
import { getStatusColor, roubleFormatter } from '~/utils';

const PartnerPageOrdersTable: FC<PartnerPageOrdersTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'archived',
        width: 40,
        Cell: ({ value }: CellProps<OrderProps, OrderProps['archived']>) => (
          <span>
            {value ? <FileProtectOutlined />: null}
          </span>
        ),
      },
      {
        Header: '№ Счета',
        accessor: 'invoice_number',
      },
      {
        Header: 'Дата счета',
        accessor: 'invoice_date',
        Cell: ({ value }: CellProps<OrderProps, OrderProps['invoice_date']>) => (
          <span>
            {value ? moment(value).format('DD.MM.YYYY') : value}
          </span>
        )
      },
      {
        Header: 'Сумма',
        accessor: 'order_sum',
        Cell: ({ value }: CellProps<OrderProps, OrderProps['order_sum']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: 'Расходы',
        accessor: 'order_expenses_fakt',
        width: 80,
        Cell: ({ value }: CellProps<OrderProps, OrderProps['order_expenses_fakt']>) => (
          <span>{roubleFormatter(value)}</span>
        )
      },
      {
        Header: 'Приб.',
        accessor: 'marzh_order_fakt',
        width: 120,
        Cell: ({ value, row }: CellProps<OrderProps, OrderProps['marzh_order_fakt']>) => (
          <span>
            {value ? (
              <>{roubleFormatter(value)} ({row.original.marzh_order_fakt_perc}%)</>
            ) : null}
          </span>
        )
      },
      {
        Header: 'Дата УПД',
        accessor: 'bill_date',
        Cell: ({ value }: CellProps<OrderProps, OrderProps['bill_date']>) => (
          <span>
            {value ? moment(value).format('DD.MM.YYYY') : value}
          </span>
        )
      },
      {
        Header: 'Сделка',
        accessor: 'order_id',
        Cell: ({ value }: CellProps<OrderProps, OrderProps['order_id']>) => (
          <span onClick={(e) => e.stopPropagation()}>
            <Link href={`/orders/${value}`}>
              <a>№{value}</a>
            </Link>
          </span>
        )
      },
      {
        Header: 'Статус',
        accessor: 'status',
        width: 95,
        Cell: ({ value }: CellProps<OrderProps, OrderProps['status']>) => {
          if (!value) return <span></span>;

          return (
            <span style={{ textOverflow: 'unset' }}>
              <Tag
                color={getStatusColor(value.name)}
                style={{
                  borderWidth: 0
                }}
              >
                { value.name }
              </Tag>
            </span>
          );
        },
      },
      {
        Header: 'Ответственный',
        accessor: 'deal_owner',
        Cell: ({ value }: CellProps<OrderProps, OrderProps['deal_owner']>) => {
          if (!value) return <span></span>;

          return (
            <span onClick={(e) => e.stopPropagation()}>
              {value ? (
                <Link href={`/employees/${value.subject_id}`}>
                  <a>{value.name || '' } {value.lastname || ''}</a>
                </Link>
              ) : null}
            </span>
          );
        },
      }
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const PartnerPageOrdersTableQuery: FC<PartnerPageOrdersTableQueryProps> = ({
  router,
}) => {
  const { id } = router.query;

  if (!id) return null;

  const { data } = useGetPartnerListQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        subject_id: { _eq: +id }
      },
      limit: 1
    }
  });

  if (!data) return null;

  const partner = (data && data.partner_list && data.partner_list[0]) || null;

  if (!partner) return null;

  return (
    <PartnerPageOrdersTable
      id="ID_PARTNER_PAGE_ORDERS_TABLE"
      data={partner.orders || []}
      pagination={false}
      getRowProps={(row) => ({
        onClick: () => window.open(`/orders/${row.original.order_id}`)
      })}
    />
  );
};

export default withRouter(PartnerPageOrdersTableQuery);

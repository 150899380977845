import { gql } from '@apollo/client';


export const ADD_DOCUMENT = gql`
  mutation addDocument($amount: numeric, $description: String, $docTypeId: Int, $firmId: bigint, $insUser: Int, $managerId: bigint, $number: String, $partnerId: bigint, $date: date, $orderId: bigint) {
    insert_t_doc(objects: {amount: $amount, description: $description, doc_type_id: $docTypeId, firm_id: $firmId, ins_user: $insUser, manager_id: $managerId, number: $number, partner_id: $partnerId, oper_date: $date, order_id: $orderId}) {
      returning {
        amount
        id: doc_id
        ins_date
        manager_id
        number
        oper_date
        partner_id
        order_id
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($docId: bigint, $set: t_doc_set_input) {
    update_t_doc(where: { doc_id: { _eq: $docId } }, _set: $set) {
      affected_rows
    }
  }
`;

export const HOLD_DOC = gql`
  mutation holdDoc($queryGuid: String!, $hold: Boolean) {
    update_t_doc_imports(
      where: { query_guid: { _eq: $queryGuid } }
      _set: { is_hold: $hold }
    ) {
      affected_rows
    }
  }
`;

export const SET_DOC_PARTNER = gql`
  mutation setDocPartner($queryGuid: String!, $partnerId: Int) {
    update_t_doc_imports(
      where: { query_guid: { _eq: $queryGuid } }
      _set: { partner_id: $partnerId }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_DOC = gql`
  mutation updateDoc($id: Int!, $set: t_doc_imports_set_input) {
    update_t_doc_imports_by_pk(
      pk_columns: { id: $id },
      _set: $set
    ) {
      id
    }
  }
`;

export const ADD_DOC_FTRAN = gql`
  mutation addDocFTran($orderId: Int, $amount: numeric, $operDate: date, $description: String) {
    ftran: insert_t_ftran_one(
      object: { amount: $amount, oper_date: $operDate, order_id: $orderId, description: $description }
    ) {
      ftran_id
    }
  }
`;

export const DELETE_DOC_FTRAN = gql`
  mutation deleteDocFTran($docId: Int!) {
    delete_t_doc_imports_by_pk(id: $docId) {
      id
    }
  }
`;


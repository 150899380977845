import { FC } from 'react';
import { Radio } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { PriorityRadioProps } from './PriorityRadio.props';

const PriorityRadio: FC<PriorityRadioProps> = ({
  buttonStyle = 'solid',
  ...props
}) => {
  const { data } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'ORDER_PRIORITY' }
        }
      }
    }
  });

  return (
    <Radio.Group
      buttonStyle={buttonStyle}
      {...props}
    >
      {data?.t_prop_value.map((p) => (
        <Radio.Button key={p.id} value={p.id}>
          {p.value}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default PriorityRadio;

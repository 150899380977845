import fetch from 'isomorphic-unfetch';

import { DEFAULT_USER_INFO_QUERY_FIELDS } from '~/apollo/fragments/users';

const USER_QUERY = `
  query($where: t_user_bool_exp) {
    user: t_user(where: $where, limit: 1) {
      ${DEFAULT_USER_INFO_QUERY_FIELDS}
    }
  }
`;

const findUserByEmail = async (email: string): Promise<any> => {
  try {
    let headers = {};

    if (process.env.HASURA_SECRET) {
      headers = {
        'x-hasura-admin-secret': process.env.HASURA_SECRET
      };
    }

    const response = await fetch(process.env.GRAPHQL_URL + '/v1/graphql', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: USER_QUERY,
        variables: {
          where: {
            email: { _eq: email },
            deleted: { _eq: false }
          }
        }
      })
    });

    const { data, errors } = await response.json();

    if (errors) {
      console.error(errors);
      return { error: errors };
    }

    const user = (data.user && data.user[0]) || null;

    return { user };
  } catch (error: any) {
    console.error(error);
    return { error: error.message };
  }
};

export default findUserByEmail;

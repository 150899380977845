import { FC } from 'react';
import { Modal, Form, message } from 'antd';

import { PartnerOrganizationAddModalProps } from './PartnerOrganizationAddModal.props';
import SubjectSelect from '../../SubjectSelect';
import { useAddSubjectLinkOneMutation } from '~/apollo/generated/hooks';

const PartnerOrganizationAddModal: FC<PartnerOrganizationAddModalProps> = ({
  partnerId,
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const [form] = Form.useForm();

  const [addLink, { loading }] = useAddSubjectLinkOneMutation({
    onCompleted: (data) => {
      if (!data.subject_link?.id) {
        message.error('При добавлении организации произошла ошибка');
      }

      message.success('Организация успешно добавлена');
      form.resetFields();
      onSave(data.subject_link?.id);
    },
    onError: (error) => {
      console.error(error);
      message.error('При добавлении организации произошла ошибка');
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        addLink({
          variables: {
            link: {
              subject_id: partnerId,
              object_id: values.organization,
              object_type_id: 3,
              link_type_id: 10,
            }
          }
        });
      })
      .catch(() => ({}));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Добавление организации"
      visible={visible}
      cancelText="Отмена"
      okText="Добавить"
      onCancel={onCancel}
      onOk={onSubmit}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        name="partner_organization_add_form"
      >
        <Form.Item
          name="organization"
          rules={[{ required: true, message: 'Обязательное поле!' }]}
        >
          <SubjectSelect
            placeholder="Выберите организацию"
            typeBrief={['ORGANIZATION']}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PartnerOrganizationAddModal;

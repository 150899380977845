import { gql } from '@apollo/client';
import {
  CALENDAR_TASK_INFO,
  CHECK_LIST_TASK_INFO,
  CURRENT_TASK_INFO,
  SHIPMENT_REQUEST_INFO,
  TASK_FOR_TASK_LIST_PAGE_INFO,
  TASK_INFO_DEFAULT
} from '../fragments/tasks';

export const GET_TASK_LIST = gql`
  ${TASK_INFO_DEFAULT}
  query getTaskList($type: String, $status: String, $limit: Int, $offset: Int, $id: bigint, $subjectId: Int, $ownerId: Int, $objectId: bigint, $objectTypeBrief: String, $orderBy: [t_task_order_by!] = [{task_id: desc}]) {
    t_task(where: {task_type: {brief: {_eq: $type}}, status: {name: {_eq: $status}}, task_id: {_eq: $id}, deleted: {_eq: false}, subject_id: {_eq: $subjectId}, owner_id: {_eq: $ownerId}, object_id: {_eq: $objectId}, object_type: {brief: {_eq: $objectTypeBrief}}}, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...taskInfoDefault
    }
  }
`;

export const GET_TASKS_COUNT_BY_ORDER_ID = gql`
  query getTasksCountByOrderId($orderId: bigint, $taskTypeBrief: String, $objectTypeBrief: String) {
    t_task_aggregate(where: {
      object_id: {_eq: $orderId},
      task_type: {brief: {_eq: $taskTypeBrief}},
      object_type: {brief: {_eq: $objectTypeBrief}}
    }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TASKS_BY_ORDER_ID = gql`
  query getTasksByOrderId($orderId: bigint, $taskTypeBrief: String, $objectTypeBrief: String, $offset: Int, $limit: Int) {
    t_task(where: {
      object_id: {_eq: $orderId},
      task_type: {brief: {_eq: $taskTypeBrief}},
      object_type: {brief: {_eq: $objectTypeBrief}}
    }, limit: $limit, offset: $offset) {
      task_id
      id: task_id
      ins_date
      deleted
      deadline
      deadline_string
      date_start
      date_start_string
      manager_id
      document: doc(path: "document")
      subject {
        lastname
        name
      }
      description
      status_id,
      status {
        name
      }
      selected_shipment_task {
        task_id
        status {
          name
        }
      }
      task_props {
        bigint_val
        datetime_val
        decimal_val
        int_val
        object_id
        prop_id
        prop_value_id
        text_val
        prop {
          brief
          name
        }
        prop_value {
          value
          brief
        }
      }
    }
  }
`;

export const ADD_TASK_FUNC = gql`
  query addTaskFunc($params: json) {
    add_task_wrapper(args: {params: $params}) {
      task_id
    }
  }
`;

export const GET_COUNT_TASK = gql`
  query getCountTask($type: String, $status: String) {
    t_task_aggregate(where: {task_type: {brief: {_eq: $type}}, status: {name: {_eq: $status}}, deleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SHIPMENT_REQUEST_LIST = gql`
  ${SHIPMENT_REQUEST_INFO}
  query getShipmentRequestList($taskId: bigint, $limit: Int, $offset: Int) {
    t_task(where: {object_task_links: {task: {task_id: {_eq: $taskId}}, object_type: {brief: {_eq: "TASK"}}, link_type: {brief: {_eq: "SHIPMENT_REQUEST"}}}, deleted: {_eq: false}}, limit: $limit, offset: $offset) {
      ...shipmentRequestInfo
    }
  }
`;

export const GET_COUNT_SHIPMENT_REQUEST_LIST = gql`
  query getCountShipmentRequestList($taskId: bigint) {
    t_task_aggregate(where: {object_task_links: {task: {task_id: {_eq: $taskId}}, object_type: {brief: {_eq: "TASK"}}, link_type: {brief: {_eq: "SHIPMENT_REQUEST"}}}, deleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_REQUEST_LIST_BY_PROVIDER = gql`
  ${SHIPMENT_REQUEST_INFO}
  query getRequestListByProvider($subjectId: Int, $taskId: Int) {
    get_request_by_provider(args: {subid: $subjectId, tskid: $taskId}, where: {deleted: {_eq: false}}) {
      ...shipmentRequestInfo
    }
  }
`;

export const GET_CURRENT_TASK = gql`
  ${CURRENT_TASK_INFO}
  query getCurrentTask($taskId: bigint) {
    t_task(where: {
      task_id: {_eq: $taskId},
      deleted: {_eq: false}
    }) {
      ...currentTaskInfo
    }
  }
`;

export const GET_TASK_FOR_TASK_LIST_PAGE = gql`
  ${TASK_FOR_TASK_LIST_PAGE_INFO}
  query getTaskForTaskListPage($where: t_task_bool_exp, $offset: Int, $limit: Int, $orderBy: [t_task_order_by!] = [{task_id: desc}]) {
    t_task(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...taskForTaskListPageInfo
    }
  }
`;

export const GET_CHECK_LIST = gql`
  ${CHECK_LIST_TASK_INFO}
  query getCheckList($parentId: bigint, $orderBy: [t_task_order_by!] = [{task_id: asc}]) {
    t_task(where: {parent_id: {_eq: $parentId}, deleted: {_eq: false}, task_type: {brief: {_eq: "CHECK_LIST"}}}, order_by: $orderBy) {
      ...checkListTaskInfo
    }
  }
`;

export const GET_TASK_LIST_DASHBOARD= gql`
  ${TASK_FOR_TASK_LIST_PAGE_INFO}
  query getTaskListDashboard($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{ task_id: desc }], $limit: Int, $offset: Int) {
    t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...taskForTaskListPageInfo
    }
  }
`;

export const GET_TASK_COUNT = gql`
  query getTaskCount($where: t_task_bool_exp ) {
    t_task_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TASK_LIST_ORGANIZATION = gql`
  ${TASK_INFO_DEFAULT}
  query getTaskListOrganization($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{ task_id: asc }], $limit: Int, $offset: Int) {
    t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...taskInfoDefault
    }
  }
`;

export const GET_TASK_LIST_CALENDAR = gql`
  ${CALENDAR_TASK_INFO}
  query getTaskListCalendar($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{ task_id: asc }], $limit: Int, $offset: Int) {
    t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...calendarTaskInfo
    }
  }
`;

export const GET_TASK_LIST_PARTNER = gql`
  ${TASK_INFO_DEFAULT}
  query getTaskListPartner($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{ task_id: asc }], $limit: Int, $offset: Int) {
    task_list: t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...taskInfoDefault
    }
  }
`;

export const GET_TASK_LIST_CONTACT = gql`
  ${TASK_INFO_DEFAULT}
  query getTaskListContact($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{ task_id: asc }], $limit: Int, $offset: Int) {
    task_list: t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      ...taskInfoDefault
    }
  }
`;

export const GET_TASK_ORDER = gql`
  query getTaskOrder($orderId: Int) {
    t_order(where: {
      order_id: { _eq: $orderId },
      deleted: { _eq: false }
    }) {
      customer_id
      customer {
        lastname
        email
        name
        phone
        subject_id
        id: subject_id
        surname
        dolznost: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}) {
          text_val
          prop_id
          prop {
            name
            brief
          }
          prop_value {
            brief
            value
            prop_value_id
          }
        }
      }
      firm {
        email
        lastname
        name
        subject_id
        phone
        contact_list: subject_links(where: {subject_object: {deleted: {_eq: false}}}) {
          subject_object {
            email
            lastname
            name
            phone
            subject_id
            id: subject_id
          }
        }
      }
      status {
        name
        order_status_id
      }
      type {
        brief
        name
      }
      format_ins_date
      firm_id
      manager_id
      manager {
        email
        lastname
        name
        phone
        subject_id
        surname
      }
    }
  }
`;

export const GET_TASK_OBJECT = gql`
  query getTaskObject($taskId: bigint!) {
    task: t_task_by_pk(task_id: $taskId) {
      id: task_id
      task_id
      name
      description
    }
  }
`;

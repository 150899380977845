import { withRouter } from 'next/router';
import { Descriptions } from 'antd';
import { useGetOrderDocTotalsQuery } from '~/apollo/generated/hooks';

import { OrderPageCostsTotalProps } from './OrderPageCostsTotal.props';

const OrderPageCostsTotal = ({ router }: OrderPageCostsTotalProps) => {
  const { id } = router.query;

  const { data } = useGetOrderDocTotalsQuery({
    variables: {
      orderId: id
    }
  });

  return (
    <Descriptions
      style={{ backgroundColor: '#FFF' }}
      column={4}
      bordered
      size="small"
      layout="vertical"
    >
      <Descriptions.Item label="Кол-во документов">{data?.data[0].totals.count}</Descriptions.Item>
      <Descriptions.Item label="Сумма документов, руб">{data?.data[0].totals.doc_amount}</Descriptions.Item>
      <Descriptions.Item label="Привязано к заказу, руб">{data?.data[0].totals.ft_amount}</Descriptions.Item>
    </Descriptions>
  );
};

export default withRouter(OrderPageCostsTotal);

import { FC } from 'react';
import { Select } from 'antd';

import { useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { ADD_PROP } from '~/apollo/mutations/prop_values';
import { TagsSelectProps } from './TagsSelect.props';

const TagsSelect: FC<TagsSelectProps> = ({
  value = undefined,
  defaultValue = undefined,
  mode = 'tags',
  size = 'middle',
  placeholder = 'Выберите теги',
  defaultActiveFirstOption = false,
  allowClear = true,
  maxTagCount = 'responsive',
  onChange = () => ({}),
  ...props
}) => {
  const { data, loading, client } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'TAGS' }
        }
      }
    }
  });

  const addProp = async (value: string) => {
    const { data } = await client.mutate({
      mutation: ADD_PROP,
      variables: {
        value: {
          value,
          prop_id: 12,
        }
      },
    });

    await client.refetchQueries({
      include: ['getPropValueList']
    });

    return data.value;
  };

  const onChangeTags = async (tags: any, option: any) => {
    const res: any[] = [];

    await Promise.all(tags.map(async (tag: string) => {
      const searchTag = tag && tag.trim();
      const findTag = data?.t_prop_value.find(el => searchTag.toLowerCase() === el.value?.toLowerCase());

      if (findTag) {
        res.push(findTag.value);
      } else {
        const newTag = await addProp(searchTag);

        if (newTag) {
          res.push(newTag.value);
        }
      }
    }));

    onChange(res, option);
  };

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      defaultActiveFirstOption={defaultActiveFirstOption}
      allowClear={allowClear}
      maxTagCount={maxTagCount}
      loading={loading}
      mode={mode}
      size={size}
      onChange={onChangeTags}
      {...props}
    >
      {data?.t_prop_value.map((tag) => (
        <Select.Option key={tag.id} value={tag.value || '-'}>
          { tag.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TagsSelect;

import { useState, FC, useEffect } from 'react';
import { Modal, Row, Col, DatePicker, Input } from 'antd';
import { Row as RowProps } from 'react-table';

import { useUpdateDocumentMutation, useUpdateSubjectByPkMutation,  } from '~/apollo/generated/hooks';
import {TDocumentProps, TOrderProps} from '~/interfaces';
import { PaymentsOrdersModalProps, FilterProps } from './PaymentsOrdersModal.props';
import ContractorDocOrdersModalTable from './PaymentsOrdersModalTable/ContractorDocOrdersModalTable';
import { SubjectSelect } from '~/components';

const { Search } = Input;

const PaymentsOrdersModal: FC<PaymentsOrdersModalProps> = ({
  visible,
  doc = null,
  refetchDocs = () => ({}),
  onOk = () => ({}),
  onCancel = () => ({}),
  hide =() => ({})
}) => {
  const [disabled, setDisabled] = useState(false);
  const archived = false;
  const [filter, setFilter] = useState<FilterProps>({});

  const onFilter = (newFilter: FilterProps) => {
    setFilter({
      ...filter,
      ...newFilter,
    });
  };

  const [setDocMutation] = useUpdateDocumentMutation({
    onCompleted: () => {
      // refetchDocs();
      onOk();
    },
    refetchQueries: ['getDocumentList', 'getCountDocuments'],
  });

  const [updateSubject] = useUpdateSubjectByPkMutation({
    // refetchQueries: ['getDocRows', 'getDocsList'],
    onCompleted: () => {
      // onOk();
    }
  })

  const updateDoc = (row: RowProps<TOrderProps>) => {
    if (!doc || disabled) return;

    if (doc.partner){

      if (doc?.partner?.subject_id !== row.original.customer_id) {
        alert('Невозможно привязать платеж к заказу другого партнера');
        return;
      }
    } else {
      // надо еще обновить партнера
      console.log('updating partner guid', doc.partner_guid);
      updateSubject({
        variables:{
          subjectId:  row.original.customer_id!,
          subject: {
            guid: doc?.partner_guid
          }
        },
      });
    }

    setDocMutation({
      variables: {
        docId: doc.id,
        // doc_rest_expenses: row.original.doc_rest_expenses
        set: {
          order_id: row.original.order_id,
          partner_id: row.original.customer_id,
        }
      }
    });
  }

  // const [addFTranMutation, { loading: adding }] = useAddDocFTranMutation({
  //   onCompleted: (data) => {
  //     if (!doc) return;
  //
  //     setFTranMutation({
  //       variables: {
  //         id: doc.id,
  //         set: {
  //           ftran_id: data.ftran?.ftran_id
  //         }
  //       }
  //     });
  //   }
  // });

  // const upda = (row: RowProps<TOrderProps>) => {
  //   if (!doc || disabled) return;
  //
  //   console.log('>>>>>>>>>>doc', doc);
  //
  //   // 1132 и 2681 (смотри комментарий)
  //   const allowPartnerId = [75565, 79287, 80091, 80092, 79395, 80166];
  //
  //   console.log('selected order is', row.original);
  //   updateDoc({
  //     // variables: {
  //     //   orderId: row.original.order_id,
  //     //   operDate: doc?.date || new Date(),
  //     //   amount: doc?.amount,
  //     //   // description: (doc.partner_id == 79287)? doc?.maket : doc?.product // 79287 НАША КО (Дизайнеры) таска 1132
  //     //   description: (doc.partner_id && allowPartnerId.includes(doc.partner_id)) ? doc?.maket : doc?.product
  //     // },
  //   });
  // };
//
  useEffect(() => {
    if (!visible) setDisabled(false);
  }, [visible, disabled]);

  return (
    <Modal
      title={`Привязать сделку к платежу ${archived ? '(aрхив)' : ''}`}
      visible={visible}
      width={1200}
      onOk={() => {
        setDisabled(false);
        setFilter({});
        onOk();
      }}
      onCancel={() => {
        setDisabled(false);
        setFilter({});
        onCancel();
      }}
      destroyOnClose={true}
    >
      <Row align="middle" gutter={[12, 0]}>
        <Col style={{ marginBottom: 12 }}>
          <SubjectSelect
            style={{ width: 180 }}
            typeBrief={['ORGANIZATION', 'PARTNER', 'PROVIDER']}
            placeholder="Партнер"
            onChange={(partnerId) => onFilter({ partnerId })}
          />
        </Col>
        <Col style={{ marginBottom: 12 }}>
          <Search
            style={{ width: 250 }}
            allowClear
            placeholder="Номер сделки/счета/УПД"
            onSearch={(search) => onFilter({ search })}
          />
        </Col>
        <Col style={{ marginBottom: 12 }}>
          <DatePicker
            placeholder="Дата с .."
            name="dateFrom"
            onChange={(dateFrom) => onFilter({ dateFrom })}
          />
        </Col>
        <Col style={{ marginBottom: 12 }}>
          <DatePicker
            placeholder="Дата по ..."
            name="dateTo"
            onChange={(dateTo) => onFilter({ dateTo })}
          />
        </Col>
        <Col style={{ marginBottom: 12 }}>
          <SubjectSelect
            style={{ width: 180 }}
            typeBrief={['EMPLOYEE']}
            placeholder="Добавил"
            onChange={(insSubjectId) => onFilter({ insSubjectId })}
          />
        </Col>
        {/* <Col style={{ marginBottom: 12 }}>
          <Select
            style={{ width: 270 }}
            placeholder="Статус"
            allowClear={true}
            mode="multiple"
            onChange={(statusId) => onFilter({ statusId })}
          >
            <Select.Option value={1}>
              Запрос
            </Select.Option>
            <Select.Option value={2}>
              В работе
            </Select.Option>
            <Select.Option value={3}>
              Отгружен
            </Select.Option>
            <Select.Option value={4}>
              Закрыт
            </Select.Option>
          </Select>
        </Col> */}
        {/* <Col style={{ marginBottom: 12 }}>
          <Checkbox
            checked={filter.archived}
            onChange={(e) => onFilter({ archived: e.target.checked || false })}
          >
            Показать архивные
          </Checkbox>
        </Col> */}
      </Row>

      <div style={{ marginBottom: 12  }}>
        <Row gutter={[12, 0]}>
          <Col>
            <SubjectSelect
              style={{ width: 500}}
              typeBrief={['EMPLOYEE']}
              placeholder="Менеджеры"
              onChange={(managerIds) => onFilter({ managerIds })}
              mode="multiple"
            />
          </Col>
          <Col>
            <SubjectSelect
              style={{ width: 180}}
              typeBrief={['EMPLOYEE']}
              placeholder="Ответственный"
              onChange={(owner) => onFilter({ owner })}
            />
          </Col>
        </Row>
      </div>

      <ContractorDocOrdersModalTable
        filter={filter}
        getRowProps={(row) => ({
          onClick: () => {
            if (disabled) return;

            setDisabled(true);
            updateDoc(row);
            hide();
            setFilter({});
          }
        })}
      />
    </Modal>
  );
};

export default PaymentsOrdersModal;

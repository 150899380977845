import { gql } from '@apollo/client';

export const ORDER_TASK_INFO = gql`
  fragment orderTaskInfo on t_order {
    customer_id
    customer {
      lastname
      email
      name
      phone
      subject_id
      id: subject_id
      surname
      dolznost: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}) {
        text_val
        prop_id
        prop {
          name
          brief
        }
        prop_value {
          brief
          value
          prop_value_id
        }
      }
    }
    firm {
      email
      lastname
      name
      subject_id
      phone
    }
    status {
      name
      order_status_id
    }
    type {
      brief
      name
    }
    format_ins_date
    firm_id
    manager_id
    manager {
      email
      lastname
      name
      phone
      subject_id
      surname
    }
  }
`;

export const DEFAULT_ORDER_INFO = gql`
  fragment defaultOrderInfo on t_order {
    id: order_id
    order_id
    comment
    description
    deleted
    manager_id
    order_sum
    manager {
      name
      lastname
      surname
    }
    ins_date
    status_id
    number
    cart_id
    cart {
      cart_id
      user_id
      checkouted
    }
    format_ins_date
    orderline_aggregate {
      aggregate {
        sum {
          price
        }
      }
    }
    orderline {
      price
      order_id
      comment
      article
      quantity
      product_id
      deleted
      id: orderline_id
      orderline_id
    }
    status {
      name
      id: order_status_id
      order_status_id
    }
    customer {
      id: subject_id
      subject_id
      lastname
      name
    }
    marzh_order_fakt
    order_expenses_fakt
  }
`;

export const DEFAULT_CURRENT_ORDER_INFO = gql`
  fragment defaultCurrentOrderInfo on t_order {
    id: order_id
    archived
    deal_owner {
      name
      lastname
    }
    deal_owner_id
    invoice_date
    invoice_number
    bill_number
    bill_date
    marzh_order_fakt
    order_expenses_fakt
    marzh_order_fakt_perc
    order_id
    number
    manager_id
    comment
    description
    format_ins_date
    order_sum
    purchase_amount
    cost_amount_order
    marzh
    marzh_percent
    status_id
    status {
      name
      id: order_status_id
      order_status_id
    }
    order_type {
      brief
      order_type_id
      id: order_type_id
      name
    }
    order_type_id
    order_quarter
    manager {
      name
      lastname
      surname
    }
    customer_id
    customer {
      id: subject_id
      subject_id
      lastname
      name
      phone
      email
        manager: subject_links(where: {t_link_type: {brief: {_eq: "PARTNER_MANAGER"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {deleted: {_eq: false}}}, limit: 1) {
      id: subject_link_id
      subject: subject_object {
        id: subject_id
        subject_id
        lastname
        name
        avatar
      }
    }
    }
    firm_id
    firm {
      id: subject_id
      subject_id
      lastname
      name
    }
    orderline {
      amount
    }
    orderline_aggregate {
      aggregate {
        sum {
          purchase_price
          fixed_cost
        }
      }
    }
    contact_list: object_subject_links(where: {t_link_type: {brief: {_eq: "CONTACT_PERSON"}}, t_object_type: {brief: {_eq: "ORDER"}}}, order_by: {subject_id: asc}) {
      id: subject_link_id
      subject_link_id
      subject_id
      subject: t_subject {
        id: subject_id
        name
        lastname
        post: doc(path: "post")
        phone
        email
      }
    }
  }
`;

import { FC, useEffect } from 'react';
import { withRouter } from 'next/router';
import { PageHeader, Button, Form, Checkbox } from 'antd';

import { ContractorDocsPageHeaderProps } from './ContractorDocsPageHeader.props';
import { SubjectSelect } from '~/components';

const ContractorDocsPageHeader: FC<ContractorDocsPageHeaderProps> = ({ router }) => {
  const [form] = Form.useForm();
  const partner = router.query.partner ? +router.query.partner : undefined;
  const archived = router.query.archived?.toString() || null;

  const onFilter = (queryName: string, value?: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{ [queryName]: value || undefined },
        page: 1,
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      partner,
      archived: archived === 'yes'
    });
  }, [partner, archived]);

  return (
    <PageHeader
      title="Таблицы Подрядчиков"
      ghost={false}
      style={{ marginBottom: 16 }}
      extra={
        <Button
          type="link"
          onClick={() => window.open('http://89.223.125.194:5516/')}
        >
          Загрузить новый документ
        </Button>
      }
    >
      <Form
        form={form}
        layout="inline"
        style={{ marginBottom: -12 }}
      >
        <Form.Item style={{ marginBottom: 12 }} name="partner">
          <SubjectSelect
            style={{ width: 180 }}
            typeBrief={['ORGANIZATION', 'PARTNER']}
            placeholder="Подрядчик"
            nullOption={{ label: 'Без подрядчика', value: -1 }}
            onChange={(partner) => onFilter('partner', partner)}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }} name="archived" valuePropName="checked">
          <Checkbox
            onChange={(e) => onFilter('archived', e.target.checked ? 'yes' : 'no')}
          >
            Показать архивные
          </Checkbox>
        </Form.Item>
      </Form>
    </PageHeader>
  );
};

export default withRouter(ContractorDocsPageHeader);

import { useState, FC } from 'react';
import { useSession } from 'next-auth/react';
import { ApolloClient } from '@apollo/client';
import { Drawer, Button, Form, Row, Col, Input, DatePicker, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useAddOrderOneMutation, useGetPropValueListQuery } from '~/apollo/generated/hooks';
import { ADD_ORDER_ONE_PROP } from '~/apollo/mutations/orders';
import { CHECK_ORDER_BY_INVOICE_NUMBER_AND_YEAR } from '~/apollo/queries/orders';
import { ADD_SUBJECT_LINK_LIST } from '~/apollo/mutations/subject_links';
import { OrdersAddDrawerProps, TPropProps, TSubjectLinkProps } from './OrdersAddDrawer.props';
import { SubjectSelect } from '~/components';
import ContactSelect from './ContactSelect/ContactSelect';
import PriorityRadio from './PriorityRadio/PriorityRadio';
import StatusSelect from './StatusSelect/StatusSelect';
import PartnersDrawer from '../../partners/PartnersDrawer';

const { TextArea } = Input;

const OrdersAddDrawer: FC<OrdersAddDrawerProps> = ({
  visible = false,
  onClose = () => ({}),
  onSave = () => ({})
}) => {
  const { data: session } = useSession();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  // приоритеты задач не приходят
  const priority = 1;

  const { data: priorityList } = useGetPropValueListQuery({
    variables: {
      where: {
        prop: {
          brief: { _eq: 'ORDER_PRIORITY' }
        }
      }
    }
  });

  const validateNumber = (value: any) => {
    if (value && !value.match(/^(ИТ|ТЕ|ЭС)\d{1,4}$/)) {
      return Promise.reject('Неверный формат');
    }

    return Promise.resolve();
  };

  const savePropList = async (
    client: ApolloClient<any>,
    propList: TPropProps[]
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_ORDER_ONE_PROP,
        variables: {
          propList
        }
      });

      return { data };
    } catch (error: any) {
      return { error: error.message };
    }
  };

  const addSubjectLinkList = async (
    client: ApolloClient<any>,
    linkList: TSubjectLinkProps[]
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_SUBJECT_LINK_LIST,
        variables: {
          linkList
        }
      });

      return { data };
    } catch (error: any) {
      console.error(error);
      return { error: error.message };
    }
  };

  const [addOrder, { loading: loadingAddOrder, client }] = useAddOrderOneMutation({
    onCompleted: async (data) => {
      setLoading(true);

      if (form.getFieldValue('priority') || priority) {
        const propList: TPropProps[] = [{
          order_id: data.order?.id,
          prop_id: priorityList?.t_prop_value[0]?.prop_id || null,
          prop_value_id: form.getFieldValue('priority') || priority
        }];

        const { error } = await savePropList(client, propList);

        if (error) {
          setLoading(false);
          message.error(`
            При добавлении заказа проищошла ошибка. Сообщение ошибки: ${error.message}
          `, 10);
          return;
        }
      }

      if (form.getFieldValue('contacts') && form.getFieldValue('contacts').length) {
        const linkList: TSubjectLinkProps[] = [];
        for (const contactId of form.getFieldValue('contacts')) {
          const link = {
            subject_id: contactId,
            object_type_id: 2,
            link_type_id: 1,
            object_id: data.order?.id
          };

          linkList.push(link);
        }

        if (linkList.length) {
          const { error } = await addSubjectLinkList(client, linkList);

          if (error) {
            setLoading(false);
            message.error(`
              При добавлении заказа проищошла ошибка. Сообщение ошибки: ${error.message}
            `, 10);
            return;
          }
        }
      }

      setLoading(false);
      message.success({ content: 'Заказ добавлен', key: 'updatable', duration: 2 });
      form.resetFields();
      client.refetchQueries({
        include: ['getOrderList']
      });
      onSave({ id: data.order?.id });
    },
    onError: (error) => {
      message.error(`
        При добавлении заказа проищошла ошибка. Сообщение ошибки: ${error.message}
      `, 10);
    }
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {

        const invoiceNumber = values.invoice_number;
        const year = values.invoice_date ? Number(values.invoice_date.format('YYYY')) : null;

        if (invoiceNumber && year) {
          const { data } = await client.query({
            query: CHECK_ORDER_BY_INVOICE_NUMBER_AND_YEAR,
            variables: {
              invoiceNumber,
              year,
            },
          });

          if (data.order[0]?.result?.if_exists === true) {
            message.error('Такой Счёт уже существует', 10);
            return;
          }
        }
        addOrder({
          variables: {
            order: {
              status_id: values.status,
              deal_owner_id: values.deal_owner,
              order_type_id: 1,
              delivery_date: null,
              customer_id: values.customer,
              description: values.description,
              ins_date: 'now()',
              invoice_date: values.invoice_date ? values.invoice_date.format('DD.MM.YYYY') : values.invoice_date,
              invoice_number: values.invoice_number,
              user_id: session?.user.id
            }
          }
        });
      })
      .catch((error) => {
        message.error(`
          При добавлении заказа проищошла ошибка. Сообщение ошибки: ${error.message}
        `, 10);
      });
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onAddNewPartner = (id?: any) => {
    form.setFieldsValue({
      customer: id,
    });

    setVisibleDrawer(false);
  };

  return (
    <Drawer
      title="Добавление заказа"
      width={720}
      onClose={onCancel}
      visible={visible}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button onClick={onSubmit} disabled={loading || loadingAddOrder} loading={loading || loadingAddOrder} type="primary">
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="order_add_form"
        initialValues={{
          status: 1,
          deal_owner: session?.user.subject_id
        }}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Дата счета" name="invoice_date">
              <DatePicker
                style={{ width: '100%' }}
                format="DD.MM.YYYY"
                placeholder="__.__.____"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Номер счета" name="invoice_number"
                       rules={[{ validator: (_, value) => validateNumber(value) },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Партнер">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="customer" noStyle>
                    <SubjectSelect
                      typeBrief={['PARTNER']}
                      placeholder="Выберите партнера"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() => setVisibleDrawer(true)}
                    icon={<PlusCircleOutlined />}
                    block
                  >
                    Добавить нового
                  </Button>
                </Col>
              </Row>

              <PartnersDrawer
                visible={visibleDrawer}
                onClose={() => setVisibleDrawer(false)}
                onSave={onAddNewPartner}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Приоритет" name="priority">
              <PriorityRadio />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Статус" name="status">
              <StatusSelect />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={
            (prevValues, currentValues) => prevValues.customer !== currentValues.customer
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('customer') ? (
              <Form.Item label="Контакты" name="contacts">
                <ContactSelect customer={getFieldValue('customer')} />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item label="Ответственный по сделке" name="deal_owner">
          <SubjectSelect
            typeBrief={['EMPLOYEE']}
            placeholder="Выберите отвественного"
          />
        </Form.Item>

        <Form.Item label="Описание" name="description">
          <TextArea
            placeholder="Введите описание"
            rows={6}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default OrdersAddDrawer;

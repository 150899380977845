import { useMemo, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { CellProps } from 'react-table';
import { Tag, Tooltip } from 'antd';

import {
  ContactPageTasksTableProps,
  ContactPageTasksTableQueryProps,
} from './ContactPageTasksTable.props';
import { useGetTaskCountQuery, useGetTaskListOrganizationQuery } from '~/apollo/generated/hooks';
import { TTaskProps } from '~/interfaces';
import { useAppContext } from '~/contexts/AppContext';
import Table from '../../Table';
import TaskPriorityIcon from '../../TaskPriorityIcon';

const ContactPageTasksTable: FC<ContactPageTasksTableProps> = ({
  data,
  ...props
}) => {
  const { state } = useAppContext();

  const localTableSettings = state.tables.find((table) => table.id === props.id);

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'id',
        width: 70,
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['id']>) => (
          <span>
            <Link href={`/tasks/${value}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
      },
      {
        Header: 'Описание',
        accessor: 'description',
        Cell: ({ value, row }: CellProps<TTaskProps, TTaskProps['description']>) => (
          <span>
            <Link href={`/tasks/${row.original.id}`}>
              <a>{value}</a>
            </Link>
          </span>
        ),
      },
      {
        Header: 'Дата создания',
        accessor: 'ins_date',
        Cell: ({ row }: CellProps<TTaskProps, TTaskProps['ins_date']>) => (
          <span>{row.original.ins_date_string || ''}</span>
        ),
        sorter: true,
      },
      {
        Header: 'Срок выполнения',
        accessor: 'deadline',
        sorter: true,
      },
      {
        Header: 'Ответственный',
        accessor: 'subject_owner',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['subject_owner']>) => (
          <span>
            {value ? (
              <Link href={`/employees/${value.id}`}>
                <a>{ value.name || '' } {value.lastname || '' }</a>
              </Link>
            ) : null }
          </span>
        )
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['status']>) => (
          <span>
            <Tag color="blue">{value?.name || '' }</Tag>
          </span>
        ),
        sorter: true,
      },
      {
        Header: 'Тип задачи',
        accessor: 'tip_zadachi',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['tip_zadachi']>) => (
          <span>
            {value.map((type) => (
              <Tooltip
                key={type.id}
                title={type.prop_value?.value}
              >
                {type.prop_value ? (
                  <TaskPriorityIcon priority={type.prop_value.brief} />
                ) : null}
              </Tooltip>
            ))}
          </span>
        )
      },
      {
        Header: 'Приоритет',
        accessor: 'task_priority',
        Cell: ({ value }: CellProps<TTaskProps, TTaskProps['task_priority']>) => (
          <span>{value?.name || ''}</span>
        )
      }
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      initialHiddenColumns={localTableSettings?.hiddenColumns}
      initialColumnOrder={localTableSettings?.columnOrder}
      {...props}
    />
  );
};

const ContactPageTasksTableQuery: FC<ContactPageTasksTableQueryProps> = ({
  router
}) => {
  const contactId = router.query.id;
  const page = router.query.page ? +router.query.page : 1;
  const sort = router.query.sort?.toString().split(',');
  const limit = 10;

  let orderBy: any[] = [];

  if (sort) {
    orderBy = sort.map((s) => {
      const [ key, value ] = s.split('+');

      if (key === 'status') {
        return { status: { name: value } };
      }

      return { [key]: value };
    });
  }

  const { data: dataTasks } = useGetTaskListOrganizationQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        task_type: {
          brief: { _eq: 'TASK' }
        },
        _or: [
          {
            object_id: { _eq: contactId },
            object_type: {
              brief: { _eq: 'SUBJECT' }
            }
          },
          {
            task_links: {
              link_type: {
                brief: { _eq: 'CONTACT_PERSON' }
              },
              object_type: {
                brief: { _eq: 'SUBJECT' }
              },
              object_id: { _eq: contactId ? +contactId : undefined}
            }
          }
        ]
      },
      limit,
      offset: (page - 1) * limit,
      orderBy,
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: dataCount } = useGetTaskCountQuery({
    variables: {
      where: {
        deleted: { _eq: false },
        object_id: { _eq: contactId },
        object_type: {
          brief: { _eq: 'SUBJECT' }
        },
        task_type: {
          brief: { _eq: 'TASK' }
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const onTableChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <ContactPageTasksTable
      id="ID_CONTACT_PAGE_TASKS_TABLE"
      data={dataTasks?.t_task || []}
      pagination={{
        current: page,
        total: (dataCount && dataCount.t_task_aggregate.aggregate?.count) || 0,
        pageSize: limit,
      }}
      onPaginationChange={onTableChange}
      onSorterChange={onSorterChange}
      initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
    />
  );
};

export default withRouter(ContactPageTasksTableQuery);

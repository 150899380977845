import { useState, FC } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Button, Space, message } from 'antd';
import { Row } from 'react-table';
import { useApolloClient } from '@apollo/client';

import { OrdersPageTableProps } from './OrdersPageTable.props';
import { Order_By, TOrderProps } from '~/interfaces';
import { useAddOrdersToArchiveMutation, useGetOrderListQuery } from '~/apollo/generated/hooks';
import { GET_ORDER_LIST } from '~/apollo/queries/orders';
import OrdersTable from '../OrdersTable';
import getQuarterInterval from '~/utils/getQuarterInterval';
import { userHaveAdmins } from '~/utils';

const checkOrders = (orders?: TOrderProps[]) => {
  let errors = {
    status: false,
    quarter: false,
    fields: false,
  };

  orders?.map((order) => {
    if (order.status_id === 2 || order.status_id === 3) {
      errors = {
        ...errors,
        status: true
      };
    }

    if (!order.order_quarter && order.status_id === 4) {
      errors = {
        ...errors,
        quarter: true
      };
    }

    if (order.status_id === 4) {
      const { bill_date, bill_number, invoice_number, invoice_date } = order;

      if (!bill_date || !bill_number || !invoice_number || !invoice_date) {
        errors = {
          ...errors,
          fields: true
        };
      }
    }
  });

  return errors;
};

const OrdersPageTable: FC<OrdersPageTableProps> = ({
  router,
  ...props
}) => {
  const client = useApolloClient();
  const { data: session } = useSession();
  const contractor = router.query.contractor ? +router.query.contractor : undefined;
  const search = router.query.search?.toString();
  const dateFrom = router.query.dateFrom ? router.query.dateFrom : undefined;
  const dateTo = router.query.dateTo ? router.query.dateTo : undefined;
  const added = router.query.added ? +router.query.added : undefined;
  const statuses = router.query.statuses
    ? router.query.statuses.toString()?.split(',')
    : undefined;
  const owner = router.query.owner ? +router.query.owner : undefined;
  const archive = router.query.archive?.toString() || null;
  const archived = router.query.archived?.toString() || null;
  const page = router.query.page ? +router.query.page : 1;
  const quarters = router.query.quarters
    ? router.query.quarters.toString()?.split(',')
    : undefined;
  const sort = router.query.sort?.toString().split(',');

  const managers = !router.query.managers
    ? [session?.user.subject_id]
    : router.query.managers && router.query.managers === 'all'
      ? undefined
      : router.query.managers.toString().split(',');
  const city = router.query.city ? +router.query.city : undefined;

  const limit = 40;

  const getVariables = () => {
    let where: any = {}, orderBy: any[] = [];

    if (search) {
      const newSearch = search.toUpperCase();
      const onlyNumber = newSearch.replace(/\D/g, '');
      const _or: any = [
        { invoice_number: { _like: `%${newSearch}%` } },
        { bill_number: { _like: `%${newSearch}%` } },
        { docs: { doc_type_id: {_eq: 3}, num_order: {_like: `%${newSearch}%`} } },
        { docs: { doc_type_id: {_eq: 4}, number: {_like: `%${newSearch}%`} } },
      ];

      if (onlyNumber && onlyNumber !== '') {
        _or.push({
          order_id: { _eq: onlyNumber }
        });
      }

      where = {
        ...where,
        _or
      };
    }

    if (quarters && quarters.length && quarters[0]) {
      const dateQuarters = quarters.map((quarter) => {
        return getQuarterInterval(quarter) ? { "order_quarter" : getQuarterInterval(quarter) } : null;
      }).filter((quarter) => !!quarter);

      const _and = where._or ? [{ _or: where._or }, { _or : dateQuarters }] : [{ _or: dateQuarters }];

      where = {
        ...where,
        _and
      };
    }

    if (owner) {
      where = {
        ...where,
        deal_owner_id: { _eq: owner },
      };
    }

    if (contractor) {
      where = {
        ...where,
        customer_id: { _eq: contractor },
      };
    }

    if (dateFrom) {
      where = {
        ...where,
        docs: {
          oper_date: {
            _gte: dateFrom, 
          },
        }
      };
    }

    if (dateTo) {
      where = {
        ...where,
        docs: {
          oper_date: {
            ...where.docs?.oper_date,
            _lte: dateTo,
          },
        }
      };
    }

    if (dateFrom || dateTo) {
      where = {
        ...where,
        docs: {
          ...where.docs,
          doc_type_id: {
            _eq: 4
          }
        }
      };
    }

    if (added) {
      where = {
        user_created: { subject_id: { _eq: added } },
        ...where,
      };
    }

    if (managers && managers.length > 0) {
      where = {
        ...where,
        customer: {
          subject_links: {
            object_id: { _in: managers },
            link_type_id: { _eq: 11 },
          },
        },
      };
    }

    if (city) {
      where = {
        ...where,
        customer: {
          props: {
            prop_value_id: { _eq: city },
          },
        },
      };
    }

    if (sort) {
      orderBy = sort.map((s) => {
        const [key, value] = s.split('+');

        if (key === 'customer') {
          return { customer: { name: value }, invoice_date: "asc" };
          // return { customer: { name: value }};
        }

        if (key === 'order_sum') {
          return { orderline_actual_aggregate: { sum: { amount: value } } };
        }

        if (key === 'status') {
          return { status: { order_status_id: value }, invoice_date: "asc" };
        }

        if (key === 'deal_owner') {
          return { deal_owner: { lastname: value }, invoice_date: "asc" };
        }

        return { [key]: value };
      });
    } else {
      orderBy = [{ order_id: Order_By.Desc }];
    }

    orderBy = Object.assign({}, ...orderBy);

    if (statuses && statuses.length > 0) {
      where = {
        ...where,
        status_id: { _in: statuses.map((s) => parseInt(s, 10)) }
      };
    }

    if (archived === 'yes' && archive !== 'yes') {
      where = {
        ...where,
        archived: { _in: [true, false] }
      };
    }

    return {
      offset: (page - 1) * limit,
      limit,
      orderBy,
      where: {
        deleted: { _eq: false },
        archived: { _eq: archive === 'yes' },
        ...where,
      },
    };
  };

  const { data } = useGetOrderListQuery({
    variables: getVariables()
  });
  console.log('data = ', data);

  const [addToArchive, { loading: archiving }] = useAddOrdersToArchiveMutation({
    onCompleted: () => {
      router.reload();
    }
  });

  const onPaginationChange = (pagination: any) => {
    router.replace({
      query: {
        ...router.query,
        ...{
          page: (pagination && pagination.current) || 1
        }
      }
    });
  };

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

  const onChange = (ids: string[]) => {
    setSelectedOrders(ids);
  };

  const archiveOrderList = async () => {
    if (!selectedOrders.length) return;

    const variables = getVariables();
    const ids = selectedOrders.map((id) => +id);

    const { data: ordersData, errors: ordersErrors } = await client.query({
      query: GET_ORDER_LIST,
      variables: {
        ...variables,
        where: {
          ...variables.where,
          order_id: {
            "_in": ids
          }
        }
      },
      fetchPolicy: 'network-only',
    });

    if (ordersErrors) {
      console.log('произошла ошибка');
      return;
    }

    if (ordersData?.order_list.length) {
      const errors = checkOrders(ordersData.order_list);

      const userHaveAdmin = userHaveAdmins(session?.user.user_id);

      // в архив заказ могут отправить только администраторы
      if (!userHaveAdmin) {
        return message.error('В архив заказ могут отправить только администраторы!');
      }

      addToArchive({
        variables: {
          orderIds: selectedOrders.map((id) => +id)
        }
      });
    }
  };

  const onSorterChange = (sorter: any) => {
    router.replace({
      query: {
        ...router.query,
        sort: sorter.map((s: any) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
      }
    });
  };

  return (
    <>
      {selectedOrders.length > 0 ? (
        <Space size={16} style={{ padding: '0 8px', marginBottom: 16 }}>
          <Button onClick={() => router.reload()} type="primary">Очистить</Button>
          <span>Выделено {selectedOrders.length} строк</span>
        </Space>
      ) : null}

      <OrdersTable
        id="ID_ORDERS_PAGE_TABLE"
        data={data?.order_list || []}
        pagination={{
          current: page,
          total: (data && data.order_list_count.aggregate?.count) || 0,
          pageSize: limit,
          showTop: true,
        }}
        onPaginationChange={onPaginationChange}
        getRowProps={(row: Row<TOrderProps>) => ({
          onClick: () => window.open(`/orders/${row.original.id}`)
        })}
        rowSelection={{ onChange }}
        getRowId={(row: TOrderProps, relativeIndex, parent) =>
          parent ? [parent.id, relativeIndex].join('.') : row.id.toString()
        }
        onSorterChange={onSorterChange}
        initialSortBy={sort?.map((s) => ({ id: s.split('+')[0], desc: s.split('+')[1] === 'desc' }))}
        {...props}
      />

      {selectedOrders.length > 0 ? (
        <Space size={16} style={{ padding: '0 24px 24px' }}>
          <Link href={`/api/order-report/?ids=${JSON.stringify(selectedOrders)}`}>
            <a target="_blank">Сформировать отчет</a>
          </Link>
          <Link href={`/api/order-opis/?ids=${JSON.stringify(selectedOrders)}`}>
            <a target="_blank">Сформировать опись</a>
          </Link>
          <Button
            type="link"
            onClick={archiveOrderList}
            disabled={archiving}
            loading={archiving}
          >
            В архив
          </Button>
        </Space>
      ) : null}
    </>
  );
};

export default withRouter(OrdersPageTable);
